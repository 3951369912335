/* eslint-disable import/prefer-default-export */
import { StyleSheet, Font } from '@react-pdf/renderer';
import AlexBrush from '../../../assets/AlexBrush-Regular.ttf';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src:
        'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src:
        'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: 'Alex Brush',
  src: AlexBrush,
});
export const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Open Sans',
  },
  author: {
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 700,
  },
  authorTitle: {
    fontSize: 9,
    textAlign: 'center',
    fontWeight: 700,
    textDecoration: 'underline',
    fontFamily: 'Open Sans',
  },
  subtitle: {
    fontSize: 11,
    fontWeight: 800,
    fontFamily: 'Open Sans',
  },
  subtitle2: {
    fontSize: 9,
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },

  text: {
    fontSize: 9,
    fontFamily: 'Open Sans',
    paddingBottom: 5,
  },
  text2: {
    fontSize: 9,
    fontFamily: 'Open Sans',
    paddingBottom: 10,
  },
  text3: {
    fontSize: 9,
    fontFamily: 'Open Sans',
    paddingBottom: 20,
  },
  crossout: { textDecoration: 'line-through' },
  image: {
    marginHorizontal: 'auto',
  },
  header: {
    marginHorizontal: 'auto',
    marginBottom: '15',
    width: '121.50px',
    height: '51.35px',
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
  },
  checkboxDivShort: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  checkboxDivIndent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 60,
  },
  checkboxDivIndentSmall: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 40,
  },
  bulletDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 15,
  },
  bulletDivWebpage: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 30,
  },
  link: {
    color: '#1155cc',
    textDecoration: 'underline',
    fontSize: 9,
    fontWeight: 400,
    paddingBottom: 10,
  },
  bulletSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bulletContainer: { width: '48%' },
  listText: { fontSize: 9, fontFamily: 'Open Sans' },

  checkbox: { width: 10, height: 10 },
  smallcheckbox: { width: 6, height: 6 },
  bullet: { width: 4, height: 4, marginTop: 4.5 },
  whiteBulletList: { width: 5, height: 5, marginTop: 4.5 },
  specialBullet: { width: 6, height: 6, marginTop: 0.5 },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});
