import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider, Text } from 'colabs-ui-react';
import { useSelector } from 'react-redux';
import NotificationTypes from './NotificationTypes';
import { limitString } from '../../utils/helper';

const StyledNotificationItem = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  grid-gap: 0.5rem;
  &.read {
    background-color: #f5f5f5;
  }
  > .thumbnail {
    border-radius: 0.313rem;
    width: 4.7rem;
    height: 4.7rem;
    object-fit: contain;
    border: 1px solid #d3d3d3;
  }
  > .content {
    flex-grow: 1;
    justify-content: center;
  }
  > .dot {
    height: 0.625rem;
    width: 0.625rem;
    background-color: #1171ff;
    border-radius: 0.625rem;
  }
`;

const NotificationItem = ({ onClick, notification }) => {
  const usersOrganizationId = useSelector(
    (state) => state.user.organization_id
  );
  return (
    <>
      <Divider />
      <StyledNotificationItem
        className={notification.read_at ? 'read' : ''}
        onClick={() => onClick(notification.id, notification.url)}
        onKeyDown={() => false}
        role="button"
        tabIndex={0}
      >
        <img className="thumbnail" src={notification.thumbnail} alt="" />
        <div className="content">
          {usersOrganizationId === 1 && notification.organization_name && (
            <Text weight="medium">{notification.organization_name}</Text>
          )}
          {NotificationTypes[notification.type].copyline({
            ...notification,
            resource: limitString(notification.resource, 40),
          })}
          <Text style={{ color: '#41B5FC' }}>
            {new Date(notification.created_at).toLocaleDateString('en-EN', {
              month: 'long',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </Text>
        </div>
        {!notification.read_at && <span className="dot" />}
      </StyledNotificationItem>
    </>
  );
};

NotificationItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  notification: PropTypes.shape().isRequired,
};

export default NotificationItem;
