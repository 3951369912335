import * as Popover from '@radix-ui/react-popover';
import React from 'react';
import { Plus, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import companies from '../../assets/companies.json';
import blueImage from '../../assets/images/BlueImage.png';
import { getDisplayName } from '../../utils/helper';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 36rem;
  .title {
    margin: 0;
    margin-bottom: 0.25rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #3b82f6;
  }
  .subtitle {
    margin: 0;
    margin-bottom: 1.5rem;
  }
  .input-container {
    display: flex;
    gap: 0.25rem;
    padding: 0.375rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    align-items: center;
    width: 100%;
    input {
      width: 100%;
      padding: 0.5rem;
      border: none;
      outline: none;
    }
    .company-info {
      display: flex;
      align-items: center;
      background-color: #bfdbfe;
      padding: 0.25rem;
      border-radius: 0.375rem;
      border: 1px solid #d1d5db;
      img {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        border-radius: 0.25rem;
      }
      button {
        margin-left: 0.25rem;
        padding: 0.25rem;
        font-size: 0.75rem;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }
    .add-button {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background-color: #3b82f6;
      color: #fff;
      padding: 0.75rem 1.25rem 0.75rem 0.75rem;
      border-radius: 0.375rem;
      white-space: nowrap;
      border: none;
      cursor: pointer;
      &.hidden {
        display: none;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .popover-content {
    margin-top: 0.25rem;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: var(--radix-popper-anchor-width);
    .search-result {
      display: flex;
      align-items: center;
      padding: 0.375rem;
      text-align: left;
      border-radius: 0.375rem;
      width: 100%;
      border: none;
      background-color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #f3f4f6;
      }
      img {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        border-radius: 0.25rem;
      }
      span {
        flex-grow: 1;
      }
      .add-result {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        background-color: #bfdbfe;
        color: #1e40af;
        padding: 0.5rem 0.5rem;
        border-radius: 0.375rem;
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  .form-section {
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    label {
      display: block;
      margin-top: 0.5rem;
      font-size: 0.875rem;
    }
    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #d1d5db;
      border-radius: 0.375rem;
      margin-top: 0.25rem;
    }
  }
  .next-button {
    display: block;
    margin-top: 1rem;
    padding: 0.75rem 1.25rem;
    background-color: #3b82f6;
    color: #fff;
    border-radius: 0.375rem;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    &:disabled {
      opacity: 0.6;
      filter: saturate(25%);
    }
  }
`;

const Step = () => {
  const user = useSelector((state) => state.user);
  const [requestStatus, setRequestStatus] = React.useState('start');
  const [inputValues, setInputValues] = React.useState({
    companyName: '',
    agencyName: user.organization.name,
    accountManager: getDisplayName(user),
  });
  const [searchBarTempValue, setSearchBarTempValue] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const { axiosWithAuth } = useAxioswithAuth();
  const { push } = useHistory();

  const handleSearch = React.useCallback((value) => {
    if (!value) {
      setSearchResults([]);
      setPopoverOpen(false);
      return;
    }
    const lowercaseValue = value.toLowerCase();
    const results = [];
    let count = 0;
    let i = 0;
    while (count < 5 && i < companies.length) {
      const company = companies[i];
      if (company.lowercase_name.includes(lowercaseValue)) {
        results.push(company);
        count += 1;
      }
      i += 1;
    }
    setSearchResults(results);
    setPopoverOpen(results.length > 0);
  }, []);

  const debounceSearch = React.useCallback(
    debounce((value) => {
      handleSearch(value);
    }, 500),
    []
  );

  const handleSetCompany = (company) => {
    setInputValues((prev) => ({
      ...prev,
      companyName: company,
    }));
    setSearchBarTempValue('');
    setPopoverOpen(false);
  };

  const handleTypeInSearchBar = (event) => {
    const { value } = event.target;
    setSearchBarTempValue(value);
    if (value.length < 3) {
      setSearchResults([]);
      setPopoverOpen(false);
      return;
    }
    debounceSearch(value);
  };

  const createNewOrganization = async (name) => {
    if (!name) return;
    setRequestStatus('loading');
    try {
      const { data } = await axiosWithAuth().post('/new-organizations', {
        name,
        type: 'non-agency',
      });
      setRequestStatus('success');
      push(`/agency/wizard/${data.organization.id}`);
    } catch (e) {
      if (
        e.response?.status === 409 &&
        e.response?.data?.message === 'you already have this client'
      ) {
        alert(`You already have ${name} as a client`);
        return;
      }
      if (
        e.response?.status === 409 &&
        e.response?.data?.message === 'this organization already exists'
      ) {
        alert(
          `This organization already has an account. If you're their agency, please contact support to get access.`
        );
        return;
      }
      alert('Error creating organization. Please contact support');
      setRequestStatus('error');
    }
  };

  return (
    <Wrapper className="step-wrapper">
      <h1 className="title">Add a Client</h1>
      <p className="subtitle">Add a brand that needs videos:</p>
      <Popover.Root open={popoverOpen}>
        <Popover.Anchor asChild>
          <div className="input-container">
            {!inputValues.companyName ? (
              <input
                type="text"
                value={searchBarTempValue}
                onChange={handleTypeInSearchBar}
                placeholder="Enter Company Name"
              />
            ) : (
              <div className="company-info">
                <img src={blueImage} alt="company logo" />
                <span>{inputValues.companyName}</span>
                <button
                  className="clear-button"
                  type="button"
                  onClick={() =>
                    setInputValues((prev) => ({ ...prev, companyName: '' }))
                  }
                >
                  <X size={16} strokeWidth={1} />
                </button>
              </div>
            )}
            <button
              type="button"
              onClick={() => handleSetCompany(searchBarTempValue)}
              className={`add-button ${
                inputValues.companyName ? 'hidden' : 'visible'
              }`}
            >
              <Plus />
              Add New Client
            </button>
          </div>
        </Popover.Anchor>
        <Popover.Content
          align="start"
          onPointerDownOutside={() => setPopoverOpen(false)}
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="popover-content"
        >
          {searchResults.length > 0 ? (
            searchResults.map((company) => (
              <button
                type="button"
                key={company.name}
                onClick={() => handleSetCompany(company.name)}
                className="search-result"
              >
                <img src={blueImage} alt={`${company.name} logo`} />
                <span>{company.name}</span>
                <div className="add-result">
                  <Plus />
                  Click to Add
                </div>
              </button>
            ))
          ) : (
            <div>No results found</div>
          )}
        </Popover.Content>
      </Popover.Root>
      <div className="form-section">
        <label htmlFor="agencyName">
          Agency Name:
          <input
            id="agencyName"
            value={inputValues.agencyName}
            onChange={(e) => {
              e.persist();
              setInputValues((prev) => ({
                ...prev,
                agencyName: e?.target?.value,
              }));
            }}
          />
        </label>
        <label htmlFor="accountManager">
          Point of Contact:
          <input
            id="accountManager"
            value={inputValues.accountManager}
            onChange={(e) => {
              e.persist();
              setInputValues((prev) => ({
                ...prev,
                accountManager: e.target.value,
              }));
            }}
          />
        </label>
      </div>
      <button
        id="cta-add-client"
        type="button"
        disabled={!inputValues.companyName || requestStatus === 'loading'}
        onClick={() => createNewOrganization(inputValues.companyName)}
        className="next-button"
      >
        Next
      </button>
    </Wrapper>
  );
};

export default Step;
