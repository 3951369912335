import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from 'colabs-ui-react';
import React from 'react';
import { Plus } from 'react-feather';
import styled from 'styled-components';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
  border-radius: 0.5rem;
  width: fit-content;
  :focus,
  :active {
    outline: none;
  }
  > .want-this {
    width: 100%;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 10rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const Separator = styled(DropdownMenu.Separator)`
  border-top: 1px solid #dfdfdf;
`;

const AllDecksMenu = ({ setNewDeck, setNewReference }) => {
  return (
    <DropdownMenu.Root>
      <Trigger className="new-deck-menu">
        <Button icon={<Plus />} variant="secondary" />
      </Trigger>
      <DropdownMenu.Portal>
        <Content side="right" align="start" sideOffset={4}>
          <Item
            onClick={() =>
              setNewDeck({
                mode: 'create',
                id: null,
                name: '',
                organization_id: null,
                starter_deck_id: undefined,
                isModalOpen: true,
              })
            }
          >
            New Deck
          </Item>
          <Separator />
          <Item
            onClick={() =>
              setNewReference({
                mode: 'create',
                id: null,
                deck_id: null,
                url: '',
                isModalOpen: true,
              })
            }
          >
            New Reference
          </Item>
          <Arrow />
        </Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default AllDecksMenu;
