import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: clamp(2rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  width: calc(100% - 1.5rem);
  max-width: 45rem;
  text-align: center;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
  > .description {
    margin: 0;
  }
  > .meetings-iframe-container {
    max-height: calc(100vh - 4rem);
    height: 42rem;
    width: 100%;
    margin: 0;
    border: none;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
  width: fit-content;
  height: fit-content;
  outline: none;
  &.corner-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
`;

const FinishedMeetingModal = ({ meetingModalType, setMeetingModalType }) => {
  const user = useSelector((state) => state.user);
  const types = {
    REVIEW_MEETING: {
      link: 'https://meetings.hubspot.com/sean-goldfaden/script-review-meeting-with-sean',
      title: 'Scripts Now in Progress! 🎉',
      description:
        "Sit back and relax. We'll have them ready for our next meeting.",
    },
    APPROVAL_MEETING: {
      link: 'https://meetings.hubspot.com/alex-feldman1/script-approval-meeting-with-alex',
      allowClose: true,
    },
  };

  return (
    <Dialog.Root
      open={meetingModalType}
      onOpenChange={
        types[meetingModalType]?.allowClose
          ? (open) => setMeetingModalType(open)
          : null
      }
    >
      <Dialog.Portal>
        <Overlay />
        <Content onClick={(e) => e.stopPropagation()}>
          <Dialog.Title className="title">
            {types[meetingModalType]?.title}
          </Dialog.Title>
          <Dialog.Description className="description">
            {types[meetingModalType]?.description}
          </Dialog.Description>
          <iframe
            title="script-review-meeting-embedding"
            className="meetings-iframe-container"
            src={`${
              types[meetingModalType]?.link
            }?embed=true&${new URLSearchParams(
              user.organization_id === 1
                ? {}
                : {
                    firstname: user?.first_name || '',
                    lastname: user?.last_name || '',
                    email: user?.email || '',
                  }
            )
              .toString()
              .replaceAll('+', '%20')}`}
          />
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default FinishedMeetingModal;
