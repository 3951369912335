/* eslint-disable no-param-reassign */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { Button } from 'colabs-ui-react';
import { ChevronLeft } from 'react-feather';

import {
  fullPodWeeks,
  fullVideoWeeks,
  numberWithCommas,
  fullLandingPageWeeks,
  validatePackages,
  fullPodAndVideoPreProduction,
  fullPodAndVideoProduction,
  fullPodAndVideoPostProduction,
} from '../utils/helper';

const PdfUpdateStyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 970px;
  max-width: 900px;
  overflow: auto;
  padding-right: 20px;
  padding-top: 23px;
  width: 100%;
  text-align: center;
  .center {
    text-align: center;
  }
`;

const PdfUpdateForm = ({
  handlePdfStateChange,
  PdfStateRef,
  proposal,
  setPdfState,
  setRandomKey,
  pdfState,
  getFormattedDate,
  handlePdfStateVideoChange,
  handlePdfStateFullPodChange,
  handleMultiPackageChange,
  pdfCurrentStateKey,
  setActiveStep,
  setChargesUploaded,
  setallComplete,
  setBlobContentLoading,
  setChangeCount,
}) => {
  const paymentDiscount = (100 - PdfStateRef.current.payment_discount) * 0.01;

  const pageBreakFunction = (e, sectionTitle) => {
    if (!e.target.checked) {
      const index = PdfStateRef.current.pageBreaks.indexOf(sectionTitle);
      if (index > -1) {
        PdfStateRef.current.pageBreaks.splice(index, 1);
      }
    } else {
      PdfStateRef.current.pageBreaks.push(sectionTitle);
    }
  };

  const [refreshKeys, setRefreshKeys] = React.useState({
    descSynopsisKey: 12,
    preLaunchKey: 139,
    Sprint: 1083,
  });

  return (
    <PdfUpdateStyledDiv>
      <div style={{ position: 'relative', marginBottom: '15px' }}>
        <Button
          variant="tertiary"
          icon={<ChevronLeft />}
          style={{ position: 'absolute', bottom: 0, padding: 0 }}
          text="Back to Proposal Inputs"
          onClick={() => {
            setActiveStep(2);
            setChargesUploaded(false);
            setallComplete(false);
          }}
        />
      </div>
      <input
        name="line1"
        onChange={handlePdfStateChange}
        defaultValue={PdfStateRef.current.line1}
        className="center"
      />
      <input
        name="line2"
        onChange={handlePdfStateChange}
        defaultValue={PdfStateRef.current.line2}
        className="center"
      />
      <input
        name="line3"
        onChange={handlePdfStateChange}
        defaultValue={PdfStateRef.current.line3}
        className="center"
      />
      <div>
        <input
          name="line4"
          onChange={handlePdfStateChange}
          defaultValue={proposal.organization_name}
          className="center"
          style={{ width: '250px' }}
        />
        <br />
        &nbsp;{' '}
        <input
          name="line5"
          onChange={handlePdfStateChange}
          defaultValue={PdfStateRef.current.line5}
          style={{ width: '150px' }}
          className="center"
        />
        &nbsp;
        <input
          name="phase"
          onChange={handlePdfStateChange}
          defaultValue={proposal.phase}
          style={{ width: '150px' }}
          className="center"
        />
        &nbsp;
        <input
          name="line6"
          onChange={handlePdfStateChange}
          defaultValue={PdfStateRef.current.line6}
          style={{ width: '150px' }}
          className="center"
        />
        <br />
        <input
          name="contract_date"
          onChange={handlePdfStateChange}
          defaultValue={proposal.contract_date}
          style={{ width: '100px' }}
          className="center"
        />
      </div>
      <br />
      <h2 style={{ textAlign: 'left' }}>TERMINOLOGY</h2>
      <textarea
        name="line7"
        onChange={handlePdfStateChange}
        defaultValue={PdfStateRef.current.line7}
        style={{ minHeight: '85px' }}
      />

      <h2 style={{ textAlign: 'left' }}>DESCRIPTION OF SERVICE</h2>
      {/* Full Pod */}
      {proposal?.packages.length &&
        proposal?.packages?.every((e) => e.name.includes('Full Pod')) && (
          <>
            <textarea
              name="descriptionOfServices"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descriptionOfServices
              }
              style={{ minHeight: '85px' }}
            />
            <input
              name="descNote"
              onChange={handlePdfStateFullPodChange}
              defaultValue={PdfStateRef.current[pdfCurrentStateKey].descNote}
            />
            <input
              name="descSynopsisIntro"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descSynopsisIntro
              }
            />
            <h3 style={{ textAlign: 'start' }}>Bullet Points:</h3>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.descSynopsisKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].descSynopsis.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <textarea
                      style={{ minHeight: '40px', width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].descSynopsis[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn.replace(/,/g, '')}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].descSynopsis.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].descSynopsis.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <input
              name="projectStartDate"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].projectStartDate
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <h2 style={{ textAlign: 'left' }}>DELIVERABLES </h2>
                <label
                  htmlFor={`page-break-section-DELIVERABLES`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`page-break-section-DELIVERABLES`}
                    defaultChecked={PdfStateRef.current.pageBreaks.includes(
                      'Deliverables'
                    )}
                    onChange={(e) => {
                      return pageBreakFunction(e, 'Deliverables');
                    }}
                  />
                  Page Break
                </label>
              </div>
              <input
                name="deliverablesDescription"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey]
                    .deliverablesDescription
                }
              />
              <h3 style={{ textAlign: 'start' }}>Pre-Launch:</h3>
              <input
                name="preLaunchTitle"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].preLaunchTitle
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.preLaunchKey}
              >
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[0][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[0].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}
                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
                <br />
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[1][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[1].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}

                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
              </div>
              <input
                name="sprintLength"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].sprintLength
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.Sprint}
              >
                {PdfStateRef.current[pdfCurrentStateKey].podWeeks.map(
                  (section, ind) => {
                    let pageBreak = false;

                    return (
                      <>
                        <div style={{ width: '100%', display: 'flex' }}>
                          <h3>
                            Section {ind + 1}{' '}
                            {Array.isArray(section.title)
                              ? section.title.join(',').replace(/,/g, ' + ')
                              : section.title}
                          </h3>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{
                              alignSelf: 'center',
                            }}
                            onClick={() => {
                              PdfStateRef.current[
                                pdfCurrentStateKey
                              ].podWeeks.splice(ind, 1);
                              setRefreshKeys({
                                ...refreshKeys,
                                Sprint: refreshKeys.Sprint + 1,
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                          <label
                            htmlFor={`page-break-section-${ind + 1}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '5px',
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`page-break-section-${ind + 1}`}
                              defaultChecked={section.pageBreak}
                              onChange={(e) => {
                                pageBreak = e.target.checked;
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].pageBreak = pageBreak;
                              }}
                            />
                            Page Break
                          </label>
                        </div>
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0]
                          }
                        />
                        {section.bulletPoints[0].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}
                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[0].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                        <br />
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1]
                          }
                        />
                        {section.bulletPoints[1].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn.replace(/,/g, '')}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}

                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[1].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      </>
                    );
                  }
                )}
              </div>
              <button
                type="button"
                onClick={() => {
                  const n =
                    4 *
                      PdfStateRef.current[pdfCurrentStateKey].podWeeks.length +
                    1;
                  PdfStateRef.current[pdfCurrentStateKey].podWeeks.push(
                    fullPodWeeks(
                      n,
                      [`Week ${n} & ${n + 1}`, `Week ${n + 2} & ${n + 3}`],
                      false,
                      2
                    )
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    Sprint: refreshKeys.Sprint + 1,
                  });
                }}
              >
                Add Section
              </button>
            </div>
          </>
        )}
      {/* Video section */}
      {proposal?.packages.length &&
        proposal?.packages?.every((e) => e.name.includes('Video')) && (
          <>
            <textarea
              name="descriptionOfServices"
              onChange={handlePdfStateVideoChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descriptionOfServices
              }
              style={{ minHeight: '85px' }}
            />
            <input
              name="descNote"
              onChange={handlePdfStateVideoChange}
              defaultValue={PdfStateRef.current[pdfCurrentStateKey].descNote}
            />
            <br />
            <input
              name="preProductionTitle"
              onChange={handlePdfStateVideoChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].preProductionTitle
              }
            />
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.preLaunchKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].preProduction.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].preProduction[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].preProduction.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}
              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].preProduction.push(
                    ''
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
              <br />
              <input
                name="productionTitle"
                onChange={handlePdfStateVideoChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].postProductionTitle
                }
              />
              {PdfStateRef.current[pdfCurrentStateKey].production.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].production[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].production.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].production.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
              <br />
              <input
                name="productionTitle"
                onChange={handlePdfStateVideoChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].productionTitle
                }
              />
              {PdfStateRef.current[pdfCurrentStateKey].postProduction.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].postProduction[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].postProduction.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].postProduction.push(
                    ''
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.Sprint}
            >
              {PdfStateRef.current[pdfCurrentStateKey].podWeeks.map(
                (section, ind) => {
                  let pageBreak = false;
                  console.log(section, '<=videoSection');

                  return (
                    <>
                      <div style={{ width: '100%', display: 'flex' }}>
                        <h3>
                          Section {ind + 1}{' '}
                          {Array.isArray(section.title)
                            ? section.title.join(',').replace(/,/g, ' + ')
                            : section.title}
                        </h3>
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          style={{
                            alignSelf: 'center',
                          }}
                          onClick={() => {
                            PdfStateRef.current[
                              pdfCurrentStateKey
                            ].podWeeks.splice(ind, 1);
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                        <label
                          htmlFor={`page-break-section-${ind + 1}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '5px',
                          }}
                        >
                          <input
                            type="checkbox"
                            id={`page-break-section-${ind + 1}`}
                            defaultChecked={section.pageBreak}
                            onChange={(e) => {
                              pageBreak = e.target.checked;
                              PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                                ind
                              ].pageBreak = pageBreak;
                            }}
                          />
                          Page Break
                        </label>
                      </div>
                      <input
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                            ind
                          ].title[0] = e.target.value;
                        }}
                        defaultValue={
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[ind]
                            .title[0]
                        }
                      />
                      <input
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                            ind
                          ].sectionTitle = e.target.value;
                        }}
                        defaultValue={
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[ind]
                            .sectionTitle
                        }
                      />
                      {section.bulletPoints[0].map((syn, index) => (
                        <div style={{ width: '100%', display: 'flex' }}>
                          <textarea
                            style={{ minHeight: '40px', width: '100%' }}
                            key={syn}
                            onChange={(e) => {
                              PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                                ind
                              ].bulletPoints[0][index] = e.target.value;
                            }}
                            defaultValue={syn}
                          />
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{
                              alignSelf: 'center',
                            }}
                            onClick={() => {
                              PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                                ind
                              ].bulletPoints[0].splice(index, 1);
                              setRefreshKeys({
                                ...refreshKeys,
                                Sprint: refreshKeys.Sprint + 1,
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </div>
                      ))}
                      <IconButton
                        style={{ width: 'fit-content' }}
                        onClick={() => {
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                            ind
                          ].bulletPoints[0].push('');
                          setRefreshKeys({
                            ...refreshKeys,
                            Sprint: refreshKeys.Sprint + 1,
                          });
                        }}
                      >
                        <ControlPointIcon />
                      </IconButton>
                      <br />

                      {section.bulletPoints[1] && (
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1]
                          }
                        />
                      )}
                      {section.bulletPoints[1] &&
                        section.bulletPoints[1].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn.replace(/,/g, '')}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}

                      {section.bulletPoints[1] && (
                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[1].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      )}
                    </>
                  );
                }
              )}
            </div>
            <button
              type="button"
              onClick={() => {
                const n =
                  2 * PdfStateRef.current[pdfCurrentStateKey].podWeeks.length +
                  1;
                PdfStateRef.current[pdfCurrentStateKey].podWeeks.push(
                  fullVideoWeeks(
                    [`Week ${n} & ${n + 1}`],
                    false,
                    1,
                    'Post Production',
                    ['a', 'b', 'c']
                  )
                );
                setRefreshKeys({
                  ...refreshKeys,
                  Sprint: refreshKeys.Sprint + 1,
                });
              }}
            >
              Add Section
            </button>
          </>
        )}
      {/* Landing Page Section */}
      {proposal?.packages.length &&
        proposal?.packages?.every((e) => e.name.includes('Landing Page')) && (
          <>
            <textarea
              name="descriptionOfServices"
              onChange={(e) => handleMultiPackageChange(e, 'landingPage')}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descriptionOfServices
              }
              style={{ minHeight: '85px' }}
            />
            <h3 style={{ textAlign: 'start' }}>Bullet Points:</h3>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.descSynopsisKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].descSynopsis.map(
                (syn, index) => (
                  <>
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].descSynopsis[
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].descSynopsis.splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  </>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].descSynopsis.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <h2 style={{ textAlign: 'left' }}>DELIVERABLES</h2>
            <input
              name="deliverablesDescription"
              onChange={(e) => handleMultiPackageChange(e, 'landingPage')}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].deliverablesDescription
              }
            />
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.Sprint}
            >
              {PdfStateRef.current[pdfCurrentStateKey].podWeeks.map(
                (section, ind) => {
                  let pageBreak = false;
                  console.log(section, '<=landingPage');

                  return (
                    <>
                      <div style={{ width: '100%', display: 'flex' }}>
                        <h3>
                          Section {ind + 1}{' '}
                          {Array.isArray(section.title)
                            ? section.title.join(',').replace(/,/g, ' + ')
                            : section.title}
                        </h3>
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          style={{
                            alignSelf: 'center',
                          }}
                          onClick={() => {
                            PdfStateRef.current[
                              pdfCurrentStateKey
                            ].podWeeks.splice(ind, 1);
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                        <label
                          htmlFor={`page-break-section-${ind + 1}`}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '5px',
                          }}
                        >
                          <input
                            type="checkbox"
                            id={`page-break-section-${ind + 1}`}
                            defaultChecked={section.pageBreak}
                            onChange={(e) => {
                              pageBreak = e.target.checked;
                              PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                                ind
                              ].pageBreak = pageBreak;
                            }}
                          />
                          Page Break
                        </label>
                      </div>
                      <input
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                            ind
                          ].title[0] = e.target.value;
                        }}
                        defaultValue={
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[ind]
                            .title[0]
                        }
                      />
                      <input
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                            ind
                          ].sectionTitle = e.target.value;
                        }}
                        defaultValue={
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[ind]
                            .sectionTitle
                        }
                      />
                      {section.bulletPoints[0].map((syn, index) => (
                        <div style={{ width: '100%', display: 'flex' }}>
                          <textarea
                            style={{ minHeight: '40px', width: '100%' }}
                            key={syn}
                            onChange={(e) => {
                              PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                                ind
                              ].bulletPoints[0][index] = e.target.value;
                            }}
                            defaultValue={syn}
                          />
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{
                              alignSelf: 'center',
                            }}
                            onClick={() => {
                              PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                                ind
                              ].bulletPoints[0].splice(index, 1);
                              setRefreshKeys({
                                ...refreshKeys,
                                Sprint: refreshKeys.Sprint + 1,
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </div>
                      ))}
                      <IconButton
                        style={{ width: 'fit-content' }}
                        onClick={() => {
                          PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                            ind
                          ].bulletPoints[0].push('');
                          setRefreshKeys({
                            ...refreshKeys,
                            Sprint: refreshKeys.Sprint + 1,
                          });
                        }}
                      >
                        <ControlPointIcon />
                      </IconButton>
                      <br />

                      {section.bulletPoints[1] && (
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1]
                          }
                        />
                      )}
                      {section.bulletPoints[1] &&
                        section.bulletPoints[1].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn.replace(/,/g, '')}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}

                      {section.bulletPoints[1] && (
                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[1].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      )}
                    </>
                  );
                }
              )}
            </div>
            <button
              type="button"
              onClick={() => {
                const n =
                  2 * PdfStateRef.current[pdfCurrentStateKey].podWeeks.length +
                  1;
                PdfStateRef.current[pdfCurrentStateKey].podWeeks.push(
                  fullLandingPageWeeks(
                    [`Week ${n + 2} & ${n + 3}`],
                    false,
                    1,
                    [
                      'Primary Research for Landing Pages (Competitor Analysis)',
                      'Sitemap is Submitted',
                      'Low-Fidelity Wireframe Submitted',
                    ],
                    null
                  )
                );
                setRefreshKeys({
                  ...refreshKeys,
                  Sprint: refreshKeys.Sprint + 1,
                });
              }}
            >
              Add Section
            </button>
          </>
        )}
      {/* Full Pod Video Section */}
      {proposal?.packages.length &&
        validatePackages(proposal?.packages, ['Full Pod', 'Video'], 2) && (
          <>
            <textarea
              name="descriptionOfServices"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descriptionOfServices
              }
              style={{ minHeight: '85px' }}
            />
            <input
              name="descNote"
              onChange={handlePdfStateFullPodChange}
              defaultValue={PdfStateRef.current[pdfCurrentStateKey].descNote}
            />
            <input
              name="descSynopsisIntro"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descSynopsisIntro
              }
            />
            <h3 style={{ textAlign: 'start' }}>Bullet Points:</h3>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.descSynopsisKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].descSynopsis.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <textarea
                      style={{ minHeight: '40px', width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].descSynopsis[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn.replace(/,/g, '')}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].descSynopsis.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].descSynopsis.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <input
              name="preProductionTitle"
              onChange={handlePdfStateVideoChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].preProductionTitle
              }
            />
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.preLaunchKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].preProduction.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].preProduction[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].preProduction.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}
              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].preProduction.push(
                    ''
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
              <br />
              <input
                name="productionTitle"
                onChange={handlePdfStateVideoChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].postProductionTitle
                }
              />
              {PdfStateRef.current[pdfCurrentStateKey].production.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].production[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].production.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].production.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
              <br />
              <input
                name="productionTitle"
                onChange={handlePdfStateVideoChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].productionTitle
                }
              />
              {PdfStateRef.current[pdfCurrentStateKey].postProduction.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].postProduction[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].postProduction.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].postProduction.push(
                    ''
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <input
              name="projectStartDate"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].projectStartDate
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <h2 style={{ textAlign: 'left' }}>DELIVERABLES </h2>
                <label
                  htmlFor={`page-break-section-DELIVERABLES`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`page-break-section-DELIVERABLES`}
                    defaultChecked={PdfStateRef.current.pageBreaks.includes(
                      'Deliverables'
                    )}
                    onChange={(e) => {
                      return pageBreakFunction(e, 'Deliverables');
                    }}
                  />
                  Page Break
                </label>
              </div>
              <input
                name="deliverablesDescription"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey]
                    .deliverablesDescription
                }
              />
              <h3 style={{ textAlign: 'start' }}>Pre-Launch:</h3>
              <input
                name="preLaunchTitle"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].preLaunchTitle
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.preLaunchKey}
              >
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[0][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[0].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}
                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
                <br />
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[1][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[1].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}

                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
              </div>
              <input
                name="sprintLength"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].sprintLength
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.Sprint}
              >
                {PdfStateRef.current[pdfCurrentStateKey].podWeeks.map(
                  (section, ind, arr) => {
                    let pageBreak = false;

                    return (
                      <>
                        <div style={{ width: '100%', display: 'flex' }}>
                          <h3>
                            Section {ind + 1}{' '}
                            {Array.isArray(section.title)
                              ? section.title.join(',').replace(/,/g, ' + ')
                              : section.title}
                          </h3>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{
                              alignSelf: 'center',
                            }}
                            onClick={() => {
                              PdfStateRef.current[
                                pdfCurrentStateKey
                              ].podWeeks.splice(ind, 1);
                              setRefreshKeys({
                                ...refreshKeys,
                                Sprint: refreshKeys.Sprint + 1,
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                          <label
                            htmlFor={`page-break-section-${ind + 1}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '5px',
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`page-break-section-${ind + 1}`}
                              defaultChecked={section.pageBreak}
                              onChange={(e) => {
                                pageBreak = e.target.checked;
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].pageBreak = pageBreak;
                              }}
                            />
                            Page Break
                          </label>
                          <label
                            htmlFor="additionalArray"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginLeft: '10px',
                            }}
                          >
                            <select
                              id="additionalArray"
                              onChange={(e) => {
                                console.log(
                                  e.target.value,
                                  'value',
                                  arr.length === ind + 1,
                                  arr
                                );
                                if (e.target.value === null) return;
                                let additionalArrays = null;
                                if (
                                  e.target.value ===
                                  `Pre-Production ${section.title[0]}`
                                ) {
                                  additionalArrays = [
                                    fullPodAndVideoPreProduction,
                                    null,
                                  ];
                                }
                                if (
                                  e.target.value ===
                                  `Pre-Production ${section.title[1]}`
                                ) {
                                  additionalArrays = [
                                    null,
                                    fullPodAndVideoPreProduction,
                                  ];
                                }
                                if (e.target.value === `Pre-Production Split`) {
                                  const half = Math.ceil(
                                    fullPodAndVideoPreProduction.length / 2
                                  );

                                  const firstHalf = fullPodAndVideoPreProduction.splice(
                                    0,
                                    half
                                  );
                                  const secondHalf = fullPodAndVideoPreProduction.splice(
                                    -half
                                  );

                                  additionalArrays = [firstHalf, secondHalf];
                                }
                                if (
                                  e.target.value ===
                                  `Production ${section.title[0]}`
                                ) {
                                  additionalArrays = [
                                    fullPodAndVideoProduction,
                                    null,
                                  ];
                                }
                                if (
                                  e.target.value ===
                                  `Production ${section.title[1]}`
                                ) {
                                  additionalArrays = [
                                    null,
                                    fullPodAndVideoProduction,
                                  ];
                                }
                                if (e.target.value === `Production Split`) {
                                  const half = Math.ceil(
                                    fullPodAndVideoProduction.length / 2
                                  );

                                  const firstHalf = fullPodAndVideoProduction.splice(
                                    0,
                                    half
                                  );
                                  const secondHalf = fullPodAndVideoProduction.splice(
                                    -half
                                  );

                                  additionalArrays = [firstHalf, secondHalf];
                                }
                                if (
                                  e.target.value ===
                                  `Post-Production ${section.title[0]}`
                                ) {
                                  additionalArrays = [
                                    fullPodAndVideoPostProduction,
                                    null,
                                  ];
                                }
                                if (
                                  e.target.value ===
                                  `Post-Production ${section.title[1]}`
                                ) {
                                  additionalArrays = [
                                    null,
                                    fullPodAndVideoPostProduction,
                                  ];
                                }
                                if (
                                  e.target.value === `Post-Production Split`
                                ) {
                                  const half = Math.ceil(
                                    fullPodAndVideoPostProduction.length / 2
                                  );

                                  const firstHalf = fullPodAndVideoPostProduction.splice(
                                    0,
                                    half
                                  );
                                  const secondHalf = fullPodAndVideoPostProduction.splice(
                                    -half
                                  );

                                  additionalArrays = [firstHalf, secondHalf];
                                }
                                if (arr.length === ind + 1) {
                                  additionalArrays.splice(1, 0, null);
                                  console.log(additionalArrays, 'array status');
                                }
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind] = fullPodWeeks(
                                  arr[ind].num,
                                  section.title,
                                  pageBreak,
                                  2,
                                  additionalArrays
                                );
                              }}
                            >
                              <option
                                selected="true"
                                disabled="disabled"
                                value=""
                              >
                                Set Video Bullet Points
                              </option>
                              <option
                                value={`Pre-Production ${section.title[0]}`}
                              >
                                Pre-Production {section.title[0]}
                              </option>
                              <option
                                value={`Pre-Production ${section.title[1]}`}
                              >
                                Pre-Production {section.title[1]}
                              </option>{' '}
                              <option value="Pre-Production Split">
                                Pre-Production Split
                              </option>
                              <option value={`Production ${section.title[0]}`}>
                                Production {section.title[0]}
                              </option>
                              <option value={`Production ${section.title[1]}`}>
                                Production {section.title[1]}
                              </option>{' '}
                              <option value="Production Split">
                                Production Split
                              </option>
                              <option
                                value={`Post-Production ${section.title[0]}`}
                              >
                                Post-Production {section.title[0]}
                              </option>
                              <option
                                value={`Post-Production ${section.title[1]}`}
                              >
                                Post-Production {section.title[1]}
                              </option>{' '}
                              <option value="Post-Production Split">
                                Post-Production Split
                              </option>
                            </select>
                          </label>
                        </div>
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0]
                          }
                        />
                        {section.bulletPoints[0].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}
                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[0].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                        <br />
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1]
                          }
                        />
                        {section.bulletPoints[1].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn.replace(/,/g, '')}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}

                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[1].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      </>
                    );
                  }
                )}
              </div>
              <button
                type="button"
                onClick={() => {
                  const n =
                    4 *
                      PdfStateRef.current[pdfCurrentStateKey].podWeeks.length +
                    1;
                  PdfStateRef.current[pdfCurrentStateKey].podWeeks.push(
                    fullPodWeeks(
                      n,
                      [`Week ${n} & ${n + 1}`, `Week ${n + 2} & ${n + 3}`],
                      false,
                      2
                    )
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    Sprint: refreshKeys.Sprint + 1,
                  });
                }}
              >
                Add Section
              </button>
            </div>
          </>
        )}

      {/* Full Pod Landing Page */}

      {proposal?.packages.length &&
        validatePackages(
          proposal?.packages,
          ['Full Pod', 'Landing Page'],
          2
        ) && (
          <>
            <textarea
              name="descriptionOfServices"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descriptionOfServices
              }
              style={{ minHeight: '85px' }}
            />
            <input
              name="descNote"
              onChange={handlePdfStateFullPodChange}
              defaultValue={PdfStateRef.current[pdfCurrentStateKey].descNote}
            />
            <input
              name="descSynopsisIntro"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descSynopsisIntro
              }
            />
            <h3 style={{ textAlign: 'start' }}>Bullet Points:</h3>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.descSynopsisKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].descSynopsis.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <textarea
                      style={{ minHeight: '40px', width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].descSynopsis[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn.replace(/,/g, '')}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].descSynopsis.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].descSynopsis.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <input
              name="projectStartDate"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].projectStartDate
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <h2 style={{ textAlign: 'left' }}>DELIVERABLES </h2>
                <label
                  htmlFor={`page-break-section-DELIVERABLES`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`page-break-section-DELIVERABLES`}
                    defaultChecked={PdfStateRef.current.pageBreaks.includes(
                      'Deliverables'
                    )}
                    onChange={(e) => {
                      return pageBreakFunction(e, 'Deliverables');
                    }}
                  />
                  Page Break
                </label>
              </div>
              <input
                name="deliverablesDescription"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey]
                    .deliverablesDescription
                }
              />
              <h3 style={{ textAlign: 'start' }}>Pre-Launch:</h3>
              <input
                name="preLaunchTitle"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].preLaunchTitle
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.preLaunchKey}
              >
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[0][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[0].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}
                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
                <br />
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[1][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[1].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}

                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
              </div>
              <div style={{ display: 'flex' }}>
                <input
                  name="sprintLength"
                  onChange={handlePdfStateFullPodChange}
                  defaultValue={
                    PdfStateRef.current[pdfCurrentStateKey].sprintLength
                  }
                  style={{ width: '65%' }}
                />
                <label
                  htmlFor={`page-break-section-SPRINT`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`page-break-section-SPRINT`}
                    defaultChecked={PdfStateRef.current.pageBreaks.includes(
                      'SPRINT'
                    )}
                    onChange={(e) => {
                      return pageBreakFunction(e, 'SPRINT');
                    }}
                  />
                  Page Break
                </label>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.Sprint}
              >
                {PdfStateRef.current[pdfCurrentStateKey].podWeeks.map(
                  (section, ind) => {
                    let pageBreak = false;

                    return (
                      <>
                        <div style={{ width: '100%', display: 'flex' }}>
                          <h3>
                            Section {ind + 1}{' '}
                            {Array.isArray(section.title)
                              ? section.title.join(',').replace(/,/g, ' + ')
                              : section.title}
                          </h3>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{
                              alignSelf: 'center',
                            }}
                            onClick={() => {
                              PdfStateRef.current[
                                pdfCurrentStateKey
                              ].podWeeks.splice(ind, 1);
                              setRefreshKeys({
                                ...refreshKeys,
                                Sprint: refreshKeys.Sprint + 1,
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                          <label
                            htmlFor={`page-break-section-${ind + 1}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '5px',
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`page-break-section-${ind + 1}`}
                              defaultChecked={section.pageBreak}
                              onChange={(e) => {
                                pageBreak = e.target.checked;
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].pageBreak = pageBreak;
                              }}
                            />
                            Page Break
                          </label>
                        </div>
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0]
                          }
                        />
                        {section.bulletPoints[0].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}
                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[0].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                        <br />
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1]
                          }
                        />
                        {section.bulletPoints[1].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn.replace(/,/g, '')}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}

                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[1].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      </>
                    );
                  }
                )}
              </div>
              <button
                type="button"
                onClick={() => {
                  const n =
                    4 *
                      PdfStateRef.current[pdfCurrentStateKey].podWeeks.length +
                    1;
                  PdfStateRef.current[pdfCurrentStateKey].podWeeks.push(
                    fullPodWeeks(
                      n,
                      [`Week ${n} & ${n + 1}`, `Week ${n + 2} & ${n + 3}`],
                      false,
                      2
                    )
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    Sprint: refreshKeys.Sprint + 1,
                  });
                }}
              >
                Add Section
              </button>
            </div>
          </>
        )}

      {/* Full Pod Website */}
      {proposal?.packages.length &&
        validatePackages(proposal?.packages, ['Full Pod', 'Website'], 2) && (
          <>
            <textarea
              name="descriptionOfServices"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descriptionOfServices
              }
              style={{ minHeight: '85px' }}
            />
            <input
              name="descNote"
              onChange={handlePdfStateFullPodChange}
              defaultValue={PdfStateRef.current[pdfCurrentStateKey].descNote}
            />
            <input
              name="descSynopsisIntro"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descSynopsisIntro
              }
            />
            <h3 style={{ textAlign: 'start' }}>Bullet Points:</h3>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.descSynopsisKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].descSynopsis.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <textarea
                      style={{ minHeight: '40px', width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].descSynopsis[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn.replace(/,/g, '')}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].descSynopsis.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].descSynopsis.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <input
              name="projectStartDate"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].projectStartDate
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <h2 style={{ textAlign: 'left' }}>DELIVERABLES </h2>
                <label
                  htmlFor={`page-break-section-DELIVERABLES`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`page-break-section-DELIVERABLES`}
                    defaultChecked={PdfStateRef.current.pageBreaks.includes(
                      'Deliverables'
                    )}
                    onChange={(e) => {
                      return pageBreakFunction(e, 'Deliverables');
                    }}
                  />
                  Page Break
                </label>
              </div>
              <input
                name="deliverablesDescription"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey]
                    .deliverablesDescription
                }
              />
              <h3 style={{ textAlign: 'start' }}>Pre-Launch:</h3>
              <input
                name="preLaunchTitle"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].preLaunchTitle
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.preLaunchKey}
              >
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[0][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[0].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}
                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
                <br />
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[1][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[1].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}

                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
              </div>
              <div style={{ display: 'flex' }}>
                <input
                  name="sprintLength"
                  onChange={handlePdfStateFullPodChange}
                  defaultValue={
                    PdfStateRef.current[pdfCurrentStateKey].sprintLength
                  }
                  style={{ width: '65%' }}
                />
                <label
                  htmlFor={`page-break-section-SPRINT`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`page-break-section-SPRINT`}
                    defaultChecked={PdfStateRef.current.pageBreaks.includes(
                      'SPRINT'
                    )}
                    onChange={(e) => {
                      return pageBreakFunction(e, 'SPRINT');
                    }}
                  />
                  Page Break
                </label>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.Sprint}
              >
                {PdfStateRef.current[pdfCurrentStateKey].podWeeks.map(
                  (section, ind) => {
                    let pageBreak = false;

                    return (
                      <>
                        <div style={{ width: '100%', display: 'flex' }}>
                          <h3>
                            Section {ind + 1}{' '}
                            {Array.isArray(section.title)
                              ? section.title.join(',').replace(/,/g, ' + ')
                              : section.title}
                          </h3>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{
                              alignSelf: 'center',
                            }}
                            onClick={() => {
                              PdfStateRef.current[
                                pdfCurrentStateKey
                              ].podWeeks.splice(ind, 1);
                              setRefreshKeys({
                                ...refreshKeys,
                                Sprint: refreshKeys.Sprint + 1,
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                          <label
                            htmlFor={`page-break-section-${ind + 1}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '5px',
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`page-break-section-${ind + 1}`}
                              defaultChecked={section.pageBreak}
                              onChange={(e) => {
                                pageBreak = e.target.checked;
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].pageBreak = pageBreak;
                              }}
                            />
                            Page Break
                          </label>
                        </div>
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0]
                          }
                        />
                        {section.bulletPoints[0].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}
                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[0].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                        <br />
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1]
                          }
                        />
                        {section.bulletPoints[1].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn.replace(/,/g, '')}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}

                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[1].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      </>
                    );
                  }
                )}
              </div>
              <button
                type="button"
                onClick={() => {
                  const n =
                    4 *
                      PdfStateRef.current[pdfCurrentStateKey].podWeeks.length +
                    1;
                  PdfStateRef.current[pdfCurrentStateKey].podWeeks.push(
                    fullPodWeeks(
                      n,
                      [`Week ${n} & ${n + 1}`, `Week ${n + 2} & ${n + 3}`],
                      false,
                      2
                    )
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    Sprint: refreshKeys.Sprint + 1,
                  });
                }}
              >
                Add Section
              </button>
            </div>
          </>
        )}

      {/* Full Pod Landing Page Video */}
      {proposal?.packages.length &&
        validatePackages(
          proposal?.packages,
          ['Full Pod', 'Landing Page', 'Video'],
          3
        ) && (
          <>
            <textarea
              name="descriptionOfServices"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descriptionOfServices
              }
              style={{ minHeight: '85px' }}
            />
            <input
              name="descNote"
              onChange={handlePdfStateFullPodChange}
              defaultValue={PdfStateRef.current[pdfCurrentStateKey].descNote}
            />
            <input
              name="descSynopsisIntro"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].descSynopsisIntro
              }
            />
            <h3 style={{ textAlign: 'start' }}>Bullet Points:</h3>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.descSynopsisKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].descSynopsis.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <textarea
                      style={{ minHeight: '40px', width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].descSynopsis[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn.replace(/,/g, '')}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].descSynopsis.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].descSynopsis.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    descSynopsisKey: refreshKeys.descSynopsisKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <input
              name="preProductionTitle"
              onChange={handlePdfStateVideoChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].preProductionTitle
              }
            />
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              key={refreshKeys.preLaunchKey}
            >
              {PdfStateRef.current[pdfCurrentStateKey].preProduction.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].preProduction[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].preProduction.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}
              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].preProduction.push(
                    ''
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
              <br />
              <input
                name="productionTitle"
                onChange={handlePdfStateVideoChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].postProductionTitle
                }
              />
              {PdfStateRef.current[pdfCurrentStateKey].production.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].production[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].production.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].production.push('');
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
              <br />
              <input
                name="productionTitle"
                onChange={handlePdfStateVideoChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].productionTitle
                }
              />
              {PdfStateRef.current[pdfCurrentStateKey].postProduction.map(
                (syn, index) => (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <input
                      style={{ width: '100%' }}
                      key={syn}
                      onChange={(e) => {
                        PdfStateRef.current[pdfCurrentStateKey].postProduction[
                          index
                        ] = e.target.value;
                      }}
                      defaultValue={syn}
                    />
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      style={{
                        alignSelf: 'center',
                      }}
                      onClick={() => {
                        PdfStateRef.current[
                          pdfCurrentStateKey
                        ].postProduction.splice(index, 1);
                        setRefreshKeys({
                          ...refreshKeys,
                          preLaunchKey: refreshKeys.preLaunchKey + 1,
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                )
              )}

              <IconButton
                style={{ width: 'fit-content' }}
                onClick={() => {
                  PdfStateRef.current[pdfCurrentStateKey].postProduction.push(
                    ''
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    preLaunchKey: refreshKeys.preLaunchKey + 1,
                  });
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
            <input
              name="projectStartDate"
              onChange={handlePdfStateFullPodChange}
              defaultValue={
                PdfStateRef.current[pdfCurrentStateKey].projectStartDate
              }
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <h2 style={{ textAlign: 'left' }}>DELIVERABLES </h2>
                <label
                  htmlFor={`page-break-section-DELIVERABLES`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <input
                    type="checkbox"
                    id={`page-break-section-DELIVERABLES`}
                    defaultChecked={PdfStateRef.current.pageBreaks.includes(
                      'Deliverables'
                    )}
                    onChange={(e) => {
                      return pageBreakFunction(e, 'Deliverables');
                    }}
                  />
                  Page Break
                </label>
              </div>
              <input
                name="deliverablesDescription"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey]
                    .deliverablesDescription
                }
              />
              <h3 style={{ textAlign: 'start' }}>Pre-Launch:</h3>
              <input
                name="preLaunchTitle"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].preLaunchTitle
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.preLaunchKey}
              >
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[0][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[0].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}
                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[0].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
                <br />
                {PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].map(
                  (syn, index) => (
                    <div style={{ width: '100%', display: 'flex' }}>
                      <textarea
                        style={{ minHeight: '40px', width: '100%' }}
                        key={syn}
                        onChange={(e) => {
                          PdfStateRef.current[pdfCurrentStateKey].preLaunch[1][
                            index
                          ] = e.target.value;
                        }}
                        defaultValue={syn.replace(/,/g, '')}
                      />
                      <IconButton
                        aria-label="delete"
                        color="secondary"
                        style={{
                          alignSelf: 'center',
                        }}
                        onClick={() => {
                          PdfStateRef.current[
                            pdfCurrentStateKey
                          ].preLaunch[1].splice(index, 1);
                          setRefreshKeys({
                            ...refreshKeys,
                            preLaunchKey: refreshKeys.preLaunchKey + 1,
                          });
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  )
                )}

                <IconButton
                  style={{ width: 'fit-content' }}
                  onClick={() => {
                    PdfStateRef.current[pdfCurrentStateKey].preLaunch[1].push(
                      ''
                    );
                    setRefreshKeys({
                      ...refreshKeys,
                      preLaunchKey: refreshKeys.preLaunchKey + 1,
                    });
                  }}
                >
                  <ControlPointIcon />
                </IconButton>
              </div>
              <input
                name="sprintLength"
                onChange={handlePdfStateFullPodChange}
                defaultValue={
                  PdfStateRef.current[pdfCurrentStateKey].sprintLength
                }
              />
              <div
                style={{ display: 'flex', flexDirection: 'column' }}
                key={refreshKeys.Sprint}
              >
                {PdfStateRef.current[pdfCurrentStateKey].podWeeks.map(
                  (section, ind, arr) => {
                    let pageBreak = false;

                    return (
                      <>
                        <div style={{ width: '100%', display: 'flex' }}>
                          <h3>
                            Section {ind + 1}{' '}
                            {Array.isArray(section.title)
                              ? section.title.join(',').replace(/,/g, ' + ')
                              : section.title}
                          </h3>
                          <IconButton
                            aria-label="delete"
                            color="secondary"
                            style={{
                              alignSelf: 'center',
                            }}
                            onClick={() => {
                              PdfStateRef.current[
                                pdfCurrentStateKey
                              ].podWeeks.splice(ind, 1);
                              setRefreshKeys({
                                ...refreshKeys,
                                Sprint: refreshKeys.Sprint + 1,
                              });
                            }}
                          >
                            <HighlightOffIcon />
                          </IconButton>
                          <label
                            htmlFor={`page-break-section-${ind + 1}`}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginLeft: '5px',
                            }}
                          >
                            <input
                              type="checkbox"
                              id={`page-break-section-${ind + 1}`}
                              defaultChecked={section.pageBreak}
                              onChange={(e) => {
                                pageBreak = e.target.checked;
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].pageBreak = pageBreak;
                              }}
                            />
                            Page Break
                          </label>
                          <label
                            htmlFor="additionalArray"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              marginLeft: '10px',
                            }}
                          >
                            <select
                              id="additionalArray"
                              onChange={(e) => {
                                console.log(
                                  e.target.value,
                                  'value',
                                  arr.length === ind + 1,
                                  arr
                                );
                                if (e.target.value === null) return;
                                let ExtraArrays = null;
                                if (
                                  e.target.value ===
                                  `Pre-Production ${section.title[0]}`
                                ) {
                                  ExtraArrays = [
                                    fullPodAndVideoPreProduction,
                                    null,
                                  ];
                                }
                                if (
                                  e.target.value ===
                                  `Pre-Production ${section.title[1]}`
                                ) {
                                  ExtraArrays = [
                                    null,
                                    fullPodAndVideoPreProduction,
                                  ];
                                }
                                if (e.target.value === `Pre-Production Split`) {
                                  const half = Math.ceil(
                                    fullPodAndVideoPreProduction.length / 2
                                  );

                                  const firstHalf = fullPodAndVideoPreProduction.splice(
                                    0,
                                    half
                                  );
                                  const secondHalf = fullPodAndVideoPreProduction.splice(
                                    -half
                                  );

                                  ExtraArrays = [firstHalf, secondHalf];
                                }
                                if (
                                  e.target.value ===
                                  `Production ${section.title[0]}`
                                ) {
                                  ExtraArrays = [
                                    fullPodAndVideoProduction,
                                    null,
                                  ];
                                }
                                if (
                                  e.target.value ===
                                  `Production ${section.title[1]}`
                                ) {
                                  ExtraArrays = [
                                    null,
                                    fullPodAndVideoProduction,
                                  ];
                                }
                                if (e.target.value === `Production Split`) {
                                  const half = Math.ceil(
                                    fullPodAndVideoProduction.length / 2
                                  );

                                  const firstHalf = fullPodAndVideoProduction.splice(
                                    0,
                                    half
                                  );
                                  const secondHalf = fullPodAndVideoProduction.splice(
                                    -half
                                  );

                                  ExtraArrays = [firstHalf, secondHalf];
                                }
                                if (
                                  e.target.value ===
                                  `Post-Production ${section.title[0]}`
                                ) {
                                  ExtraArrays = [
                                    fullPodAndVideoPostProduction,
                                    null,
                                  ];
                                }
                                if (
                                  e.target.value ===
                                  `Post-Production ${section.title[1]}`
                                ) {
                                  ExtraArrays = [
                                    null,
                                    fullPodAndVideoPostProduction,
                                  ];
                                }
                                if (
                                  e.target.value === `Post-Production Split`
                                ) {
                                  const half = Math.ceil(
                                    fullPodAndVideoPostProduction.length / 2
                                  );

                                  const firstHalf = fullPodAndVideoPostProduction.splice(
                                    0,
                                    half
                                  );
                                  const secondHalf = fullPodAndVideoPostProduction.splice(
                                    -half
                                  );

                                  ExtraArrays = [firstHalf, secondHalf];
                                }
                                if (arr.length === ind + 1) {
                                  ExtraArrays.splice(1, 0, null);
                                }
                                console.log(
                                  PdfStateRef.current[pdfCurrentStateKey]
                                    .podWeeks[ind],
                                  'array status'
                                );
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind] = fullPodWeeks(
                                  arr[ind].num,
                                  section.title,
                                  pageBreak,
                                  2,
                                  PdfStateRef.current[pdfCurrentStateKey]
                                    .podWeeks[ind].additionalArrays,
                                  ExtraArrays
                                );
                              }}
                            >
                              <option
                                selected="true"
                                disabled="disabled"
                                value=""
                              >
                                Set Video Bullet Points
                              </option>
                              <option
                                value={`Pre-Production ${section.title[0]}`}
                              >
                                Pre-Production {section.title[0]}
                              </option>
                              <option
                                value={`Pre-Production ${section.title[1]}`}
                              >
                                Pre-Production {section.title[1]}
                              </option>{' '}
                              <option value="Pre-Production Split">
                                Pre-Production Split
                              </option>
                              <option value={`Production ${section.title[0]}`}>
                                Production {section.title[0]}
                              </option>
                              <option value={`Production ${section.title[1]}`}>
                                Production {section.title[1]}
                              </option>{' '}
                              <option value="Production Split">
                                Production Split
                              </option>
                              <option
                                value={`Post-Production ${section.title[0]}`}
                              >
                                Post-Production {section.title[0]}
                              </option>
                              <option
                                value={`Post-Production ${section.title[1]}`}
                              >
                                Post-Production {section.title[1]}
                              </option>{' '}
                              <option value="Post-Production Split">
                                Post-Production Split
                              </option>
                            </select>
                          </label>
                        </div>
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[0]
                          }
                        />
                        {section.bulletPoints[0].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[0].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}
                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[0].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                        <br />
                        <input
                          onChange={(e) => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1] = e.target.value;
                          }}
                          defaultValue={
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].title[1]
                          }
                        />
                        {section.bulletPoints[1].map((syn, index) => (
                          <div style={{ width: '100%', display: 'flex' }}>
                            <textarea
                              style={{ minHeight: '40px', width: '100%' }}
                              key={syn}
                              onChange={(e) => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1][index] =
                                  e.target.value;
                              }}
                              defaultValue={syn.replace(/,/g, '')}
                            />
                            <IconButton
                              aria-label="delete"
                              color="secondary"
                              style={{
                                alignSelf: 'center',
                              }}
                              onClick={() => {
                                PdfStateRef.current[
                                  pdfCurrentStateKey
                                ].podWeeks[ind].bulletPoints[1].splice(
                                  index,
                                  1
                                );
                                setRefreshKeys({
                                  ...refreshKeys,
                                  Sprint: refreshKeys.Sprint + 1,
                                });
                              }}
                            >
                              <HighlightOffIcon />
                            </IconButton>
                          </div>
                        ))}

                        <IconButton
                          style={{ width: 'fit-content' }}
                          onClick={() => {
                            PdfStateRef.current[pdfCurrentStateKey].podWeeks[
                              ind
                            ].bulletPoints[1].push('');
                            setRefreshKeys({
                              ...refreshKeys,
                              Sprint: refreshKeys.Sprint + 1,
                            });
                          }}
                        >
                          <ControlPointIcon />
                        </IconButton>
                      </>
                    );
                  }
                )}
              </div>
              <button
                type="button"
                onClick={() => {
                  const n =
                    4 *
                      PdfStateRef.current[pdfCurrentStateKey].podWeeks.length +
                    1;
                  PdfStateRef.current[pdfCurrentStateKey].podWeeks.push(
                    fullPodWeeks(
                      n,
                      [`Week ${n} & ${n + 1}`, `Week ${n + 2} & ${n + 3}`],
                      false,
                      2
                    )
                  );
                  setRefreshKeys({
                    ...refreshKeys,
                    Sprint: refreshKeys.Sprint + 1,
                  });
                }}
              >
                Add Section
              </button>
            </div>
          </>
        )}

      <div style={{ display: 'flex' }}>
        <h2 style={{ textAlign: 'left' }}>SERVICE LENGTH </h2>
        <label
          htmlFor={`page-break-section-SERVICE LENGTH`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '5px',
          }}
        >
          <input
            type="checkbox"
            id={`page-break-section-SERVICE LENGTH`}
            defaultChecked={PdfStateRef.current.pageBreaks.includes(
              'SERVICE LENGTH'
            )}
            onChange={(e) => {
              return pageBreakFunction(e, 'SERVICE LENGTH');
            }}
          />
          Page Break
        </label>
      </div>
      <div style={{ textAlign: 'initial' }}>
        <input
          name="project_start_date"
          onChange={handlePdfStateChange}
          defaultValue={getFormattedDate(proposal.project_start_date)}
          style={{ width: '100px' }}
        />
        {'  -  '}
        <input
          name="project_end_date"
          onChange={handlePdfStateChange}
          defaultValue={getFormattedDate(proposal.project_end_date)}
          style={{ width: '100px' }}
        />
      </div>
      <textarea
        name="service_length_desc"
        onChange={handlePdfStateChange}
        defaultValue={PdfStateRef.current.service_length_desc}
        style={{ minHeight: '85px' }}
      />
      <div style={{ display: 'flex' }}>
        <h2 style={{ textAlign: 'left' }}>FEES FOR GROWTH SPRINT </h2>
        <label
          htmlFor={`page-break-section-FEES FOR GROWTH SPRINT`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '5px',
          }}
        >
          <input
            type="checkbox"
            id={`page-break-section-FEES FOR GROWTH SPRINT`}
            defaultChecked={PdfStateRef.current.pageBreaks.includes(
              'FEES FOR GROWTH SPRINT'
            )}
            onChange={(e) => {
              return pageBreakFunction(e, 'FEES FOR GROWTH SPRINT');
            }}
          />
          Page Break
        </label>
      </div>
      <textarea
        name="fees_for_growth_sprint_desc"
        onChange={handlePdfStateChange}
        defaultValue={PdfStateRef.current.fees_for_growth_sprint_desc}
        style={{ minHeight: '45px' }}
      />
      <div key={paymentDiscount}>
        {pdfState.payments.map((payment, index) => (
          <div key={`${payment}+${Math.random(1000)}`}>
            <input
              onChange={(e) => {
                PdfStateRef.current.payments[index] = {
                  ...PdfStateRef.current.payments[index],
                  payment_amount: e.target.value,
                };
              }}
              defaultValue={numberWithCommas(payment.payment_amount)}
              style={{ width: '100px' }}
            />{' '}
            {'   '}{' '}
            <input
              onChange={(e) => {
                PdfStateRef.current.payments[index] = {
                  ...PdfStateRef.current.payments[index],
                  payment_date: new Date(e.target.value),
                };
              }}
              defaultValue={new Date(payment.payment_date).toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              )}
              style={{ width: '100px' }}
            />
          </div>
        ))}
      </div>
      <div style={{ marginLeft: 'auto', marginTop: '10px' }}>
        % of discount{' '}
        <input
          name="payment_discount"
          onChange={(e) => {
            if (/^[0-9]*$/.test(e.target.value) && e.target.value.length < 3) {
              return handlePdfStateChange(e);
            }
          }}
          type="number"
          defaultValue={PdfStateRef.current.payment_discount}
          style={{ width: '50px' }}
          max="100"
          maxLength="2"
        />
      </div>
      {proposal?.packages?.some((e) => e.name.includes('Full Pod')) && (
        <div style={{ marginLeft: 'auto', marginTop: '5px' }}>
          Set-up Fee
          <input
            name="setUpFee"
            onChange={(e) => {
              console.log(e.target.value, '?');
              return handlePdfStateChange(e);
            }}
            type="number"
            defaultValue={PdfStateRef.current.setUpFee}
            style={{ width: '80px' }}
            maxLength="4"
          />
        </div>
      )}
      {proposal?.packages?.some((e) => e.name.includes('Full Pod')) && (
        <>
          <div style={{ display: 'flex' }}>
            <h2 style={{ textAlign: 'left' }}>PERFORMANCE-BASED BONUS </h2>
            <label
              htmlFor={`page-break-section-PERFORMANCE-BASED BONUS`}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '5px',
              }}
            >
              <input
                type="checkbox"
                id={`page-break-section-PERFORMANCE-BASED BONUS`}
                defaultChecked={PdfStateRef.current.pageBreaks.includes(
                  'PERFORMANCE-BASED BONUS'
                )}
                onChange={(e) => {
                  return pageBreakFunction(e, 'PERFORMANCE-BASED BONUS');
                }}
              />
              Page Break
            </label>
          </div>
          <input
            name="line8"
            onChange={handlePdfStateChange}
            defaultValue={PdfStateRef.current.line8}
          />
          <br />
          <textarea
            name="line9"
            onChange={handlePdfStateChange}
            defaultValue={PdfStateRef.current.line9}
            style={{ minHeight: '85px' }}
          />
          <br />
          <textarea
            name="line10"
            onChange={handlePdfStateChange}
            defaultValue={PdfStateRef.current.line10}
            style={{ minHeight: '85px' }}
          />
          <br />
          <textarea
            name="line11"
            onChange={handlePdfStateChange}
            defaultValue={PdfStateRef.current.line11}
            style={{ minHeight: '55px' }}
          />
          <br />
          <textarea
            name="line12"
            onChange={handlePdfStateChange}
            defaultValue={PdfStateRef.current.line12}
            style={{ minHeight: '85px' }}
          />
          <br />
          <textarea
            name="line13"
            onChange={handlePdfStateChange}
            defaultValue={PdfStateRef.current.line13}
            style={{ minHeight: '85px' }}
          />
          <br />
          <textarea
            name="line14"
            onChange={handlePdfStateChange}
            defaultValue={PdfStateRef.current.line14}
            style={{ minHeight: '85px' }}
          />
        </>
      )}
      <br />
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          setPdfState(PdfStateRef.current);
          setRandomKey(Math.random(10000));
          setBlobContentLoading(true);
          setChangeCount((state) => state + 1);
          setTimeout(() => {
            console.log(pdfState, '<=PDFSTATE After Submit');
          }, 2000);
        }}
      >
        Update PDF
      </button>
    </PdfUpdateStyledDiv>
  );
};
PdfUpdateForm.propTypes = {
  handlePdfStateChange: PropTypes.func.isRequired,
  PdfStateRef: PropTypes.objectOf(PropTypes.any).isRequired,
  proposal: PropTypes.objectOf(PropTypes.any).isRequired,
  setPdfState: PropTypes.func.isRequired,
  setRandomKey: PropTypes.func.isRequired,
  pdfState: PropTypes.objectOf(PropTypes.any),
  getFormattedDate: PropTypes.func.isRequired,
  handlePdfStateVideoChange: PropTypes.func,
  handlePdfStateFullPodChange: PropTypes.func,
  handleMultiPackageChange: PropTypes.func,
  pdfCurrentStateKey: PropTypes.string.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setChargesUploaded: PropTypes.func.isRequired,
  setallComplete: PropTypes.func.isRequired,
  setBlobContentLoading: PropTypes.func.isRequired,
  setChangeCount: PropTypes.func.isRequired,
};

PdfUpdateForm.defaultProps = {
  pdfState: null,
  handlePdfStateVideoChange: null,
  handlePdfStateFullPodChange: null,
  handleMultiPackageChange: null,
};
export default PdfUpdateForm;
