import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import styled from 'styled-components';
import defaultAvatar from '../../assets/images/default-avatar.png';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import SortIcons from '../SortIcons';
import NewUserModal from './NewUserModal';
import UserActionsMenu from './UserActionsMenu';

const StyledUsersPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
      text-transform: uppercase;
    }
    > .cui-textfield {
      flex-grow: 1;
    }
  }
  > .list-row,
  > .list-body > .list-row {
    display: grid;
    grid-template-columns: 0.5fr 1fr 0.5fr 1.5fr 1fr 0.5fr 0.5fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    .message-icon {
      stroke-width: 1px;
      height: 1.25rem;
      width: 1.25rem;
    }
    &.user {
      font-size: 14px;
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      > .profle_pic_url {
        height: 3.75rem;
        width: 3.75rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .name {
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
  }
  > .list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .no-items {
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

const UsersPanel = ({ selectedOrganizationId, organizationName }) => {
  const [usersFetch, setUsersFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const [newUser, setNewUser] = useState({
    mode: '', // 'create', 'edit', 'delete'
    first_name: '',
    last_name: '',
    email: '',
    organization_role: '',
    phone: '',
    profle_pic_url: '',
    id: null,
    actionState: 'start',
    isModalOpen: false,
  });
  const { axiosWithAuth } = useAxioswithAuth();

  // const [columnSort, setColumnSort] = useState({
  //   column: 'name',
  //   order: 'asc',
  // });

  // const updateColumnSort = (column) => {
  //   if (columnSort.column === column) {
  //     setColumnSort({
  //       column,
  //       order: columnSort.order === 'asc' ? 'desc' : 'asc',
  //     });
  //   } else {
  //     setColumnSort({
  //       column,
  //       order: 'asc',
  //     });
  //   }
  // };

  const fetchUsers = async () => {
    setUsersFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(
        `users/in_organization/${selectedOrganizationId}`
      );
      const users = data.slice().sort((a, b) => {
        const nameA = a.organization_role.toUpperCase(); // ignore upper and lowercase
        const nameB = b.organization_role.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      setUsersFetch({ fetchState: 'success', data: users });
    } catch (error) {
      setUsersFetch({ fetchState: 'error', data: [] });
    }
  };

  useEffect(() => {
    if (!selectedOrganizationId) return;
    fetchUsers();
  }, [selectedOrganizationId]);

  return (
    <StyledUsersPanel className="users-nav-panel">
      <NewUserModal
        newUser={newUser}
        setNewUser={setNewUser}
        fetchUsers={fetchUsers}
        selectedOrganizationId={selectedOrganizationId}
      />
      <div className="panel-header">
        <span className="title">
          {selectedOrganizationId && `${organizationName} Members`}
        </span>
        <Button
          className="stand-alone-btn"
          onClick={() =>
            setNewUser({
              mode: 'create',
              id: null,
              first_name: '',
              last_name: '',
              email: '',
              organization_role: 'approver',
              phone: '',
              profle_pic_url: '',
              actionState: 'start',
              isModalOpen: true,
            })
          }
          variant="secondary"
          icon={<Plus />}
        />
      </div>
      <div className="list-row">
        <div className="column-title" />
        {[
          { property: '', label: 'Name' },
          { property: '', label: 'Role' },
          { property: '', label: 'Email' },
          { property: '', label: 'Phone' },
          { property: '', label: 'Last Logged' },
        ].map(({ property, label }) => (
          <div
            className="column-title"
            // onClick={() => updateColumnSort(property)}
            // role="button"
            // tabIndex={0}
            // onKeyDown={() => updateColumnSort(property)}
          >
            {label}
            {/* <SortIcons
              sorting={
                organizationsFetch.fetchState === 'success' &&
                columnSort.column === property
                  ? columnSort.order
                  : undefined
              }
            /> */}
          </div>
        ))}
        <div className="column-title" />
      </div>
      <div className="list-body">
        {usersFetch.fetchState === 'success' && !usersFetch.data.length && (
          <div className="no-items">No users yet</div>
        )}
        {usersFetch?.data?.map((user) => (
          <div className="list-row user">
            <img
              className="profle_pic_url"
              src={user.profile_pic_url || defaultAvatar}
              alt="profile pic"
              onError={(e) => {
                e.target.src = defaultAvatar;
              }}
            />
            <div className="name">
              {user.first_name} {user.last_name}{' '}
              {!user.first_name && !user.last_name && '—'}
            </div>
            <div className="role">{user.organization_role || '—'}</div>
            <div className="email">{user.email || '—'}</div>
            <div className="phone">{user.phone || '—'}</div>
            <div className="last-log-in">
              {user.last_login
                ? new Date(user.last_login).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })
                : 'Never'}
            </div>
            <UserActionsMenu user={user} setNewUser={setNewUser} />
          </div>
        ))}
      </div>
    </StyledUsersPanel>
  );
};

export default UsersPanel;
