import React, { useEffect, useState } from 'react';

const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

const TikTokThumbnail = ({ filePreview, setFilePreview }) => {
  const [thumbnail, setThumbnail] = useState('');

  const debouncedFetch = debounce(async () => {
    try {
      const response = await fetch(
        `https://www.tiktok.com/oembed?url=${encodeURIComponent(
          filePreview.url
        )}`
      );
      const data = await response.json();
      setThumbnail(data.thumbnail_url);
      setFilePreview((prev) => ({ status: 'success', url: prev.url }));
    } catch (error) {
      setThumbnail('');
      setFilePreview((prev) => ({
        url: prev.url,
        error: 'TikTok url not found',
        status: 'error',
      }));
    }
  }, 2000);

  useEffect(() => {
    if (!filePreview?.url) {
      setThumbnail('');
      return () => clearTimeout(debouncedFetch);
    }
    if (!filePreview?.url?.match(/^(https?:\/\/)?(www\.)?tiktok\.com/)) {
      setThumbnail('');
      setFilePreview((prev) => ({
        url: prev.url,
        error: 'Not valid tiktok url',
        status: 'error',
      }));
      return () => clearTimeout(debouncedFetch);
    }
    setFilePreview((prev) => ({
      url: prev.url,
      error: '',
      status: 'loading',
    }));
    debouncedFetch();
    return () => clearTimeout(debouncedFetch);
  }, [filePreview.url]);

  if (filePreview.status !== 'success' || !thumbnail) return null;
  return <img className="cui-media" src={thumbnail} alt="TikTok thumbnail" />;
};

export default TikTokThumbnail;
