import React from 'react';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledCard = styled.div`
  display: grid;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #d3d3d3;
  z-index: 1000;
  background-color: white;
  width: 100%;
  grid-template-columns: min-content min-content min-content;
  > .tag-bucket {
    padding: 0.25rem 0.75rem 0.75rem 0.75rem;
    &.Type {
    }
    &.UGC {
      border-left: 1px solid #d3d3d3;
      border-right: 1px solid #d3d3d3;
    }
    &.Trends > .tags {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &.Industry {
      grid-column: 1 / 4;
      padding-top: 0.75rem;
      border-top: 1px solid #d3d3d3;
      width: 29rem;
      > .tags {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    > .category-title {
      margin-bottom: 0.2rem;
      font-size: 16px;
    }
    > .tags {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      > .tag {
        border-radius: 9999px;
        border: 1px solid #dceaff;
        padding: 0.1rem 0.75rem;
        white-space: nowrap;
        font-size: 14px;
        width: fit-content;
        cursor: pointer;
        &.active,
        :hover {
          background-color: #dceaff;
        }
      }
    }
  }
`;

const TagsCard = ({ activeTags, setActiveTags, referenceId }) => {
  const { axiosWithAuth } = useAxioswithAuth();

  const addTag = async (tag) => {
    setActiveTags([...activeTags, tag]);
    try {
      await axiosWithAuth().post(`/reference-tool/referencetags`, {
        reference_id: referenceId,
        label: tag,
        color: '#dceaff',
      });
    } catch (err) {
      setActiveTags(activeTags.filter((t) => t !== tag));
      console.log(err);
    }
  };

  const removeTag = async (tag) => {
    setActiveTags(activeTags.filter((t) => t !== tag));
    try {
      await axiosWithAuth().delete(
        `/reference-tool/referencetags/${referenceId}/${tag}`
      );
    } catch (err) {
      setActiveTags([...activeTags, tag]);
      console.log(err);
    }
  };

  return (
    <StyledCard sideOffset={5} side="right" align="start">
      {[
        { category: 'Type', tags: ['mobile', 'saas'] },
        {
          category: 'UGC',
          tags: [
            'conversation',
            'listicle',
            'review',
            'testimonial',
            'tutorial',
            'walk-through',
          ],
        },
        {
          category: 'Trends',
          tags: [
            'dances',
            'duests',
            'filters',
            'lip-sync',
            'stitches',
            'skits',
            'sounds',
            'transitions',
          ],
        },
        {
          category: 'Industry',
          tags: [
            'apparel',
            'baby/kids',
            'beauty',
            'business',
            'education',
            'electronics',
            'fintech',
            'food',
            'health',
            'home',
            'news',
            'pets',
            'services',
            'sports',
            'transportation',
            'travel',
          ],
        },
      ].map(({ category, tags }) => (
        <div className={`tag-bucket ${category}`}>
          <div className="category-title">{category}</div>
          <div className="tags">
            {tags.map((tag) => {
              const active = activeTags?.includes(tag);
              return (
                <div
                  className={`tag${active ? ' active' : ''}`}
                  key={tag}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => (active ? removeTag(tag) : addTag(tag))}
                  onClick={() => (active ? removeTag(tag) : addTag(tag))}
                >
                  {tag}
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </StyledCard>
  );
};

export default TagsCard;
