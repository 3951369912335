import React from 'react';
import {
  BarChart,
  BookOpen,
  CheckCircle,
  FileText,
  Film,
  Image,
  ShoppingBag,
  Smartphone,
  Tool,
  TrendingUp,
  UserPlus,
  Users,
  Video,
  Zap,
} from 'react-feather';
import AdApproval from './pages/AdApproval';
import BriefAdmin from './pages/AdminBrief';
import Brief from './pages/Brief';
import AdminPageOrgGrid from './pages/ColabAdminPages/AdminPageOrgGrid';
import CreateProposalPage from './pages/ColabAdminPages/CreateProposal';
import ProductsAndPricing from './pages/ProductsAndPricing';
import ReferenceToolPage from './pages/ReferenceTool';
import ReportsPage from './pages/Reports';
import UGCBundleWizard from './pages/UGCBundleWizard';
import UsersAndOrganizations from './pages/UsersAndOrganizations';

export default {
  paid_acquisition: {
    name: 'Paid Acquisition',
    icon: <TrendingUp />,
    genericPath: '/orders/:orderId',
    getPaths: (user) =>
      user?.orders
        .filter((order) =>
          order.order_items.some(
            (orderItem) => orderItem.product_name === 'paid_acquisition'
          )
        )
        .map((order) => `/orders/${order.id}`),
    pages: {
      'ad-approval': {
        icon: <CheckCircle />,
        name: 'Ad Approval',
        innerPath: '/:type?/:deckId?',
        component: AdApproval,
      },
      reports: {
        icon: <BarChart />,
        name: 'Reports',
        component: ReportsPage,
      },
    },
  },
  video_bundle: {
    name: 'UGC Bundle',
    icon: <Smartphone />,
    genericPath: '/orders/:orderId',
    getPaths: (user) =>
      user?.orders
        .filter((order) =>
          order.order_items.some(
            (orderItem) => orderItem.product_name === 'video_bundle'
          )
        )
        .map((order) => `/orders/${order.id}`),
    pages: {
      brief: {
        icon: <BookOpen />,
        name: 'Brief',
        component: Brief,
        innerPath: '/:briefStep?',
      },
      creatives: {
        icon: <Image />,
        name: 'Creatives',
        disabled: true,
      },
    },
  },
  agency: {
    name: 'Agency Management',
    icon: <Zap />,
    genericPath: '/agency',
    getPaths: (user) =>
      user?.organization?.type === 'agency' ? ['/agency'] : [],
    pages: {
      brief: {
        icon: <Smartphone />,
        name: 'UGC Bundles',
        component: BriefAdmin,
      },
      'users-teams': {
        icon: <Users />,
        name: 'Users & Organizations',
        component: UsersAndOrganizations,
      },
      wizard: {
        icon: <UserPlus />,
        name: 'Add a Client',
        important: true,
        innerPath: '/:organizationId?/:orderId?',
        component: UGCBundleWizard,
      },
    },
  },
  ops: {
    name: 'CoEfficient Labs Ops',
    icon: <Video />,
    genericPath: '/ops',
    getPaths: (user) =>
      user?.organization?.type === 'superadmin' ? ['/ops'] : [],
    pages: {
      brief: { icon: <BookOpen />, name: 'Brief', component: BriefAdmin },
      creatives: {
        icon: <Image />,
        name: 'Creatives',
        disabled: true,
      },
      'ad-approval': {
        icon: <CheckCircle />,
        innerPath: '/:type?/:deckId?/:adId?',
        name: 'Ad Approval',
        component: AdApproval,
      },
      reports: {
        icon: <TrendingUp />,
        name: 'Reports',
        component: () => <AdminPageOrgGrid resource="reports" />,
      },
      'reference-tool': {
        icon: <Film />,
        innerPath: '/:deckId?',
        name: 'Reference Tool',
        component: ReferenceToolPage,
      },
      'create-proposal': {
        icon: <FileText />,
        name: 'Proposal Generator',
        component: CreateProposalPage,
      },
    },
  },
  platform: {
    name: 'Platform Management',
    genericPath: '/platform',
    getPaths: (user) =>
      user?.organization?.type === 'superadmin' ? ['/platform'] : [],
    icon: <Tool />,
    pages: {
      'users-teams': {
        icon: <Users />,
        name: 'Users & Organizations',
        component: UsersAndOrganizations,
      },
      'products-pricing': {
        icon: <ShoppingBag />,
        name: 'Products & Pricing',
        component: ProductsAndPricing,
        disabled: true,
      },
    },
  },
};
