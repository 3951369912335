import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  span.avoidwrap { display:inline-block; }
  a {
    text-decoration: none;
    color: #000;
  }
  * {
    font-family: ${({ theme }) => theme.font.main.regular};
    font-variant-ligatures: none;
    /* font-size: 1rem; */
    font-weight: normal;
  }
  .card{
      background: white;
  }
  .dark-mode {
    color: #fff;
    background-color: #1f2022 !important;
    .MuiContainer-root {
     background-color: #1f2022 !important;   
    }
    .card {
        background: #1f2022;
    }
    .legend {
        background: #1f2022 !important;
    }
    h2,h3,label{
        color:white;
    }
    .MuiPaper-root{
        background-color: #1f2022 !important;
        color: #fff;
        .MuiOutlinedInput-notchedOutline{
            border-color: #fff;
        }
        label input{
            color:#fff
        }
        .MuiStepLabel-label{
            color:#fff
        }
        .MuiFormLabel-root{
            color: #fff;
        }
        .MuiInputBase-root{
            color: #fff;
        }
        .MuiButtonBase-root:not(.Mui-disabled){
            color: #fff;
        }
    }
    #cardGrid{
        background-color:#313843 !important;
    }
    main{
        background-color: #313843 !important;
        color: #fff;
    }
    nav{
        background-color: #1f2022;
        border: none;
    }
    nav span{
        color: #fff;
    }
    .navbar {
      background-color: #1f2022;
      border: none;
    }
  }
  .reportCard {
        box-shadow: none !important;
  }
  .unsubscribeBackground{
    display: flex;
    background-color: #edeff2 !important;
    height: 100vh;
    .unsubscribeGif{
        width: 450px
    }
    @media (max-width: 500px) {
        .unsubscribeGif{
            width: 250px
        }
    
    }
  }
`;

export default GlobalStyles;
