import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button, Textfield } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import S3 from 'react-aws-s3';
import { Plus, Trash2, X } from 'react-feather';
import styled from 'styled-components';
import { camelToSentenceCase, formatUsd } from '../../utils/helper';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const snakeCaseToTitleCase = (str) => {
  return str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

const parseCurrency = (input) => parseFloat(input.replace(/[^0-9.]/g, ''));

const sum = (arr) =>
  arr.reduce(
    (acc, curr) => (typeof curr.price === 'number' ? acc + curr.price : acc),
    0
  );

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0.5rem;
  height: 34px;
  background: none;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3;
  width: 100%;
  text-align: left;
  :focus,
  :active {
    outline: 1px solid #d3d3d3;
  }
`;

const DDContent = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 6rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000000000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
  &[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 24rem;
  padding: clamp(1.5rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    &.delete {
      margin-bottom: 0.5rem;
    }
  }
  > .description {
    margin: 0 0 1rem 0;
  }
  > .categories {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    > .category {
      > .items {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: center;
      }
      > .add-item {
        background-color: #f5f5f5;
        &:hover {
          background-color: #e8e8e8;
        }
      }
      > .fileInput {
        width: 100%;
        grid-column: 1 / 3;
        padding: 0.5rem;
        border: 1px solid #d3d3d3;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
        &::file-selector-button {
          cursor: pointer;
          border: 1px solid #d3d3d3;
          border-radius: 8px;
          padding: 0.5rem;
          margin-right: 0.75rem;
        }
      }
    }
    .item {
      display: grid;
      grid-template-columns: 11rem 1fr min-content;
      grid-gap: 6px;
      width: 100%;
      align-items: center;
      &:last-child {
        margin-bottom: 6px;
      }
      > .label {
        justify-self: end;
      }
      > .price {
        border: 1px solid #d3d3d3;
        border-radius: 8px;
        padding: 0.25rem 0.75rem;
      }
    }
  }
  > .buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }
  > .cui-button {
    text-transform: capitalize;
    align-self: flex-end;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

const NewOrderModal = ({
  newOrder,
  setNewOrder,
  fetchOrders,
  organizationName,
}) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const [productsListFetch, setProductsListFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const fileInputRef = useRef(null);

  const fetchProducts = async () => {
    setProductsListFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get('/orders/product-list');
      setProductsListFetch({ fetchState: 'success', data });
    } catch (e) {
      setProductsListFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  const getOrderItems = () =>
    Object.keys(newOrder.items).reduce((acc, key) => {
      return [
        ...acc,
        ...newOrder.items[key]
          .filter((item) => item.product_id && item.price)
          .map((item) => ({
            product_id: item.product_id,
            price: item.price,
          })),
      ];
    }, []);

  const totalCost =
    sum(newOrder.items.baseProducts) +
    sum(newOrder.items.addOns) -
    sum(newOrder.items.discounts);

  const uploadFile = async () => {
    try {
      if (!fileInputRef.current.files[0]) return '';
      const ReactS3Client = new S3({
        bucketName: 'colab-platformbucket',
        dirName: `clients/${organizationName}/documents`,
        region: 'us-west-1',
        accessKeyId: process.env.REACT_APP_AWS_TOKEN_KEYID,
        secretAccessKey: process.env.REACT_APP_AWS_TOKEN_KEYSECRET,
      });
      const res = await ReactS3Client.uploadFile(
        fileInputRef.current.files[0],
        `${crypto.randomUUID()}-sow.pdf`
      );
      if (!res.status === 204) throw new Error('Error uploading file');
      return res.location;
    } catch (err) {
      console.log(err);
      return '';
    }
  };

  const createOrder = async (fileUrl) => {
    try {
      if (newOrder.items.baseProducts.length !== 1) {
        throw new Error('You must select one base product');
      }
      await axiosWithAuth().post('/orders', {
        organization_id: newOrder.organizationId,
        order_items: getOrderItems(),
        sow_url: fileUrl,
      });
      await fetchOrders();
    } catch (e) {
      setNewOrder((prev) => ({
        ...prev,
        errorMessage: e.message,
      }));
    }
  };

  const deleteOrder = async () => {
    try {
      await axiosWithAuth().delete(`/orders/${newOrder.id}`);
      await fetchOrders();
    } catch (e) {
      setNewOrder((prev) => ({
        ...prev,
        errorMessage: e.message,
      }));
    }
  };

  const updateOrder = async (fileUrl) => {
    try {
      await axiosWithAuth().put(`/orders/${newOrder.id}`, {
        order_items: getOrderItems(),
        sow_url: fileUrl,
      });
      await fetchOrders();
    } catch (e) {
      setNewOrder((prev) => ({
        ...prev,
        errorMessage: e.message,
      }));
    }
  };

  const handleConfirm = async () => {
    const fileUrl = await uploadFile();
    await {
      create: () => createOrder(fileUrl),
      edit: () => updateOrder(fileUrl),
    }[newOrder.mode]();
    setNewOrder((prev) => ({ ...prev, isModalOpen: false }));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Dialog.Root
      open={newOrder.isModalOpen}
      onOpenChange={(open) => {
        if (open) fetchOrders();
        setNewOrder((prev) => ({ ...prev, isModalOpen: open }));
      }}
    >
      <Dialog.Portal>
        <Overlay />
        {newOrder.mode === 'delete' ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newOrder.mode}`}>
              Delete Order #{newOrder.id}?
            </Dialog.Title>
            <Dialog.Description className="description">
              You will loose all the data associated with this order.
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewOrder((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button
                text="Confirm"
                onClick={async () => {
                  await deleteOrder();
                  setNewOrder((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }));
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newOrder.mode}`}>
              {
                {
                  edit: 'Edit Order',
                  create: 'Create New Order',
                }[newOrder.mode]
              }
            </Dialog.Title>
            <div className="categories">
              {[
                { key: 'baseProducts', key2: 'base_product' },
                { key: 'addOns', key2: 'add_on' },
                { key: 'discounts', key2: 'discount' },
              ].map(({ key, key2 }) => (
                <div className="category">
                  <span>{camelToSentenceCase(key)}</span>
                  <div className="items">
                    {newOrder.items[key]?.map((item, index) => (
                      <div className="item">
                        <DropdownMenu.Root>
                          <Trigger>
                            {item.product_name
                              ? snakeCaseToTitleCase(item.product_name)
                              : `Select ${camelToSentenceCase(key)}`}
                          </Trigger>
                          <DropdownMenu.Portal>
                            <DDContent onClick={(e) => e.stopPropagation()}>
                              {productsListFetch.data[key2]?.map((product) => (
                                <Item
                                  onSelect={() => {
                                    setNewOrder((prev) => ({
                                      ...prev,
                                      items: {
                                        ...prev.items,
                                        [key]: prev.items[key].map((a, i) =>
                                          i === index
                                            ? {
                                                product_id: product.id,
                                                product_type: product.type,
                                                product_name: product.name,
                                                price: product.price,
                                              }
                                            : a
                                        ),
                                      },
                                    }));
                                  }}
                                >
                                  {snakeCaseToTitleCase(product.name)}
                                </Item>
                              ))}
                            </DDContent>
                          </DropdownMenu.Portal>
                        </DropdownMenu.Root>
                        <Textfield
                          name="price"
                          value={item.price}
                          inputTag="number"
                          thousandSeparator=","
                          decimalSeparator="."
                          prefix={
                            item.product_type === 'discount' ? '-$' : '+$'
                          }
                          decimalScale={2}
                          fixedDecimalScale
                          allowNegative={false}
                          onChange={(e) => {
                            setNewOrder((prev) => ({
                              ...prev,
                              items: {
                                ...prev.items,
                                [key]: prev.items[key].map((a, i) =>
                                  i === index
                                    ? {
                                        ...a,
                                        price: parseCurrency(e.target.value),
                                      }
                                    : a
                                ),
                              },
                            }));
                          }}
                        />
                        {key !== 'baseProducts' && (
                          <Button
                            icon={<Trash2 />}
                            variant="secondary"
                            onClick={() => {
                              setNewOrder((prev) => ({
                                ...prev,
                                items: {
                                  ...prev.items,
                                  [key]: prev.items[key].filter(
                                    (a, i) => i !== index
                                  ),
                                },
                              }));
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                  {key !== 'baseProducts' && (
                    <Button
                      icon={<Plus />}
                      className="add-item"
                      variant="tertiary"
                      fullWidth
                      onClick={() => {
                        setNewOrder((prev) => ({
                          ...prev,
                          items: {
                            ...prev.items,
                            [key]: [...prev.items[key], {}],
                          },
                        }));
                      }}
                    />
                  )}
                </div>
              ))}
              <div className="item">
                <span className="label">TOTAL:</span>
                <span className="price">{formatUsd.format(totalCost)}</span>
              </div>
              <div className="category">
                <span>SOW</span>
                <input
                  type="file"
                  className="fileInput"
                  accept=".pdf"
                  ref={fileInputRef}
                />
              </div>
            </div>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewOrder((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button text="Confirm" onClick={handleConfirm} />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewOrderModal;
