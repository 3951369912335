import React from 'react';
import styled from 'styled-components';
import { Card } from 'colabs-ui-react';
import { Helmet } from 'react-helmet';
import CoEfficientLabsIcon from '../assets/icons/CoefficientLabs';

const StyledPrivacyPolicy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f5f5f5;
  > .container {
    padding: 2rem;
    width: 100%;
    max-width: 1200px;
    .logo {
      height: 2rem;
      fill: #1171fa;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #1171fa;
    }
  }
`;

const PrivacyPolicy = () => (
  <StyledPrivacyPolicy>
    <Helmet>
      <title>Privacy Policy</title>
      <meta name="description" content="Coefficient Labs Privacy Policy" />
      <meta
        property="og:url"
        content="https://staging-portal.coefficientlabs.com/privacy"
      />
      <link rel="canonical" href="https://staging-portal.coefficientlabs.com" />
    </Helmet>

    <Card elevation={5} className="container">
      <CoEfficientLabsIcon className="logo" />
      <h1 className="c28" id="h.bvxqihug6798">
        <span className="c30">Privacy Policy of </span>
        <span className="c29 c30 c17">coefficientlabs.com</span>
      </h1>
      <p className="c5">
        <span className="c0">
          This Application collects some Personal Data from its Users.
        </span>
      </p>
      <p className="c23 c22 c16">
        <span className="c11" />
      </p>
      <p className="c25 c16">
        <span className="c15">This document contains </span>
        <span className="c24">
          <a
            className="c26"
            href="https://www.google.com/url?q=https://www.iubenda.com/privacy-policy/86858406/legal%23california_info&amp;sa=D&amp;source=editors&amp;ust=1651104336627398&amp;usg=AOvVaw0gjpxw3zLH5P-CeNQnxANo"
          >
            a section dedicated to Californian consumers and their privacy
            rights
          </a>
        </span>
        <span className="c0">.</span>
      </p>
      <p className="c5">
        <span className="c0">
          This document can be printed for reference by using the print command
          in the settings of any browser.
        </span>
      </p>
      <h2 className="c27" id="h.qoqolyx2jvtn">
        <span className="c17 c19">Owner and Data Controller</span>
      </h2>
      <p className="c7">
        <span className="c0">CoEfficient Labs Inc.</span>
      </p>
      <p className="c7">
        <span className="c0">312 Arizona Ave Santa Monica 90405</span>
      </p>
      <p className="c1">
        <span className="c15 c17">Owner contact email:</span>
        <span className="c0">&nbsp;sean@coefficientlabs.com</span>
      </p>
      <h2 className="c27" id="h.ufamy5ov3z55">
        <span className="c19 c17">Types of Data collected</span>
      </h2>
      <p className="c7">
        <span className="c0">
          Among the types of Personal Data that this Application collects, by
          itself or through third parties, there are: Tracker; Usage Data.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Complete details on each type of Personal Data collected are provided
          in the dedicated sections of this privacy policy or by specific
          explanation texts displayed prior to the Data collection.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Personal Data may be freely provided by the User, or, in case of Usage
          Data, collected automatically when using this Application.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Unless specified otherwise, all Data requested by this Application is
          mandatory and failure to provide this Data may make it impossible for
          this Application to provide its services. In cases where this
          Application specifically states that some Data is not mandatory, Users
          are free not to communicate this Data without consequences to the
          availability or the functioning of the Service.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Users who are uncertain about which Personal Data is mandatory are
          welcome to contact the Owner.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Any use of Cookies &ndash; or of other tracking tools &ndash; by this
          Application or by the owners of third-party services used by this
          Application serves the purpose of providing the Service required by
          the User, in addition to any other purposes described in the present
          document and in the Cookie Policy, if available.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Users are responsible for any third-party Personal Data obtained,
          published or shared through this Application and confirm that they
          have the third party&#39;s consent to provide the Data to the Owner.
        </span>
      </p>
      <h2 className="c27" id="h.tpcauebtakzu">
        <span className="c19 c17">Mode and place of processing the Data</span>
      </h2>
      <h3 className="c13" id="h.4lotzwybjoog">
        <span className="c8">Methods of processing</span>
      </h3>
      <p className="c5">
        <span className="c0">
          The Owner takes appropriate security measures to prevent unauthorized
          access, disclosure, modification, or unauthorized destruction of the
          Data.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          The Data processing is carried out using computers and/or IT enabled
          tools, following organizational procedures and modes strictly related
          to the purposes indicated. In addition to the Owner, in some cases,
          the Data may be accessible to certain types of persons in charge,
          involved with the operation of this Application (administration,
          sales, marketing, legal, system administration) or external parties
          (such as third-party technical service providers, mail carriers,
          hosting providers, IT companies, communications agencies) appointed,
          if necessary, as Data Processors by the Owner. The updated list of
          these parties may be requested from the Owner at any time.
        </span>
      </p>
      <h3 className="c13" id="h.ts0ztka64nk4">
        <span className="c8">Legal basis of processing</span>
      </h3>
      <p className="c5">
        <span className="c0">
          The Owner may process Personal Data relating to Users if one of the
          following applies:
        </span>
      </p>
      <ul className="c9 lst-kix_t1yp9pnl2ugw-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0">
            Users have given their consent for one or more specific purposes.
            Note: Under some legislations the Owner may be allowed to process
            Personal Data until the User objects to such processing
            (&ldquo;opt-out&rdquo;), without having to rely on consent or any
            other of the following legal bases. This, however, does not apply,
            whenever the processing of Personal Data is subject to European data
            protection law;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            provision of Data is necessary for the performance of an agreement
            with the User and/or for any pre-contractual obligations thereof;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            processing is necessary for compliance with a legal obligation to
            which the Owner is subject;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            processing is related to a task that is carried out in the public
            interest or in the exercise of official authority vested in the
            Owner;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            processing is necessary for the purposes of the legitimate interests
            pursued by the Owner or by a third party.
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c0">
          In any case, the Owner will gladly help to clarify the specific legal
          basis that applies to the processing, and in particular whether the
          provision of Personal Data is a statutory or contractual requirement,
          or a requirement necessary to enter into a contract.
        </span>
      </p>
      <h3 className="c13" id="h.9ti944vbzkpf">
        <span className="c8">Place</span>
      </h3>
      <p className="c5">
        <span className="c0">
          The Data is processed at the Owner&#39;s operating offices and in any
          other places where the parties involved in the processing are located.
        </span>
      </p>
      <p className="c5 c22">
        <span className="c0" />
      </p>
      <p className="c5">
        <span className="c0">
          Depending on the User&#39;s location, data transfers may involve
          transferring the User&#39;s Data to a country other than their own. To
          find out more about the place of processing of such transferred Data,
          Users can check the section containing details about the processing of
          Personal Data.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Users are also entitled to learn about the legal basis of Data
          transfers to a country outside the European Union or to any
          international organization governed by public international law or set
          up by two or more countries, such as the UN, and about the security
          measures taken by the Owner to safeguard their Data.
        </span>
      </p>
      <p className="c1 c22">
        <span className="c0" />
      </p>
      <p className="c1">
        <span className="c0">
          If any such transfer takes place, Users can find out more by checking
          the relevant sections of this document or inquire with the Owner using
          the information provided in the contact section.
        </span>
      </p>
      <h3 className="c13" id="h.ga7xcaexfj6i">
        <span className="c8">Retention time</span>
      </h3>
      <p className="c5">
        <span className="c0">
          Personal Data shall be processed and stored for as long as required by
          the purpose they have been collected for.
        </span>
      </p>
      <p className="c1">
        <span className="c0">Therefore:</span>
      </p>
      <ul className="c9 lst-kix_egg3t01ba628-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0">
            Personal Data collected for purposes related to the performance of a
            contract between the Owner and the User shall be retained until such
            contract has been fully performed.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            Personal Data collected for the purposes of the Owner&rsquo;s
            legitimate interests shall be retained as long as needed to fulfill
            such purposes. Users may find specific information regarding the
            legitimate interests pursued by the Owner within the relevant
            sections of this document or by contacting the Owner.
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c0">
          The Owner may be allowed to retain Personal Data for a longer period
          whenever the User has given consent to such processing, as long as
          such consent is not withdrawn. Furthermore, the Owner may be obliged
          to retain Personal Data for a longer period whenever required to do so
          for the performance of a legal obligation or upon order of an
          authority.
        </span>
      </p>
      <p className="c1 c22">
        <span className="c0" />
      </p>
      <p className="c1">
        <span className="c0">
          Once the retention period expires, Personal Data shall be deleted.
          Therefore, the right to access, the right to erasure, the right to
          rectification and the right to data portability cannot be enforced
          after expiration of the retention period.
        </span>
      </p>
      <h2 className="c27" id="h.xr5ko2h5c1uk">
        <span className="c19 c17">The purposes of processing</span>
      </h2>
      <p className="c7">
        <span className="c0">
          The Data concerning the User is collected to allow the Owner to
          provide its Service, comply with its legal obligations, respond to
          enforcement requests, protect its rights and interests (or those of
          its Users or third parties), detect any malicious or fraudulent
          activity, as well as the following: Analytics and Registration and
          authentication.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          For specific information about the Personal Data used for each
          purpose, the User may refer to the section &ldquo;Detailed information
          on the processing of Personal Data&rdquo;.
        </span>
      </p>
      <h2 className="c27" id="h.7a1zac79l9b4">
        <span className="c19 c17">
          Detailed information on the processing of Personal Data
        </span>
      </h2>
      <p className="c7">
        <span className="c0">
          Personal Data is collected for the following purposes and using the
          following services:
        </span>
      </p>
      <h3 className="c4" id="h.k2o8dyqlnzyk">
        <span className="c8">Contacting the User</span>
      </h3>
      <h4 className="c25" id="h.ovfkqzb2lcv">
        <span className="c6">Contact form (this Application)</span>
      </h4>
      <p className="c7">
        <span className="c0">
          By filling in the contact form with their Data, the User authorizes
          this Application to use these details to reply to requests for
          information, quotes or any other kind of request as indicated by the
          form&rsquo;s header.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Personal Data processed: company name; email address; first name; last
          name; phone number.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Category of personal data collected according to CCPA: identifiers;
          commercial information.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          This processing constitutes a sale based on the definition under the
          CCPA. In addition to the information in this clause, the User can find
          information regarding how to opt out of the sale in the section
          detailing the rights of Californian consumers.
        </span>
      </p>
      <h4 className="c2" id="h.fju8pic7van9">
        <span className="c6">
          Mailing list or newsletter (this Application)
        </span>
      </h4>
      <p className="c7">
        <span className="c0">
          By registering on the mailing list or for the newsletter, the
          User&rsquo;s email address will be added to the contact list of those
          who may receive email messages containing information of commercial or
          promotional nature concerning this Application. Your email address
          might also be added to this list as a result of signing up to this
          Application or after making a purchase.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Personal Data processed: company name; email address; first name; last
          name; phone number.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Category of personal data collected according to CCPA: identifiers;
          commercial information.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          This processing constitutes a sale based on the definition under the
          CCPA. In addition to the information in this clause, the User can find
          information regarding how to opt out of the sale in the section
          detailing the rights of Californian consumers.
        </span>
      </p>
      <p className="c7 c22">
        <span className="c0" />
      </p>
      <h3 className="c4" id="h.dc1rl363v5me">
        <span className="c8">Analytics</span>
      </h3>
      <p className="c12">
        <span className="c0">
          The services contained in this section enable the Owner to monitor and
          analyze web traffic and can be used to keep track of User behavior.
        </span>
      </p>
      <h4 className="c2" id="h.jgysuwsywzo4">
        <span className="c6">Google Analytics (Google LLC)</span>
      </h4>
      <p className="c10">
        <span className="c0">
          Google Analytics is a web analysis service provided by Google LLC
          (&ldquo;Google&rdquo;). Google utilizes the Data collected to track
          and examine the use of this Application, to prepare reports on its
          activities and share them with other Google services.
        </span>
      </p>
      <p className="c32">
        <span className="c0">
          Google may use the Data collected to contextualize and personalize the
          ads of its own advertising network.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          Personal Data processed: Tracker; Usage Data.
        </span>
      </p>
      <p className="c18">
        <span className="c15">Place of processing: United States &ndash; </span>
        <span className="c24">
          <a
            className="c26"
            href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1651104336632099&amp;usg=AOvVaw1xgGTPUzPPK0-IrgEG70d4"
          >
            Privacy Policy
          </a>
        </span>
        <span className="c15">&nbsp;&ndash; </span>
        <span className="c24">
          <a
            className="c26"
            href="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1651104336632342&amp;usg=AOvVaw3Cl9z28oP5QMg_Ozy7cEp1"
          >
            Opt Out
          </a>
        </span>
        <span className="c0">.</span>
      </p>
      <p className="c10">
        <span className="c0">
          Category of personal data collected according to CCPA: internet
          information.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          This processing constitutes a sale based on the definition under the
          CCPA. In addition to the information in this clause, the User can find
          information regarding how to opt out of the sale in the section
          detailing the rights of Californian consumers.
        </span>
      </p>
      <h3 className="c4" id="h.ykf81y1v8mip">
        <span className="c8">Registration and authentication</span>
      </h3>
      <p className="c12">
        <span className="c0">
          By registering or authenticating, Users allow this Application to
          identify them and give them access to dedicated services.
        </span>
      </p>
      <p className="c12">
        <span className="c0">
          Depending on what is described below, third parties may provide
          registration and authentication services. In this case, this
          Application will be able to access some Data, stored by these
          third-party services, for registration or identification purposes.
        </span>
      </p>
      <p className="c12">
        <span className="c0">
          Some of the services listed below may also collect Personal Data for
          targeting and profiling purposes; to find out more, please refer to
          the description of each service.
        </span>
      </p>
      <h4 className="c2" id="h.55f5vtrqhffs">
        <span className="c6">Google OAuth (Google LLC)</span>
      </h4>
      <p className="c32">
        <span className="c0">
          Google OAuth is a registration and authentication service provided by
          Google LLC and is connected to the Google network.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          Personal Data processed: various types of Data as specified in the
          privacy policy of the service.
        </span>
      </p>
      <p className="c18">
        <span className="c15">Place of processing: United States &ndash; </span>
        <span className="c24">
          <a
            className="c26"
            href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1651104336633114&amp;usg=AOvVaw31v0N0BY5aZAJ_1aEdpiCE"
          >
            Privacy Policy
          </a>
        </span>
        <span className="c0">.</span>
      </p>
      <p className="c10">
        <span className="c0">
          Category of personal data collected according to CCPA: internet
          information.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          This processing constitutes a sale based on the definition under the
          CCPA. In addition to the information in this clause, the User can find
          information regarding how to opt out of the sale in the section
          detailing the rights of Californian consumers.
        </span>
      </p>
      <h4 className="c2" id="h.l61h5ur3zvob">
        <span className="c6">Linkedin OAuth (LinkedIn Corporation)</span>
      </h4>
      <p className="c32">
        <span className="c0">
          Linkedin Oauth is a registration and authentication service provided
          by Linkedin Corporation and is connected to the Linkedin social
          network.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          Personal Data processed: various types of Data as specified in the
          privacy policy of the service.
        </span>
      </p>
      <p className="c18">
        <span className="c15">Place of processing: United States &ndash; </span>
        <span className="c24">
          <a
            className="c26"
            href="https://www.google.com/url?q=https://www.linkedin.com/legal/privacy-policy&amp;sa=D&amp;source=editors&amp;ust=1651104336633662&amp;usg=AOvVaw19Cr2JqN93B58K5jW8s7gn"
          >
            Privacy Policy
          </a>
        </span>
        <span className="c0">.</span>
      </p>
      <p className="c10">
        <span className="c0">
          Category of personal data collected according to CCPA: internet
          information.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          This processing constitutes a sale based on the definition under the
          CCPA. In addition to the information in this clause, the User can find
          information regarding how to opt out of the sale in the section
          detailing the rights of Californian consumers.
        </span>
      </p>
      <h4 className="c2" id="h.4753bxendf01">
        <span className="c6">Twitter OAuth (Twitter, Inc.)</span>
      </h4>
      <p className="c32">
        <span className="c0">
          Twitter Oauth is a registration and authentication service provided by
          Twitter, Inc. and is connected to the Twitter social network.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          Personal Data processed: various types of Data as specified in the
          privacy policy of the service.
        </span>
      </p>
      <p className="c18">
        <span className="c15">Place of processing: United States &ndash; </span>
        <span className="c24">
          <a
            className="c26"
            href="https://www.google.com/url?q=https://dev.twitter.com/terms/api-terms&amp;sa=D&amp;source=editors&amp;ust=1651104336634194&amp;usg=AOvVaw2NDciu3JcUPCCicFpaAUEh"
          >
            Privacy Policy
          </a>
        </span>
        <span className="c0">.</span>
      </p>
      <p className="c10">
        <span className="c0">
          Category of personal data collected according to CCPA: internet
          information.
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          This processing constitutes a sale based on the definition under the
          CCPA. In addition to the information in this clause, the User can find
          information regarding how to opt out of the sale in the section
          detailing the rights of Californian consumers.
        </span>
      </p>
      <h2 className="c27 c16" id="h.4i1b24hoel74">
        <span className="c19 c17">Further information about Personal Data</span>
      </h2>
      <h3 className="c4" id="h.v8wyfadx6fzx">
        <span className="c8">Preference Cookies</span>
      </h3>
      <p className="c10">
        <span className="c0">
          Preference Cookies store the User preferences detected on this
          Application in the local domain such as, for example, their timezone
          and region.
        </span>
      </p>
      <h3 className="c4" id="h.2h9ontt9js6z">
        <span className="c8">localStorage</span>
      </h3>
      <p className="c10">
        <span className="c0">
          localStorage allows this Application to store and access data right in
          the User&#39;s browser with no expiration date.
        </span>
      </p>
      <h2 className="c27 c16" id="h.i5t50t39cfq3">
        <span className="c19 c17">The rights of Users</span>
      </h2>
      <p className="c7">
        <span className="c0">
          Users may exercise certain rights regarding their Data processed by
          the Owner.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          In particular, Users have the right to do the following:
        </span>
      </p>
      <ul className="c9 lst-kix_hgmw5n3gblhs-0 start">
        <li className="c3 li-bullet-0">
          <span className="c15 c17">Withdraw their consent at any time.</span>
          <span className="c0">
            &nbsp;Users have the right to withdraw consent where they have
            previously given their consent to the processing of their Personal
            Data.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c15 c17">Object to processing of their Data.</span>
          <span className="c0">
            &nbsp;Users have the right to object to the processing of their Data
            if the processing is carried out on a legal basis other than
            consent. Further details are provided in the dedicated section
            below.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c15 c17">Access their Data.</span>
          <span className="c0">
            &nbsp;Users have the right to learn if Data is being processed by
            the Owner, obtain disclosure regarding certain aspects of the
            processing and obtain a copy of the Data undergoing processing.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c15 c17">Verify and seek rectification.</span>
          <span className="c0">
            &nbsp;Users have the right to verify the accuracy of their Data and
            ask for it to be updated or corrected.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c15 c17">
            Restrict the processing of their Data.
          </span>
          <span className="c0">
            &nbsp;Users have the right, under certain circumstances, to restrict
            the processing of their Data. In this case, the Owner will not
            process their Data for any purpose other than storing it.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c15 c17">
            Have their Personal Data deleted or otherwise removed.
          </span>
          <span className="c0">
            &nbsp;Users have the right, under certain circumstances, to obtain
            the erasure of their Data from the Owner.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c15 c17">
            Receive their Data and have it transferred to another controller.
          </span>
          <span className="c0">
            &nbsp;Users have the right to receive their Data in a structured,
            commonly used and machine readable format and, if technically
            feasible, to have it transmitted to another controller without any
            hindrance. This provision is applicable provided that the Data is
            processed by automated means and that the processing is based on the
            User&#39;s consent, on a contract which the User is part of or on
            pre-contractual obligations thereof.
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c15 c17">Lodge a complaint.</span>
          <span className="c0">
            &nbsp;Users have the right to bring a claim before their competent
            data protection authority.
          </span>
        </li>
      </ul>
      <h3 className="c13 c16" id="h.btqey24dh412">
        <span className="c8">
          Details about the right to object to processing
        </span>
      </h3>
      <p className="c5">
        <span className="c0">
          Where Personal Data is processed for a public interest, in the
          exercise of an official authority vested in the Owner or for the
          purposes of the legitimate interests pursued by the Owner, Users may
          object to such processing by providing a ground related to their
          particular situation to justify the objection.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Users must know that, however, should their Personal Data be processed
          for direct marketing purposes, they can object to that processing at
          any time without providing any justification. To learn, whether the
          Owner is processing Personal Data for direct marketing purposes, Users
          may refer to the relevant sections of this document.
        </span>
      </p>
      <h3 className="c13 c16" id="h.pcpllwkr172">
        <span className="c8">How to exercise these rights</span>
      </h3>
      <p className="c5">
        <span className="c0">
          Any requests to exercise User rights can be directed to the Owner
          through the contact details provided in this document. These requests
          can be exercised free of charge and will be addressed by the Owner as
          early as possible and always within one month.
        </span>
      </p>
      <h2 className="c27 c16" id="h.eixdhep6p9c7">
        <span className="c19 c17">
          Additional information about Data collection and processing
        </span>
      </h2>
      <h3 className="c13 c16" id="h.q29hdqr9xjfa">
        <span className="c8">Legal action</span>
      </h3>
      <p className="c5">
        <span className="c0">
          The User&#39;s Personal Data may be used for legal purposes by the
          Owner in Court or in the stages leading to possible legal action
          arising from improper use of this Application or the related Services.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          The User declares to be aware that the Owner may be required to reveal
          personal data upon request of public authorities.
        </span>
      </p>
      <h3 className="c13 c16" id="h.l3hvb3q1qchl">
        <span className="c8">
          Additional information about User&#39;s Personal Data
        </span>
      </h3>
      <p className="c5">
        <span className="c0">
          In addition to the information contained in this privacy policy, this
          Application may provide the User with additional and contextual
          information concerning particular Services or the collection and
          processing of Personal Data upon request.
        </span>
      </p>
      <h3 className="c13 c16" id="h.jrws8l209qp2">
        <span className="c8">System logs and maintenance</span>
      </h3>
      <p className="c5">
        <span className="c0">
          For operation and maintenance purposes, this Application and any
          third-party services may collect files that record interaction with
          this Application (System logs) use other Personal Data (such as the IP
          Address) for this purpose.
        </span>
      </p>
      <h3 className="c13 c16" id="h.rn3tb3awg2ca">
        <span className="c8">Information not contained in this policy</span>
      </h3>
      <p className="c5">
        <span className="c0">
          More details concerning the collection or processing of Personal Data
          may be requested from the Owner at any time. Please see the contact
          information at the beginning of this document.
        </span>
      </p>
      <h3 className="c13 c16" id="h.k4afcwrljxol">
        <span className="c8">
          How &ldquo;Do Not Track&rdquo; requests are handled
        </span>
      </h3>
      <p className="c5">
        <span className="c0">
          This Application does not support &ldquo;Do Not Track&rdquo; requests.
        </span>
      </p>
      <p className="c5">
        <span className="c0">
          To determine whether any of the third-party services it uses honor the
          &ldquo;Do Not Track&rdquo; requests, please read their privacy
          policies.
        </span>
      </p>
      <h3 className="c13 c16" id="h.dcuuend38b6u">
        <span className="c8">Changes to this privacy policy</span>
      </h3>
      <p className="c5">
        <span className="c0">
          The Owner reserves the right to make changes to this privacy policy at
          any time by notifying its Users on this page and possibly within this
          Application and/or - as far as technically and legally feasible -
          sending a notice to Users via any contact information available to the
          Owner. It is strongly recommended to check this page often, referring
          to the date of the last modification listed at the bottom.
        </span>
      </p>
      <p className="c5 c22">
        <span className="c0" />
      </p>
      <p className="c5">
        <span className="c0">
          Should the changes affect processing activities performed on the basis
          of the User&rsquo;s consent, the Owner shall collect new consent from
          the User, where required.
        </span>
      </p>
      <h2 className="c27 c16" id="h.oef4qxzewpqr">
        <span className="c19 c17">Information for Californian consumers</span>
      </h2>
      <p className="c7">
        <span className="c0">
          This part of the document integrates with and supplements the
          information contained in the rest of the privacy policy and is
          provided by the business running this Application and, if the case may
          be, its parent, subsidiaries and affiliates (for the purposes of this
          section referred to collectively as &ldquo;we&rdquo;,
          &ldquo;us&rdquo;, &ldquo;our&rdquo;).
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          The provisions contained in this section apply to all Users who are
          consumers residing in the state of California, United States of
          America, according to &quot;The California Consumer Privacy Act of
          2018&quot; (Users are referred to below, simply as &ldquo;you&rdquo;,
          &ldquo;your&rdquo;, &ldquo;yours&rdquo;), and, for such consumers,
          these provisions supersede any other possibly divergent or conflicting
          provisions contained in the privacy policy.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          This part of the document uses the term &ldquo;personal
          information&ldquo; as it is defined in The California Consumer Privacy
          Act (CCPA).
        </span>
      </p>
      <h3 className="c13 c16" id="h.g2uv0bu7uau1">
        <span className="c8">
          Categories of personal information collected, disclosed or sold
        </span>
      </h3>
      <p className="c5">
        <span className="c15">
          In this section we summarize the categories of personal information
          that we&#39;ve collected, disclosed or sold and the purposes thereof.{' '}
        </span>
        <span className="c15 c17 c29">
          You can read about these activities in detail in the section titled
          &ldquo;Detailed information on the processing of Personal Data&rdquo;
          within this document.
        </span>
      </p>
      <h4 className="c2 c16" id="h.sigjyhkrqw9n">
        <span className="c6">
          Information we collect: the categories of personal information we
          collect
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          We have collected the following categories of personal information
          about you: internet information.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          We will not collect additional categories of personal information
          without notifying you.
        </span>
      </p>
      <h4 className="c2 c16" id="h.pk66ygq6s98s">
        <span className="c6">
          How we collect information: what are the sources of the personal
          information we collect?
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          We collect the above mentioned categories of personal information,
          either directly or indirectly, from you when you use this Application.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          For example, you directly provide your personal information when you
          submit requests via any forms on this Application. You also provide
          personal information indirectly when you navigate this Application, as
          personal information about you is automatically observed and
          collected. Finally, we may collect your personal information from
          third parties that work with us in connection with the Service or with
          the functioning of this Application and features thereof.
        </span>
      </p>
      <h4 className="c2 c16" id="h.6vhvn1s6gcv4">
        <span className="c6">
          How we use the information we collect: sharing and disclosing of your
          personal information with third parties for a business purpose
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          We may disclose the personal information we collect about you to a
          third party for business purposes. In this case, we enter a written
          agreement with such third party that requires the recipient to both
          keep the personal information confidential and not use it for any
          purpose(s) other than those necessary for the performance of the
          agreement.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          We may also disclose your personal information to third parties when
          you explicitly ask or authorize us to do so, in order to provide you
          with our Service.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          To find out more about the purposes of processing, please refer to the
          relevant section of this document.
        </span>
      </p>
      <h4 className="c2 c16" id="h.npct8o397bqa">
        <span className="c6">Sale of your personal information</span>
      </h4>
      <p className="c1">
        <span className="c15">
          For our purposes, the word &ldquo;sale&rdquo; means any
          &ldquo;selling, renting, releasing, disclosing, disseminating, making
          available, transferring or otherwise communicating orally, in writing,
          or by electronic means, a consumer&#39;s personal information by the
          business to{' '}
        </span>
        <span className="c15 c17">
          another business or a third party, for monetary or other valuable
          consideration
        </span>
        <span className="c0">&rdquo;.</span>
      </p>
      <p className="c1">
        <span className="c0">
          This means that, for example, a sale can happen whenever an
          application runs ads, or makes statistical analyses on the traffic or
          views, or simply because it uses tools such as social network plugins
          and the like.
        </span>
      </p>
      <h4 className="c2 c16" id="h.zbgrobpa24g9">
        <span className="c6">
          Your right to opt out of the sale of personal information
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          You have the right to opt out of the sale of your personal
          information. This means that whenever you request us to stop selling
          your data, we will abide by your request.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Such requests can be made freely, at any time, without submitting any
          verifiable request, simply by following the instructions below.
        </span>
      </p>
      <h4 className="c2 c16" id="h.a7sxg910py9b">
        <span className="c6">
          Instructions to opt out of the sale of personal information
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          If you&rsquo;d like to know more, or exercise your right to opt out in
          regard to all the sales carried out by this Application, both online
          and offline, you can contact us for further information using the
          contact details provided in this document.
        </span>
      </p>
      <h4 className="c2 c16" id="h.rt4lb2cjf16p">
        <span className="c6">
          What are the purposes for which we use your personal information?
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          We may use your personal information to allow the operational
          functioning of this Application and features thereof (&ldquo;business
          purposes&rdquo;). In such cases, your personal information will be
          processed in a fashion necessary and proportionate to the business
          purpose for which it was collected, and strictly within the limits of
          compatible operational purposes.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          We may also use your personal information for other reasons such as
          for commercial purposes (as indicated within the section
          &ldquo;Detailed information on the processing of Personal Data&rdquo;
          within this document), as well as for complying with the law and
          defending our rights before the competent authorities where our rights
          and interests are threatened or we suffer an actual damage.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          We will not use your personal information for different, unrelated, or
          incompatible purposes without notifying you.
        </span>
      </p>
      <h3 className="c13 c16" id="h.mhxi082ac8u3">
        <span className="c8">
          Your California privacy rights and how to exercise them
        </span>
      </h3>
      <h4 className="c2 c16" id="h.f34rd2d8kjg3">
        <span className="c6">The right to know and to portability</span>
      </h4>
      <p className="c1">
        <span className="c0">
          You have the right to request that we disclose to you:
        </span>
      </p>
      <ul className="c9 lst-kix_eskvh4ewn1mj-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0">
            the categories and sources of the personal information that we
            collect about you, the purposes for which we use your information
            and with whom such information is shared;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            in case of sale of personal information or disclosure for a business
            purpose, two separate lists where we disclose:
          </span>
        </li>
      </ul>
      <ul className="c9 lst-kix_eskvh4ewn1mj-1 start">
        <li className="c21 li-bullet-0">
          <span className="c0">
            for sales, the personal information categories purchased by each
            category of recipient; and
          </span>
        </li>
        <li className="c21 li-bullet-0">
          <span className="c0">
            for disclosures for a business purpose, the personal information
            categories obtained by each category of recipient.
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c0">
          The disclosure described above will be limited to the personal
          information collected or used over the past 12 months.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          If we deliver our response electronically, the information enclosed
          will be &quot;portable&quot;, i.e. delivered in an easily usable
          format to enable you to transmit the information to another entity
          without hindrance &ndash; provided that this is technically feasible.
        </span>
      </p>
      <h4 className="c2 c16" id="h.172gi8t58msj">
        <span className="c6">
          The right to request the deletion of your personal information
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          You have the right to request that we delete any of your personal
          information, subject to exceptions set forth by the law (such as,
          including but not limited to, where the information is used to
          identify and repair errors on this Application, to detect security
          incidents and protect against fraudulent or illegal activities, to
          exercise certain rights etc.).
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          If no legal exception applies, as a result of exercising your right,
          we will delete your personal information and direct any of our service
          providers to do so.
        </span>
      </p>
      <h4 className="c2 c16" id="h.baqqj7vhrj8v">
        <span className="c6">How to exercise your rights</span>
      </h4>
      <p className="c1">
        <span className="c0">
          To exercise the rights described above, you need to submit your
          verifiable request to us by contacting us via the details provided in
          this document.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          For us to respond to your request, it&rsquo;s necessary that we know
          who you are. Therefore, you can only exercise the above rights by
          making a verifiable request which must:
        </span>
      </p>
      <ul className="c9 lst-kix_ds796d5w0mv5-0 start">
        <li className="c3 li-bullet-0">
          <span className="c0">
            provide sufficient information that allows us to reasonably verify
            you are the person about whom we collected personal information or
            an authorized representative;
          </span>
        </li>
        <li className="c3 li-bullet-0">
          <span className="c0">
            describe your request with sufficient detail that allows us to
            properly understand, evaluate, and respond to it.
          </span>
        </li>
      </ul>
      <p className="c1">
        <span className="c0">
          We will not respond to any request if we are unable to verify your
          identity and therefore confirm the personal information in our
          possession actually relates to you.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          If you cannot personally submit a verifiable request, you can
          authorize a person registered with the California Secretary of State
          to act on your behalf.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          If you are an adult, you can make a verifiable request on behalf of a
          minor under your parental authority.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          You can submit a maximum number of 2 requests over a period of 12
          months.
        </span>
      </p>
      <h4 className="c2 c16" id="h.h4ijua4n0vrs">
        <span className="c6">
          How and when we are expected to handle your request
        </span>
      </h4>
      <p className="c1">
        <span className="c0">
          We will confirm receipt of your verifiable request within 10 days and
          provide information about how we will process your request.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          We will respond to your request within 45 days of its receipt. Should
          we need more time, we will explain to you the reasons why, and how
          much more time we need. In this regard, please note that we may take
          up to 90 days to fulfill your request.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Our disclosure(s) will cover the preceding 12 month period.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          Should we deny your request, we will explain you the reasons behind
          our denial.
        </span>
      </p>
      <p className="c1">
        <span className="c0">
          We do not charge a fee to process or respond to your verifiable
          request unless such request is manifestly unfounded or excessive. In
          such cases, we may charge a reasonable fee, or refuse to act on the
          request. In either case, we will communicate our choices and explain
          the reasons behind it.
        </span>
      </p>
      <p className="c22 c23">
        <span className="c14" />
      </p>
      <h3 className="c31" id="h.psea2lo1l7eg">
        <span className="c8">Definitions and legal references</span>
      </h3>
      <h4 className="c25" id="h.q5hcz4rqre7i">
        <span className="c6">Personal Data (or Data)</span>
      </h4>
      <p className="c10">
        <span className="c0">
          Any information that directly, indirectly, or in connection with other
          information &mdash; including a personal identification number &mdash;
          allows for the identification or identifiability of a natural person.
        </span>
      </p>
      <h4 className="c2" id="h.d2qhdpkm3wc8">
        <span className="c6">Usage Data</span>
      </h4>
      <p className="c10">
        <span className="c0">
          Information collected automatically through this Application (or
          third-party services employed in this Application), which can include:
          the IP addresses or domain names of the computers utilized by the
          Users who use this Application, the URI addresses (Uniform Resource
          Identifier), the time of the request, the method utilized to submit
          the request to the server, the size of the file received in response,
          the numerical code indicating the status of the server&#39;s answer
          (successful outcome, error, etc.), the country of origin, the features
          of the browser and the operating system utilized by the User, the
          various time details per visit (e.g., the time spent on each page
          within the Application) and the details about the path followed within
          the Application with special reference to the sequence of pages
          visited, and other parameters about the device operating system and/or
          the User&#39;s IT environment.
        </span>
      </p>
      <h4 className="c2" id="h.tcflmp4vr4f3">
        <span className="c6">User</span>
      </h4>
      <p className="c10">
        <span className="c0">
          The individual using this Application who, unless otherwise specified,
          coincides with the Data Subject.
        </span>
      </p>
      <h4 className="c2" id="h.nrg3k3uco4ks">
        <span className="c6">Data Subject</span>
      </h4>
      <p className="c10">
        <span className="c0">
          The natural person to whom the Personal Data refers.
        </span>
      </p>
      <h4 className="c2" id="h.w7t135r6dv9l">
        <span className="c6">Data Processor (or Data Supervisor)</span>
      </h4>
      <p className="c10">
        <span className="c0">
          The natural or legal person, public authority, agency or other body
          which processes Personal Data on behalf of the Controller, as
          described in this privacy policy.
        </span>
      </p>
      <h4 className="c2" id="h.sorovxlfva3z">
        <span className="c6">Data Controller (or Owner)</span>
      </h4>
      <p className="c10">
        <span className="c0">
          The natural or legal person, public authority, agency or other body
          which, alone or jointly with others, determines the purposes and means
          of the processing of Personal Data, including the security measures
          concerning the operation and use of this Application. The Data
          Controller, unless otherwise specified, is the Owner of this
          Application.
        </span>
      </p>
      <h4 className="c2" id="h.l3wyactcr2ju">
        <span className="c6">This Application</span>
      </h4>
      <p className="c10">
        <span className="c0">
          The means by which the Personal Data of the User is collected and
          processed.
        </span>
      </p>
      <h4 className="c2" id="h.lnt40fgvht8v">
        <span className="c6">Service</span>
      </h4>
      <p className="c10">
        <span className="c0">
          The service provided by this Application as described in the relative
          terms (if available) and on this site/application.
        </span>
      </p>
      <h4 className="c2" id="h.c90pgll7xsy7">
        <span className="c6">European Union (or EU)</span>
      </h4>
      <p className="c10">
        <span className="c0">
          Unless otherwise specified, all references made within this document
          to the European Union include all current member states to the
          European Union and the European Economic Area.
        </span>
      </p>
      <h4 className="c2" id="h.c8g8u8l2umu9">
        <span className="c6">Cookie</span>
      </h4>
      <p className="c10">
        <span className="c0">
          Cookies are Trackers consisting of small sets of data stored in the
          User&#39;s browser.
        </span>
      </p>
      <h4 className="c2" id="h.8vgbs88y31k0">
        <span className="c6">Tracker</span>
      </h4>
      <p className="c10">
        <span className="c0">
          Tracker indicates any technology - e.g Cookies, unique identifiers,
          web beacons, embedded scripts, e-tags and fingerprinting - that
          enables the tracking of Users, for example by accessing or storing
          information on the User&rsquo;s device.
        </span>
      </p>
      <hr />
      <p className="c32 c22">
        <span className="c11" />
      </p>
      <h4 className="c2" id="h.xc20gnwu8n99">
        <span className="c6">Legal information</span>
      </h4>
      <p className="c10">
        <span className="c0">
          This privacy statement has been prepared based on provisions of
          multiple legislations, including Art. 13/14 of Regulation (EU)
          2016/679 (General Data Protection Regulation).
        </span>
      </p>
      <p className="c10">
        <span className="c0">
          This privacy policy relates solely to this Application, if not stated
          otherwise within this document.
        </span>
      </p>
    </Card>
  </StyledPrivacyPolicy>
);

PrivacyPolicy.propTypes = {};

PrivacyPolicy.defaultProps = {};

export default PrivacyPolicy;
