import * as Dialog from '@radix-ui/react-dialog';
import { Button, Textfield } from 'colabs-ui-react';
import React, { useState } from 'react';
import S3 from 'react-aws-s3';
import { Loader, X } from 'react-feather';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import TikTokThumbnail from './TikTokThumbnail';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 32rem;
  padding: clamp(2rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    &.delete,
    &.remove-from-deck {
      margin-bottom: 0.5rem;
    }
  }
  > .description {
    margin: 0 0 1rem 0;
  }
  > .cui-textfield {
    margin-bottom: 0.5rem;
    .loader-icon {
      height: 1rem;
      width: 1rem;
      color: #6da7fd;
      animation: spin 2s linear infinite;
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  > .or {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0.5rem 0;
  }
  > .dropZone {
    border: 1px dashed #ccc;
    height: 10rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    :hover {
      cursor: pointer;
    }
    &[data-dragover='true'] {
      border: 1px solid #1171ff;
      > p {
        color: #1171ff;
      }
    }
    > input {
      z-index: 100;
      opacity: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      :hover {
        cursor: pointer;
      }
    }
  }
  > .cui-media {
    height: 16rem;
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid #d3d3d3;
    position: relative;
    z-index: 100;
    background-color: white;
    object-fit: contain;
    object-position: center center;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
  }
  > .cui-dropdown {
    margin-bottom: 0.5rem;
    .select {
      overflow-y: scroll;
      max-height: 14rem;
    }
  }
  > .buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }
  .cui-button {
    text-transform: capitalize;
    align-self: flex-end;
  }
  > .error {
    color: red;
    font-size: 0.75rem;
    margin-top: 0.5rem;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

function dataURLtoFile(dataUrl, fileName) {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}

const NewReferenceModal = ({
  newReference,
  setNewReference,
  fetchAllReferences,
  fetchDeck,
}) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const [filePreview, setFilePreview] = useState({
    status: 'start',
    file: null,
    data: null,
    url: '',
    error: '',
  });
  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();
  const ReactS3Client = new S3({
    bucketName: 'colabs-ads',
    s3Url: 'https://colabs-ads.s3.amazonaws.com',
    region: 'us-east-1',
    accessKeyId: process.env.REACT_APP_AWS_TOKEN_KEYID,
    secretAccessKey: process.env.REACT_APP_AWS_TOKEN_KEYSECRET,
  });

  const handlePreview = (files) => {
    if (files && files[0]) {
      Object.values(files).map((file) => {
        if (!['video', 'image'].includes(file.type.split('/')[0])) {
          setFilePreview((prev) => ({
            ...prev,
            error: 'Please provide a valid file to upload!',
          }));
          return undefined;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
          setFilePreview({
            data: e.target.result,
            file,
            status: 'success',
            url: '',
          });
        };
        reader.readAsDataURL(file);
        return undefined;
      });
    }
    setDragOver(false);
  };

  const uploadAssetAndGetUrl = async () => {
    try {
      const file = dataURLtoFile(filePreview.data, filePreview.file.name);
      const fileName = `${new Date().getTime()}-${filePreview.file.name.replace(
        /[^a-zA-Z0-9-]/gi,
        ''
      )}`;
      const { location } = await ReactS3Client.uploadFile(file, fileName);
      return location;
    } catch (err) {
      console.error('Could not upload file to S3 ', filePreview.file.name, err);
      return null;
    }
  };

  const createReference = async () => {
    setNewReference((prev) => ({ ...prev, actionState: 'loading' }));
    try {
      const url = filePreview.data
        ? await uploadAssetAndGetUrl()
        : filePreview.url;
      await axiosWithAuth().post('/reference-tool/references', {
        url,
        deck_id: newReference.deck_id,
      });
      setNewReference((prev) => ({
        ...prev,
        actionState: 'success',
        isModalOpen: false,
      }));
      if (newReference.deck_id) {
        await fetchDeck();
      } else {
        await fetchAllReferences();
      }
    } catch (e) {
      setNewReference((prev) => ({
        ...prev,
        actionState: 'error',
        isModalOpen: false,
      }));
    }
    setFilePreview({
      status: 'start',
      file: null,
      data: null,
      url: '',
    });
  };

  const deleteReference = async () => {
    if (!newReference.id) return;
    setNewReference((prev) => ({ ...prev, actionState: 'loading' }));
    try {
      await axiosWithAuth().delete(
        `/reference-tool/references/${newReference.id}`
      );
      setNewReference((prev) => ({
        ...prev,
        actionState: 'success',
        isModalOpen: false,
      }));
      await fetchAllReferences();
    } catch (e) {
      setNewReference((prev) => ({
        ...prev,
        actionState: 'error',
        isModalOpen: false,
      }));
    }
  };

  const removeReferenceFromDeck = async () => {
    setNewReference((prev) => ({ ...prev, actionState: 'loading' }));
    try {
      await axiosWithAuth().post('/reference-tool/reference-deck', {
        reference_id: newReference.id,
        deck_id: newReference.deck_id,
      });
      setNewReference((prev) => ({
        ...prev,
        actionState: 'success',
        isModalOpen: false,
      }));
      await fetchDeck();
    } catch (e) {
      setNewReference((prev) => ({
        ...prev,
        actionState: 'error',
        isModalOpen: false,
      }));
    }
  };

  return (
    <Dialog.Root
      open={newReference.isModalOpen}
      onOpenChange={(open) =>
        setNewReference((prev) => ({ ...prev, isModalOpen: open }))
      }
    >
      <Dialog.Portal>
        <Overlay />
        {['delete', 'remove-from-deck'].includes(newReference.mode) ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newReference.mode}`}>
              {
                {
                  delete: 'Delete Reference',
                  'remove-from-deck': 'Remove Reference from Deck',
                }[newReference.mode]
              }
            </Dialog.Title>
            <Dialog.Description className="description">
              {
                {
                  delete:
                    'This will permanently remove all associated comments and ratings from every deck it has been added to. This action cannot be undone. Proceed?',
                  'remove-from-deck':
                    'This will permanently delete all associated comments and ratings. This action cannot be undone. Proceed?.',
                }[newReference.mode]
              }
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                disabled={newReference.actionState === 'loading'}
                variant="secondary"
                onClick={() =>
                  setNewReference((prev) => ({ ...prev, isModalOpen: false }))
                }
              />
              <Button
                text={
                  newReference.actionState === 'loading'
                    ? 'Loading...'
                    : 'Confirm'
                }
                disabled={newReference.actionState === 'loading'}
                onClick={async () => {
                  await {
                    delete: deleteReference,
                    'remove-from-deck': removeReferenceFromDeck,
                  }[newReference.mode]();
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newReference.mode}`}>
              {newReference.deck_id
                ? 'Create Reference and Add to this Deck'
                : 'Create New Reference'}{' '}
            </Dialog.Title>
            <TikTokThumbnail
              filePreview={filePreview}
              setFilePreview={setFilePreview}
            />
            {filePreview?.file?.type.match(/^video/) != null && (
              <video
                className="cui-media"
                muted
                src={`${filePreview.data}#t=2`}
              />
            )}
            {filePreview?.file?.type.match(/^image/) != null && (
              <img
                className="cui-media"
                alt="media"
                src={filePreview.data}
                onError={(e) => {
                  e.target.src = blueImage;
                }}
              />
            )}
            {filePreview.status === 'success' && (
              <div className="buttons">
                <Button
                  text="Cancel"
                  disabled={newReference.actionState === 'loading'}
                  variant="secondary"
                  onClick={() => {
                    setFilePreview({
                      status: 'start',
                      file: null,
                      data: null,
                      url: '',
                    });
                  }}
                />
                <Button
                  text={
                    newReference.actionState === 'loading'
                      ? 'Loading...'
                      : 'Confirm'
                  }
                  disabled={newReference.actionState === 'loading'}
                  onClick={createReference}
                />
              </div>
            )}
            {filePreview.status !== 'success' && (
              <>
                <Textfield
                  id="deck-name-textfield"
                  label="Paste TikTok Url:"
                  name="url"
                  placeholder="https://www.tiktok.com/@username/video/1234567890"
                  value={filePreview.url}
                  icon={
                    filePreview.status === 'loading' ? (
                      <Loader className="loader-icon" />
                    ) : null
                  }
                  iconPos="right"
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    setFilePreview((prev) => ({
                      ...prev,
                      url: e.target.value,
                    }))
                  }
                />
                <div className="or">Or</div>
                <div
                  htmlFor="file"
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDrop={(e) => {
                    e.preventDefault();
                    handlePreview(e.dataTransfer.files);
                  }}
                  data-dragover={dragOver}
                  className="dropZone"
                >
                  <p size={5}>
                    {!dragOver
                      ? 'Select/Drop your Files here...'
                      : 'Drop here...'}
                  </p>
                  <input
                    multiple
                    type="file"
                    id="file"
                    name="file"
                    onChange={(e) => handlePreview(e.target.files)}
                  />
                </div>
              </>
            )}
            {filePreview?.error && (
              <div className="error">{filePreview.error}</div>
            )}
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewReferenceModal;
