import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import frame from '../../assets/images/iPhoneFrame.png';

const StyledPhoneMockup = styled.div`
  height: 736px;
  width: 368px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.mobile {
    transform: scale(${({ scale }) => scale}%);
    transform-origin: top ${({ scale }) => (scale < 100 ? 'left' : 'center')};
  }
  > .frame {
    object-fit: contain;
    height: 115.2%;
    width: 135%;
    position: absolute;
    top: -1%;
    left: -35%;
    z-index: 999;
    pointer-events: none;
  }
  > .content {
    position: absolute;
    overflow: hidden;
    top: 2.2%;
    left: 5.4%;
    height: 95.9%;
    width: 89%;
    border-radius: 20px;
    &.isMobile {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

const PhoneMockup = ({ children, isMobile, width = 368 }) => {
  return (
    <StyledPhoneMockup
      className={`phoneMockup ${isMobile ? 'mobile' : ''}`}
      scale={(width / 368) * 100}
    >
      {!isMobile && (
        <>
          <img className="frame" src={frame} alt="" />
        </>
      )}
      <span className={`content ${isMobile ? 'isMobile' : ''}`}>
        {children}
      </span>
    </StyledPhoneMockup>
  );
};

PhoneMockup.propTypes = {
  children: PropTypes.element.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

PhoneMockup.defaultProps = {};

export default PhoneMockup;
