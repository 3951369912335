import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { FetchData } from '../../actions';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import HomePage from '../Home';

const AdminHomePage = () => {
  const { orderId } = useParams();
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchData(axiosWithAuthRef.current, `/organizations/${orderId}`)); // orderId is organizationId temporarily
  }, [orderId, dispatch, axiosWithAuthRef]);
  const org = useSelector((state) => state.data);

  return <HomePage org={org} />;
};

export default AdminHomePage;
