import { Button, Divider, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Clock, X } from 'react-feather';
import styled from 'styled-components';
import PinIcon from '../../assets/icons/PinIcon';
import CommentTextfield from '../copys/CommentTextField';
import CopyComment from '../copys/CopyComment';
import Pin from '../Pin';

const StyledCommentsPanel = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  > .commentsBody {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.scrollbar}
    > .noCommentsMsg {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  > .commentTextfield {
    padding: 1rem;
  }
  .cui-divider {
    border: none !important;
    border-top: 1px solid transparent !important;
    background: #e0e0e0 !important;
  }
`;

const CommentsPanel = ({
  comments,
  editComment,
  likeComment,
  deleteComment,
  postComment,
  sendDisabled,
  textfieldPlaceholder,
  pinIsAttached,
  setPinIsGrabbed,
  cleanUpPin,
  pins,
  creativeMediaEl,
}) => {
  const [isAttachedPinHovered, setIsAttachedPinHovered] = useState(false);

  return (
    <StyledCommentsPanel className="commentsWrapper">
      <div className="commentsBody">
        {comments?.length > 0 ? (
          comments?.map((comment) => (
            <CopyComment
              key={comment.id}
              editComment={editComment}
              comment={comment}
              likeComment={likeComment}
              deleteComment={deleteComment}
              onTextfieldCancel={() => {}}
              postComment={postComment}
              // commentAttachment={(() => {
              //   const pinIndex = pins?.findIndex((pin) => {
              //     return pin.comment_id === comment.id;
              //   });
              //   if (pinIndex < 0) return undefined;
              //   return (
              //     <Pin
              //       number={`${pinIndex + 1}${` — ${new Date(
              //         pins[pinIndex]?.time_in_seconds * 1000
              //       )
              //         .toUTCString()
              //         .split(' ')[4]
              //         .substring(3)}`}`}
              //       onClick={() => {
              //         if (
              //           creativeMediaEl?.tagName !== 'VIDEO' ||
              //           !pins[pinIndex]?.time_in_seconds
              //         )
              //           return;
              //         creativeMediaEl.currentTime =
              //           pins[pinIndex].time_in_seconds;
              //       }}
              //       className={`${
              //         pins[pinIndex]?.time_in_seconds ? 'timestamp' : ''
              //       }${(() => {
              //         if (pins[pinIndex]?.is_internal) return ' internal';
              //         if (pins[pinIndex]?.user_organization_id === 1)
              //           return ' colabs';
              //         return '';
              //       })()}`}
              //     />
              //   );
              // })()}
            />
          ))
        ) : (
          <div className="noCommentsMsg">
            <Text>No Comments in Reference</Text>
          </div>
        )}
      </div>
      <Divider />
      <div className="commentTextfield">
        <CommentTextfield
          postComment={postComment}
          onCancel={() => {}}
          sendDisabled={sendDisabled}
          commentAttachments={
            pinIsAttached ? (
              <Pin
                number={
                  isAttachedPinHovered ? (
                    <X size="0.9rem" color="#1171FA" />
                  ) : (
                    <Text size={1} color="blue">
                      {(pins?.length || 0) + 1}
                    </Text>
                  )
                }
                onMouseEnter={() => setIsAttachedPinHovered(true)}
                onMouseLeave={() => setIsAttachedPinHovered(false)}
                onClick={cleanUpPin}
              />
            ) : (
              ''
            )
          }
          placeholder={textfieldPlaceholder}
          annotationTools={
            <>
              {!pinIsAttached && (
                <Button
                  className="tool"
                  disabled
                  variant="tertiary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPinIsGrabbed(true);
                  }}
                  icon={<PinIcon className={`icon bigger`} />}
                />
              )}
              <Button
                className="tool"
                variant="tertiary"
                disabled={
                  !creativeMediaEl || creativeMediaEl?.tagName !== 'VIDEO'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  const inputEl = document.querySelector('#editableInput-root');
                  inputEl.innerHTML += ` <span class="timestamp" contenteditable="false" >
                    ${new Date(creativeMediaEl?.currentTime * 1000)
                      .toUTCString()
                      .split(' ')[4]
                      .substring(3)}
                  </span> `;
                }}
                icon={
                  <Clock
                    className={`icon ${
                      !creativeMediaEl || creativeMediaEl.tagName !== 'VIDEO'
                        ? 'disabled'
                        : ''
                    }`}
                  />
                }
              />
            </>
          }
        />
      </div>
    </StyledCommentsPanel>
  );
};

CommentsPanel.propTypes = {
  isCommentPanelOpen: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape()),
  copyToComment: PropTypes.shape(),
  editComment: PropTypes.func.isRequired,
  likeComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  postComment: PropTypes.func.isRequired,
  changeCopyToComment: PropTypes.func.isRequired,
  commentAttachment: PropTypes.shape().isRequired,
};

CommentsPanel.defaultProps = {
  comments: null,
  copyToComment: null,
};

export default CommentsPanel;
