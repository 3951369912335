import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AdApprovalDisplay from '../components/ads/AdApprovalDisplay';
import AdApprovalBar from '../components/ads/Bar';
import FeedbackModal from '../components/ads/FeedbackModal';
import usePin from '../hooks/usePin';
import useWindowDimensions from '../hooks/useWindowDimensions';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';
// import PhoneView from '../components/adApprovalSections/PhoneView';
import AdsCommentsPanel from '../components/ads/AdsCommentsPanel';

const StyledAdsPage = styled.div`
  padding: 0.75rem;
  display: grid;
  height: 100%;
  max-height: calc(100vh - 4rem);
  grid-template-columns: 1fr 28rem;
  grid-template-rows: min-content 1fr;
  grid-gap: 0.75rem;
  width: 100%;
  max-width: 100rem;
  ${({ pinIsGrabbed }) => (pinIsGrabbed ? 'cursor: grabbing;' : '')}
  @media ${({ theme }) => theme.breakpoint.lg} {
    grid-template-columns: 1fr;
  }
`;

const AdsPage = () => {
  const [creativeDeck, setCreativeDeck] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [activeAdIndex, setActiveAdIndex] = useState();
  const [activeAssetIndex, setActiveAssetIndex] = useState(0);
  const [commentsWithTextfields, setCommentsWithTextfields] = useState([]);
  // const [mediaType, setMediaType] = useState();
  const [dataFetchingStatus, setDataFetchingStatus] = useState('start');
  const [isViewInGrid, setIsViewInGrid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [internalCommentsShown, setInternalCommentsShown] = useState(false);
  const [currentMainAction, setCurrentMainAction] = useState();
  const [adPinnedNotes, setAdPinnedNotes] = useState([]);
  const [internalAdPinnedNotes, setInternalAdPinnedNotes] = useState([]);
  const { deckId, adId } = useParams();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  const { push } = useHistory();
  const { width } = useWindowDimensions();
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const isMounted = useRef(true);
  const newPinRef = useRef(null);
  const adContainerRef = useRef();
  const videoRef = { current: document.querySelector('#adDisplay') };
  const currentAd = creativeDeck?.ads[activeAdIndex];
  // let creativeMediaEl = document.querySelector('#creativeMedia');
  const shownAdPinnedNotes = internalCommentsShown
    ? internalAdPinnedNotes
    : adPinnedNotes;
  const { cleanUpPin, pinIsAttached, pinIsGrabbed, setPinIsGrabbed } = usePin(
    newPinRef?.current
      ? newPinRef
      : { current: document.querySelector('#newPin') },
    adContainerRef?.current
      ? adContainerRef
      : { current: document.querySelector('#creativeWrapper') },
    commentsWithTextfields?.length > 0 || internalCommentsShown
  );

  const pushAdId = (index) =>
    push(
      `${pathname.split('/').splice(0, 5).join('/')}/${
        creativeDeck?.ads[index].id
      }`
    );

  const changeAd = (step, index) => {
    cleanUpPin();
    setActiveAssetIndex(0);
    if (index >= 0) {
      pushAdId(index);
      return;
    }
    if (step === 1 && creativeDeck?.ads.length === activeAdIndex + 1) {
      pushAdId(0);
      return;
    }
    if (step === -1 && activeAdIndex === 0) {
      pushAdId(creativeDeck?.ads.length - 1);
      return;
    }
    pushAdId(activeAdIndex + step);
  };

  const fetchCreativeDeck = useCallback(async () => {
    if (!isMounted.current) return;
    try {
      setDataFetchingStatus('loading');
      const { data } = await axiosWithAuthRef
        .current()
        .post('/cms/creativeDeck', {
          id: deckId,
        });
      setCreativeDeck(data);
      setDataFetchingStatus('success');
    } catch (e) {
      setDataFetchingStatus('error');
    }
  }, [axiosWithAuthRef, deckId]);

  const reviewAd = useCallback(async () => {
    try {
      await axiosWithAuthRef.current().post('/cms/adView', {
        ad_id: adId,
      });
    } catch (e) {
      // setRequestState('error');
    }
  }, [adId, axiosWithAuthRef, user.id]);

  const assignCurrentMainAction = () => {
    if (!currentAd?.approved_by?.some(({ id }) => id === user?.id)) {
      setCurrentMainAction('approve');
      return;
    }
    setCurrentMainAction('nextAd');
  };

  const redirectToAd = () => {
    if (!creativeDeck) return undefined;
    if (!adId) return push(`${pathname}/${creativeDeck?.ads[0].id}`);
    if (
      activeAdIndex !== undefined &&
      currentAd.id !== adId &&
      !creativeDeck?.ads.find(({ id }) => id === Number(adId))
    ) {
      return push(
        `${pathname.split('/').splice(0, 3).join('/')}/${
          creativeDeck?.ads[0].id
        }`
      );
    }
    return setActiveAdIndex(
      creativeDeck?.ads.findIndex(({ id }) => Number(adId) === id)
    );
  };

  // useEffect(() => {
  //   creativeMediaEl = document.querySelector('#creativeMedia');
  //   setMediaType(creativeMediaEl?.tagName);
  // }, [creativeDeck]);

  useEffect(() => {
    if (
      !creativeDeck ||
      activeAdIndex === undefined ||
      currentAd?.reviewed_by.map(({ id }) => id).includes(user?.id)
    ) {
      return;
    }
    reviewAd();
  }, [activeAdIndex, creativeDeck, user.id, reviewAd]);

  useEffect(() => {
    assignCurrentMainAction();
  }, [activeAdIndex, creativeDeck, user.id]);

  useEffect(() => {
    if (user?.email !== 'sean@coefficientlabs.com') return;
    setInternalCommentsShown(true);
  }, [user]);

  useEffect(() => {
    fetchCreativeDeck();
  }, [fetchCreativeDeck]);

  useEffect(() => {
    redirectToAd();
  }, [creativeDeck, activeAdIndex, adId, pathname, push]);

  return (
    <MainLayout centered>
      {user.permissions.includes('approveAds') && (
        <FeedbackModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          creativeDeck={creativeDeck}
        />
      )}
      <StyledAdsPage pinIsGrabbed={pinIsGrabbed}>
        {(width > 1279 || activeTab === 0) && (
          <AdApprovalBar
            ads={creativeDeck?.ads}
            dataFetchingStatus={dataFetchingStatus}
            changeAd={(step, index) => {
              cleanUpPin();
              setCommentsWithTextfields([]);
              changeAd(step, index);
            }}
            activeAdIndex={activeAdIndex}
            creativeDeck={creativeDeck}
          />
        )}
        {/*  <PhoneView
             creative={
               currentAsset.url
             }
             changeAsset={changeAsset}
             pins={adPinnedNotes.filter(
               (pin) =>
                 pin.asset_id ===
                 currentAsset.id
             )}
             socialProfilePicture=""
             socialUsername=""
             currentPlatform="instagram"
             setCurrentPlatform={() => {}}
             multipleAssets={
               currentAd?.assets?.length > 1
             }
             pinsEnabled
             mediaType={mediaType}
             creativeMediaEl={creativeMediaEl}
           /> */}
        {(width > 1279 || activeTab === 0) && (
          <AdApprovalDisplay
            ad={currentAd}
            activeAssetIndex={activeAssetIndex}
            setActiveAssetIndex={setActiveAssetIndex}
            cleanUpPin={cleanUpPin}
            adContainerRef={adContainerRef}
            adPinnedNotes={shownAdPinnedNotes}
            isViewInGrid={isViewInGrid}
            setIsViewInGrid={setIsViewInGrid}
            newPinRef={newPinRef}
            videoRef={videoRef}
            lastUpdate={new Date(creativeDeck?.updated_at)}
            internalCommentsShown={internalCommentsShown}
          />
        )}
        <AdsCommentsPanel
          internalCommentsShown={internalCommentsShown}
          currentAd={currentAd}
          activeAssetIndex={activeAssetIndex}
          shownAdPinnedNotes={shownAdPinnedNotes}
          adContainerRef={adContainerRef}
          videoRef={videoRef}
          fetchCreativeDeck={fetchCreativeDeck}
          setIsModalOpen={setIsModalOpen}
          creativeDeck={creativeDeck}
          activeAdIndex={activeAdIndex}
          setActiveAssetIndex={setActiveAssetIndex}
          pushAdId={pushAdId}
          cleanUpPin={cleanUpPin}
          setCommentsWithTextfields={setCommentsWithTextfields}
          setCurrentMainAction={setCurrentMainAction}
          dataFetchingStatus={dataFetchingStatus}
          activeTab={activeTab}
          setInternalCommentsShown={setInternalCommentsShown}
          setActiveTab={setActiveTab}
          pinIsAttached={pinIsAttached}
          pinIsGrabbed={pinIsGrabbed}
          setPinIsGrabbed={setPinIsGrabbed}
          isViewInGrid={isViewInGrid}
          currentMainAction={currentMainAction}
          changeAd={changeAd}
          setAdPinnedNotes={setAdPinnedNotes}
          setInternalAdPinnedNotes={setInternalAdPinnedNotes}
        />
      </StyledAdsPage>
    </MainLayout>
  );
};

export default AdsPage;
