import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'colabs-ui-react';

const StyledPin = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  backdrop-filter: blur(10px);
  z-index: 100;
  cursor: pointer;
  transform: translate(-50%, -50%);
  background: #ae41e8;
  transform: translate(0, 0);
  :hover {
    background: #ae41e8;
  }
  &.timestamp {
    width: auto;
    padding: 0 0.5rem;
  }
  &.colabs {
    background: #1171ff;
  }
  &.internal {
    background: #ffa311;
  }
  &.old {
    background: gray;
  }
  &.newPin {
    display: none;
    position: fixed;
    background: ${({ theme }) => theme.color.secondary.dark};
    filter: drop-shadow(0 0 0.5rem ${({ theme }) => theme.color.secondary.dark})
      contrast(1.5);
    :hover {
      ${({ pinIsGrabbed }) => (pinIsGrabbed ? '' : 'cursor: grab;')}
    }
    :focus {
      outline: none;
    }
  }
`;

const Pin = ({ onClick, number, className, ...props }) => {
  return (
    <StyledPin className={`pin ${className}`} onClick={onClick} {...props}>
      <Text color="white" className="pin-number">
        {number}
      </Text>
    </StyledPin>
  );
};

Pin.propTypes = {
  onClick: PropTypes.func.isRequired,
  // number: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Pin.defaultProps = {
  className: '',
};

export default Pin;
