import * as Dialog from '@radix-ui/react-dialog';
import { Button } from 'colabs-ui-react';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import InviteEmailTextfield from '../paymentSetup/InviteEmail';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 24rem;
  padding: clamp(2rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  gap: 12px;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    &.delete {
      margin-bottom: 0.5rem;
    }
  }
  > .description {
    margin: 0 0 1rem 0;
  }
  > .cui-textfield {
    margin-bottom: 0.5rem;
  }
  > .cui-dropdown {
    margin-bottom: 0.5rem;
    .select {
      overflow-y: scroll;
      max-height: 14rem;
    }
  }
  > .buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    margin-top: 2rem;
    align-self: flex-end;
    &.right {
      justify-content: flex-end;
    }
  }
  .cui-button {
    text-transform: capitalize;
    align-self: flex-end;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

const NewMemberModal = ({ newMember, setNewMember, fetchMembers }) => {
  const [inviteEmail, setInviteEmail] = useState([]);
  const user = useSelector((state) => state.user);
  const { axiosWithAuth } = useAxioswithAuth();

  const sendInvite = async () => {
    try {
      await axiosWithAuth().post(
        `/users/in_organization/${user.organization_id}`,
        inviteEmail.map((email) => ({ email }))
      );
      await axiosWithAuth().put(`/organizations/${user.organization_id}`, {
        onboarding_step: 2,
      });
    } catch (err) {
      // alert('Error sending invite. Please try again later.');
    }
  };

  return (
    <Dialog.Root
      open={newMember.isModalOpen}
      onOpenChange={(open) => {
        if (open) fetchMembers();
        setNewMember((prev) => ({ ...prev, isModalOpen: open }));
      }}
    >
      <Dialog.Portal>
        <Overlay />
        {newMember.mode === 'delete' ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newMember.mode}`}>
              <span className="avoidwrap">Are you sure you want to remove</span>{' '}
              <span className="avoidwrap">"{newMember.name}"?</span>
            </Dialog.Title>
            <Dialog.Description className="description">
              This person will loose access to this organization.
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewMember((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button
                text="Confirm"
                onClick={async () => {
                  // await deleteMember();
                  setNewMember((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }));
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            <InviteEmailTextfield
              inviteEmail={inviteEmail}
              setInviteEmail={setInviteEmail}
            />
            <div className="buttons">
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() =>
                  setNewMember((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button
                text="Send Invite"
                disabled={inviteEmail.length === 0}
                onClick={sendInvite}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewMemberModal;
