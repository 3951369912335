import React from 'react';
import PropTypes from 'prop-types';
import americanexpress from './americanexpress.svg';
import bitcoin from './bitcoin.svg';
import dinnersclub from './dinnersclub.svg';
import discover from './discover.svg';
import jcb from './jcb.svg';
import mastercard from './mastercard.svg';
import paypal from './paypal.svg';
import visa from './visa.svg';
import other from './genericCard.svg';

const logos = {
  americanexpress,
  bitcoin,
  dinnersclub,
  discover,
  jcb,
  mastercard,
  paypal,
  visa,
};

const PaymentMethodIcon = ({ brand }) => {
  return (
    <img
      className="paymentMethodIcon"
      src={logos[brand] || other}
      alt={brand}
    />
  );
};

PaymentMethodIcon.propTypes = {
  brand: PropTypes.oneOf(Object.keys(logos)).isRequired,
};

PaymentMethodIcon.defaultProps = {};

export default PaymentMethodIcon;
