import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CopyListContainer from './CopyListContainer';
import CopyHeader from './CopyHeader';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledCopysDetails = styled.div`
  padding: 1rem 0.5rem 0 0.5rem;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoint.sm} {
    padding: 0.5rem;
  }
`;

const CopysDetails = ({
  copyDeck,
  currentPlatform,
  setCurrentPlatform,
  getCopyCharLimit,
  shownPanels,
  setShownPanels,
  selectedCopys,
  copys,
  copyToComment,
  changeSelectedCopys,
  changeCopyToComment,
  comments,
  setSelectedCopys,
  setCopys,
}) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const user = useSelector((state) => state.user);

  const changeApproval = async (copy, index, action) => {
    try {
      const { data } = await axiosWithAuthRef
        .current()
        .put('/copyDeck/changeApproval', {
          action,
          copy_id: copy.id,
        });
      const newCopys = copys;
      newCopys[copy.platform][copy.type][index].approval_changes = [data];
      setCopys({ ...newCopys });
    } catch (e) {
      console.log(e);
    }
  };

  const editCopy = async (copyId, content, index) => {
    try {
      const { data } = await axiosWithAuth().put(
        `/copyDeck/editCopy/${copyId}`,
        { content }
      );
      const propertyInSelectedCopys = Object.entries(selectedCopys).find(
        ([, { id }]) => id === copyId
      );
      if (propertyInSelectedCopys) {
        setSelectedCopys((prev) => ({
          ...prev,
          [propertyInSelectedCopys[0]]: data,
        }));
      }
      const newCopys = copys;
      newCopys[data.platform][data.type][index] = data;
      setCopys({ ...newCopys });
    } catch (e) {
      console.log(e);
    }
  };

  const countReplies = (replies) => {
    if (!replies || replies.length === 0) return 0;
    return (
      replies.length +
      replies.reduce((acc, reply) => acc + countReplies(reply.replies), 0)
    );
  };
  return (
    <StyledCopysDetails>
      <CopyHeader
        commentsCount={countReplies(comments)}
        organizationLogo={copyDeck?.organization_logo}
        round={copyDeck?.round}
        createdAt={copyDeck?.created_at}
        reviewedBy={[
          ...(copyDeck?.reviewed_by
            ? copyDeck?.reviewed_by?.filter((id) => id !== user?.id?.toString())
            : []),
          ...(user.id ? [user.id?.toString()] : []),
        ]}
        shownPanels={shownPanels}
        setShownPanels={setShownPanels}
      />
      <CopyListContainer
        copys={copys}
        copyDeck={copyDeck}
        currentPlatform={currentPlatform}
        setCurrentPlatform={setCurrentPlatform}
        getCopyCharLimit={getCopyCharLimit}
        copyToComment={copyToComment}
        selectedCopys={selectedCopys}
        editCopy={editCopy}
        setSelectedCopys={changeSelectedCopys}
        changeApproval={changeApproval}
        changeCopyToComment={changeCopyToComment}
      />
    </StyledCopysDetails>
  );
};

CopysDetails.propTypes = {};

CopysDetails.defaultProps = {};

export default CopysDetails;
