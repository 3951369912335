import { Button, Textfield } from 'colabs-ui-react';
import React, { useRef, useState } from 'react';
import { ArrowLeft, Eye, EyeOff } from 'react-feather';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CoEfficientLabsIcon from '../assets/icons/CoefficientLabs';
import LinearGradient from '../components/presentational/LinearGradient';
import StartLayout from '../layouts/Start';
import { api } from '../utils/useAxioswithAuth';

const StyledUpdatePassword = styled.div`
  > .link {
    margin-bottom: 2.5rem;
    display: block;
  }
  > svg {
    height: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    .logo {
      fill: url(#linearGradient);
    }
  }
  > .title {
    font-family: ${({ theme }) => theme.font.main.medium};
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  > .description {
    margin: 0;
    margin-bottom: 1rem;
  }
  > form {
    display: grid;
    grid-gap: 1rem;
    svg {
      cursor: pointer;
    }
    .recaptcha-wrapper {
      > div > div {
        margin: 0 auto;
      }
    }
  }
`;

const UpdatePassword = () => {
  const { userId, token } = useParams();
  const [updateState, setUpdateState] = useState('start');
  const { push } = useHistory();

  const [password, setPassword] = useState({ value: '', pwCheck: '' });
  const [submitError, setSubmitError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const recaptchaRef = useRef();

  function handleChange(e) {
    if (submitError) setSubmitError(null);
    setPassword({ ...password, [e.target.name]: e.target.value });
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      if (!recaptchaRef.current.getValue().length) {
        setSubmitError('Please verify you are not a robot');
        return;
      }
      if (password.pwCheck !== password.value) {
        setSubmitError('Passwords do not match');
        return;
      }
      setUpdateState('loading');
      await api.put('/auth/store-password', {
        user_id: userId,
        token,
        password: password.value,
      });
      setUpdateState('success');
      setTimeout(() => {
        push('/home');
      }, 3000);
    } catch (error) {
      console.log('here', error.message);
      if (error.response.status === 400) {
        alert('Invalid or Expired Link. Please try again.');
        push('/forgot-password');
        return;
      }
      setUpdateState('error');
    }
  }

  return (
    <StartLayout>
      <StyledUpdatePassword>
        <Link to="/login" className="link">
          <Button
            variant="secondary"
            text="Go Back"
            icon={<ArrowLeft />}
            size="sm"
          />
        </Link>
        <LinearGradient color1="#1171FF" color2="#61E3FF" id="linearGradient" />
        <CoEfficientLabsIcon />
        {updateState === 'success' && (
          <>
            <h1 className="title">Password Updated!</h1>
            <p className="description">
              Redirecting <Link to="/home"> Home</Link>...
            </p>
          </>
        )}
        {updateState === 'error' && (
          <>
            <h1 className="title">Error Updating Password</h1>
            <p className="description">Please try again or contact support.</p>
          </>
        )}
        {['loading', 'start'].includes(updateState) && (
          <>
            <h1 className="title">Update Password</h1>
            <p className="description">Please enter your new password below.</p>
            <form onSubmit={onSubmit}>
              <Textfield
                elevated
                label="New Password"
                required
                name="pwCheck"
                autoComplete="off"
                minLength={8}
                helperText="min 8 characters &nbsp;|&nbsp; a letter &nbsp;|
                &nbsp; a number"
                maxLength={32}
                title="Must contain at least 8 characters, including letters and numbers."
                pattern={
                  '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};:\'"\\\\|,.<>?~]*$'
                }
                onChange={handleChange}
                icon={
                  passwordShown ? (
                    <EyeOff onClick={() => setPasswordShown(false)} />
                  ) : (
                    <Eye onClick={() => setPasswordShown(true)} />
                  )
                }
                iconPos="right"
                type={passwordShown ? 'text' : 'password'}
              />
              <Textfield
                elevated
                label="Confirm New Password"
                name="value"
                value={password.value}
                required
                minLength={8}
                autoComplete="off"
                maxLength={32}
                onChange={handleChange}
                pattern={
                  '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};:\'"\\\\|,.<>?~]*$'
                }
                icon={
                  passwordShown ? (
                    <EyeOff onClick={() => setPasswordShown(false)} />
                  ) : (
                    <Eye onClick={() => setPasswordShown(true)} />
                  )
                }
                iconPos="right"
                type={passwordShown ? 'text' : 'password'}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                className="recaptcha-wrapper"
                sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                text="Update Password"
              />
            </form>
          </>
        )}
      </StyledUpdatePassword>
    </StartLayout>
  );
};

export default UpdatePassword;
