import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import howItWorksImage from '../../assets/images/how-it-works.png';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;
  max-width: 64rem;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: min-content 1fr;
  }
  .main-content {
    .title {
      margin: 0;
      margin-bottom: 0.25rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: #3b82f6;
    }
    .subtitle {
      margin: 0;
      margin-bottom: 0.5rem;
    }
    .iframe-container {
      width: 25rem;
      height: 38rem;
      margin: 0;
      border: none;
    }
    .redirect-message {
      margin-top: 0.5rem;
      font-size: 1.125rem;
      text-align: center;
    }
  }
  .side-content {
    color: #6b7280;
    .expect-title {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: 500;
      text-align: center;
    }
    .image-container,
    .video-container {
      width: 100%;
      aspect-ratio: 1;
      border: 1px solid #d1d5db;
      border-radius: 0.375rem;
      overflow: hidden;
      img,
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

const Step = ({ organizationName }) => {
  const user = useSelector((state) => state.user);
  const { orderId } = useParams();
  const [redirectCountdown, setRedirectCountdown] = React.useState(0);
  const { push } = useHistory();
  const { axiosWithAuth } = useAxioswithAuth();

  const updateOrderStatus = async () => {
    try {
      await axiosWithAuth().put(`/orders/${orderId}`, {
        status: '1.5-booked',
      });
    } catch (error) {
      // console.error(error);
    }
  };

  const handleMeetingSubmit = (event) => {
    if (event.origin !== 'https://meetings.hubspot.com') return;
    if (event.data.meetingBookSucceeded) {
      updateOrderStatus();
      let countdown = 5;
      setRedirectCountdown(countdown);
      const intervalId = setInterval(() => {
        countdown -= 1;
        setRedirectCountdown(countdown);
        if (countdown <= 0) {
          clearInterval(intervalId);
          push('/home');
        }
      }, 1000);
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', handleMeetingSubmit);
    return () => {
      window.removeEventListener('message', handleMeetingSubmit);
    };
  }, []);

  return (
    <Wrapper>
      <div className="main-content">
        <h1 className="title">Grab a Creative Brief Slot</h1>
        <p className="subtitle">
          Brands Get 3 FREE trends for being a {user.organization.name} customer
        </p>
        <iframe
          className="iframe-container"
          title="Creative Brief Scheduler"
          src={`https://meetings.hubspot.com/sean-goldfaden/creative-bundle-sync-team?embed=true&${new URLSearchParams(
            Object.fromEntries(
              Object.entries({
                company: organizationName,
              }).filter(([_, v]) => v != null)
            )
          )
            .toString()
            .replaceAll('+', '%20')}`}
        />
        {redirectCountdown ? (
          <p className="redirect-message">Redirecting in {redirectCountdown}</p>
        ) : null}
      </div>
      <div className="side-content">
        <h3 className="expect-title">Here&rsquo;s What to Expect</h3>
        <div className="image-container">
          <img src={howItWorksImage} alt="How it works" />
        </div>
      </div>
    </Wrapper>
  );
};

export default Step;
