import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import blueImage from '../assets/images/BlueImage.png';
import newClientImage from '../assets/images/new-client-btn.webp';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledAgencyHome = styled.div`
  padding: 0.75rem;
  width: 100%;
  max-width: 1310px;
  display: grid;
  gap: 1rem;
  align-content: start;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
  }
  .new-client-card-link {
    grid-column: span 2 !important;
  }
  .card {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    border-radius: 0.5rem;
    overflow: hidden;
    &:hover {
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
    &.new-client {
      background-color: #fff;
      color: #000;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      padding-top: 0.75rem;
      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 3/2;
        object-fit: contain;
      }
      span {
        padding: 0.75rem;
      }
    }
    > img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
    }
    > .company-name-container {
      background-color: #f1f5f9;
      flex-grow: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      h2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        font-size: 2.5rem;
        font-weight: bold;
        text-align: center;
        word-break: break-all;
      }
    }
    .details {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      font-size: 0.875rem;
      padding: 0.75rem;
      .name {
        /* padding: 0.5rem; */
      }
      .status {
        padding: 0.125rem 0.5rem;
        border-radius: 9999px;
        text-align: center;
        color: #fff;
        background-color: #94a3b8;
        &.not-started {
          color: #fff;
          background-color: #f59e0b;
        }
        &.start {
          color: #fff;
          background-color: #f59e0b;
        }
        &.booked {
          color: #fff;
          background-color: #94a3b8;
        }
        &.trends {
          color: #fff;
          background-color: #94a3b8;
        }
        &.scripts-pending,
        &.scripts-reviewed {
          color: #fff;
          background-color: #94a3b8;
        }
        &.actors {
          color: #fff;
          background-color: #94a3b8;
        }
        &.complete {
          color: #fff;
          background-color: #22c55e;
        }
      }
    }
  }
`;

const AgencyHome = () => {
  const { axiosWithAuth } = useAxioswithAuth();
  const [organizationsFetch, setOrganizationsFetch] = useState({
    fetchState: 'start',
    data: [],
  });

  const allOrders = organizationsFetch.data
    .reduce((acc, organization) => {
      const { orders } = organization;
      if (orders.length === 0) {
        acc.push({
          id: `no-orders-${organization.id}`,
          organizationName: organization.name,
          organizationLogo: organization.logo_url,
          organizationId: organization.id,
          baseProducts: [],
          status: '0-not-started',
        });
      } else {
        const ordersWithOrgInfo = orders.map((order) => ({
          id: order.id,
          organizationName: organization.name,
          organizationLogo: organization.logo_url,
          organizationId: organization.id,
          baseProducts: order.order_items?.find(
            (orderItem) => orderItem.product_type === 'base_product'
          )?.product_name,
          status: order.status,
        }));
        acc.push(...ordersWithOrgInfo);
      }
      return acc;
    }, [])
    .sort((a, b) => {
      return a.status.localeCompare(b.status);
    });

  const fetchOrganizations = async () => {
    setOrganizationsFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get('/new-organizations/list');
      setOrganizationsFetch({
        fetchState: 'success',
        data: data?.organizations,
      });
    } catch (err) {
      setOrganizationsFetch((prev) => ({ ...prev, fetchState: 'error' }));
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <MainLayout centered>
      <StyledAgencyHome>
        {allOrders.map((order) => {
          const link = (() => {
            if (order.status === '0-not-started') {
              return `/agency/wizard/${order.organizationId}`;
            }
            if (order.status === '1-start') {
              return `/agency/wizard/${order.organizationId}/${order.id}`;
            }
            return `/orders/${order.id}/brief`;
          })();
          return (
            <Link key={order.id} to={link}>
              <div className={`card ${order.status}`}>
                {!order.organizationLogo && (
                  <div className="company-name-container">
                    <h2>{order.organizationName}</h2>
                  </div>
                )}
                {order.organizationLogo && (
                  <img
                    src={order.organizationLogo || blueImage}
                    onError={(e) => {
                      e.target.src = blueImage;
                    }}
                    alt={`${order.organizationName}-logo`}
                  />
                )}
                <div className="details">
                  <div className="name">{order.organizationName}</div>
                  <div
                    className={`status ${order.status
                      .split('-')
                      .slice(1)
                      .join('-')}`}
                  >
                    {{
                      '0-not-started': 'Incomplete',
                      '1-start': 'Incomplete',
                      '1.5-booked': 'Brief Booked',
                      '2-trends': 'Trends',
                      '3-scripts-pending': 'Scripts',
                      '3-scripts-reviewed': 'Scripts',
                      '4-actors': 'Actors',
                      '5-complete': 'Complete',
                    }[order.status] || 'Unknown'}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
        {organizationsFetch.fetchState === 'success' && (
          <Link to="/agency/wizard" className="new-client-card-link">
            <div className="card new-client">
              <img src={newClientImage} alt="new-client" />
              <span>Click to start a UGC Video Brief</span>
            </div>
          </Link>
        )}
      </StyledAgencyHome>
    </MainLayout>
  );
};

export default AgencyHome;
