import { Button } from 'colabs-ui-react';
import React from 'react';
import styled from 'styled-components';
import phonesImage from '../../assets/phones.png';

const StyledOnboardingStep4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    height: 50vh;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }
  > .title {
    text-align: center;
    font-size: 20px;
    margin: 0 0 1rem 0;
  }
`;

const OnboardingStep4 = ({ setIsOpen }) => {
  return (
    <StyledOnboardingStep4 className="step step-4">
      <img alt="smartphones mockup" src={phonesImage} />
      <h3 className="title">You're all set!</h3>
      <Button size="md" text="Close" onClick={() => setIsOpen(false)} />
    </StyledOnboardingStep4>
  );
};

export default OnboardingStep4;
