import { Card, Chip, Divider, Dropdown, Text } from 'colabs-ui-react';
import { GoogleSpreadsheet } from 'google-spreadsheet';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FetchData } from '../actions';
import executiveReportsThumbnail from '../assets/images/executive-reports.png';
import BudgetGraph from '../components/home/BudgetGraph';
import LeadsGraph from '../components/home/LeadsGraph';
import OverviewCard from '../components/home/OverviewCard';
import SprintGraph from '../components/home/SprintGraph';
import Tasks from '../components/home/Tasks';
import Main from '../layouts/Main';
import { approvalStatus } from '../staticData';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledHomePage = styled.div`
  flex: 1 1 auto;
  padding: 2rem;
  > .header-overview-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > .decks-and-reports {
    margin-top: 1rem;
  }
  > .cards {
    padding: 1rem 0;
    display: grid;
    max-width: 100rem;
    width: 100%;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    @media ${({ theme }) => theme.breakpoint.lg} {
      grid-template-columns: 1fr 1fr;
    }
    @media ${({ theme }) => theme.breakpoint.md} {
      grid-template-columns: 1fr;
    }
    > .card {
      /* BG color in app.scss  */
      cursor: pointer;
      display: flex;
      flex-direction: column;
      @media ${({ theme }) => theme.breakpoint.xl} {
        grid-area: initial !important;
        min-height: 12rem;
      }
      > .title {
        font-size: 1.25rem;
        font-weight: ${({ theme }) => theme.font.main.medium};
      }
      &.leadsGraph {
        overflow: hidden;
      }
      &.budgetGraph {
        overflow: hidden;
      }
      &.sprintGraph {
        overflow: hidden;
      }
    }
  }
`;

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const HomePage = ({ org }) => {
  const { push } = useHistory();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [tasks, setTasks] = useState();
  const [tasksFetchingStatus, setTasksFetchingStatus] = useState('start');
  const latestExecutiveReport = useSelector((state) =>
    Array.isArray(state.executiveReports)
      ? state.executiveReports.sort((a, b) => b.id - a.id)[0]
      : null
  );

  const [data, setdata] = useState(null);
  const [sheetIdSwitcher, setSheetIdSwitcher] = useState(0);
  const { axiosWithAuth } = useAxioswithAuth();
  const [creativeDecks, setCreativeDecks] = useState();
  const [copyDecks, setCopyDecks] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const [currentLeadsProjected, setcurrentLeadsProjected] = useState();
  const dispatch = useDispatch();
  const SPREADSHEET_ID = '1llmYlFdYO7pCp6ckWXrbmWKXAlhoPpjQjfFScPxtTvk';
  const [SHEET_ID, setSHEET_ID] = useState(
    org ? org?.GSheet_ID : user.organization?.GSheet_ID
  );
  const [reRender, setReRender] = useState(null);
  const orgNameVerification = org ? org?.name : user.organization?.name;

  const fetchCreativeDecks = async () => {
    try {
      const { data: _creativeDecks } = await axiosWithAuth().get(
        '/cms/creativeDecks',
        {
          params: { page: 1 },
        }
      );
      setCreativeDecks(_creativeDecks);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTasks = async (organizationId) => {
    setTasksFetchingStatus('loading');
    try {
      const { data: _tasks } = await axiosWithAuth().get(
        `/tasks/${organizationId}`
      );
      setTasks(_tasks);
      setTasksFetchingStatus('success');
    } catch (err) {
      setTasksFetchingStatus('error');
    }
  };

  const fetchCopyDecks = async (organizationId) => {
    try {
      const { data: _copyDecks } = await axiosWithAuth().get(
        `/copydeck/list/${organizationId}`
      );
      setCopyDecks(_copyDecks);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (orgNameVerification === 'Stacker Software Ltd') {
      const stackerAirSheetId = '1520915172';
      const stackerStandAloneSheetId = '1751197736';
      if (sheetIdSwitcher === 0) {
        setSHEET_ID(stackerAirSheetId);
        if (!reRender) {
          setReRender(stackerAirSheetId);
        }
      } else {
        setSHEET_ID(stackerStandAloneSheetId);
      }
    }
    if (orgNameVerification === 'Raydiant') {
      const raydiantSheetId = '1092689499';
      const hooplaSheetId = '1649131928';
      if (sheetIdSwitcher === 0) {
        setSHEET_ID(raydiantSheetId);
        if (!reRender) {
          setReRender(raydiantSheetId);
        }
      } else {
        setSHEET_ID(hooplaSheetId);
      }
    }
    if (
      orgNameVerification !== 'Stacker Software Ltd' &&
      orgNameVerification !== 'Raydiant'
    ) {
      setSHEET_ID(org ? org?.GSheet_ID : user.organization?.GSheet_ID);
      setReRender(org ? org?.GSheet_ID : user.organization?.GSheet_ID);
    }
  }, [sheetIdSwitcher, orgNameVerification, org, user, SHEET_ID]);

  const sheetIdSwitchOptionName = () => {
    if (sheetIdSwitcher === 0 && orgNameVerification === 'Raydiant') {
      return 'Raydiant';
    }
    if (sheetIdSwitcher === 1 && orgNameVerification === 'Raydiant') {
      return 'Hoopla';
    }
    if (
      sheetIdSwitcher === 0 &&
      orgNameVerification === 'Stacker Software Ltd'
    ) {
      return 'Stacker Air';
    }
    if (
      sheetIdSwitcher === 1 &&
      orgNameVerification === 'Stacker Software Ltd'
    ) {
      return 'Stacker StandAlone';
    }
    return undefined;
  };

  async function appendSpreadsheet() {
    const doc = new GoogleSpreadsheet(SPREADSHEET_ID);
    try {
      await doc.useServiceAccountAuth({
        client_email: process.env.REACT_APP_CLIENT_EMAIL,
        private_key: process.env.REACT_APP_PRIVATE_KEY.split('\\n')
          .concat()
          .join('\n'),
      });
      // loads document properties and worksheets
      await doc.loadInfo();
    } catch (e) {
      console.error('Error: ', e);
    }
    const sheet = doc.sheetsById[SHEET_ID];
    await sheet.loadCells('A1:ZI16');
    let TotalSprintLeads = 0;
    let currentWeek = 1;
    let TotalBudget = 0;
    let ActualSpendPlatform1 = 0;
    let ActualSpendPlatform2 = 0;
    let ActualSpendPlatform3 = 0;
    // let ActualSpendPlatform4 = 0;
    // let ActualSpendPlatform5 = 0;
    // let ActualSpendPlatform6 = 0;
    let currentStartDate = '';
    let currentEndDate = '';
    let renewalDate = '';
    let nextSprint = '';
    const sprintActual = [];
    for (let index = 1; index < 13; index += 1) {
      const startDate = new Date(`${sheet.getCell(index, 0).formattedValue}`);
      const endDate = new Date(`${sheet.getCell(index, 1).formattedValue}`);
      const TotalBudgetperRound = Math.round(sheet.getCell(index, 13).value);
      const SpendperRound1 = Math.round(sheet.getCell(index, 15).value);
      ActualSpendPlatform1 += SpendperRound1;
      const SpendperRound2 = Math.round(sheet.getCell(index, 16).value);
      ActualSpendPlatform2 += SpendperRound2;
      const SpendperRound3 = Math.round(sheet.getCell(index, 17).value);
      ActualSpendPlatform3 += SpendperRound3;
      // const SpendperRound4 = Math.round(sheet.getCell(index, 45).value);
      // ActualSpendPlatform4 += SpendperRound4;
      // const SpendperRound5 = Math.round(sheet.getCell(index, 57).value);
      // ActualSpendPlatform5 += SpendperRound5;
      // const SpendperRound6 = Math.round(sheet.getCell(index, 69).value);
      // ActualSpendPlatform6 += SpendperRound6;
      const roundLeadsProjected = Math.round(sheet.getCell(index, 14).value);
      const roundLeadsActual = Math.round(sheet.getCell(index, 10).value);
      sprintActual.push(roundLeadsActual);
      TotalSprintLeads += roundLeadsProjected;
      TotalBudget += TotalBudgetperRound;
      if (new Date() >= startDate && new Date() <= endDate) {
        setcurrentLeadsProjected(roundLeadsProjected);
        if (Math.round(sheet.getCell(index, 2).value) > 0) {
          currentWeek = Math.round(sheet.getCell(index, 2).value);
        }
      } else if (new Date() >= startDate && new Date() >= endDate) {
        setcurrentLeadsProjected(roundLeadsProjected);
        if (Math.round(sheet.getCell(index, 2).value) > 0) {
          currentWeek = Math.round(sheet.getCell(index, 2).value);
        }
      }
      if (index === 1) {
        currentStartDate = `${
          monthNames[startDate.getMonth().toString()]
        } ${startDate
          .getDate()
          .toString()}, ${startDate.getFullYear().toString()}`;
      }
      if (index === 12) {
        currentEndDate = `${
          monthNames[endDate.getMonth().toString()]
        } ${endDate.getDate().toString()}, ${endDate.getFullYear().toString()}`;
        const renewDate = new Date(endDate);
        const nextSprintDate = new Date(endDate);
        const numberOfDaysToAdd = 7;
        renewDate.setDate(renewDate.getDate() - numberOfDaysToAdd);
        nextSprintDate.setDate(nextSprintDate.getDate() + 3);
        renewalDate = `${
          monthNames[renewDate.getMonth().toString()]
        } ${renewDate
          .getDate()
          .toString()}, ${renewDate.getFullYear().toString()}`;
        nextSprint = `${
          monthNames[nextSprintDate.getMonth().toString()]
        } ${nextSprintDate
          .getDate()
          .toString()}, ${nextSprintDate.getFullYear().toString()}`;
      }
    }
    const spent = [];
    if (sheet.getCell(0, 15).value) {
      spent.push({
        source: sheet.getCell(0, 15).value.split(' ')[0], // H
        amount: ActualSpendPlatform1,
      });
    }
    if (sheet.getCell(0, 16).value) {
      spent.push({
        source: sheet.getCell(0, 16).value.split(' ')[0], // T
        amount: ActualSpendPlatform2,
      });
    }
    if (sheet.getCell(0, 17).value) {
      spent.push({
        source: sheet.getCell(0, 17).value.split(' ')[0], // H
        amount: ActualSpendPlatform3,
      });
    }
    const data1 = {
      leads: {
        sprintGoal: TotalSprintLeads,
        sprintActual,
      },
      budget: TotalBudget,
      spent,
      week: currentWeek,
      mainDates: {
        startOfSprint: currentStartDate,
        endOfSprint: currentEndDate,
        renewalDay: renewalDate,
        startOfNextSprint: nextSprint,
      },
    };
    setdata(data1);
    setTimeout(() => {
      setReRender(SHEET_ID);
    }, 200);
    return data1;
  }

  const timeOutTime =
    orgNameVerification === 'Stacker Software Ltd' ||
    orgNameVerification === 'Raydiant'
      ? 2500
      : 1500;

  useEffect(() => {
    if (user && SHEET_ID) {
      appendSpreadsheet();
      setTimeout(() => {
        setIsLoading(false);
      }, timeOutTime);
    } else {
      const data1 = {
        leads: {
          sprintGoal: null,
          sprintActual: null,
        },
        budget: null,
        week: null,
        mainDates: {
          startOfSprint: null,
          endOfSprint: null,
          renewalDay: null,
          startOfNextSprint: null,
        },
      };
      setdata(data1);
      setTimeout(() => {
        setIsLoading(false);
      }, timeOutTime);
    }
  }, [SHEET_ID, sheetIdSwitcher]);

  useEffect(() => {
    const organizationId =
      user?.organization?.id === 1 ? org?.id : user?.organization?.id;
    if (!organizationId) return;
    (async () => {
      await fetchCreativeDecks(organizationId);
      await fetchTasks(organizationId);
      await fetchCopyDecks(organizationId);
    })();
  }, [org, user]);

  // useEffect(() => {
  // ! Analytics for GA when needed
  // if (user) {
  //   axiosWithAuthRef
  //     .current()
  //     .get(`/analytics/${user?.organization?.id}/googleanalytics`)
  //     .then((res) => {
  //       window.gapi.client.setToken({
  //         client_id: process.env.REACT_APP_CLIENT_ID,
  //         scope: 'https://www.googleapis.com/auth/analytics.readonly',
  //         access_token: res.data.access_token,
  //       });
  //     })
  //     .catch((err) => console.error(err));
  // }
  // }, [user]);

  useEffect(() => {
    if (user) {
      if (id) {
        dispatch(
          FetchData(
            axiosWithAuthRef.current,
            `/documents/org/${
              id && user?.organization_id === 1 ? id : user?.organization_id
            }/executivereports`,
            'executiveReports'
          )
        );
      } else {
        dispatch(
          FetchData(
            axiosWithAuthRef.current,
            `/documents/org/${user?.organization_id}/executivereports`,
            'executiveReports'
          )
        );
      }
    }
  }, [user, dispatch, axiosWithAuthRef]);

  if (isLoading)
    return (
      <Main>
        <StyledHomePage>
          <p>Loading ...</p>
        </StyledHomePage>
      </Main>
    );

  return (
    <Main>
      <StyledHomePage>
        <div className="header-overview-container">
          <Text size={5} weight="medium" tag="h1">
            Overview
          </Text>
          {(orgNameVerification === 'Stacker Software Ltd' ||
            orgNameVerification === 'Raydiant') && (
            <div
              style={{
                marginBottom: '5px',
                width:
                  orgNameVerification === 'Stacker Software Ltd'
                    ? '200px'
                    : '145px',
              }}
            >
              <Dropdown
                size="md"
                align="left"
                toggle={<Text size={4}>{sheetIdSwitchOptionName()} </Text>}
                items={[
                  <div
                    variant="body1"
                    aria-hidden="true"
                    onClick={() => {
                      if (sheetIdSwitcher !== 0) {
                        setSheetIdSwitcher(0);
                        setIsLoading(true);
                      }
                    }}
                  >
                    <Text noWrap size={3}>
                      {orgNameVerification === 'Stacker Software Ltd'
                        ? 'Stacker Air'
                        : 'Raydiant'}
                    </Text>
                  </div>,
                  <div
                    variant="body1"
                    aria-hidden="true"
                    onClick={() => {
                      if (sheetIdSwitcher !== 1) {
                        setSheetIdSwitcher(1);
                        setIsLoading(true);
                      }
                    }}
                  >
                    <Text noWrap size={3}>
                      {orgNameVerification === 'Stacker Software Ltd'
                        ? 'Stacker Stand Alone'
                        : 'Hoopla'}
                    </Text>
                  </div>,
                ]}
              />
            </div>
          )}
        </div>
        <Divider />
        <div className="cards">
          {tasksFetchingStatus === 'success' &&
            tasks?.some(({ completed }) => !completed) && (
              <Tasks tasks={tasks} setTasks={setTasks} />
            )}
          {(tasksFetchingStatus === 'error' ||
            tasks?.every(({ completed }) => completed)) && (
            <div className="card sprintGraph" key={reRender}>
              <SprintGraph week={data?.week} mainDates={data?.mainDates} />
            </div>
          )}
          <Card className="card leadsGraph">
            {(() => {
              if (!data && user && !SHEET_ID) {
                return (
                  <LeadsGraph
                    goal={data?.leads.sprintGoal}
                    actual={data?.leads.sprintActual}
                    currentLeadsProjected={currentLeadsProjected}
                  />
                );
              }
              if (
                SHEET_ID &&
                data &&
                data.leads &&
                data.leads.sprintGoal &&
                data.leads.sprintActual
              ) {
                return (
                  <div key={reRender}>
                    <LeadsGraph
                      goal={data.leads.sprintGoal}
                      actual={data.leads.sprintActual}
                      currentLeadsProjected={currentLeadsProjected}
                    />
                  </div>
                );
              }
              return (
                <LeadsGraph
                  goal={data?.leads?.sprintGoal}
                  actual={data?.leads?.sprintActual}
                  currentLeadsProjected={currentLeadsProjected}
                />
              );
            })()}
          </Card>
          <Card className="card budgetGraph">
            <div key={reRender}>
              <BudgetGraph budget={data?.budget} spent={data?.spent} />
            </div>
          </Card>
        </div>
        <Text size={5} weight="medium" tag="h1" className="decks-and-reports">
          Decks and Reports
        </Text>
        <Divider />
        <div className="cards">
          {creativeDecks?.length > 0 && (
            <OverviewCard
              isNew
              onClick={() =>
                push(`/adApproval/creative/${creativeDecks[0].id}`)
              }
              title={`Creative Deck · Round ${creativeDecks[0]?.round}`}
              subtitle={
                <Chip
                  label={creativeDecks[0]?.approval_state}
                  color={
                    approvalStatus.find(
                      ({ text }) => text === creativeDecks[0]?.approval_state
                    )?.chipColor
                  }
                />
              }
              thumbnail={creativeDecks[0]?.ads[0]?.assets[0]?.url}
            />
          )}
          {copyDecks?.length > 0 && (
            <OverviewCard
              isNew
              onClick={() => push(`/adApproval/copy/${copyDecks[0].id}`)}
              title={copyDecks[0]?.round}
              subtitle={
                <Chip
                  color={
                    approvalStatus.find(
                      ({ text }) => text === copyDecks[0]?.approval_state
                    )?.chipColor
                  }
                  label={copyDecks[0]?.approval_state}
                />
              }
              thumbnail={copyDecks[0]?.creative_image}
            />
          )}
          {latestExecutiveReport && (
            <OverviewCard
              isNew
              onClick={() => push('/reports')}
              title={`Executive Report: ${latestExecutiveReport.name}`}
              subtitle={`Created on: ${new Date(
                latestExecutiveReport.created_at
              ).toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
              })}`}
              thumbnail={executiveReportsThumbnail}
            />
          )}
        </div>
      </StyledHomePage>
    </Main>
  );
};

export default HomePage;
