import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import S3 from 'react-aws-s3';
import Button from '@material-ui/core/Button';
import add from 'date-fns/add';
import Typography from '@material-ui/core/Typography';
import Loader from 'react-loader-spinner';
import { saveAs } from 'file-saver';
import { Document, Page, pdfjs } from 'react-pdf';
import CreateNonClientOrg from '../../components/colabsAdmin/CreateNonClientOrg';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import MainLayout from '../../layouts/Main';
// import Alert from '../../components/presentational/Alert';
import CreateProposalInputs from '../../components/colabsAdmin/CreateProposalInputs';
import ProposalDatesandPayments from '../../components/colabsAdmin/ProposalDatesandPayments';
// import ProposalDocument from '../../components/colabsAdmin/ProposalDocument';
import {
  getFormattedDate,
  pdfLines,
  validatePackages,
} from '../../utils/helper';
import PdfUpdateForm from '../../components/PdfUpdateForm';
import { useExportCallback } from '../../hooks/useExportCallback';

// ? PDF workers in node_modules
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    border: '1px black solid',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    height: '100%',
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    // height: '100%',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      // marginTop: theme.spacing(6),
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Create/Choose Org', 'Proposal Inputs', ' Dates and Payments'];

function getStepContent(
  step,
  cb,
  next,
  setProposal,
  proposal,
  org,
  setorg,
  handleNext,
  chargesUploaded,
  setChargesUploaded,
  add,
  getFormattedDate,
  allComplete,
  setallComplete,
  setActiveStep
) {
  switch (step) {
    case 0:
      return (
        <CreateNonClientOrg
          cb={cb}
          setProposal={setProposal}
          proposal={proposal}
          org={org}
          handleNext={handleNext}
          setorg={setorg}
        />
      );
    case 1:
      return (
        <CreateProposalInputs
          setProposal={setProposal}
          proposal={proposal}
          setActiveStep={setActiveStep}
        />
      );
    case 2:
      return (
        <ProposalDatesandPayments
          orgId={proposal.organization_id}
          org={org}
          chargesUploaded={chargesUploaded}
          setChargesUploaded={setChargesUploaded}
          cb={cb}
          setProposal={setProposal}
          proposal={proposal}
          add={add}
          getFormattedDate={getFormattedDate}
          allComplete={allComplete}
          setallComplete={setallComplete}
          setActiveStep={setActiveStep}
        />
      );
    default:
      throw new Error('Unknown step');
  }
}
const initial = {
  name: '',
  logo_url: '',
  client: false,
};

export default function CreateOrgSteps() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [next, setNext] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [s3Link, setS3Link] = useState('');
  const [blobContent, setBlobContent] = useState();

  const emailFormRef = React.useRef({
    title: 'Looking forward to working with you!!',
    email: '',
    name: '',
    url: '',
  });

  const currentDate = new Date();

  const proposalInit = {
    organization_id: '',
    organization_name: '',
    proposal_name: '',
    client_name: '',
    client_title: '',
    phase: '',
    contract_date: getFormattedDate(new Date()),
    project_start_date: currentDate,
    project_end_date: add(new Date(currentDate), {
      years: 0,
      months: 3,
      days: 13,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }),
    funding_round: [],
    social_platforms: ['Instagram'],
    mediaGoals: [],
    packages: [],
    payments: [],
    payment_discount: 20,
    performance_measurement_choice: '',
    images: [],
    media_plan_link: '',
    performance_measurement_date: add(new Date(currentDate), {
      years: 0,
      months: 3,
      days: 13,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }),
  };
  const [proposal, setProposal] = useState(proposalInit);
  const [pdfState, setPdfState] = useState(pdfLines(proposal));
  const PdfStateRef = React.useRef(pdfLines(proposal));
  const [org, setorg] = useState(initial);
  const [chargesUploaded, setChargesUploaded] = useState(false);
  const [allComplete, setallComplete] = useState(false);
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [randomKey, setRandomKey] = useState(101);
  const [blobContentLoading, setBlobContentLoading] = useState(false);
  const [changeCount, setChangeCount] = useState(0);

  // const useExportCallback = () =>
  //   useAsyncCallback(async () => {
  //     const worker = new Worker();
  //     const pdfWorker = Comlink.wrap(worker);

  //     pdfWorker.onProgress(
  //       Comlink.proxy((info) => console.log(info, '<=Info'))
  //     );
  //     console.log(changeCount, 'CHANGE');

  //     const pdfBlob = await pdfWorker.generateAll(
  //       proposal,
  //       pdfState,
  //       pdfCurrentStateKey
  //     );
  //     const fileName = `${proposal.organization_name.replace(
  //       / +/g,
  //       '-'
  //     )}_CoEfficient-Labs${
  //       proposal.phase ? `-Phase-${proposal.phase}` : ''
  //     }-SOW`;
  //     const file = new File(
  //       [pdfBlob],
  //       `${proposal.organization_name.replace(/ +/g, '-')}_CoEfficient-Labs${
  //         proposal.phase ? `-Phase-${proposal.phase}` : ''
  //       }-SOW${changeCount > 0 ? `-${changeCount}` : ''}`,
  //       { type: 'application/pdf' }
  //     );
  //     console.log(file, 'CheckOrg', org);

  //     fileUploadtoS3(file);

  //     return { pdfBlob, fileName };
  //   });

  async function updateBlobContent() {
    const { pdfBlob } = await exportAction.execute();
    console.log(pdfBlob, '<=InUseEffect');
    setBlobContent(pdfBlob);
    setBlobContentLoading(false);
  }

  React.useEffect(() => {
    PdfStateRef.current = pdfLines(proposal);
    setPdfState(pdfLines(proposal));
    console.log(org, '??ORG', proposal.organization_name);
  }, [proposal]);

  React.useEffect(() => {
    if (blobContentLoading === true) {
      updateBlobContent();
    }
  }, [pdfState, blobContentLoading]);

  const { axiosWithAuth } = useAxioswithAuth();

  let pdfCurrentStateKey;
  if (
    validatePackages(
      proposal?.packages,
      ['Full Pod', 'Landing Page', 'Video'],
      3
    )
  ) {
    pdfCurrentStateKey = 'fullPodLpAndVideo';
  }

  if (validatePackages(proposal?.packages, ['Video', 'Full Pod'], 2)) {
    pdfCurrentStateKey = 'fullPodVideo';
  }
  if (validatePackages(proposal?.packages, ['Landing Page', 'Full Pod'], 2)) {
    pdfCurrentStateKey = 'fullPodLanding';
  }
  if (validatePackages(proposal?.packages, ['Website', 'Full Pod'], 2)) {
    pdfCurrentStateKey = 'fullPodWebsite';
  }

  if (proposal?.packages?.every((e) => e.name.includes('Video'))) {
    pdfCurrentStateKey = 'video';
  }
  if (proposal?.packages?.every((e) => e.name.includes('Full Pod'))) {
    pdfCurrentStateKey = 'fullPod';
  }
  if (proposal?.packages?.every((e) => e.name.includes('Landing Page'))) {
    pdfCurrentStateKey = 'landingPage';
  }
  if (proposal?.packages?.every((e) => e.name.includes('Website'))) {
    pdfCurrentStateKey = 'Website';
  }

  function handlePdfStateChange(e) {
    e.preventDefault();
    PdfStateRef.current = {
      ...PdfStateRef.current,
      [e.target.name]: e.target.value,
    };
  }

  function handlePdfStateFullPodChange(e) {
    e.preventDefault();
    PdfStateRef.current.fullPod = {
      ...PdfStateRef.current.fullPod,
      [e.target.name]: e.target.value,
    };
  }

  function handlePdfStateVideoChange(e) {
    e.preventDefault();
    PdfStateRef.current.video = {
      ...PdfStateRef.current.video,
      [e.target.name]: e.target.value,
    };
  }

  function handleMultiPackageChange(e, pckg) {
    e.preventDefault();
    PdfStateRef.current[pckg] = {
      ...PdfStateRef.current[pckg],
      [e.target.name]: e.target.value,
    };
  }

  const fileUploadtoS3 = async (file) => {
    const newFileName = file.name.replace(/[^A-Z0-9]+/gi, '-');
    const config = {
      bucketName: 'colab-platformbucket',
      dirName:
        process.env.NODE_ENV === 'development'
          ? `development/clients/${`${proposal.organization_name.replace(
              ' ',
              '-'
            )}/documents`}`
          : `clients/${proposal.organization_name.replace(' ', '-')}/documents`,
      region: 'us-west-1',
      accessKeyId: process.env.REACT_APP_AWS_TOKEN_KEYID,
      secretAccessKey: process.env.REACT_APP_AWS_TOKEN_KEYSECRET,
    };
    const ReactS3Client = new S3(config);

    await ReactS3Client.uploadFile(file, newFileName).then(async (d) => {
      // Might save in documents at a later date
      if (d.status === 204) {
        setS3Link(d.location);
        return 'success';
      }
      throw new Error('Error Uploading files');
    });
    return 'success';
  };

  const exportAction = useExportCallback(
    changeCount,
    proposal,
    pdfState,
    pdfCurrentStateKey,
    fileUploadtoS3
  );

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await axiosWithAuth().post(
        `/proposals/${proposal.organization_id}`,
        proposal
      );
      setProposal({
        ...proposal,
        contract_date: getFormattedDate(new Date(proposal.contract_date)),
        project_end_date: new Date(proposal.project_end_date),
        project_start_date: new Date(proposal.project_start_date),
        performance_measurement_date: new Date(
          proposal.performance_measurement_date
        ),
        proposal_name: `${proposal.proposal_name}`,
      });
      if (activeStep === 2) {
        handleNext();
        const { pdfBlob } = await exportAction.execute();
        console.log(pdfBlob, '??<=pdfBlob');
        return setBlobContent(pdfBlob);
      }
      return handleNext();
    } catch (error) {
      console.log('ERROR', error);
      throw new Error(error.response);
    }
  }
  console.log(proposal, '<=proposal', pdfLines(proposal));

  const ExportReports = () => {
    return (
      <button
        type="button"
        onClick={async () => {
          saveAs(
            blobContent,
            `${proposal.organization_name.replace(
              / +/g,
              '-'
            )}_CoEfficient-Labs${
              proposal.phase ? `-Phase-${proposal.phase}` : ''
            }-SOW`
          );
        }}
      >
        {exportAction.loading ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            Loading document
            <Loader
              style={{ marginLeft: '8px' }}
              type="Oval"
              color="#00BFFF"
              height={15}
              width={15}
            />
          </span>
        ) : (
          'Download'
        )}
      </button>
    );
  };
  const [nPages, setNPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNPages(numPages);
  }

  return (
    <MainLayout>
      <CssBaseline />

      <main className={classes.layout}>
        <Paper className={classes.paper} id="PAPER">
          {activeStep === steps.length ? null : (
            <>
              <Typography component="h1" variant="h4" align="center">
                Proposal Generator
              </Typography>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    onClick={() => {
                      setProposal(proposalInit);
                      setActiveStep(index);
                      setChargesUploaded(false);
                      setallComplete(false);
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </>
          )}
          <>
            {activeStep === steps.length ? (
              <div
                style={{
                  display: 'flex',
                  maxHeight: ' 980px',
                  height: '100%',
                  justifyContent: 'center',
                  overflow: ' auto',
                }}
              >
                <PdfUpdateForm
                  handlePdfStateChange={handlePdfStateChange}
                  PdfStateRef={PdfStateRef}
                  proposal={proposal}
                  setPdfState={setPdfState}
                  setRandomKey={setRandomKey}
                  pdfState={pdfState}
                  getFormattedDate={getFormattedDate}
                  handlePdfStateVideoChange={handlePdfStateVideoChange}
                  handlePdfStateFullPodChange={handlePdfStateFullPodChange}
                  handleMultiPackageChange={handleMultiPackageChange}
                  pdfCurrentStateKey={pdfCurrentStateKey}
                  setActiveStep={setActiveStep}
                  setChargesUploaded={setChargesUploaded}
                  setallComplete={setallComplete}
                  setBlobContentLoading={setBlobContentLoading}
                  setChangeCount={setChangeCount}
                />
                <div
                  key={randomKey}
                  style={{
                    display: `flex`,
                    width: '810px',
                    minWidth: '810px',
                    flexDirection: 'column',
                    alignItems: 'end',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      paddingLeft: '15px',
                    }}
                  >
                    <ExportReports />
                    {emailSubmitted ? (
                      <p style={{ margin: 0 }}>
                        Email has been sent successfully
                      </p>
                    ) : (
                      <>
                        <button
                          style={
                            showEmailField
                              ? { visibility: 'hidden' }
                              : { width: '200px' }
                          }
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowEmailField(true);
                          }}
                        >
                          Send Email
                        </button>
                        <form
                          style={!showEmailField ? { display: 'none' } : null}
                          onSubmit={async (e) => {
                            e.preventDefault();
                            try {
                              await axiosWithAuth().post(
                                '/proposals/user/sendemail',
                                {
                                  ...emailFormRef.current,
                                  url: s3Link,
                                  name: proposal.client_name,
                                }
                              );
                              setEmailSubmitted(true);
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          <label htmlFor="emailForm">
                            Client Email address:
                            <input
                              name="email"
                              id="emailForm"
                              onChange={(e) => {
                                e.preventDefault();
                                emailFormRef.current = {
                                  ...emailFormRef.current,
                                  email: e.target.value,
                                };
                              }}
                            />
                          </label>
                          <button type="submit">Submit</button>
                        </form>
                      </>
                    )}
                  </div>
                  {blobContent && blobContentLoading === false && (
                    <div
                      style={{
                        maxHeight: '980px',
                        overflow: 'auto',
                        width: '810px',
                      }}
                    >
                      {console.log(blobContent, '<====blobContent')}

                      <Document
                        file={window.URL.createObjectURL(blobContent)}
                        options={{ workerSrc: '/pdf.worker.js' }}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        {Array.from(new Array(nPages), (el, index) => (
                          <Page
                            scale={96 / 72}
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        ))}
                      </Document>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                {getStepContent(
                  activeStep,
                  setNext,
                  next,
                  setProposal,
                  proposal,
                  org,
                  setorg,
                  handleNext,
                  chargesUploaded,
                  setChargesUploaded,
                  add,
                  getFormattedDate,
                  allComplete,
                  setallComplete,
                  setActiveStep
                )}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={(() => {
                      if (activeStep === 0) {
                        return !next;
                      }
                      if (activeStep === 2) {
                        return !allComplete;
                      }
                      return false;
                    })()}
                    onClick={(e) => {
                      return handleSubmit(e);
                    }}
                    className={classes.button}
                  >
                    {{
                      1: 'Next',
                      2: 'Finish',
                    }[activeStep] ?? 'Next'}
                  </Button>
                </div>
              </>
            )}
          </>
        </Paper>
      </main>
    </MainLayout>
  );
}
