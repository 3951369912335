import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text, Button, Checkbox, Popper } from 'colabs-ui-react';
import {
  X,
  Check,
  Edit2,
  MessageSquare,
  Save,
  MoreVertical,
} from 'react-feather';
import Textfield from './NewTextfield';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledPopper = styled(Popper)`
  .popperCard {
    padding: 0.25rem 0.75rem;
    background: #ffffffcc;
    backdrop-filter: blur(10px);
    z-index: 10;
    > * {
      margin: 0.5rem 0;
    }
  }
`;

const StyledCopy = styled.div`
  padding: 0.75rem 1.25rem;
  display: flex;
  cursor: pointer;
  justify-content: center;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
  @media ${({ theme }) => theme.breakpoint.sm} {
    padding: 0.25rem 0.5rem;
  }
  :hover {
    background-color: #f4f4f7;
  }
  > .cui-checkbox {
    height: fit-content;
    margin-top: 0.65rem;
    border-radius: 1rem;
  }
  > .editCopyTextfield {
    width: 100%;
  }
  > .order {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
  > .content {
    margin-top: 0.5rem;
    flex-grow: 1;
  }
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 11.2rem;
  > .cui-button {
    background: #fff;
    margin-left: 0.5rem;
    height: 2.25rem;
    width: 2.25rem;
  }
  > .commentBtn {
    &.clicked {
      background: #edf5ff;
      svg {
        color: #1171ff;
      }
    }
  }
  > .approveBtn {
    &.clicked {
      background: #c7ffdb;
      svg {
        color: #51df84;
      }
    }
  }
  > .rejectBtn {
    &.clicked {
      background: #ffdbdb;
      svg {
        color: #f65c5c;
      }
    }
  }
  .content {
    white-space: pre-wrap;
  }
  > .actionsSpace {
    flex: 1 0 auto;
    width: 5.6rem;
  }
  .newMessageIcon {
    position: relative;
    height: 1.75rem;
    width: 1.75rem;
    > svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 1rem;
      width: 1rem;
    }
    > .badge {
      background-color: #edf5ff;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 1rem;
      height: 0.75rem;
      width: 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const Copy = ({
  selectedCopys,
  handleCheckboxChange,
  content,
  approvalChanges,
  changeApproval,
  changeCopyToComment,
  copy,
  index,
  copyToComment,
  editCopy,
  id,
  checkboxType,
  getCopyCharLimit,
}) => {
  const [isMouseOver, setMouseIsOver] = useState(false);
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [newCopy, setNewCopy] = useState();
  const { width } = useWindowDimensions();

  const Actions = ({ showNotesTools }) => (
    <StyledActions>
      {showNotesTools && (
        <>
          {!isEditing && (
            <Button
              variant="tertiary"
              icon={<Edit2 />}
              className="editBtn"
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
                setActionsMenuOpen(false);
              }}
            />
          )}
          <Button
            variant="tertiary"
            icon={
              <span className="newMessageIcon">
                <MessageSquare />
                <span className="badge">
                  <Text color="blue" weight="bold" noWrap size={2}>
                    +
                  </Text>
                </span>
              </span>
            }
            className={`commentBtn ${
              copyToComment?.id === copy?.id ? 'clicked' : ''
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (copyToComment) {
                changeCopyToComment(null);
                return;
              }
              changeCopyToComment(id, copy?.orderedIndex + 1);
            }}
          />
          <span className="grow" />
        </>
      )}
      <Button
        variant="tertiary"
        icon={<Check />}
        className={`approveBtn ${
          approvalChanges && approvalChanges[0]?.action === 'approve'
            ? 'clicked'
            : ''
        }`}
        onClick={(e) => {
          e.stopPropagation();
          changeApproval(copy, index, 'approve');
        }}
      />
      <Button
        variant="tertiary"
        icon={<X />}
        className={`rejectBtn ${
          approvalChanges && approvalChanges[0]?.action === 'reject'
            ? 'clicked'
            : ''
        }`}
        onClick={(e) => {
          e.stopPropagation();
          changeApproval(copy, index, 'reject');
        }}
      />
    </StyledActions>
  );

  return (
    <StyledCopy
      className="container"
      id={`copy-${id}`}
      onMouseEnter={() => setMouseIsOver(true)}
      onMouseLeave={() => setMouseIsOver(false)}
      onFocus={() => setMouseIsOver(true)}
      onBlur={() => setMouseIsOver(false)}
      onClick={() => handleCheckboxChange({ target: { checked: true } }, copy)}
    >
      {checkboxType === 'none' && <div style={{ width: '1.75rem' }} />}
      {checkboxType !== 'none' && (
        <Checkbox
          type={checkboxType}
          checked={Object.keys(selectedCopys)
            .map((selectedType) => selectedCopys[selectedType].id)
            .includes(id)}
          onChange={(e) => handleCheckboxChange(e, copy)}
        />
      )}
      <Text className="order" style={{ marginRight: '0.5rem' }}>{`${
        copy.orderedIndex + 1
      }.`}</Text>
      {isEditing &&
        (() => {
          const charLimit = getCopyCharLimit(copy, 'max');
          return (
            <>
              <Textfield
                className="editCopyTextfield"
                defaultContent={content}
                onClick={(e) => e.stopPropagation()}
                helperText={
                  charLimit
                    ? `${
                        newCopy || newCopy === ''
                          ? newCopy?.length
                          : content?.length
                      } / ${charLimit} characters`
                    : null
                }
                onChange={(e) => {
                  e.preventDefault();
                  setNewCopy(e.target.value);
                }}
                inputTag="textarea"
                rows={3}
                maxLength={charLimit}
                icon={
                  <X
                    cursor="pointer"
                    color="#1171FA"
                    size="1rem"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditing(false);
                      setNewCopy();
                    }}
                  />
                }
              />
              <Button
                variant="tertiary"
                icon={<Save />}
                className="editBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  editCopy(copy?.id, newCopy, index);
                  setIsEditing(false);
                }}
              />
            </>
          );
        })()}
      {!isEditing && (
        <Text className="content" size={2}>
          {content}
        </Text>
      )}
      {width > 1279 && (
        <Actions
          showNotesTools={
            isMouseOver || isEditing || copyToComment?.id === copy?.id
          }
        />
      )}
      {width <= 1279 && (
        <>
          <StyledPopper
            isOpen={actionsMenuOpen}
            parentId="popper"
            anchorSelector={`#viewMore-${id}`}
            withArrow
            yPosition="bottom"
            xPosition="left"
            onClose={() => setActionsMenuOpen(false)}
            closeOnOutsideClick
          >
            <Actions showNotesTools />
          </StyledPopper>
          <Button
            id={`viewMore-${id}`}
            variant="secondary"
            onClick={() => setActionsMenuOpen(true)}
            icon={<MoreVertical />}
          />
        </>
      )}
    </StyledCopy>
  );
};

Copy.propTypes = {
  selectedCopys: PropTypes.shape().isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  approvalChanges: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  changeApproval: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  changeCopyToComment: PropTypes.func.isRequired,
  copyToComment: PropTypes.shape(),
  editCopy: PropTypes.func.isRequired,
  copy: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  checkboxType: PropTypes.oneOf(['radio', 'checkbox', 'none']).isRequired,
  getCopyCharLimit: PropTypes.func.isRequired,
};

Copy.defaultProps = {
  copyToComment: null,
};

export default Copy;
