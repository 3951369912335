import React, { useState } from 'react';
import styled from 'styled-components';
import AllBriefsNavPanel from '../components/brief/AllBriefsNavPanel';
import QuestionsPanel from '../components/brief/QuestionsPanel';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledBrief = styled.div`
  flex: 1 1 auto;
  padding: 0.5rem;
  width: 100%;
  max-width: 1310px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const Brief = () => {
  const { axiosWithAuth } = useAxioswithAuth();
  const [nameQueryTextfield, setNameQueryTextfield] = useState();
  const [activeBriefIdx, setActiveBriefIdx] = useState(0);
  const [briefsFetch, setBriefsFetch] = useState({
    fetchState: 'start',
    data: [],
    page: 0,
    columnSort: { column: 'created_at', order: 'desc' },
    reachedEnd: false,
    statusFilteredOut: [],
  });
  const brief = briefsFetch?.data[activeBriefIdx];

  const fetchAllBriefs = async (
    page,
    column,
    statusFilteredOut = briefsFetch.statusFilteredOut
  ) => {
    setBriefsFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const columnSort = (() => {
        if (!column) return briefsFetch.columnSort;
        if (briefsFetch.columnSort.column !== column)
          return { column, order: 'desc' };
        if (briefsFetch.columnSort.order === 'desc')
          return { column, order: 'asc' };
        return { column: 'created_at', order: 'desc' };
      })();
      const { data } = await axiosWithAuth().get('/orders/video-bundle', {
        params: {
          column_sort: columnSort,
          page,
          status_filtered_out: statusFilteredOut,
        },
      });
      const newData = [
        ...(page > 1 && briefsFetch.data ? briefsFetch.data : []),
        ...data,
      ];
      setBriefsFetch({
        fetchState: 'success',
        data: newData,
        columnSort,
        reachedEnd: data.length < 10,
        statusFilteredOut,
        page: page || 1,
      });
    } catch (e) {
      setBriefsFetch(({ data }) => ({ fetchState: 'error', data }));
    }
  };

  return (
    <MainLayout centered>
      <StyledBrief>
        <AllBriefsNavPanel
          setNameQueryTextfield={setNameQueryTextfield}
          briefsFetch={briefsFetch}
          setActiveBriefIdx={setActiveBriefIdx}
          activeBriefIdx={activeBriefIdx}
          fetchAllBriefs={fetchAllBriefs}
        />
        <QuestionsPanel brief={brief} />
      </StyledBrief>
    </MainLayout>
  );
};

Brief.propTypes = {};

Brief.defaultProps = {};

export default Brief;
