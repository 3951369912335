import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { logger } from 'redux-logger';
import reducer from '../reducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const store = compose(applyMiddleware(...middlewares))(createStore)(reducer);

const ReduxProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

function ReduxTestingProvider({ children, initialState }) {
  const testStore = createStore(reducer, initialState);
  return <Provider store={testStore}>{children}</Provider>;
}

export { ReduxTestingProvider };
export default ReduxProvider;
