import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { X } from 'react-feather';
import { Modal } from 'react-overlays';

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1010;
  padding: 1rem;
  bottom: 1rem;
  left: 1rem;
  border-radius: 1rem;
  min-width: 24rem;
  max-width: 90%;
  background-color: ${({ theme, severity }) => {
    if (severity === 'error') return '#F44336';
    if (severity === 'warning') return '#FF9800';
    if (severity === 'success') return '#4CAF50';
    return theme.color.primary.main;
  }};
  backdrop-filter: opacity(50%);
  backdrop-filter: blur(10px);
  :focus {
    outline: none;
  }
  > span {
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      fill: white;
      cursor: pointer;
    }
  }
`;

const Alert = ({ text, open, autoHideDuration, severity, style }) => {
  const timerRef = useRef();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);
    if (open) setOpen(true);
    timerRef.current = setTimeout(() => setOpen(false), autoHideDuration);
    return () => clearTimeout(timerRef.current);
  }, [open, autoHideDuration]);

  return (
    <StyledModal
      aria-labelledby="modal-label"
      show={isOpen}
      severity={severity}
      style={style}
    >
      <span>
        {text}
        <X onClick={() => setOpen(false)} />
      </span>
    </StyledModal>
  );
};

Alert.propTypes = {
  text: PropTypes.string,
  open: PropTypes.number,
  autoHideDuration: PropTypes.number,
  severity: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  style: PropTypes.shape(),
};

Alert.defaultProps = {
  autoHideDuration: 5000,
  severity: 'info',
  style: null,
  open: null,
  text: null,
};

export default Alert;
