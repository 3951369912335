import { Button, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatNameOrEmail } from '../../utils/helper';

const StyledTextField = styled.div`
  display: flex;
  > .avatar {
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 100rem;
    object-fit: cover;
  }
  > .container {
    margin: 0 auto;
    padding: 0.75rem;
    width: 100%;
    border-radius: 0.8rem;
    overflow: hidden;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    ${({ multiline }) => (multiline ? '' : 'height: 3rem')};
    /* align-items: center; */
    > :nth-child(1) {
      height: 1.25rem;
      padding: 0;
      /* margin: ${({ iconPos }) =>
        iconPos === 'left' ? '0 0.5rem 0 0' : '0 0 0 0.5rem'}; */
    }
    > .content {
      max-height: 6rem;
      height: auto;
      min-height: 3rem;
      overflow-y: scroll;
      > .cui-text {
        margin-right: 0.5rem;
      }
      .pin {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.25rem;
        backdrop-filter: blur(10px);
        z-index: 100;
        transform: translate(-50%, -50%);
        background: ${({ theme }) => theme.color.secondary.dark};
        transform: translate(0, 0);
        cursor: pointer;
        filter: contrast(1.5);
      }
      > .editableInput {
        flex: 1;
        display: block;
        width: 100%;
        height: fit-content;
        margin-bottom: 0.5rem;
        background: #f5f5f5;
        resize: none;
        border: 0;
        font-size: 1rem;
        letter-spacing: -0.02em;
        :focus {
          outline: none;
        }
        &[placeholder]:empty::before {
          content: attr(placeholder);
          color: #555;
        }
        /* &[placeholder]:empty:focus::before {
          content: '';
        } */
        > .timestamp {
          color: #1171fa;
          font-family: ${({ theme }) => theme.font.main.medium};
        }
      }
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.color.primary.main};
      }
      ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
    }
    > .annotationTools {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      > :nth-child(2) {
        margin-right: 0.5rem;
      }
      > .cui-text {
        margin-right: 0.5rem;
      }
      > .removeBtn {
        cursor: pointer;
      }
      > .cui-button {
        .icon {
          height: 1.25rem;
          width: 1.25rem;
          stroke: #2b333a;
          &.bigger {
            height: 1.5rem;
            width: 1.5rem;
          }
          &.disabled {
            stroke: #d3d3d3 !important;
          }
        }
      }
      /* > .tool {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 1.25rem;
        backdrop-filter: blur(10px);
        z-index: 100;
        transform: translate(-50%, -50%);
        background: white;
        transform: translate(0, 0);
        cursor: pointer;
        :focus {
          outline: none;
        }
        svg {
          * {
            stroke: ${({ theme }) => theme.color.primary.light};
          }
        }
      } */
      > .colorPicker {
        display: flex;
        > .color {
          border-radius: 1rem;
          margin-right: 0.25rem;
          height: 1rem;
          width: 1rem;
          cursor: pointer;
          caret-color: #5729e9;
          :focus {
            outline: none;
          }
        }
      }
    }
  }
  > .helperText {
    color: ${({ error }) => (error ? 'red' : 'gray')};
    margin-left: 1rem;
  }
`;

function TextField({
  icon,
  error,
  helperText,
  postComment,
  defaultContent,
  onCancel,
  commentToReply,
  annotationTools,
  commentAttachments,
  placeholder,
  sendDisabled,
}) {
  const inputRef = useRef();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    inputRef.current.focus();
    if (!defaultContent) return;
    inputRef.current.innerHTML = defaultContent;
  }, []);

  return (
    <StyledTextField className="cui-textfield" multiline rows={2} error={error}>
      <img
        src={
          user?.profile_pic_url ||
          'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x'
        }
        className="avatar"
        alt=""
        onError={(e) => {
          e.target.src =
            'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x';
        }}
      />
      <span
        className="container"
        onKeyDown={() => false}
        role="button"
        tabIndex={0}
        onClick={() => inputRef.current.focus()}
      >
        {icon}
        {/* CONTENT OF A COMMENT */}
        <span className="content">
          {commentToReply && (
            <Text tag="span" color="blue" weight="medium" noWrap>
              {`@${formatNameOrEmail(
                commentToReply?.user_first_name,
                commentToReply?.user_last_name,
                commentToReply?.user_email
              )}`}
            </Text>
          )}
          {commentAttachments}{' '}
          <span
            ref={inputRef}
            contentEditable="true"
            className="editableInput"
            id={`editableInput-${commentToReply?.id || 'root'}`}
            placeholder={placeholder || '  '}
          />
        </span>
        <div className="annotationTools">
          <Button
            size="sm"
            text="Send"
            disabled={sendDisabled}
            onClick={(e) => {
              e.stopPropagation();
              postComment(inputRef.current.innerText);
              inputRef.current.innerHTML = '';
            }}
          />
          {onCancel && (
            <Button
              size="sm"
              text="Cancel"
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
              }}
            />
          )}
          <div style={{ flexGrow: 1 }} />
          {/* <div className="colorPicker">
            {['#ff0000', '#00ff00', '#0000ff'].map((color) => (
              <div
                onKeyDown={() => false}
                role="button"
                aria-label="payment method"
                tabIndex={0}
                className="color"
                style={{ backgroundColor: color }}
                onClick={() => setActiveColor(color)}
              />
            ))}
          </div> */}
          {annotationTools}
        </div>
      </span>
      {helperText && <span className="helperText">{helperText}</span>}
    </StyledTextField>
  );
}

TextField.propTypes = {
  ...NumberFormat.propTypes,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  icon: PropTypes.element,
  label: PropTypes.string,
  required: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  autoComplete: PropTypes.bool,
};

TextField.defaultProps = {
  onFocus: null,
  value: undefined,
  name: '',
  autoComplete: false,
  placeholder: '',
  icon: null,
  setValue: null,
  type: 'text',
  label: null,
  required: false,
  minLength: 1,
  maxLength: 250,
};

export default TextField;
