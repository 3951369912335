import React from 'react';
import styled from 'styled-components';

const StyledCompletePanel = styled.div`
  background-color: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > h1 {
    margin: 0;
  }
  > ol {
    list-style-type: none;
    padding: 0;
    margin: 1.5rem 0;
    > span {
      font-size: 1rem;
      margin-bottom: 0.2rem;
      display: block;
    }
    > li {
      font-size: 1.25rem;
    }
  }
  > p {
    margin: 0;
  }
`;

const CompletePanel = () => {
  return (
    <StyledCompletePanel>
      <h1>Well Done, You've Finished!</h1>
      <ol>
        <span>Next Steps:</span>
        <li>We shoot 🎥</li>
        <li>We edit 🖥️</li>
        <li>You celebrate 🎉</li>
      </ol>
      <p>We'll reach out when your videos are ready.</p>
      <p>Thanks for trusting us. Can't wait to show you the magic.</p>
    </StyledCompletePanel>
  );
};

export default CompletePanel;
