import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Overlay } from 'react-overlays';
import { Trash2, Edit2 } from 'react-feather';
import { Text } from 'colabs-ui-react';
// import useWindowDimensions from '../hooks/useWindowDimensions';

const StyledCommentMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  border: 1px solid #d3d3d3;
  overflow: hidden;
  cursor: pointer;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  .item {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    :hover,
    &.selected {
      background: #1171ff;
      color: white;
    }
    > .icon {
      height: 1rem;
      width: 1rem;
      margin-right: 0.5rem;
    }
  }
`;

const CommentMenu = ({
  elementRef,
  isOpen,
  setIsOpen,
  deleteComment,
  setIsEditing,
  commentId,
}) => {
  // const { width } = useWindowDimensions();

  return (
    <Overlay
      show={isOpen}
      onHide={() => setIsOpen(false)}
      placement="right"
      target={elementRef}
      container={elementRef}
      rootClose
    >
      {({ props, placement }) => (
        <StyledCommentMenu
          {...props}
          placement={placement}
          // onMouseLeave={() => setIsOpen(false)}
          style={{ right: '100%', top: '50%' }}
        >
          <span
            className="item"
            onKeyDown={() => false}
            onFocus={() => false}
            role="button"
            tabIndex={0}
            onClick={() => {
              setIsEditing(true);
              setIsOpen(false);
            }}
          >
            <Edit2 className="icon" />
            <Text>Edit</Text>
          </span>
          <span
            className="item"
            onKeyDown={() => false}
            onFocus={() => false}
            role="button"
            tabIndex={0}
            onClick={() => {
              deleteComment(commentId);
              setIsOpen(false);
            }}
          >
            <Trash2 className="icon" />
            <Text>Delete</Text>
          </span>
        </StyledCommentMenu>
      )}
    </Overlay>
  );
};

CommentMenu.propTypes = {
  elementRef: PropTypes.shape().isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  commentId: PropTypes.number.isRequired,
};

CommentMenu.defaultProps = {};

export default CommentMenu;
