import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'colabs-ui-react';

const StyledTabs = styled.div`
  display: flex;
  width: 100%;
  > .tab {
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    @media ${({ theme }) => theme.breakpoint.sm} {
      padding: 0.5rem 1rem;
    }
    &.active {
      opacity: 1;
      border-bottom: 2px solid #1171fa;
      > .cui-text {
        color: #1171fa;
      }
    }
    &:focus {
      outline: none;
    }
  }
`;

const Tabs = ({ value, onChange, tabs }) => {
  return (
    <StyledTabs className="cui-tabs">
      {tabs.map(({ label }, index) => (
        <span
          key={label}
          onKeyDown={() => false}
          role="button"
          aria-label="tab"
          tabIndex={0}
          onClick={() => onChange(index)}
          className={`tab ${index === value ? 'active' : ''}`}
        >
          <Text size={3} noWrap weight={index === value ? 'medium' : 'regular'}>
            {label}
          </Text>
        </span>
      ))}
    </StyledTabs>
  );
};

Tabs.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

Tabs.defaultProps = {};

export default Tabs;
