import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledDrawerBlock = styled.ul`
  padding-left: 2px;
  &[data-collapsed='true'] {
    > .permission-title > .chevron-icon {
      transform: rotate(-90deg);
    }
    > .pages > .page {
      max-height: 0;
      overflow: hidden;
      &[data-selected='true'] {
        max-height: 20rem;
      }
    }
  }
  &[data-disabled='true'] {
    opacity: 0.5;
    pointer-events: none;
  }
  a {
    text-decoration: none;
    color: black;
  }
  svg {
    height: 1.5rem;
    width: 1.5rem;
    stroke-width: 2px;
    flex-shrink: 0;
  }
  .permission-title {
    cursor: pointer;
    list-style: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 48px;
    :hover {
      background: #f5f5f5;
    }
    > .chevron-icon {
      transition: transform 0.2s ease-in-out;
      height: 1rem;
      width: 1rem;
      stroke-width: 1px;
    }
    > .name {
      max-width: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out 0.49s;
    }
  }
  > .pages {
    padding-left: 0;
    > .page {
      max-height: 20rem;
      padding-left: 0.75rem;
      transition: all 0.2s ease-in-out 0.49s;
      border-left: 3px solid #ffffffff;
      cursor: pointer;
      list-style: none;
      white-space: nowrap;
      display: flex;
      align-items: center;
      height: 48px;
      :hover {
        background: #f5f5f5;
      }
      &[data-disabled='true'] {
        opacity: 0.5;
        pointer-events: none;
      }
      &[data-selected='true'] {
        border-left: 3px solid #1171fa;
        background: #edf4ff;
        cursor: default;
        :hover {
          background: #edf4ff;
        }
      }
      &[data-important='true'] {
        background: #1171fa;
        border-left: 3px solid #1171fa;
        color: white;
        border-radius: 0.5rem;
        margin: 0 0.25rem;
        padding-left: 0.5rem;
        cursor: pointer;
        :hover {
          background: #1171fa;
          color: white;
          opacity: 0.9;
        }
      }
      > .name {
        max-width: 0;
        overflow: hidden;
        transition: all 0.2s ease-in-out 0.49s;
      }
    }
  }
`;

const DrawerBlock = ({ permissionBlock, disabled }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const selectedInBlock = Object.keys(permissionBlock.pages).some((page) =>
      location.pathname.includes(page)
    );
    if (!selectedInBlock) return;
    setIsCollapsed(false);
  }, [location]);

  return (
    <StyledDrawerBlock
      key={permissionBlock.name}
      className="permission-block"
      data-collapsed={isCollapsed}
      data-disabled={disabled}
    >
      <li
        className="permission-title"
        onClick={() => setIsCollapsed((prev) => !prev)}
        role="button"
        tabIndex={0}
        onKeyDown={() => setIsCollapsed((prev) => !prev)}
      >
        <ChevronDown className="chevron-icon" />
        {permissionBlock.icon}
        <span className="name">{permissionBlock.name}</span>
      </li>
      <ul className="pages">
        {Object.keys(permissionBlock.pages).map((page) => (
          <NavLink
            key={page}
            // temporarily hardcoding the link for reports page
            to={
              permissionBlock.name === 'Paid Acquisition' && page === 'reports'
                ? `/orders/${user?.organization_id}/reports`
                : `${permissionBlock.blockPath}/${page}`
            }
            className="page"
            data-important={permissionBlock.pages[page].important}
            data-disabled={permissionBlock.pages[page].disabled}
            data-selected={location.pathname.includes(
              `${permissionBlock.blockPath}/${page}`
            )}
          >
            {permissionBlock.pages[page].icon}
            <span className="name">{permissionBlock.pages[page].name}</span>
          </NavLink>
        ))}
      </ul>
    </StyledDrawerBlock>
  );
};

export default DrawerBlock;
