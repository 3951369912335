import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledSwitchFieldset = styled.fieldset`
  padding: 0.75rem 0;
  border: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .toggleWrapper {
    position: relative;
    .toggle {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      :checked {
        & + span:before {
          background: linear-gradient(to bottom, #7eb1fa, #1272ff);
          width: 100%;
          transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
        }
        & + span:after {
          left: 1.75rem;
        }
      }
    }
    span {
      position: relative;
      display: block;
      user-select: none;
      transition: 0.4s ease;
      height: 1.5rem;
      width: 3.125rem;
      background: linear-gradient(to bottom, #dfdfdf, #bebcbc);
      border-radius: 0.875rem;
      ::before {
        content: '';
        position: absolute;
        display: block;
        transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
        height: 1.5rem;
        width: 1.5rem;
        top: 0;
        left: 0;
        border-radius: 0.875rem;
      }
      ::after {
        //circle
        content: '';
        position: absolute;
        display: block;
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
          0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
          0 3px 3px hsla(0, 0%, 0%, 0.05);
        transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
        background: whitesmoke;
        height: 1.4rem;
        width: 1.4rem;
        top: 0;
        left: 0;
        border-radius: 0.875rem;
      }
    }
  }
  label {
    position: absolute;
    padding-right: 4rem;
    width: 100%;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.font.main.regular};
  }
`;

const SwitchFieldset = ({ text, checked, onChange, ...props }) => {
  return (
    <StyledSwitchFieldset className="switch" {...props}>
      <legend hidden>{text}</legend>
      <span className="toggleWrapper">
        <input
          id={text}
          checked={checked}
          className="toggle"
          type="checkbox"
          onChange={onChange}
        />
        <span />
      </span>
      <label htmlFor={text}>{text}</label>
    </StyledSwitchFieldset>
  );
};

SwitchFieldset.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SwitchFieldset;
