import { Card, Divider } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { scrollIntoView } from '../../utils/helper';
import Breadcrumbs from './Breadcrumbs';
import Copy from './Copy';
import CopyCategory from './CopyCategory';

const StyledCopyListContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  > .scrollableContainer {
    height: 1rem;
    flex-grow: 1;
    overflow-y: auto;
    scroll-behavior: smooth;
    ${({ theme }) => theme.scrollbar}
    .copyContainer {
      background-color: #fafafd;
    }
  }
`;

const CopyListContainer = ({
  setSelectedCopys,
  selectedCopys,
  currentPlatform,
  setCurrentPlatform,
  copys,
  changeApproval,
  changeCopyToComment,
  editCopy,
  copyToComment,
  getCopyCharLimit,
}) => {
  const [currentType, setCurrentType] = useState();

  const handleCheckboxChange = (e, copy) => {
    if (!e.target.checked) return;
    if (currentPlatform === copy.platform) {
      if (copy.platform === 'google' && copy.type === 'description') {
        setSelectedCopys((prev) => ({
          ...prev,
          description: prev.description2,
          description2: copy,
        }));
        return;
      }
      setSelectedCopys((prev) => ({
        ...prev,
        [copy.type]: copy,
      }));
    } else {
      setCurrentPlatform(copy.platform);
      setSelectedCopys({ [copy.type]: copy });
    }
  };

  const selectDefaultCopys = useCallback(() => {
    const multiTypes =
      currentPlatform === 'google'
        ? {
            description2:
              copys?.google?.description?.length > 1 &&
              copys?.google?.description[1],
          }
        : {};
    setSelectedCopys({
      ...Object.keys(copys[currentPlatform]).reduce(
        (acc, type) => ({
          ...acc,
          [type]: copys[currentPlatform][type][0],
        }),
        null
      ),
      ...multiTypes,
    });
  }, [copys, currentPlatform, setSelectedCopys]);

  const handleDropdownClick = (setFunc, option, titleId) => {
    scrollIntoView(`category-${titleId}`);
    setFunc(option);
    selectDefaultCopys();
  };

  useEffect(() => {
    if (!copys || !currentPlatform) return;
    setCurrentType(Object.keys(copys[currentPlatform])[0]);
    selectDefaultCopys();
  }, [currentPlatform, copys, selectDefaultCopys]);

  return (
    <StyledCopyListContainer>
      {currentPlatform && currentType && (
        <Breadcrumbs
          handleDropdownClick={handleDropdownClick}
          setCurrentPlatform={setCurrentPlatform}
          currentPlatform={currentPlatform}
          copys={copys}
          currentType={currentType}
          setCurrentType={setCurrentType}
        />
      )}
      <div className="scrollableContainer">
        {copys &&
          Object.keys(copys).map((platform, platformIdx, platforms) => {
            return (
              <Fragment key={platform}>
                <CopyCategory
                  index={platformIdx}
                  array={platforms}
                  id={platform}
                  setCurrent={setCurrentPlatform}
                  isPlatform
                />
                {Object.keys(copys[platform]).map((type, typeIdx, types) => {
                  return (
                    <Fragment key={type}>
                      <CopyCategory
                        index={typeIdx}
                        array={types}
                        setCurrent={setCurrentType}
                        id={`${platform}-${type}`}
                        reviewedCount={
                          copys[platform][type].filter(
                            (copy) => copy?.approval_changes.length > 0
                          ).length
                        }
                        totalCount={copys[platform][type].length}
                        tooltip={(() => {
                          if (platform === 'google') {
                            if (type === 'headline3')
                              return 'Not shown in phone preview (copy for desktop only)';
                            if (type === 'description') return 'Select two';
                          }
                          return undefined;
                        })()}
                      />
                      <Card className="copyContainer">
                        {copys[platform][type].map((copy, index) => (
                          <Fragment key={copy.id}>
                            <Copy
                              checkboxType={(() => {
                                if (platform === 'google') {
                                  if (type === 'headline3') return 'none';
                                  if (type === 'description') return 'checkbox';
                                }
                                return 'radio';
                              })()}
                              selectedCopys={selectedCopys}
                              handleCheckboxChange={handleCheckboxChange}
                              content={copy.content}
                              approvalChanges={copy.approval_changes}
                              changeCopyToComment={changeCopyToComment}
                              changeApproval={changeApproval}
                              getCopyCharLimit={getCopyCharLimit}
                              copy={copy}
                              editCopy={editCopy}
                              index={index}
                              copyToComment={copyToComment}
                              id={copy.id}
                            />
                            {index + 1 < copys[platform][type].length && (
                              <Divider />
                            )}
                          </Fragment>
                        ))}
                      </Card>
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
      </div>
    </StyledCopyListContainer>
  );
};

CopyListContainer.propTypes = {
  setSelectedCopys: PropTypes.func.isRequired,
  selectedCopys: PropTypes.shape().isRequired,
  currentPlatform: PropTypes.string.isRequired,
  setCurrentPlatform: PropTypes.func.isRequired,
  copys: PropTypes.shape().isRequired,
  copyToComment: PropTypes.func,
  changeApproval: PropTypes.func.isRequired,
  changeCopyToComment: PropTypes.func.isRequired,
  editCopy: PropTypes.func.isRequired,
  getCopyCharLimit: PropTypes.func.isRequired,
};

CopyListContainer.defaultProps = {
  copyToComment: null,
};

export default CopyListContainer;
