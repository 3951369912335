import React, { useState } from 'react';
import styled from 'styled-components';
import ReactGoogleSlides from 'react-google-slides';
import { Trash2, Download } from 'react-feather';
import PropTypes from 'prop-types';
import { Button, Card, Text } from 'colabs-ui-react';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';

function getIdFromUrl(url) {
  return url.match(/[-\w]{25,}/)[0];
}

const StyledReportCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  @media ${({ theme }) => theme.breakpoint.md} {
    flex-direction: row;
    width: 100%;
  }
  > .thumbnailWrapper {
    width: 100%;
    height: 12rem;
    @media ${({ theme }) => theme.breakpoint.md} {
      height: 4rem;
      width: 4rem;
      flex-shrink: 0;
    }
  }
  > .reportDetails {
    padding: 0.75rem;
    flex-grow: 1;
    overflow: hidden;
    @media ${({ theme }) => theme.breakpoint.md} {
      display: flex;
      align-items: center;
    }
    > .title {
      margin-bottom: 0.5rem;
      flex-grow: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      @media ${({ theme }) => theme.breakpoint.md} {
        margin: 0;
      }
    }
    > .actions {
      display: flex;
    }
  }
`;

export default function ReportCard({
  report,
  setSelectedReport,
  handleDelete,
  handleDownload,
}) {
  const user = useSelector((state) => state.user);
  const isColabsAdmin =
    user?.organization_role === 'admin' && user?.organization_id === 1;
  const [loader, setloader] = useState(false);

  return (
    <StyledReportCard onClick={() => setSelectedReport(report?.file_url)}>
      <div className="thumbnailWrapper">
        <ReactGoogleSlides
          width="100%"
          height="100%"
          slidesLink={report?.file_url}
          position={1}
          loop
        />
      </div>
      <div className="reportDetails">
        <Text size={4} className="title">
          {report?.name}
        </Text>
        <span className="actions">
          {loader ? (
            <Loader type="Oval" color="#00BFFF" height={30} width={30} />
          ) : (
            <Button
              variant="tertiary"
              icon={<Download />}
              onClick={(e) =>
                handleDownload(
                  e,
                  getIdFromUrl(report?.file_url),
                  report?.name,
                  setloader,
                  report?.id
                )
              }
              iconPos="right"
            />
          )}
          {isColabsAdmin && (
            <Button
              onClick={(e) =>
                handleDelete(e, report?.id, getIdFromUrl(report?.file_url))
              }
              variant="tertiary"
              icon={<Trash2 />}
            />
          )}
        </span>
      </div>
    </StyledReportCard>
  );
}

ReportCard.propTypes = {
  report: PropTypes.func,
  setSelectedReport: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDownload: PropTypes.func,
};

ReportCard.defaultProps = {
  report: '',
  setSelectedReport: '',
  handleDelete: '',
  handleDownload: '',
};
