import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Divider, Text } from 'colabs-ui-react';
import LinearGradient from '../presentational/LinearGradient';
import Overlay from '../presentational/Overlay';

const StyledBudgetGraph = styled.div`
  > .section {
    padding: 1rem;
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
    > .summary {
      display: flex;
      justify-content: space-around;
      > .metric {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    > .progressBar {
      background: #e0e0e0;
      width: 100%;
      height: 0.5rem;
      overflow: hidden;
      border-radius: 0.25rem;
      > .progress {
        height: 100%;
        /* background: url(#budgetProgressBar); */
        background: #1273ff;
      }
      &.stacked {
        display: flex;
        justify-content: flex-start;
        height: 2rem;
        border-radius: 0.25rem;
        margin-bottom: 1rem;
        > .progress {
          /* background: url(#budgetProgressBar); */
        }
      }
    }
    > .labels {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      > .label {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        > .color {
          height: 0.5rem;
          width: 0.5rem;
          border-radius: 0.25rem;
          margin-right: 0.25rem;
        }
      }
    }
    .skyblue {
      color: #48bafc;
    }
  }
`;

const StyledOverlayCard = styled.div`
  background: #303030;
  color: ${({ color }) => color};
  padding: 0.5rem;
  border-radius: 0.25rem;
`;

const StyledProgressBarSection = styled.div`
  height: 100%;
  width: ${({ width }) => width};
  background-color: ${({ backgroundColor: { h, s, l } }) =>
    `hsl(${h}, ${s}%, ${l}%)`};
  :hover {
    background-color: ${({ backgroundColor: { h, s, l } }) =>
      `hsl(${h * 0.97}, 100%, ${l * 1.03}%)`};
  }
`;

const BudgetGraph = ({ budget, spent }) => {
  const spentTotal = spent?.reduce((a, b) => a + b.amount || 0, 0);
  const compactNumber = new Intl.NumberFormat('en', { notation: 'compact' });
  const spentPercent = ((spentTotal * 100) / budget).toFixed(0);
  const [overlayProps, setOverlayProps] = useState({});
  const [hoverBarRef, setHandleBarRef] = useState();
  const handleBarMouseEnter = (e, label, color) => {
    setOverlayProps({ label, color });
    setHandleBarRef(e.target);
  };

  const handleBarMouseLeave = () => {
    setOverlayProps(0);
    setHandleBarRef(null);
  };
  const progressBarColors = [
    { h: 211, s: 96, l: 64 },
    { h: 332, s: 96, l: 64 },
    { h: 16, s: 96, l: 64 },
    { h: 257, s: 93, l: 72 },
    { h: 226, s: 95, l: 64 },
    { h: 191, s: 44, l: 77 },
  ];

  const genHslColor = ({ h, s, l }) => {
    return `hsl(${h}, ${s}%, ${l}%)`;
  };

  // const actualAverage = actualTotal / actual.length;
  return (
    <StyledBudgetGraph>
      <Overlay
        triggerRef={hoverBarRef}
        placement="top"
        show={!!overlayProps}
        arrowColor="#303030"
      >
        <StyledOverlayCard color={overlayProps.color}>
          {overlayProps.label}
        </StyledOverlayCard>
      </Overlay>
      <LinearGradient
        color1="#1171FF"
        color2="#61E3FF"
        id="budgetProgressBar"
      />
      <div className="section">
        <div className="header">
          <Text size={3}>Total Sprint Budget</Text>
          <Text size={3}>SPRINT</Text>
        </div>
        <div className="summary">
          <div className="metric">
            <Text size={5} weight="medium">
              {budget ? `$${compactNumber.format(budget)}` : '—'}
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              BUDGET
            </Text>
          </div>
          <div className="metric">
            <Text size={5} weight="medium" style={{ color: '#48bafc' }}>
              {spent ? `$${compactNumber.format(spentTotal)}` : '—'}
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              SPENT
            </Text>
          </div>
          <div className="metric">
            <Text size={5} weight="medium">
              {spent && budget ? `${spentPercent}%` : '—'}
            </Text>
            <Text size={1} style={{ color: '#b0b0b0' }}>
              OF BUDGET
            </Text>
          </div>
        </div>
      </div>
      <Divider />
      <div className="graph section">
        <Text gutterBottom weight="medium" size={2}>
          Budget Progress
        </Text>
        <div className="progressBar">
          <div
            className="progress"
            style={{ width: `${spent && budget ? spentPercent : '0'}%` }}
          />
        </div>
      </div>
      <Divider />
      <div className="graph section">
        <Text gutterBottom weight="medium" size={2}>
          Budget Breakdown
        </Text>
        <div className="progressBar stacked">
          {spent?.map(({ source, amount }, index) => (
            <StyledProgressBarSection
              key={source}
              width={`${amount ? (amount * 100) / budget : '0'}%`}
              backgroundColor={progressBarColors[index]}
              onMouseEnter={(e) =>
                handleBarMouseEnter(
                  e,
                  `${source}: $${compactNumber.format(amount)}`,
                  genHslColor(progressBarColors[index])
                )
              }
              onMouseLeave={handleBarMouseLeave}
            />
          ))}
        </div>
        <div className="labels">
          {spent?.map(({ source }, index) => (
            <span key={source} className="label">
              <div
                className="color"
                style={{
                  backgroundColor: genHslColor(progressBarColors[index]),
                }}
              />
              <Text size={2}>{source}</Text>
            </span>
          ))}
        </div>
      </div>
    </StyledBudgetGraph>
  );
};

BudgetGraph.propTypes = {
  budget: PropTypes.number,
  spent: PropTypes.arrayOf(PropTypes.shape()),
};

BudgetGraph.defaultProps = {
  budget: null,
  spent: null,
};

export default BudgetGraph;
