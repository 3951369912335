import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CheckSquare, Square } from 'react-feather';
import { Text, Card } from 'colabs-ui-react';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledSprintGraph = styled(Card)`
  position: relative;
  > .title {
    padding: 1rem;
  }
  > svg {
    position: absolute;
    /* border: 1px solid red; */
    right: 1rem;
    top: 1rem;
    height: 2.9rem;
    width: 2.9rem;
    // transform: rotate(-90deg);
    circle.progress {
      border: 1px solid blue;
      fill: transparent;
      stroke: #9a75fa;
      stroke-width: 5;
      stroke-linecap: round;
      stroke-dasharray: 314.16;
      stroke-dashoffset: 314.16;
      transition: stroke-dashoffset 0.5s ease;
    }
  }
  > .weeks {
    padding: 0 1rem;
    height: 100%;
    overflow: auto;
    max-height: 24rem;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: gray;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .week {
      margin-bottom: 1rem;
      > .tasks {
        > .task {
          display: flex;
          padding: 0.25rem 0;
          align-items: center;
          > .icon {
            margin-right: 0.25rem;
            height: 1rem;
            width: 1rem;
            cursor: ${({ isColabsAdmin }) =>
              isColabsAdmin ? 'pointer' : 'default'};
          }
        }
      }
    }
  }
`;

const SprintGraph = ({ tasks, setTasks }) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const user = useSelector((state) => state.user);
  const isColabsAdmin = user.organization.id === 1;
  const nestedTasks = tasks.reduce((previous, current) => {
    if (!previous[current.weeks_assigned[0]]) {
      return { ...previous, [current.weeks_assigned[0]]: [current] };
    }
    return {
      ...previous,
      [current.weeks_assigned[0]]: [
        ...previous[current.weeks_assigned[0]],
        current,
      ],
    };
  }, []);

  const updateTaskStatus = async (taskId, newCompletedState) => {
    if (!isColabsAdmin) return;
    try {
      await axiosWithAuth().put(`tasks/${user.organization.id}/${taskId}`, {
        completed: newCompletedState,
      });
      setTasks((prevTasks) => {
        return prevTasks.map((task) => {
          if (task.id === taskId) {
            return { ...task, completed: newCompletedState };
          }
          return task;
        });
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const completedTasksCount = tasks.filter(({ completed }) => completed)
      .length;
    const progress = (completedTasksCount / tasks.length) * 100;
    const progressCircle = document.querySelector('circle.progress');
    const percentageText = document.querySelector('.chart-number');
    progressCircle.style.strokeDashoffset =
      314.16 - (progress / 100) * 314.16 * 0.4;
    percentageText.textContent = `${Math.round(progress)}%`;
  }, [tasks]);

  return (
    <StyledSprintGraph isColabsAdmin={isColabsAdmin}>
      <Text className="title">Onboarding Activities</Text>
      <svg viewbox="0 0 114 114">
        <circle
          className="progress"
          transform="translate(23 23) rotate(-90)"
          r="20"
        />
        <g className="chart-text">
          <text x="22%" y="60%" className="chart-number">
            0%
          </text>
        </g>
      </svg>
      <div className="weeks">
        {Object.keys(nestedTasks).map((week) => (
          <div className="week">
            <Text weight="medium">Week {week}</Text>
            <div className="tasks">
              {nestedTasks[week].map(({ name, completed, id }) => (
                <span className="task">
                  {completed ? (
                    <CheckSquare
                      className="icon"
                      color="#9a75fa"
                      onClick={() => updateTaskStatus(id, !completed)}
                    />
                  ) : (
                    <Square
                      className="icon"
                      onClick={() => updateTaskStatus(id, !completed)}
                    />
                  )}
                  <Text size={2}>{name}</Text>
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </StyledSprintGraph>
  );
};

SprintGraph.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape()),
  setTasks: PropTypes.func.isRequired,
};

SprintGraph.defaultProps = {
  tasks: [],
};

export default SprintGraph;
