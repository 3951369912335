import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AdsPage from './Ads';
import CopysPage from './Copys';
import DecksPage from './Decks';

const AdApproval = () => {
  const { push } = useHistory();
  const { type } = useParams();
  if (type === 'creative') return <AdsPage />;
  if (type === 'copy') return <CopysPage />;
  if (type) push('/home');
  if (!type) return <DecksPage />;
};

export default AdApproval;
