import { Media } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { ChevronLeft } from 'react-feather';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import star from '../../assets/icons/star.svg';
import DeckMenu from './DeckMenu';
import ReferenceDeckActionsMenu from './ReferenceDeckActionsMenu';
import ReferenceTagsPopover from './ReferenceTagsPopover';

const StyledReferenceDeckNavPanel = styled.div`
  display: flex;
  flex-direction: column;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .back-btn {
      color: #1171fa;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 0.5rem;
      padding: 0.25rem 0.5rem 0.25rem 0;
      :hover {
        background-color: #fafafa;
      }
      > svg {
        width: 1rem;
      }
    }
    > .title {
      margin-right: 1rem;
      flex-grow: 1;
    }
  }
  > .deck-list-row,
  > .deck-list-body > .deck-list-row {
    display: grid;
    grid-template-columns: 1fr 3.5fr 1fr 1fr 0.75fr;
    grid-gap: 0.5rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
    }
    &.deck {
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
      :hover {
        background-color: #f5f5f5;
      }
      &[data-active='true'] {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
        cursor: grab;
      }
      > .thumbnail {
        height: 5rem;
        width: 5rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .deck-name {
        font-size: 0.75rem;
      }
      > .decks-actions-menu {
        justify-self: end;
      }
      > .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
        > .tag {
          border-radius: 9999px;
          padding: 0.1rem 0.75rem;
          font-size: 14px;
          background-color: #dceaff;
        }
      }
    }
  }
  > .deck-list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
`;

const ReferenceDeckNavPanel = ({
  activeDeckFetch,
  activeRefIdx,
  setActiveRefIdx,
  setNewDeck,
  setNewReference,
  setActiveDeckFetch,
  reorderReferenceWithinDeck,
}) => {
  const { push } = useHistory();
  const [referenceBeingDragged, setReferenceBeingDragged] = useState(null);
  const [references, setReferences] = useState([]);

  const handleDragOver = (e, idx) => {
    e.preventDefault();
    if ([idx, null, undefined].includes(referenceBeingDragged)) return;
    const newReferences = [...references];
    const draggedReference = newReferences[referenceBeingDragged];
    newReferences.splice(referenceBeingDragged, 1);
    newReferences.splice(idx, 0, draggedReference);
    setReferences(newReferences);
    setReferenceBeingDragged(idx);
  };

  useEffect(() => {
    if (activeDeckFetch.fetchState === 'success') {
      setReferences(activeDeckFetch.data.references);
    }
  }, [activeDeckFetch]);

  return (
    <StyledReferenceDeckNavPanel className="deck-nav-panel">
      <div className="panel-header">
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => push('/ops/reference-tool')}
          onClick={() => push('/ops/reference-tool')}
          className="back-btn"
        >
          <ChevronLeft strokeWidth={1} />
          Decks
        </div>
        <span className="title">
          {activeDeckFetch.fetchState === 'success' &&
            activeDeckFetch.data.name.toUpperCase()}
        </span>
        {activeDeckFetch.fetchState === 'success' && (
          <DeckMenu
            setNewDeck={setNewDeck}
            id={activeDeckFetch.data.id}
            setNewReference={setNewReference}
          />
        )}
      </div>
      <div className="deck-list-row">
        {['Thumbnail', 'Tags', 'Rating', 'Date'].map((label) => (
          <div className="column-title">{label}</div>
        ))}
        <div className="column-title" />
      </div>
      <div className="deck-list-body">
        {activeDeckFetch.fetchState === 'success' &&
          references?.map((reference, idx) => (
            <div
              className="deck-list-row deck"
              data-active={
                activeDeckFetch.data.references[activeRefIdx].id ===
                reference.id
              }
              key={reference.id}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData('text/plain', idx);
                setActiveRefIdx(idx);
                setReferenceBeingDragged(idx);
              }}
              onDragEnd={() => setReferenceBeingDragged(null)}
              onDragOver={(e) => handleDragOver(e, idx)}
              onDrop={(e) => {
                e.preventDefault();
                reorderReferenceWithinDeck(
                  referenceBeingDragged,
                  activeDeckFetch.data.id,
                  references
                );
                setReferenceBeingDragged(null);
              }}
              onClick={() => setActiveRefIdx(idx)}
              role="button"
              tabIndex={0}
              onKeyDown={() => setActiveRefIdx(idx)}
            >
              <Media
                className="thumbnail"
                src={reference.url}
                srcSuffix="#t=2"
              />
              <div className="tags">
                {reference.tags.map((tag) => (
                  <div className="tag">{tag}</div>
                ))}
                <ReferenceTagsPopover
                  activeTags={reference.tags}
                  referenceId={reference.id}
                  setActiveTags={(newTags) => {
                    setActiveDeckFetch((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        references: prev.data.references.map((ref) => {
                          if (ref.id === reference.id) {
                            return {
                              ...ref,
                              tags: newTags,
                            };
                          }
                          return ref;
                        }),
                      },
                    }));
                  }}
                />
              </div>
              <div className="rating">
                {reference.rating_score ? (
                  <>
                    {reference.rating_score}{' '}
                    <img src={star} alt="star" className="star-icon" />
                  </>
                ) : (
                  '—'
                )}
              </div>
              <div className="date">
                {new Date(reference.created_at).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </div>
              <ReferenceDeckActionsMenu
                referenceId={reference.id}
                viewReference={() => setActiveRefIdx(idx)}
                deckId={activeDeckFetch.data.id}
                setNewReference={setNewReference}
              />
            </div>
          ))}
      </div>
    </StyledReferenceDeckNavPanel>
  );
};

export default ReferenceDeckNavPanel;
