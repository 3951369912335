import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dropzone-uploader/dist/styles.css'; // React uploader styles
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import ReduxProvider from './contexts/ReduxProvider';
import ThemeProvider from './contexts/ThemeProvider';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';
import GlobalStyles from './styles/GlobalStyles';

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

ReactDOM.render(
  <ReduxProvider>
    <Router>
      <React.StrictMode>
        <CssBaseline />
        <ThemeProvider>
          <GlobalStyles />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => window.location.reload()}
          >
            <Routes />
          </ErrorBoundary>
        </ThemeProvider>
      </React.StrictMode>
    </Router>
  </ReduxProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
