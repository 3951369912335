/* eslint-disable react/prop-types */
import 'date-fns';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Button from '@material-ui/core/Button';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import add from 'date-fns/add';
import styled from 'styled-components';
import FileUploader from '../FileUploader';
import AdminOrgProposalList from './AdminOrgProposalList';
import { NumberFormatCustom } from '../NumberFormatInput';

const StyledContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  .Dates {
    align-self: center;
    width: 25%;
    margin-right: 15px;
  }
`;

class ChargeInputs extends React.Component {
  constructor(props) {
    super(props);
    const { payment } = this.props;
    this.state = {
      dateInput:
        typeof payment === 'object'
          ? payment.payment_date
          : add(new Date(new Date()), {
              years: 0,
              months: payment,
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }),
      paymentInput: this.props.payment ? this.props.payment.payment_amount : 0,
    };
  }

  handleDateChange = (dateInput) => {
    const { handleChange, index } = this.props;
    this.setState({ dateInput });
    handleChange(null, index, dateInput);
  };

  handlePaymentChange = (e) => {
    this.setState({ payment: e.target.value });
  };

  render() {
    const { handleChange, index } = this.props;
    const { dateInput, paymentInput } = this.state;
    return (
      <Grid container spacing={3} style={{ justifyContent: 'center' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            style={{ alignSelf: 'center', width: '35%' }}
            variant="inline"
            inputVariant="outlined"
            label="Payment Date"
            format="MM/dd/yyyy"
            value={dateInput}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date) => this.handleDateChange(date)}
          />
        </MuiPickersUtilsProvider>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="outlined"
            label="Payment amount"
            value={paymentInput}
            onChange={(e) =>
              handleChange(e, index) &
              this.setState({ paymentInput: e.target.value })
            }
            name="payment_amount"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: NumberFormatCustom,
              shrink: 'true',
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

function ProposalDatesandPayments({
  orgId,
  org,
  chargesUploaded,
  setChargesUploaded,
  cb,
  setProposal,
  proposal,
  add,
  getFormattedDate,
  allComplete,
  setallComplete,
  setActiveStep,
}) {
  const [finalStep, setFinalStep] = useState({ charges: false, docs: false });
  const [inputAmount, setinputAmount] = useState(() =>
    proposal.payments.length
      ? proposal.payments.map((e) => e)
      : [...Array(3).keys()]
  );
  const [charge, setCharge] = useState(() =>
    inputAmount.map((e, ind) => {
      const currentDate = new Date();

      if (ind === 1) {
        return {
          payment_date: add(new Date(currentDate), {
            years: 0,
            months: 1,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          }),
          payment_amount: 0,
        };
      }
      if (ind === 2) {
        return {
          payment_date: add(new Date(currentDate), {
            years: 0,
            months: 2,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          }),
          payment_amount: 0,
        };
      }
      return {
        payment_date: new Date(),
        payment_amount: 0,
      };
    })
  );

  function LinkRetrieval(link) {
    setProposal({ ...proposal, images: link });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    const promises = charge.map(async (form) => {
      // try {
      // const result = formatISO(form.payment_date);
      // const updatedForm = {
      //   ...form,
      //   payment_date: result,
      //   payment_amount: Number(form.payment_amount),
      // };
      // dispatch(
      //   PostData(
      //     axiosWithAuth,
      //     `/charges/${form.organization_id}`,
      //     updatedForm
      //   )
      // );
      // } catch (error) {
      //   throw new Error(error);
      // }
      return 'success';
    });

    await Promise.all(promises)
      .then(() => {
        setChargesUploaded(true);
        setFinalStep({ ...finalStep, charges: true, docs: true });
        setProposal({ ...proposal, payments: charge });
        cb(true);
        setallComplete(true);

        return 'success';
      })
      .catch((error) => {
        console.log(`Error in executing ${error}`);
      });
  }

  function handleChange(event, index, date) {
    if (date) {
      setCharge((e) => {
        e[index] = { ...e[index], payment_date: date };
        return [...e];
      });
    } else {
      setCharge((e) => {
        e[index] = { ...e[index], [event.target.name]: event.target.value };
        return [...e];
      });
    }
  }

  function handleSelectChange(event) {
    setProposal({
      ...proposal,
      performance_measurement_date: add(new Date(proposal.project_end_date), {
        years: 0,
        months: 0,
        days: Number(event.target.value),
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      performance_measurement_choice: event.target.value,
    });
  }

  // const handleContractDateStart = (dateInput) => {
  //   setProposal({ ...proposal, contract_date: getFormattedDate(dateInput) });
  // };
  const handleProjectDateStart = (dateInput) => {
    setProposal({
      ...proposal,
      project_start_date: dateInput,
      project_end_date: add(new Date(dateInput), {
        years: 0,
        months: 3,
        days: -1,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    });
  };
  const handleProjectDateEnd = (dateInput) => {
    setProposal({ ...proposal, project_end_date: dateInput });
  };
  useEffect(() => {
    setChargesUploaded(false);
    setFinalStep({
      charges: false,
      docs: false,
    });
    setallComplete(false);
  }, []);

  useEffect(() => {
    if (proposal.payments.length) {
      const parsedPayments = [];
      if (typeof proposal.payments[0] === 'string') {
        proposal.payments.map((p) => parsedPayments.push(JSON.parse(p)));
      } else {
        proposal.payments.map((p) => parsedPayments.push(p));
      }

      setCharge(
        parsedPayments.map((p) => {
          return {
            payment_date: new Date(p.payment_date),
            payment_amount: p.payment_amount,
          };
        })
      );
      setinputAmount(parsedPayments.map((e) => e));

      cb(true);
    } else {
      cb(false);
    }
  }, [finalStep, cb, proposal]);

  return !orgId || !proposal.proposal_name ? (
    <AdminOrgProposalList
      proposal={proposal}
      setProposal={setProposal}
      setActiveStep={setActiveStep}
    />
  ) : (
    <StyledContainer>
      <div>
        <Grid
          style={{ padding: '24px', paddingBottom: '0', paddingTop: '0' }}
          container
          spacing={3}
        >
          <Grid
            container
            spacing={3}
            style={{ justifyContent: 'flex-start', paddingLeft: '24px' }}
          >
            <Typography
              style={{ marginBottom: '30px' }}
              variant="h6"
              gutterBottom
            >
              Dates{' '}
            </Typography>
            <br />

            <Grid container spacing={3} style={{ justifyContent: 'center' }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  className="Dates"
                  variant="inline"
                  inputVariant="outlined"
                  label="Project Start Date"
                  format="MM/dd/yyyy"
                  name="project_start_date"
                  value={proposal.project_start_date}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => handleProjectDateStart(date)}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  className="Dates"
                  variant="inline"
                  inputVariant="outlined"
                  label="Project End Date"
                  format="MM/dd/yyyy"
                  name="project_end_date"
                  value={proposal.project_end_date}
                  InputAdornmentProps={{ position: 'start' }}
                  onChange={(date) => handleProjectDateEnd(date)}
                />
              </MuiPickersUtilsProvider>{' '}
            </Grid>
          </Grid>
          <Grid style={{ marginTop: '20px', width: '100%' }}>
            <Typography style={{ marginTop: '30px' }} variant="h6" gutterBottom>
              Payments{' '}
            </Typography>
            <br />
            {!chargesUploaded ? (
              <div>
                {inputAmount.map((e, index) => (
                  <>
                    <ChargeInputs
                      id={Math.random()}
                      key={e}
                      orgId={orgId}
                      index={index}
                      handleChange={handleChange}
                      payment={e}
                    />
                  </>
                ))}
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <label htmlFor="performance_measurement_date-select">
                    Performance Measurement Date <br /> (if Project End Date
                    Changes please reset this field) <br />
                    <br />
                    <select
                      name="performance_measurement_date"
                      id="performance_measurement_date-select"
                      onChange={handleSelectChange}
                      value={proposal.performance_measurement_choice}
                    >
                      <option value="">--Please choose an option--</option>
                      <option value="14">14-days</option>
                      <option value="30">30-days</option>
                      <option value="60">60-days</option>
                    </select>
                  </label>
                </div>
                <br />
                <br />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '1%',
                  }}
                >
                  <Button
                    onClick={(e) => handleSubmit(e)}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: '11%' }}
                  >
                    Upload Payments
                  </Button>
                  <HighlightOffIcon
                    onClick={() => {
                      setinputAmount((state) => {
                        const inputs = [...state];
                        inputs.pop();
                        return inputs;
                      });
                      setCharge((state) => {
                        const inputs = [...state];
                        inputs.pop();
                        return inputs;
                      });
                    }}
                    style={{ cursor: 'pointer' }}
                  />{' '}
                  &nbsp;
                  <ControlPointIcon
                    onClick={() => {
                      setinputAmount((state) => [
                        ...state,
                        {
                          payment_date: new Date(),
                          payment_amount: 0,
                        },
                      ]);
                      setCharge([
                        ...charge,
                        {
                          payment_date: new Date(),
                          payment_amount: 0,
                        },
                      ]);
                    }}
                    style={{ cursor: 'pointer' }}
                  />{' '}
                  &nbsp;
                  <span style={{ width: '100px', textAlign: 'center' }}>
                    Add/Subtract inputs
                  </span>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h2 style={{ fontWeight: 'bold', fontSize: '20px' }}>
                  Payments Uploaded Successfully
                </h2>
                <CheckCircleIcon
                  style={{
                    color: green[500],
                    fontSize: '200px',
                  }}
                />
              </div>
            )}
            <br />
          </Grid>
          <Typography variant="h6" gutterBottom>
            Organization Document Upload
          </Typography>
          <FileUploader
            dbPostURL={`/documents/${orgId}`}
            s3UploadURL={`clients/${proposal.organization_name}/documents`}
            dbOrgID={orgId}
            fileTypes="document-sensitive"
            getLinks={LinkRetrieval}
            smallDropZone
          />
        </Grid>
      </div>
    </StyledContainer>
  );
}

ProposalDatesandPayments.propTypes = {
  orgId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  org: PropTypes.shape().isRequired,
  chargesUploaded: PropTypes.bool.isRequired,
  setChargesUploaded: PropTypes.func.isRequired,
  cb: PropTypes.func.isRequired,
};

ProposalDatesandPayments.defaultProps = {};

export default ProposalDatesandPayments;
