import * as Dialog from '@radix-ui/react-dialog';
import { Button, Dropdown, Textfield } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 24rem;
  padding: clamp(2rem, 5vw, 1rem);
  display: flex;
  flex-direction: column;
  > .title {
    margin-top: 0;
    font-size: 1.25rem;
    &.duplicate,
    &.delete {
      margin-bottom: 0.5rem;
    }
  }
  > .description {
    margin: 0 0 1rem 0;
  }
  > .cui-textfield {
    margin-bottom: 0.5rem;
  }
  > .cui-dropdown {
    margin-bottom: 0.5rem;
    .select {
      overflow-y: scroll;
      max-height: 14rem;
    }
  }
  > .buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }
  .cui-button {
    text-transform: capitalize;
    align-self: flex-end;
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  z-index: 100000000;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

const NewDeckModal = ({ newDeck, setNewDeck, fetchDecks }) => {
  const [organizationsFetch, setOrganizationsFetch] = useState({
    fetchStatus: 'start',
  });
  const { axiosWithAuth } = useAxioswithAuth();

  const fetchOrganizations = async () => {
    setOrganizationsFetch((prev) => ({ ...prev, fetchStatus: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get('/organizations');
      setOrganizationsFetch({
        fetchStatus: 'success',
        data: data.sort(
          (a, b) =>
            +b.active_client - +a.active_client || a.name.localeCompare(b.name)
        ),
      });
    } catch (e) {
      setOrganizationsFetch((prev) => ({ ...prev, fetchStatus: 'error' }));
    }
  };

  const createDeck = async () => {
    if (!newDeck.name || !newDeck.organization_id) return;
    try {
      await axiosWithAuth().post('/reference-tool/decks', {
        name: newDeck.name,
        organization_id: newDeck.organization_id,
        starter_deck_id: newDeck.starter_deck_id,
      });
      await fetchDecks();
    } catch (e) {
      console.log(e);
    }
  };

  const deleteDeck = async () => {
    try {
      await axiosWithAuth().delete(`/reference-tool/decks/${newDeck.id}`);
      await fetchDecks();
    } catch (e) {
      console.log(e);
    }
  };

  const updateDeck = async () => {
    try {
      await axiosWithAuth().put(`/reference-tool/decks/${newDeck.id}`, {
        name: newDeck.name,
      });
      await fetchDecks();
    } catch (e) {
      console.log(e);
    }
  };

  const handleConfirm = async () => {
    await {
      create: createDeck,
      edit: updateDeck,
      duplicate: createDeck,
    }[newDeck.mode]();
    setNewDeck((prev) => ({ ...prev, isModalOpen: false }));
  };

  const updateForm = (e) => {
    setNewDeck((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const updateFormDomValues = () => {
    const field = document.querySelector(`#deck-name-textfield`);
    if (!field) return;
    field.value = newDeck.name;
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    updateFormDomValues();
  }, [newDeck.isModalOpen]);

  return (
    <Dialog.Root
      open={newDeck.isModalOpen}
      onOpenChange={(open) => {
        if (open) fetchOrganizations();
        setNewDeck((prev) => ({ ...prev, isModalOpen: open }));
      }}
    >
      <Dialog.Portal>
        <Overlay />
        {newDeck.mode === 'delete' ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newDeck.mode}`}>
              <span className="avoidwrap">Are you sure you want to delete</span>{' '}
              <span className="avoidwrap">"{newDeck.name}"?</span>
            </Dialog.Title>
            <Dialog.Description className="description">
              You will loose all the comments and ratings on this deck. This
              action cannot be undone.
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewDeck((prev) => ({ ...prev, isModalOpen: false }))
                }
              />
              <Button
                text="Confirm"
                onClick={async () => {
                  await deleteDeck();
                  setNewDeck((prev) => ({ ...prev, isModalOpen: false }));
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newDeck.mode}`}>
              {
                {
                  edit: 'Edit Deck',
                  create: 'Create New Deck',
                  duplicate: 'Duplicate Deck',
                }[newDeck.mode]
              }
            </Dialog.Title>
            {newDeck.mode === 'duplicate' && (
              <Dialog.Description className="description">
                Create a new deck from "{newDeck.starter_deck_name}" without
                comments or ratings, but with the same references.
              </Dialog.Description>
            )}
            <Textfield
              id="deck-name-textfield"
              label="Deck Name:"
              name="name"
              value={newDeck.name}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={updateForm}
            />
            <span>Organization:</span>
            <Dropdown
              fullWidth
              align="left"
              toggle={
                <div>
                  {(() => {
                    if (
                      ['loading', 'start'].includes(
                        organizationsFetch.fetchStatus
                      )
                    ) {
                      return 'Loading...';
                    }
                    if (organizationsFetch.fetchStatus === 'error') {
                      return 'Error';
                    }
                    if (organizationsFetch?.data?.length > 0) {
                      return (
                        organizationsFetch?.data?.find(
                          ({ id }) => id === newDeck?.organization_id
                        )?.name || 'None Selected'
                      );
                    }
                    return 'No Organizations in the System';
                  })()}
                </div>
              }
              items={
                organizationsFetch?.data
                  ? organizationsFetch?.data?.map((organization) => (
                      <div
                        role="button"
                        onKeyDown={() => false}
                        tabIndex={0}
                        onClick={() =>
                          setNewDeck((prev) => ({
                            ...prev,
                            organization_id: organization.id,
                          }))
                        }
                        className="option"
                      >
                        {organization?.name}
                      </div>
                    ))
                  : []
              }
            />
            <Button text="Confirm" onClick={handleConfirm} />
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewDeckModal;
