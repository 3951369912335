import * as Popover from '@radix-ui/react-popover';
import { Button } from 'colabs-ui-react';
import React, { useRef, useState } from 'react';
import { X } from 'react-feather';
import styled from 'styled-components';

const StyledTrigger = styled(Popover.Trigger)`
  outline: none;
  border: none;
  background: transparent;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d3d3d3;
  width: 100%;
  max-width: 400px;
  border-radius: 0.5rem;
  &[data-focus='true'] {
    border: 1px solid #1171fa;
  }
  > .invite-emails {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    > .invite-email {
      background-color: #e7f1ff;
      border-radius: 9999px;
      padding: 0.25rem 0.5rem 0.25rem 0.5rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 16px;
      > svg {
        stroke-width: 1px;
        height: 1rem;
        width: 1rem;
        cursor: pointer;
        :hover {
          stroke: #1171fa;
        }
      }
    }
  }
  > .invite-email-textfield {
    width: 100%;
    font-size: 16px;
    border: none;
    height: 100%;
    resize: none;
    :focus {
      outline: none;
    }
  }
`;

const StyledContent = styled(Popover.Content)`
  z-index: 1000000;
  :focus {
    outline: none;
  }
`;

const Arrow = styled(Popover.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const InviteEmail = ({ inviteEmail, setInviteEmail }) => {
  // const [popoverOpen, setPopoverOpen] = useState(false);
  const textareaRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState('');

  const inviteEmailTextfieldChange = (e) => {
    e.target.value = e.target.value.replace(/\s/g, '');
    const isValidEmail =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(e.target.value);
    setCurrentValue(isValidEmail ? e.target.value : '');
  };

  const addEmailToList = () => {
    if (!inviteEmail.includes(currentValue)) {
      setInviteEmail((prev) => [...prev, currentValue]);
    }
    setCurrentValue('');
    textareaRef.current.value = '';
    textareaRef.current.focus();
  };

  const removeEmailFromList = (email) => {
    setInviteEmail((prev) => prev.filter((prevEmail) => prevEmail !== email));
  };

  return (
    <Popover.Root open={currentValue}>
      <StyledTrigger data-focus={isFocused} onClick={(e) => e.preventDefault()}>
        <div className="invite-emails">
          {inviteEmail.map((email) => (
            <span className="invite-email" key={email}>
              {email}
              <X onClick={() => removeEmailFromList(email)} />
            </span>
          ))}
        </div>
        <textarea
          ref={textareaRef}
          className="invite-email-textfield"
          placeholder="Add Your Teammates' Emails"
          onChange={inviteEmailTextfieldChange}
          rows={2}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </StyledTrigger>
      <Popover.Anchor />
      <Popover.Portal>
        <StyledContent
          onCloseAutoFocus={(e) => e.preventDefault()}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onPointerDownOutside={(e) => e.preventDefault()}
          onInteractOutside={(e) => e.preventDefault()}
          sideOffset={-16}
        >
          <Button
            text={currentValue}
            fullWidth
            // variant="secondary"
            onClick={addEmailToList}
          />
          <Arrow />
        </StyledContent>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default InviteEmail;
