import React from 'react';

export default function CoEfficientLabsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 918 460"
      {...props}
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="1059.5"
          y1="309.24"
          x2="1113.92"
          y2="361.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0000001b" />
          <stop offset="1" stopColor="#00000000" />
        </linearGradient>
      </defs>
      <g>
        <path
          className="logo"
          d="M1294.48,373.87A91.85,91.85,0,0,1,1161.73,247l5.87-5.83a91.84,91.84,0,0,1,126.88,132.75m147-154.52A230.62,230.62,0,0,0,1319.08,97c-1.74-.73-3.47-1.44-5.25-2.14a229.71,229.71,0,0,0-242.24,46.88c-1.61,1.47-3.17,3-4.69,4.52s-3.07,3.11-4.56,4.7L835.93,377.34l-.34-.36a91.85,91.85,0,1,1,0-136.17l97.74-97.75a230.08,230.08,0,1,0-.36,332l.3.3,82.21-82.16c.71,1.76,1.42,3.5,2.15,5.24a230.06,230.06,0,0,0,423.82-179.11"
          transform="translate(-543 -78.88)"
        />
        <path
          fill="url(#linear-gradient)"
          d="M1158,251.3,1015.64,393.64q1,2.42,2,4.83a229.39,229.39,0,0,0,35.16,57.66L1151,357.9l.12-1.1A92,92,0,0,1,1158,251.3"
          transform="translate(-543 -78.88)"
        />
      </g>
    </svg>
  );
}
