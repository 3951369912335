import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text, Card, Button, Media, Chip } from 'colabs-ui-react';

const StyledOverviewCard = styled(Card)`
  display: flex;
  padding: 1rem;
  position: relative;
  > .cui-chip {
    position: absolute;
    top: 0rem;
    right: -0.5rem;
    transform: translateY(-50%);
  }
  > .thumbnail {
    height: 6rem;
    width: 6rem;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 0.675rem;
    margin-right: 1rem;
  }
  > .text-content {
    display: flex;
    flex-direction: column;
    > .grow {
      flex-grow: 1;
    }
  }
`;

const OverviewCard = ({ title, subtitle, onClick, thumbnail, isNew }) => {
  return (
    <StyledOverviewCard>
      {isNew && <Chip size="sm" label="NEW" color="red" variant="primary" />}
      <Media className="thumbnail" src={thumbnail} alt="" />
      <div className="text-content">
        <Text weight="medium">{title}</Text>
        {subtitle}
        <span className="grow" />
        <Button onClick={onClick} text="Review" variant="secondary" />
      </div>
    </StyledOverviewCard>
  );
};

OverviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  thumbnail: PropTypes.string,
  isNew: PropTypes.bool,
};

OverviewCard.defaultProps = {
  subtitle: '',
  onClick: () => null,
  thumbnail: '',
  isNew: false,
};

export default OverviewCard;
