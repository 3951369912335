import { Media, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { ChevronRight } from 'react-feather';
import styled from 'styled-components';
import blueImage from '../../../assets/images/BlueImage.png';
import tiktokBottom from '../../../assets/images/tiktok_bottom.png';
import tiktokFollowing from '../../../assets/images/tiktok_following.png';
import tiktokMusicIcon from '../../../assets/images/tiktok_musicIcon.png';
import tiktokRightUi from '../../../assets/images/tiktok_rightui.png';
import tiktokTop from '../../../assets/images/tiktok_top.png';
import Pin from '../../Pin';

const StyledTiktok = styled.div`
  background: #c9ccd1;
  background-color: #c9ccd1;
  width: 100%;
  height: 100%;
  > * {
    pointer-events: none;
  }
  > .appTop,
  > .appBottom {
    background: #fafafa;
    object-fit: contain;
    width: 100%;
    margin-bottom: -0.4rem;
    z-index: 10;
  }
  > .appTop {
    position: absolute;
    margin-bottom: 0rem;
  }
  > .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 10%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.4) 75%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
  > .creativeWrapper {
    pointer-events: auto;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    > .pin {
      position: absolute;
    }
    > .creative {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center center;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
  > .appBottom {
    position: absolute;
    bottom: 0;
    z-index: 10;
  }
  .tiktokFollowing {
    position: absolute;
    z-index: 10;
    top: 8.5%;
    width: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
  .tiktokRightUi {
    position: absolute;
    right: 2.5%;
    bottom: 9.5%;
    height: 34%;
    opacity: 0.95;
  }
  .avatar {
    position: absolute;
    border: 2px solid #fff;
    right: 3%;
    bottom: 48%;
    height: 5.8%;
    width: 12.5%;
    background: white;
    object-fit: contain;
    border-radius: 2rem;
  }
  .avatarMusic {
    position: absolute;
    right: 4.95%;
    bottom: 10.9%;
    height: 3.5%;
    width: 8%;
    background: white;
    object-fit: contain;
    border-radius: 2rem;
  }
  > .textContent {
    position: absolute;
    bottom: 9%;
    left: 3%;
    width: 70%;
    > * {
      margin: 0.45rem 0;
    }
    .username {
      font-size: 0.9rem;
    }
    .copy {
      font-size: 0.75rem;
      line-height: 18px;
      display: block;
      .sponsoredTag {
        font-size: 0.55rem;
        background: #88888888;
        padding: 2px 5px;
        width: fit-content;
      }
    }
    > .music {
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      .tiktokMusicIcon {
        height: 0.75rem;
        margin-right: 0.4rem;
      }
    }
    > .cta {
      background-color: #99abb7;
      padding: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 0.5rem;
      border-radius: 2px;
      .cui-text {
        font-size: 0.7rem;
      }
      > .chevron {
        height: 0.75rem;
        width: 0.75rem;
        margin-bottom: -0.1rem;
      }
    }
  }
`;

const Tiktok = ({
  selectedCopys,
  cta,
  creative,
  socialProfilePicture,
  socialUsername,
  isInteractive,
  //   adTextIsExpanded,
  //   setAdTextIsExpanded,
  //   trimCopy,
  //   isLongerThanLimit,
  pins,
  pinsEnabled,
  newPinIndex,
}) => {
  return (
    <StyledTiktok>
      <div id="creativeWrapper" className="creativeWrapper">
        {pins?.map((pin) => (
          <Pin
            style={{ top: `${pin.top}%`, left: `${pin.left}%` }}
            className={(() => {
              if (pin.is_internal) return 'internal';
              if (pin.user_organization_id === 1) return 'colabs';
              return '';
            })()}
            number={pin?.number}
          />
        ))}
        {pinsEnabled && (
          <Pin className="newPin" number={newPinIndex} id="newPin" />
        )}
        <Media
          onError={(e) => {
            e.target.src = blueImage;
          }}
          videoProps={
            isInteractive
              ? {
                  autoPlay: true,
                  loop: true,
                }
              : {}
          }
          alt=""
          id="creativeMedia"
          className="creative"
          src={creative}
        />
      </div>
      <div className="gradient" />
      <img className="appTop" alt="" src={tiktokTop} />
      <img alt="" className="tiktokFollowing" src={tiktokFollowing} />
      <img alt="" className="appBottom" src={tiktokBottom} />
      <img alt="" className="tiktokRightUi" src={tiktokRightUi} />
      <Media
        onError={(e) => {
          e.target.src = blueImage;
        }}
        alt=""
        className="avatar"
        src={socialProfilePicture || blueImage}
      />
      <Media
        onError={(e) => {
          e.target.src = blueImage;
        }}
        alt=""
        className="avatarMusic"
        src={socialProfilePicture}
      />
      <div className="textContent">
        <Text className="username" color="white">
          @{socialUsername}
        </Text>
        <Text className="copy" color="white" tag="span">
          {selectedCopys?.description?.content}{' '}
          <span className="sponsoredTag">Sponsored</span>
        </Text>
        <Text className="music" color="white">
          <img alt="" className="tiktokMusicIcon" src={tiktokMusicIcon} />
          Music - {socialUsername}
        </Text>
        <div className="cta">
          <Text color="white">{cta?.substring(0, 18)}</Text>
          <ChevronRight className="chevron" color="white" />
        </div>
      </div>
    </StyledTiktok>
  );
};

Tiktok.propTypes = {
  selectedCopys: PropTypes.shape().isRequired,
  cta: PropTypes.string.isRequired,
  creative: PropTypes.string.isRequired,
  socialProfilePicture: PropTypes.string.isRequired,
  socialUsername: PropTypes.string.isRequired,
  //   adTextIsExpanded: PropTypes.bool.isRequired,
  //   setAdTextIsExpanded: PropTypes.func.isRequired,
  //   trimCopy: PropTypes.func.isRequired,
  //   isLongerThanLimit: PropTypes.func.isRequired,
};

Tiktok.defaultProps = {};

export default Tiktok;
