import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Image, FileText } from 'react-feather';
import { Text, Divider, Card } from 'colabs-ui-react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import AdApprovalDecksFetchingStatus from './AdApprovalDecksFetchingStatus';
import CreativeDeckRow from './CreativeDeckRow';
import CopyDeckRow from './CopyDeckRow';
import AdApprovalDecksHeader from './AdApprovalDecksHeader';
import useOnScreen from '../../hooks/useOnScreen';

const StyledAllDecksList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  > .cui-divider {
    grid-column: 1 / 3;
    margin-bottom: 0.75rem;
  }
  > .deckType {
    @media ${({ theme }) => theme.breakpoint.lg} {
      grid-column: 1 / 3;
    }
    > .deckHeader {
      display: flex;
      padding: 0.25rem 0.75rem;
      justify-content: space-between;
      @media ${({ theme }) => theme.breakpoint.lg} {
        display: none;
      }
      > .cui-text {
        line-height: 2.5rem;
      }
    }
    .decks {
      width: 100%;
      min-height: 12rem;
      max-height: calc(100vh - 41rem);
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 6px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #cdcdcd;
      }
      ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
    }
  }
`;

const AllDecksList = ({
  organizations,
  filteredOrganizations,
  setFilteredOrganizations,
  setNewDeckMenuIsOpen,
  creativesFetchingStatus,
  creativeDecks,
  changeDeckPreview,
  selectedDeck,
  hasReachedEndOfList,
  creativesLoadingMsgRef,
  copysFetchingStatus,
  copyDecks,
  fetchCreativeDecks,
  creativeDeckPage,
  setCreativeDeckPage,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { width } = useWindowDimensions();
  const user = useSelector((state) => state.user);
  const isColabsMember = user?.organization?.id === 1;
  const isCreativesLoadingMsgVisible = useOnScreen(creativesLoadingMsgRef, [
    creativesFetchingStatus,
  ]);

  const handleDeckKeyDown = () => {
    // const asd = document?.querySelector('.deck.selected');
    // if (e.key === 'ArrowDown') {
    //   changeDeckPreview(copyDecks[index + 1], 'copy');
    // }
    // if (e.key === 'ArrowUp') {
    //   changeDeckPreview(copyDecks[index - 1], 'copy');
    // }
  };

  const fetchNewCreativeDecks = async () => {
    if (
      creativesFetchingStatus !== 'success' ||
      hasReachedEndOfList ||
      !isCreativesLoadingMsgVisible
    ) {
      return;
    }
    fetchCreativeDecks(creativeDeckPage + 1, 'loadingNew');
    setCreativeDeckPage((prev) => prev + 1);
  };

  useEffect(() => {
    fetchNewCreativeDecks();
  }, [isCreativesLoadingMsgVisible, creativesFetchingStatus]);

  return (
    <StyledAllDecksList>
      <AdApprovalDecksHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isColabsMember={isColabsMember}
        organizations={organizations}
        filteredOrganizations={filteredOrganizations}
        setFilteredOrganizations={setFilteredOrganizations}
        setNewDeckMenuIsOpen={setNewDeckMenuIsOpen}
      />
      <Divider />
      {(width > 1279 || activeTab === 0) && (
        <Card className="deckType">
          <div className="deckHeader">
            <Text size={4} weight="medium" noWrap>
              Creative Decks
            </Text>
          </div>
          <Divider />
          <div className="decks" onScroll={fetchNewCreativeDecks}>
            {creativeDecks?.map((deck, index) => (
              <Fragment key={deck.id}>
                <CreativeDeckRow
                  deck={deck}
                  changeDeckPreview={changeDeckPreview}
                  selectedDeck={selectedDeck}
                  handleDeckKeyDown={handleDeckKeyDown}
                  isColabsMember={isColabsMember}
                />
                {index !== creativeDecks.length - 1 && <Divider />}
              </Fragment>
            ))}
            <AdApprovalDecksFetchingStatus
              name="Creative"
              status={creativesFetchingStatus}
              decks={creativeDecks}
              iconComponent={Image}
              hasReachedEndOfList={hasReachedEndOfList}
              creativesLoadingMsgRef={creativesLoadingMsgRef}
            />
          </div>
        </Card>
      )}
      {(width > 1279 || activeTab === 1) && (
        <Card className="deckType">
          <div className="deckHeader">
            <Text size={4} weight="medium">
              Copy Decks
            </Text>
          </div>
          <Divider />
          <div className="decks">
            {copyDecks?.map((deck, index) => (
              <Fragment key={deck.id}>
                <CopyDeckRow
                  selectedDeck={selectedDeck}
                  index={index}
                  handleDeckKeyDown={handleDeckKeyDown}
                  changeDeckPreview={changeDeckPreview}
                  deck={deck}
                  isColabsMember={isColabsMember}
                />
                {index !== copyDecks.length - 1 && <Divider />}
              </Fragment>
            ))}
            <AdApprovalDecksFetchingStatus
              status={copysFetchingStatus}
              name="Copy"
              decks={copyDecks}
              iconComponent={FileText}
            />
          </div>
        </Card>
      )}
    </StyledAllDecksList>
  );
};

AllDecksList.propTypes = {};

AllDecksList.defaultProps = {};

export default AllDecksList;
