import { Card } from 'colabs-ui-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CommentsPanel from '../components/copys/CommentsPanel';
import CopysDetails from '../components/copys/CopysDetails';
import PhoneContainer from '../components/copys/PhoneContainer';
import Pin from '../components/Pin';
import useWindowDimensions from '../hooks/useWindowDimensions';
import MainLayout from '../layouts/Main';
import { copyCharLimits } from '../staticData';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledCopysPage = styled.div`
  padding: 0.75rem;
  max-width: 100rem;
  width: 100%;
  > .container {
    display: grid;
    margin: 0;
    justify-content: center;
    height: calc(100vh - 4.5rem);
  }
`;

const CopysPage = () => {
  const [currentPlatform, setCurrentPlatform] = useState();
  const [comments, setComments] = useState();
  const [shownPanels, setShownPanels] = useState({ maxSlots: 1, panels: [1] });
  const [internalComments, setInternalComments] = useState();
  const [internalCommentsShown, setInternalCommentsShown] = useState(false);
  const [copyDeck, setCopyDeck] = useState();
  const [copys, setCopys] = useState();
  const [copyToComment, setCopyToComment] = useState();
  const [adTextIsExpanded, setAdTextIsExpanded] = useState(false);
  const [copyDeckFetchingState, setCopyDeckFetchingState] = useState('start');
  const [selectedCopys, setSelectedCopys] = useState({});
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const { deckId } = useParams();
  const user = useSelector((state) => state.user);
  const { width } = useWindowDimensions();

  const changeSelectedCopys = useCallback((newSelectedCopys) => {
    setAdTextIsExpanded(false);
    setSelectedCopys(newSelectedCopys);
  }, []);

  const getOrderedIndex = () => {
    const countCopys = (prevTypes, platform) =>
      prevTypes.reduce((acc, type) => acc + copys[platform][type].length, 0);
    return Object.keys(copys)
      .map((platform, platformIdx, platforms) => {
        const prevPlatforms = platforms.slice(null, platformIdx);
        const prevPlatformsCopyCount = prevPlatforms.reduce(
          (acc, prevPlatform) =>
            acc + countCopys(Object.keys(copys[prevPlatform]), prevPlatform),
          0
        );
        return Object.keys(copys[platform]).map((type, typeIdx, types) => {
          const prevTypes = types.slice(null, typeIdx);
          const prevTypesCopyCount = countCopys(prevTypes, platform);
          return copys[platform][type].map((copy, copyIdx) => {
            const orderedIndex =
              prevPlatformsCopyCount + prevTypesCopyCount + copyIdx;
            copys[platform][type][copyIdx].orderedIndex = orderedIndex;
            // return { id: copy.id, orderedIndex };
            return copy.id;
          });
        });
      })
      .flat(2);
  };

  const fetchComments = async () => {
    const copysMap = getOrderedIndex();
    try {
      const { data } = await axiosWithAuthRef
        .current()
        .get(`/comments/copy-deck/${deckId}`);
      const sortedComments = data
        ?.map((comment) => ({
          ...comment,
          copyOrderedIndex: copysMap.findIndex((id) => id === comment?.copy_id),
        }))
        ?.sort((a, b) => a.copyOrderedIndex - b.copyOrderedIndex);
      setComments(sortedComments.filter((comment) => !comment.is_internal));
      setInternalComments(
        sortedComments.filter((comment) => comment.is_internal)
      );
    } catch (e) {
      console.log(e);
    }
  };

  const changeCopyToComment = (id, orderedIndex) => {
    if (id === null) {
      setCopyToComment(null);
      return;
    }
    setShownPanels((prev) => ({
      maxSlots: prev.maxSlots,
      panels: [2, 1, 0].slice(0, prev.maxSlots),
    }));
    setCopyToComment({ id, orderedIndex });
  };

  const nestCopys = (e) =>
    e?.reduce((acc, item, index) => {
      if (!acc || !acc[item.platform]) {
        return {
          ...acc,
          [item.platform]: {
            [item.type]: [{ unorderedIndex: index, ...item }],
          },
        };
      }
      return {
        ...acc,
        [item.platform]: {
          ...acc[item.platform],
          [item.type]: acc[item.platform][item.type]
            ? [
                ...acc[item.platform][item.type],
                { unorderedIndex: index, ...item },
              ]
            : [{ unorderedIndex: index, ...item }],
        },
      };
    }, null);

  const fetchCopyDeck = useCallback(async () => {
    setCopyDeckFetchingState('loading');
    try {
      const { data } = await axiosWithAuthRef
        .current()
        .get(`/copyDeck/${deckId}`);
      setCurrentPlatform(data.copys[0].platform);
      setCopyDeck(data);
      setCopys(nestCopys(data.copys));
      setCopyDeckFetchingState('success');
    } catch (e) {
      setCopyDeckFetchingState('error');
    }
  }, [axiosWithAuthRef, deckId]);

  const reviewDeck = async () => {
    if (!copyDeck || copyDeck.reviewed_by.includes(user?.id?.toString())) {
      return;
    }
    try {
      await axiosWithAuthRef.current().put(`/copyDeck/review/${copyDeck?.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  const getCopyCharLimit = (copy, limitType) => {
    if (!copy || !limitType) return undefined;
    return copyCharLimits[copy?.platform][copy?.type][limitType];
  };

  const handleWidthChange = () => {
    if (width < 959) {
      setShownPanels((prev) => ({ maxSlots: 1, panels: [prev.panels[0]] }));
      return;
    }
    if (width < 1279) {
      setShownPanels((prev) => ({
        maxSlots: 2,
        panels: [
          prev.panels[0],
          prev.panels[1] || (prev.panels[0] === 0 ? 1 : 0),
        ],
      }));
      return;
    }
    setShownPanels(() => ({ maxSlots: 3, panels: [1, 0] }));
  };

  useEffect(() => {
    reviewDeck();
  }, [copyDeck, axiosWithAuthRef, user?.id]);

  useEffect(() => {
    if (user?.email !== 'sean@coefficientlabs.com') return;
    setInternalCommentsShown(true);
  }, [user]);

  useEffect(() => {
    fetchCopyDeck();
  }, [fetchCopyDeck]);

  useEffect(() => {
    if (!copys) return;
    fetchComments();
  }, [copys]);

  useEffect(() => {
    handleWidthChange();
  }, [width]);

  return (
    <MainLayout centered>
      <StyledCopysPage>
        <Card
          className="container"
          style={{
            gridTemplateColumns: [
              '',
              '1fr',
              shownPanels.panels[1] === 1
                ? '1.5fr 1fr'
                : 'minmax(min-content, 1fr) 1.5fr',
              'minmax(min-content, 1fr) 1.5fr 1fr',
            ][shownPanels.panels.length],
          }}
        >
          {shownPanels.panels.includes(0) && (
            <PhoneContainer
              shownPanels={shownPanels}
              copyDeck={copyDeck}
              getCopyCharLimit={getCopyCharLimit}
              currentPlatform={currentPlatform}
              setCurrentPlatform={setCurrentPlatform}
              adTextIsExpanded={adTextIsExpanded}
              setAdTextIsExpanded={setAdTextIsExpanded}
              selectedCopys={selectedCopys}
              setShownPanels={setShownPanels}
            />
          )}
          {shownPanels.panels.includes(1) && (
            <CopysDetails
              copyDeck={copyDeck}
              comments={comments}
              currentPlatform={currentPlatform}
              setCurrentPlatform={setCurrentPlatform}
              getCopyCharLimit={getCopyCharLimit}
              shownPanels={shownPanels}
              setShownPanels={setShownPanels}
              selectedCopys={selectedCopys}
              copys={copys}
              copyToComment={copyToComment}
              setCopys={setCopys}
              changeSelectedCopys={changeSelectedCopys}
              changeCopyToComment={changeCopyToComment}
            />
          )}
          {shownPanels.panels.includes(2) && (
            <CommentsPanel
              isCommentPanelOpen={shownPanels.panels.includes(2)}
              comments={comments}
              shownPanels={shownPanels}
              internalComments={internalComments}
              internalCommentsShown={internalCommentsShown}
              setInternalCommentsShown={setInternalCommentsShown}
              copyToComment={copyToComment}
              fetchComments={fetchComments}
              changeCopyToComment={changeCopyToComment}
              commentAttachment={<Pin />}
              setShownPanels={setShownPanels}
              textfieldPlaceholder={
                copyToComment
                  ? 'Leave a comment'
                  : 'Click a Copy to leave a comment'
              }
              sendDisabled={!copyToComment}
            />
          )}
        </Card>
      </StyledCopysPage>
    </MainLayout>
  );
};

export default CopysPage;
