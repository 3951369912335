import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';

const dashSeparatedToUppercase = (str) => str.split('_').join(' ');

const StyledSettingsHeader = styled.div`
  grid-column: 1 / 3;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  padding: 0.75rem;
  > .organization-logo {
    height: 64px;
    width: 64px;
    border-radius: 12px;
    border: 1px solid #d3d3d3;
  }
  > .organization-info {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    > .name {
      font-size: 18px;
      font-family: ${({ theme }) => theme.font.main.medium};
    }
    > .members {
      font-size: 14px;
    }
  }
  > .orders {
    display: grid;
    justify-items: end;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
    justify-items: center;
    direction: rtl;
    grid-gap: 5px;
    > .order {
      text-transform: lowercase;
      width: fit-content;
      border-radius: 9999px;
      padding: 0.1rem 0.75rem;
      font-size: 14px;
      background-color: rgb(220, 234, 255);
    }
  }
`;

const SettingsHeader = () => {
  const user = useSelector((state) => state.user);
  const ordersBaseProduct = user.orders
    .map((order) =>
      order.order_items
        .filter((item) => item.product_type === 'base_product')
        .map((item) => item.product_name)
    )
    .flat();

  return (
    <StyledSettingsHeader>
      <img
        src={user.organization.logo_url || blueImage}
        onError={(e) => {
          e.target.src = blueImage;
        }}
        alt="organization-logo"
        className="organization-logo"
      />
      <div className="organization-info">
        <span className="name">{user.organization.name}</span>
        <span className="members">
          {user.organization.members_count} members
        </span>
      </div>
      <div className="orders">
        {ordersBaseProduct.map((product) => (
          <div className="order">{dashSeparatedToUppercase(product)}</div>
        ))}
      </div>
    </StyledSettingsHeader>
  );
};

export default SettingsHeader;
