import { Widget } from '@typeform/embed-react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledQuestionsMainPanel = styled.div`
  .my-form {
    height: 100%;
    width: 100%;
  }
`;

const QuestionsMainPanel = ({ setNextBtnDisabled }) => {
  const { orderId } = useParams();
  const { axiosWithAuth } = useAxioswithAuth();
  const formId = 'gJOPRCOp';

  const handleSubmit = async ({ responseId }) => {
    try {
      await axiosWithAuth().post(`/typeform`, {
        response_id: responseId,
        order_id: orderId,
        form_id: formId,
      });
      setNextBtnDisabled(false);
    } catch (error) {
      alert('Error saving response id');
    }
  };

  return (
    <StyledQuestionsMainPanel className="questions">
      {useMemo(
        () => (
          <Widget
            id={formId}
            className="my-form"
            onSubmit={handleSubmit}
            hidden={{ order_id: orderId }}
          />
        ),
        [formId]
      )}
    </StyledQuestionsMainPanel>
  );
};

export default QuestionsMainPanel;
