import * as Dialog from '@radix-ui/react-dialog';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import SetupPaymentMethod from './SetupPaymentMethod';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const Content = styled(Dialog.Content)`
  background: white;
  border-radius: 0.75rem;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 100vh;
  max-width: 46rem;
  padding: clamp(2rem, 5vw, 1rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .close-btn {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    > :hover {
      opacity: 0.5;
    }
    > svg {
      opacity: 0.87;
      stroke-width: 1.5px;
      height: 1.25rem;
      width: 1.25rem;
    }
  }
  > .brief-stepper {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
    align-items: center;
    height: 3.25rem;
    padding: 0 0.75rem;
    gap: 1rem;
    width: 100%;
    > .step {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      justify-content: center;
      &.done {
        > .number {
          background-color: #1171fa;
        }
      }
      &.active {
        opacity: 1;
        > .number {
          background-color: #1171fa;
        }
        > .label {
          color: #1171fa;
        }
      }
      > .number {
        height: 1.375rem;
        width: 1.375rem;
        border-radius: 100rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #888;
        color: white;
        > .check-icon {
          height: 1rem;
          width: 1rem;
        }
      }
    }
    > .line {
      border-bottom: 1px solid #d3d3d3;
      min-width: 1rem;
      flex-grow: 1;
    }
  }
  > .step {
    max-width: 600px;
    width: 100%;
    &.step-1 {
      display: grid;
      grid-template-columns: 16rem 1fr 1fr;
      grid-gap: 1rem;
      > .profilePic {
        grid-row: 1 / 6;
        grid-column: 1 / 2;
        > .avatar {
          width: 100%;
          object-fit: cover;
        }
      }
      .email,
      .position,
      .location,
      .phone {
        grid-column: 2 / 4;
      }
      > .editing-actions {
        grid-column: 1 / 4;
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
`;

const Overlay = styled(Dialog.Overlay)`
  background: rgba(0 0 0 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10000;
  padding: 0 0.5rem;
`;

const Close = styled(Dialog.Close)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  background-color: #00000000;
  border: none;
  padding: 0;
`;

const NewPaymentMethodModal = ({
  newPaymentMethod,
  setNewPaymentMethod,
  fetchPaymentMethods,
}) => {
  const user = useSelector((state) => state.user);
  const { axiosWithAuth } = useAxioswithAuth();
  const [clientSecret, setClientSecret] = useState('');

  const createSetupIntent = async () => {
    try {
      const { data } = await axiosWithAuth().post('/payments/setup_intents', {
        organization_id: user.organization_id,
      });
      setClientSecret(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!newPaymentMethod.isModalOpen) return;
    createSetupIntent();
  }, [newPaymentMethod.isModalOpen]);

  return (
    <Dialog.Root
      open={newPaymentMethod.isModalOpen}
      onOpenChange={(open) => {
        if (open) fetchPaymentMethods();
        setNewPaymentMethod((prev) => ({ ...prev, isModalOpen: open }));
      }}
    >
      <Dialog.Portal>
        <Overlay />
        {newPaymentMethod.mode === 'delete' ? (
          <Content onClick={(e) => e.stopPropagation()}>
            <Dialog.Title className={`title ${newPaymentMethod.mode}`}>
              <span className="avoidwrap">Are you sure you want to remove</span>{' '}
              <span className="avoidwrap">"{newPaymentMethod.name}"?</span>
            </Dialog.Title>
            <Dialog.Description className="description">
              This person will loose access to this organization.
            </Dialog.Description>
            <div className="buttons">
              <Button
                text="Cancel"
                variant="secondary"
                onClick={() =>
                  setNewPaymentMethod((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }))
                }
              />
              <Button
                text="Confirm"
                onClick={async () => {
                  // await deleteMember();
                  setNewPaymentMethod((prev) => ({
                    ...prev,
                    isModalOpen: false,
                  }));
                }}
              />
            </div>
            <Close>
              <X />
            </Close>
          </Content>
        ) : (
          <Content onClick={(e) => e.stopPropagation()}>
            {clientSecret ? (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret,
                  appearance: {
                    hideIcon: false,
                    theme: 'stripe',
                    letiables: {
                      colorPrimary: '#1171ff',
                      colorBackground: 'white',
                      colorText: '#000000',
                    },
                  },
                  loader: 'never',
                  paymentMethodOrder: [
                    'us_bank_account',
                    'card',
                    // 'acss_debit',
                    // 'bancontact',
                    // 'card_present',
                    // 'cashapp',
                    // 'ideal',
                    // 'link',
                    'sepa_debit',
                    // 'sofort',
                  ],
                }}
              >
                <SetupPaymentMethod
                  closeModal={() =>
                    setNewPaymentMethod((prev) => ({
                      ...prev,
                      isModalOpen: false,
                    }))
                  }
                />
              </Elements>
            ) : (
              <div>Loading...</div>
            )}
            <Close>
              <X />
            </Close>
          </Content>
        )}
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewPaymentMethodModal;
