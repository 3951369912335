import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Plus } from 'react-feather';
import { Button, Text } from 'colabs-ui-react';
import OrganizationsDropdown from './OrganizationsDropdown';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Tabs from '../presentational/Tabs';

const StyledAdApprovalDecksHeader = styled.div`
  grid-column: 1 / 3;
  display: flex;
  > .grow {
    flex-grow: 1;
  }
  > .cui-dropdown,
  > .cui-button {
    margin-left: 0.5rem;
  }
`;

const AdApprovalDecksHeader = ({
  activeTab,
  setActiveTab,
  isColabsMember,
  organizations,
  filteredOrganizations,
  setFilteredOrganizations,
  setNewDeckMenuIsOpen,
}) => {
  const { width } = useWindowDimensions();
  return (
    <StyledAdApprovalDecksHeader className="decksHeader">
      {width < 1279 && (
        <Tabs
          value={activeTab}
          onChange={setActiveTab}
          tabs={[{ label: 'Creative Decks' }, { label: 'Copy Decks' }]}
        />
      )}
      {width >= 1279 && (
        <Text size={5} weight="medium" noWrap>
          All Decks
        </Text>
      )}
      <span className="grow" />
      {isColabsMember && (
        <>
          <Button
            text={width > 959 ? 'Create New Deck' : undefined}
            icon={width <= 959 ? <Plus /> : undefined}
            onClick={() => setNewDeckMenuIsOpen(true)}
            id="newDeckMenuBtn"
            variant="secondary"
          />
          <OrganizationsDropdown
            organizations={organizations}
            filteredOrganizations={filteredOrganizations}
            setFilteredOrganizations={setFilteredOrganizations}
          />
        </>
      )}
    </StyledAdApprovalDecksHeader>
  );
};

AdApprovalDecksHeader.propTypes = {};

AdApprovalDecksHeader.defaultProps = {};

export default AdApprovalDecksHeader;
