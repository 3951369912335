import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Axios from 'axios';
import { useParams } from 'react-router';
import unsubGif from '../assets/images/unsubscribeGif.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: 'auto',
    position: 'relative',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 38,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
    },
  },
  title2: {
    fontSize: 25,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  pos: {
    marginBottom: 12,
  },
}));

const UnsubscribePage = () => {
  const classes = useStyles();
  const params = useParams();

  const [message, setMessage] = useState("You're unsubscribed.");
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!params.id) {
      setErrorMessage(true);
    } else {
      (async () => {
        try {
          const { data } = await Axios.put(
            `${process.env.REACT_APP_API_URL}/email-marketing/${params.id}`,
            {
              subscription: false,
            }
          );
          console.log(data, 'response');
        } catch (e) {
          console.log(e, 'error');
        }
      })();
    }
  }, [params.id]);

  return (
    <div className="unsubscribeBackground">
      {params.id && params.id.length > 0 && (
        <Card className={classes.root}>
          <CardContent>
            <Typography className={classes.title} variant="h5" component="h2">
              {message}
            </Typography>
            <img src={unsubGif} alt="Unsubscribe" className="unsubscribeGif" />
            <button
              style={{ position: 'absolute', bottom: '10%', right: '31%' }}
              type="button"
              onClick={async () => {
                setMessage("You're Resubscribed!");
                try {
                  const { data } = await Axios.put(
                    `${process.env.REACT_APP_API_URL}/email-marketing/${params.id}`,
                    {
                      subscription: true,
                    }
                  );
                  console.log(data, 'response');
                } catch (e) {
                  console.log(e, 'error');
                }
              }}
            >
              <Typography
                className={classes.title2}
                variant="h5"
                component="h2"
                color="textSecondary"
              >
                Re-Subscribe?
              </Typography>
            </button>
          </CardContent>
        </Card>
      )}
      {!params.id && errorMessage && (
        <p>
          Sorry Something went wrong unsubscribing, please click the link again
        </p>
      )}
    </div>
  );
};

export default UnsubscribePage;
