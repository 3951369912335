import { Button, Divider, Text } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, PieChart } from 'react-feather';
import ReactGoogleSlides from 'react-google-slides';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { DeleteData, FetchBlob, FetchData } from '../actions';
import ReportCard from '../components/reports/ReportCard';
import ReportsBar from '../components/reports/ReportsBar';
import MainLayout from '../layouts/Main';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledReportsPage = styled.div`
  padding: 0.75rem;
  max-width: 100rem;
  width: 100%;
  > .container {
    padding: 0.5rem;
    &.message {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 70vh;
    }
    > .cui-divider {
      margin-bottom: 0.5rem;
    }
    > .fullViewWrapper {
      height: 80vh;
      width: 100%;
    }
    > .reports {
      display: grid;
      grid-column-gap: 1rem;
      grid-row-gap: 2rem;
      height: auto;
      justify-items: center;
      grid-template-columns: repeat(4, 1fr);
      @media ${({ theme }) => theme.breakpoint.lg} {
        grid-template-columns: repeat(2, 1fr);
      }
      @media ${({ theme }) => theme.breakpoint.sm} {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 0.5rem;
      }
    }
  }
`;

const ReportsPage = () => {
  const user = useSelector((state) => state.user);
  const orgExecutiveReportUrlFromState = useSelector(
    (state) => state.data.Exec_Report_Url
  );
  const { orderId } = useParams(); // orderId is the organizationId temporarily
  const organizationId = Number(orderId) || user?.organization_id;
  const [selectedReport, setSelectedReport] = useState();
  const [orgExecutiveReportUrl, setOrgExecutiveReportUrl] = useState(
    orgExecutiveReportUrlFromState ?? ''
  );
  const [fetchingData, setFetchingData] = useState(true);
  const dispatch = useDispatch();
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const execReports = useSelector((state) => state.executiveReports);
  const isColabsAdmin =
    user?.organization_role === 'admin' && user?.organization_id === 1;

  function handleDelete(e, docId, googleId) {
    e.preventDefault();
    e.stopPropagation();
    try {
      dispatch(
        DeleteData(
          axiosWithAuth,
          `${process.env.REACT_APP_API_URL}/documents/${docId}?google_id=${googleId}`,
          'executiveReports'
        )
      );
      dispatch(
        FetchData(
          axiosWithAuthRef.current,
          `/documents/org/${organizationId}/executivereports`,
          'executiveReports'
        )
      );
    } catch (error) {
      console.log(error);
    }
  }

  function handleDownload(e, docId, reportTitle, setloader, documentID) {
    e.preventDefault();
    e.stopPropagation();
    try {
      dispatch(
        FetchBlob(
          axiosWithAuthRef.current,
          `/documents/${organizationId}/download?google_id=${docId}&doc_id=${documentID}`,
          `${reportTitle}.pdf`,
          setloader
        )
      );
    } catch (error) {
      console.log(error);
    }
  }

  const fetchExecutiveReports = async () => {
    dispatch(
      FetchData(
        axiosWithAuthRef.current,
        `/documents/org/${organizationId}/executivereports`,
        'executiveReports'
      )
    );
    dispatch(
      FetchData(axiosWithAuthRef.current, `/organizations/${organizationId}`)
    );
    if (orgExecutiveReportUrlFromState !== undefined) {
      setFetchingData(false);
    }
    if (user && orgExecutiveReportUrlFromState) {
      setOrgExecutiveReportUrl(orgExecutiveReportUrlFromState);
    }
  };

  useEffect(() => {
    if (!user) return;
    fetchExecutiveReports();
  }, [user, dispatch, axiosWithAuthRef, orgExecutiveReportUrlFromState]);

  return (
    <MainLayout centered>
      <StyledReportsPage>
        {isColabsAdmin && !selectedReport && (
          <ReportsBar
            organizationId={organizationId}
            orgExecutiveReportUrl={orgExecutiveReportUrl}
            setOrgExecutiveReportUrl={setOrgExecutiveReportUrl}
            orgExecutiveReportUrlFromState={orgExecutiveReportUrlFromState}
          />
        )}
        {selectedReport && (
          <div className="container">
            <Button
              text="Back to All Reports"
              variant="secondary"
              icon={<ChevronLeft />}
              onClick={() => setSelectedReport(null)}
            />
            <div className="fullViewWrapper">
              <ReactGoogleSlides
                width="100%"
                height="100%"
                slidesLink={selectedReport}
                position={1}
                showControls
                loop
              />
            </div>
          </div>
        )}
        {!fetchingData && !execReports?.length && (
          <div className="container message">
            <PieChart size="8rem" strokeWidth="1" />
            <Text align="center">
              You don&apos;t have any Reports to show right now
            </Text>
          </div>
        )}
        {!fetchingData && !selectedReport && execReports?.length > 0 && (
          <div className="container">
            <div className="decksHeader">
              <Text size={5} weight="medium" noWrap>
                All Reports
              </Text>
            </div>
            <Divider />
            <div className="reports">
              {execReports?.map((report) => (
                <ReportCard
                  report={report}
                  handleDelete={handleDelete}
                  setSelectedReport={setSelectedReport}
                  handleDownload={handleDownload}
                />
              ))}
            </div>
          </div>
        )}
      </StyledReportsPage>
    </MainLayout>
  );
};

export default ReportsPage;
