import { Button, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { ChevronLeft } from 'react-feather';
import styled from 'styled-components';
import CoEfficientLabsIcon from '../../assets/icons/CoefficientLabs';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledAdApprovalCommentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.75rem 0 0.75rem;
  > .internalCommentsToggle {
    .icon {
      fill: #2c81fb;
      &.active {
        fill: #ffa311;
      }
    }
  }
`;

const AdApprovalCommentsHeader = ({
  internalCommentsShown,
  setInternalCommentsShown,
  setActiveTab,
}) => {
  const { width } = useWindowDimensions();
  return (
    <StyledAdApprovalCommentsHeader>
      {width < 1279 && (
        <Button
          onClick={() => {
            setActiveTab(0);
            setInternalCommentsShown(false);
          }}
          icon={<ChevronLeft />}
          variant="tertiary"
        />
      )}
      <Text
        size={5}
        weight="bold"
        color={internalCommentsShown ? 'orange' : undefined}
      >
        {internalCommentsShown ? 'Internal Comments' : 'Comments'}
      </Text>
      <Button
        onClick={() => setInternalCommentsShown((prev) => !prev)}
        icon={
          <CoEfficientLabsIcon
            className={`icon  ${internalCommentsShown ? 'active' : ''}`}
          />
        }
        variant={internalCommentsShown ? 'secondary' : 'tertiary'}
        size="sm"
        className="internalCommentsToggle"
      />
    </StyledAdApprovalCommentsHeader>
  );
};

AdApprovalCommentsHeader.propTypes = {
  internalCommentsShown: PropTypes.bool.isRequired,
  setInternalCommentsShown: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default AdApprovalCommentsHeader;
