import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import actorsImage from '../../assets/images/actors.png';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 64rem;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  .content {
    flex-grow: 1;
    .eyebrow {
      margin-top: 0;
      margin-bottom: 0.5rem;
      color: #6b7280;
    }
    .title {
      margin: 0;
      margin-bottom: 0.25rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: #3b82f6;
    }
    .subtitle {
      margin: 0;
      margin-bottom: 1.5rem;
    }
    .bundle-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .bundle-button {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 1.5rem;
        cursor: pointer;
        padding: 1rem;
        text-align: center;
        font-weight: 500;
        border-radius: 0.375rem;
        background-color: #f9fafb;
        border: 1px solid #e5e7eb;
        width: 100%;
        transition: background-color 0.3s, color 0.3s;
        &:hover {
          background-color: #3b82f6;
          color: #fff;
        }
        .bundle-info {
          padding: 0.25rem;
          border-left: 1px solid #e5e7eb;
          &:first-child {
            border-left: none;
          }
          .sub-text {
            font-size: 1rem;
          }
        }
      }
      .unknown-videos-button {
        padding: 0.75rem 1.5rem;
        color: #3b82f6;
        background-color: transparent;
        border-radius: 0.375rem;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
          background-color: #f9fafb;
        }
      }
    }
  }
  .info-box {
    padding: 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    background-color: #f9fafb;
    color: #6b7280;
    height: min-content;
    @media (min-width: 768px) {
      padding: 2rem;
    }
    .info-title {
      margin-bottom: 1rem;
      margin-top: 0;
      font-size: 1.25rem;
      font-weight: 500;
    }
    .info-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 1rem;
      list-style-type: none;
      padding: 0;
    }
    .image-container {
      position: relative;
      width: 100%;
      padding-top: 63.5%; // Aspect ratio 411/262
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.375rem;
      }
    }
  }
`;

function Step({ organizationName }) {
  const { organizationId } = useParams();
  const { axiosWithAuth } = useAxioswithAuth();
  const { replace } = useHistory();
  const [requestStatus, setRequestStatus] = React.useState('start');

  const createNewBundle = async (totalPrice) => {
    setRequestStatus('loading');
    try {
      const { data } = await axiosWithAuth().post('/orders', {
        organization_id: organizationId,
        order_items: [{ product_name: 'video_bundle', price: totalPrice }],
      });
      setRequestStatus('success');
      replace(`/agency/wizard/${organizationId}/${data.order_id}`);
    } catch (err) {
      alert('Error creating bundle. Please contact support');
      setRequestStatus('error');
    }
  };

  return (
    <Wrapper>
      <div className="content">
        <p className="eyebrow">{organizationName}</p>
        <h1 className="title">Choose a UGC Bundle</h1>
        <p className="subtitle">
          Get 3 Free Trends • Schedule a Creative Brief:
        </p>
        <div className="bundle-container">
          {[
            {
              videos: 6,
              price: '$1000',
              totalPrice: 6000,
              actors: 1,
            },
            {
              videos: 20,
              price: '$900',
              totalPrice: 18000,
              actors: 2,
            },
            {
              videos: 30,
              price: '$800',
              totalPrice: 24000,
              actors: 3,
            },
          ].map((bundle) => (
            <button
              key={bundle.videos}
              type="button"
              disabled={requestStatus === 'loading'}
              id={`cta-pick-bundle-${bundle.videos}`}
              onClick={() => createNewBundle(bundle.totalPrice)}
              className="bundle-button"
            >
              <span className="bundle-info">
                {bundle.videos} <br />
                videos
              </span>
              <span className="bundle-info">
                {bundle.actors} <br />
                actor{bundle.actors > 1 ? 's' : ''}
              </span>
              <span className="bundle-info">
                {bundle.price} <br />
                <span className="sub-text">per video</span>
              </span>
            </button>
          ))}
          <button
            type="button"
            disabled={requestStatus === 'loading'}
            className="unknown-videos-button"
            onClick={() => createNewBundle(0)}
          >
            I don’t know how many videos we need
          </button>
        </div>
      </div>
      <div className="info-box">
        <h3 className="info-title">
          What&rsquo;s included in
          <br /> each UGC bundle?
        </h3>
        <ul className="info-list">
          <li>🔥 Actors & Actresses (Pay $0 in Influencer Fees)</li>
          <li>🔥 Unlimited Usage (You Own the Videos)</li>
          <li>🔥 3-Day Professional Shoot In-Studio</li>
          <li>🔥 Videos Delivered in 7-10 Days</li>
        </ul>
        <div className="image-container">
          <img src={actorsImage} alt="actors" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Step;
