import React, { createElement } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { Text } from 'colabs-ui-react';

const StyledBottomNavButton = styled.div`
  > * {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .icon {
      height: 2rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        object-fit: cover;
        stroke: #000;
      }
    }
    > .text {
      font-family: ${({ theme }) => theme.font.main.regular};
      font-size: 0.75rem;
      color: #000;
      white-space: nowrap;
    }
  }
`;

const BottomNavButton = ({ icon, text, disabled, component, ...props }) => {
  const Component = createElement(
    component,
    { ...props },
    <>
      <span className="icon">{icon}</span>
      <span className="text">{text.substring(0, 12)}</span>
    </>
  );
  return (
    <StyledBottomNavButton
      disabled={disabled}
      style={{
        opacity: disabled ? 0.25 : 1,
        pointerEvents: disabled ? 'none' : 'initial',
      }}
    >
      {Component}
    </StyledBottomNavButton>
  );
};

BottomNavButton.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.element,
  ]),
};

BottomNavButton.defaultProps = {
  disabled: false,
  component: 'li',
};

export default BottomNavButton;
