import React from 'react';
import { Text } from 'colabs-ui-react';

export default {
  'create-creativeDeck': {
    label: (
      <>
        A new Creative Deck{' '}
        <Text tag="span" weight="bold">
          is released
        </Text>
      </>
    ),
    category: 'Creative Decks',
    copyline: ({ resource }) => (
      <span>
        {/* source: null, resource: creative deck */}
        <Text tag="span">A new creative deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
        <Text tag="span"> has been created</Text>
      </span>
    ),
  },
  'update-creativeDeck': {
    label: (
      <>
        A past Creative Deck{' '}
        <Text tag="span" weight="bold">
          is updated
        </Text>
      </>
    ),
    category: 'Creative Decks',
    copyline: ({ resource }) => (
      <span>
        {/* source: commentator name, resource: creative deck */}
        <Text tag="span">The creative deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
        <Text tag="span"> has some edits for review</Text>
      </span>
    ),
  },
  'approved-creativeDeck': {
    label: (
      <>
        A Creative Deck{' '}
        <Text tag="span" weight="bold">
          is approved
        </Text>
      </>
    ),
    category: 'Creative Decks',
    copyline: ({ source, resource }) => (
      <span>
        {/* source: organization name, resource: creative deck */}
        <Text tag="span" weight="bold">
          {source}
        </Text>
        <Text tag="span"> has approved the creative deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
      </span>
    ),
  },
  'reviewed-creativeDeck': {
    label: (
      <>
        A Creative Deck{' '}
        <Text tag="span" weight="bold">
          is reviewed
        </Text>
      </>
    ),
    category: 'Creative Decks',
    copyline: ({ source, resource }) => (
      <span>
        {/* source: organization name, resource: creative deck */}
        <Text tag="span" weight="bold">
          {source}
        </Text>
        <Text tag="span"> has finished reviewing the creative deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
      </span>
    ),
  },
  'commented-ad': {
    label: (
      <>
        A Creative{' '}
        <Text tag="span" weight="bold">
          is commented
        </Text>{' '}
        (high frequency!)
      </>
    ),
    category: 'Creative Decks',
    copyline: ({ source, resource }) => (
      <span>
        {/* source: commentator name, resource: creative deck */}
        <Text tag="span" weight="bold">
          {source}
        </Text>
        <Text tag="span"> commented on an ad of the creative deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
      </span>
    ),
  },
  'create-internalComment': {
    colabsOnly: true,
    label: (
      <>
        A Creative{' '}
        <Text tag="span" weight="bold">
          is commented internally
        </Text>{' '}
        (high frequency!)
      </>
    ),
    category: 'Creative Decks',
    copyline: ({ source, resource }) => (
      <span>
        {/* source: commentator name, resource: creative deck */}
        <Text tag="span" weight="bold">
          {source}
        </Text>
        <Text tag="span"> commented on an ad of the creative deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
      </span>
    ),
  },
  'commented-copy': {
    label: (
      <>
        A Copy{' '}
        <Text tag="span" weight="bold">
          is commented
        </Text>{' '}
        (high frequency!)
      </>
    ),
    category: 'Copy Decks',
    copyline: ({ source, resource }) => (
      <span>
        {/* source: commentator name, resource: creative deck */}
        <Text tag="span" weight="bold">
          {source}
        </Text>
        <Text tag="span"> commented on the copy deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
      </span>
    ),
  },
  'create-copyDeck': {
    label: (
      <>
        A new Copy Deck{' '}
        <Text tag="span" weight="bold">
          is released
        </Text>
      </>
    ),
    category: 'Copy Decks',
    copyline: ({ resource }) => (
      <span>
        {/* source: null, resource: creative deck */}
        <Text tag="span">A new copy deck </Text>
        <Text tag="span" weight="bold">
          &quot;{resource}&quot;
        </Text>
        <Text tag="span"> has been created</Text>
      </span>
    ),
  },
};
