import React from 'react';
import styled from 'styled-components';

const StyledTempMobileMessage = styled.div`
  display: none;
  text-align: center;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    display: flex;
  }
`;

const TempMobileMessage = () => {
  return (
    <StyledTempMobileMessage>
      <h2>A new mobile experience is on its way ✨</h2>
      <span>In the meantime visit us on a desktop or laptop computer.</span>
    </StyledTempMobileMessage>
  );
};

export default TempMobileMessage;
