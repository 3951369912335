import PropTypes from 'prop-types';
import React from 'react';
import Facebook from './platformTemplates/Facebook';
import Google from './platformTemplates/Google';
import Instagram from './platformTemplates/Instagram';
import Linkedin from './platformTemplates/Linkedin';
import NoPlatform from './platformTemplates/NoPlatform';
import Tiktok from './platformTemplates/Tiktok';
import Youtube from './platformTemplates/Youtube';

const CopyDeckPlatformTemplates = ({
  currentPlatform,
  selectedCopys,
  cta,
  creative,
  socialProfilePicture,
  socialUsername,
  adTextIsExpanded,
  setAdTextIsExpanded,
  isInteractive,
  pins,
  pinsEnabled,
  newPinIndex,
  getCopyCharLimit,
}) => {
  const trimCopy = (copy, limitType) => {
    if (!copy || !limitType) return undefined;
    return copy?.content?.substring(0, getCopyCharLimit(copy, limitType));
  };
  const isLongerThanLimit = (copy, limitType) => {
    if (!copy || !limitType) return undefined;
    return copy?.content?.length > getCopyCharLimit(copy, limitType);
  };

  switch (currentPlatform) {
    case 'youtube':
      return (
        <Youtube
          selectedCopys={selectedCopys}
          cta={cta}
          creative={creative}
          socialProfilePicture={socialProfilePicture}
          socialUsername={socialUsername}
          adTextIsExpanded={adTextIsExpanded}
          setAdTextIsExpanded={setAdTextIsExpanded}
          trimCopy={trimCopy}
          isLongerThanLimit={isLongerThanLimit}
          isInteractive={isInteractive}
          pins={pins}
          pinsEnabled={pinsEnabled}
          newPinIndex={newPinIndex}
        />
      );
    case 'tiktok':
      return (
        <Tiktok
          selectedCopys={selectedCopys}
          cta={cta}
          creative={creative}
          socialProfilePicture={socialProfilePicture}
          socialUsername={socialUsername}
          adTextIsExpanded={adTextIsExpanded}
          setAdTextIsExpanded={setAdTextIsExpanded}
          trimCopy={trimCopy}
          isLongerThanLimit={isLongerThanLimit}
          isInteractive={isInteractive}
          pins={pins}
          pinsEnabled={pinsEnabled}
          newPinIndex={newPinIndex}
        />
      );
    case 'google':
      return (
        <Google
          selectedCopys={selectedCopys}
          cta={cta}
          socialProfilePicture={socialProfilePicture}
          socialUsername={socialUsername}
          adTextIsExpanded={adTextIsExpanded}
          setAdTextIsExpanded={setAdTextIsExpanded}
          trimCopy={trimCopy}
          isLongerThanLimit={isLongerThanLimit}
        />
      );
    case 'facebook':
      return (
        <Facebook
          selectedCopys={selectedCopys}
          cta={cta}
          creative={creative}
          socialProfilePicture={socialProfilePicture}
          socialUsername={socialUsername}
          adTextIsExpanded={adTextIsExpanded}
          setAdTextIsExpanded={setAdTextIsExpanded}
          trimCopy={trimCopy}
          isLongerThanLimit={isLongerThanLimit}
          isInteractive={isInteractive}
          pins={pins}
          pinsEnabled={pinsEnabled}
          newPinIndex={newPinIndex}
        />
      );
    case 'linkedin':
      return (
        <Linkedin
          selectedCopys={selectedCopys}
          cta={cta}
          creative={creative}
          socialProfilePicture={socialProfilePicture}
          socialUsername={socialUsername}
          adTextIsExpanded={adTextIsExpanded}
          setAdTextIsExpanded={setAdTextIsExpanded}
          trimCopy={trimCopy}
          isLongerThanLimit={isLongerThanLimit}
          isInteractive={isInteractive}
          pins={pins}
          pinsEnabled={pinsEnabled}
          newPinIndex={newPinIndex}
        />
      );
    case 'instagram':
      return (
        <Instagram
          selectedCopys={selectedCopys}
          cta={cta}
          creative={creative}
          socialProfilePicture={socialProfilePicture}
          socialUsername={socialUsername}
          adTextIsExpanded={adTextIsExpanded}
          setAdTextIsExpanded={setAdTextIsExpanded}
          trimCopy={trimCopy}
          isLongerThanLimit={isLongerThanLimit}
          isInteractive={isInteractive}
          pins={pins}
          pinsEnabled={pinsEnabled}
          newPinIndex={newPinIndex}
        />
      );
    default:
      return (
        <NoPlatform
          creative={creative}
          isInteractive={isInteractive}
          pins={pins}
          pinsEnabled={pinsEnabled}
          newPinIndex={newPinIndex}
        />
      );
  }
};

CopyDeckPlatformTemplates.propTypes = {
  currentPlatform: PropTypes.string.isRequired,
  selectedCopys: PropTypes.shape().isRequired,
  cta: PropTypes.string.isRequired,
  creative: PropTypes.string.isRequired,
  socialProfilePicture: PropTypes.string.isRequired,
  socialUsername: PropTypes.string.isRequired,
  adTextIsExpanded: PropTypes.bool.isRequired,
  setAdTextIsExpanded: PropTypes.func.isRequired,
  getCopyCharLimit: PropTypes.func.isRequired,
  newPinIndex: PropTypes.number.isRequired,
  isInteractive: PropTypes.bool.isRequired,
  pins: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pinsEnabled: PropTypes.bool.isRequired,
};

CopyDeckPlatformTemplates.defaultProps = {};

export default CopyDeckPlatformTemplates;
