import React from 'react';
import Proptypes from 'prop-types';

const LinearGradient = ({ color1, color2, id }) => (
  <svg
    style={{ width: 0, height: 0, position: 'absolute' }}
    aria-hidden="true"
    focusable="false"
  >
    <linearGradient id={id} x2="1" y2="1">
      <stop offset="0%" stopColor={color1} />
      <stop offset="100%" stopColor={color2} />
    </linearGradient>
  </svg>
);

LinearGradient.propTypes = {
  color1: Proptypes.string.isRequired,
  color2: Proptypes.string.isRequired,
  id: Proptypes.string.isRequired,
};

export default LinearGradient;
