import queryString from 'query-string';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { SET_USER } from '../reducers';
import { api, useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledAuthVerifier = styled.div``;

const AuthVerifier = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { axiosWithAuth } = useAxioswithAuth();
  const axiosWithAuthRef = useRef(axiosWithAuth);
  const parse = queryString.parse(location.search);

  useEffect(() => {
    const apiCall = async () => {
      let res;
      if (parse.google_id) {
        res = await api.post(`/auth/oauthlogin`, {
          email: parse.email,
          google_id: parse.google_id + process.env.REACT_APP_JWT_SECRETS,
        });
      }
      if (parse.linkedin_id) {
        res = await api.post(`/auth/oauthlogin`, {
          email: parse.email,
          linkedin_id: parse.linkedin_id + process.env.REACT_APP_JWT_SECRETS,
        });
      }
      if (parse.twitter_id) {
        res = await api.post(`/auth/oauthlogin`, {
          email: parse.email,
          twitter_id: parse.twitter_id + process.env.REACT_APP_JWT_SECRETS,
        });
      }
      const userDetails = await axiosWithAuthRef.current().get('/users/user');
      dispatch({ type: SET_USER, payload: userDetails.data });
    };

    apiCall().then(() => {
      window.location.replace(
        `${
          process.env.REACT_APP_VC_MAP_URL ||
          'https://vc-map.coefficientlabs.com'
        }/start`
      );
      // window.location.replace(`http://localhost:3001/home`);
    });
  }, [axiosWithAuthRef, dispatch, parse]);

  return <StyledAuthVerifier>Verifying...</StyledAuthVerifier>;
};

export default AuthVerifier;
