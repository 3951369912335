import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { CheckCircle, Eye, EyeOff, MessageSquare, Plus } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SortIcons from '../SortIcons';
import NewScriptModal from './NewScriptModal';
import ScriptActionsMenu from './ScriptActionsMenu';

const StyledScriptWall = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.75) 100%
  );
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  > button {
    padding: 1rem;
    border-radius: 12px;
    background-color: transparent;
    color: #1171fa;
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: 20rem;
    cursor: pointer;
    outline: none;
    border: none;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const StyledScriptNavPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  overflow: hidden;
  > .panel-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.25rem;
    > .title {
      margin-right: 1rem;
      flex-grow: 1;
    }
    > .cui-textfield {
      flex-grow: 1;
    }
  }
  > .script-list-row,
  > .script-list-body > .script-list-row {
    display: grid;
    grid-template-columns: 1fr 1.5fr 0.5fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    &[data-is-colabs='true'] {
      grid-template-columns: 1.25fr 1fr 0.5fr 0.5fr;
    }
    &.columns {
      padding-right: 1.25rem;
    }
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    .message-icon {
      stroke-width: 1px;
      height: 1.25rem;
      width: 1.25rem;
    }
    &.script {
      height: 4.75rem;
      font-size: 14px;
      cursor: pointer;
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &[data-active='true'] {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
      }
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      > .organization-logo {
        height: 3.75rem;
        width: 3.75rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .chip-label {
        border: 1px solid #d3d3d3;
        border-radius: 9999px;
        padding: 0.25rem 0.5rem;
        width: fit-content;
        &.green {
          border: 1px solid #44dc7a;
          color: #44dc7a;
          background-color: #c7ffdb;
        }
        &.red {
          border: 1px solid #f55050;
          color: #f55050;
          background-color: #ffdbdb;
        }
        &.orange {
          border: 1px solid #ffa311;
          color: #ffa311;
          background-color: #ffe9c7;
        }
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
  }
  > .script-list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .message {
      padding: 1rem;
      text-align: center;
    }
  }
`;

const ScriptsNavPanel = ({
  scriptsFetch,
  activeScriptId,
  setActiveScriptId,
  fetchScripts,
  setNextBtnDisabled,
  isEditing,
}) => {
  const dispatch = useDispatch();
  const [columnSort, setColumnSort] = useState({
    column: 'name',
    order: 'asc',
  });
  const [showCompleted, setShowCompleted] = useState(false);
  const [newScript, setNewScript] = useState({
    mode: '', // 'create', 'edit', 'delete'
    id: null,
    name: '',
    templateId: null,
    actionState: 'start',
    isModalOpen: false,
  });
  const user = useSelector((state) => state.user);
  const isSuperAdmin = user?.organization?.type === 'superadmin';

  const updateColumnSort = (column) => {
    if (columnSort.column === column) {
      setColumnSort({
        column,
        order: columnSort.order === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setColumnSort({
        column,
        order: 'asc',
      });
    }
  };

  const goToNextPendingScript = () => {
    const nextPendingScript =
      scriptsFetch.data.find(({ status }) => status === 'pending') ||
      scriptsFetch.data.find(({ status }) => status !== 'completed') ||
      scriptsFetch.data[0];
    if (!nextPendingScript) return;
    setActiveScriptId(nextPendingScript.id);
  };

  const updateActiveScript = (scriptId) => {
    if (isEditing) {
      const shouldContinue = window.confirm(
        `You're still editing, unsaved changes will be lost. Continue?`
      );
      if (!shouldContinue) return;
    }
    setActiveScriptId(scriptId);
  };

  useEffect(() => {
    if (scriptsFetch.fetchState !== 'success') return;
    if (!activeScriptId) {
      goToNextPendingScript();
    }
    if (scriptsFetch.data.every(({ status }) => status === 'approved')) {
      setNextBtnDisabled(false);
    } else {
      setNextBtnDisabled(true);
    }
  }, [scriptsFetch.fetchState]);

  useEffect(() => {
    if (user?.organization?.onboarding_step < 3) return;
    fetchScripts();
  }, []);

  return (
    <>
      {user?.organization?.onboarding_step < 3 && (
        <StyledScriptWall>
          <button
            type="button"
            onClick={() =>
              dispatch({
                type: 'UPDATE_ONBOARDING_MODAL_STATE',
                payload: { isOpen: true },
              })
            }
          >
            Finish setting up your account to access your scripts
          </button>
        </StyledScriptWall>
      )}
      <StyledScriptNavPanel className="scripts-nav-panel">
        <NewScriptModal
          newScript={newScript}
          setNewScript={setNewScript}
          fetchScripts={fetchScripts}
        />
        <div className="panel-header">
          <span className="title">
            SCRIPTS{' '}
            {scriptsFetch.fetchState === 'start'
              ? ''
              : `(${
                  scriptsFetch.data.filter(({ status }) =>
                    showCompleted ? true : status !== 'completed'
                  )?.length
                })`}
          </span>
          {isSuperAdmin && (
            <>
              <Button
                className="stand-alone-btn"
                onClick={() => setShowCompleted((prev) => !prev)}
                variant="secondary"
                icon={
                  <>
                    <CheckCircle />
                    {showCompleted ? (
                      <EyeOff size={12} style={{ marginTop: '0.22rem' }} />
                    ) : (
                      <Eye size={12} style={{ marginTop: '0.22rem' }} />
                    )}
                  </>
                }
              />
              <Button
                className="stand-alone-btn"
                onClick={() =>
                  setNewScript({
                    mode: 'create',
                    id: null,
                    name: '',
                    templateId: null,
                    actionState: 'start',
                    isModalOpen: true,
                  })
                }
                variant="secondary"
                icon={<Plus />}
              />
            </>
          )}
        </div>
        <div className="script-list-row columns" data-is-colabs={isSuperAdmin}>
          {[
            { property: 'name', label: 'Name' },
            { property: 'status', label: 'Revision' },
            {
              property: 'comments_count',
              label: <MessageSquare className="message-icon" />,
            },
          ].map(({ property, label }) => (
            <div
              className="column-title clickable"
              onClick={() => updateColumnSort(property)}
              role="button"
              tabIndex={0}
              onKeyDown={() => updateColumnSort(property)}
            >
              {label}
              <SortIcons
                sorting={
                  scriptsFetch.fetchState === 'success' &&
                  columnSort.column === property
                    ? columnSort.order
                    : undefined
                }
              />
            </div>
          ))}
          {isSuperAdmin && <div className="column-title" />}
        </div>
        <div className="script-list-body">
          {scriptsFetch?.data
            ?.filter(({ status }) =>
              showCompleted ? true : status !== 'completed'
            )
            ?.sort(
              (a, b) =>
                (columnSort.order === 'asc' ? 1 : -1) *
                (a[columnSort.column] > b[columnSort.column] ? 1 : -1)
            )
            ?.map((script) => (
              <div
                className="script-list-row script"
                // disabled={!script.references_count}
                data-is-colabs={isSuperAdmin}
                data-id={script.id}
                data-active={activeScriptId === script.id}
                onClick={() => updateActiveScript(script.id)}
                role="button"
                tabIndex={0}
                onKeyDown={() => updateActiveScript(script.id)}
              >
                <div className="name">{script.name}</div>
                <span
                  className={`chip-label ${
                    { pending: 'red', approved: 'green', reviewed: 'orange' }[
                      script.status
                    ]
                  }`}
                >
                  {script.status}
                </span>
                <div className="commentsCount">{script.comments_count}</div>
                {isSuperAdmin && (
                  <ScriptActionsMenu
                    script={script}
                    fetchScripts={fetchScripts}
                    setNewScript={(e) => {
                      setNewScript(e);
                      updateActiveScript(script.id);
                    }}
                  />
                )}
              </div>
            ))}
        </div>
      </StyledScriptNavPanel>
    </>
  );
};

export default ScriptsNavPanel;
