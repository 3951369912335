import { Button } from 'colabs-ui-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import InviteEmailTextfield from './InviteEmail';

const StyledOnboardingStep2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  > .cui-textfield {
    max-width: 400px;
    width: 100%;
  }
  > .editing-actions {
    margin-top: 2rem;
    display: flex;
    align-self: flex-end;
    gap: 8px;
  }
`;

const OnboardingStep2 = ({ setCurrentStep, currentStep }) => {
  const [inviteEmail, setInviteEmail] = useState([]);
  const user = useSelector((state) => state.user);
  const { axiosWithAuth } = useAxioswithAuth();

  const handleSkip = async () => {
    try {
      await axiosWithAuth().put(`/organizations/${user.organization_id}`, {
        onboarding_step: 2,
      });
    } catch (err) {
      // alert('Error skipping. Please try again later.');
    }
    setCurrentStep(currentStep + 1);
  };

  const sendInvite = async () => {
    try {
      await axiosWithAuth().post(
        `/users/in_organization/${user.organization_id}`,
        { emails: inviteEmail }
      );
    } catch (err) {
      if (err.status === 409) {
        // alert('Some emails sent are already in use.');
      }
      // alert('Error sending invite. Please contact support.');
    }
    try {
      await axiosWithAuth().put(`/organizations/${user.organization_id}`, {
        onboarding_step: 2,
      });
    } catch (err) {
      // alert('');
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <StyledOnboardingStep2 className="step step-2">
      <InviteEmailTextfield
        inviteEmail={inviteEmail}
        setInviteEmail={setInviteEmail}
      />
      <div className="editing-actions">
        <Button
          text="Send Invite"
          disabled={inviteEmail.length === 0}
          onClick={sendInvite}
        />
        <Button variant="secondary" text="Skip" onClick={handleSkip} />
      </div>
    </StyledOnboardingStep2>
  );
};

export default OnboardingStep2;
