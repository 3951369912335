import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { Check, ExternalLink, Plus, X } from 'react-feather';
import styled from 'styled-components';
import { formatUsd } from '../../utils/helper';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';
import SortIcons from '../SortIcons';
import NewOrderModal from './NewOrderModal';
import OrderActionsMenu from './OrderActionsMenu';

const StyledOrdersPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
      text-transform: uppercase;
    }
    > .onboarding-step {
      border: 1px solid #d3d3d3;
      border-radius: 9999px;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      &.green {
        border: 1px solid #44dc7a;
        color: #44dc7a;
        /* background-color: #c7ffdb; */
      }
      &.red {
        border: 1px solid #f55050;
        color: #f55050;
        /* background-color: #ffdbdb; */
      }
      &.orange {
        border: 1px solid #ffa311;
        color: #ffa311;
        /* background-color: #ffe9c7; */
      }
    }
    > .grow {
      flex-grow: 1;
    }
  }
  > .list-row,
  > .list-body > .list-row {
    display: grid;
    grid-template-columns: 0.5fr 1.5fr 2.25fr 1fr 0.5fr 1fr 0.5fr 0.5fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    .message-icon {
      stroke-width: 1px;
      height: 1.25rem;
      width: 1.25rem;
    }
    &.order {
      font-size: 14px;
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &[data-active='true'] {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
      }
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      > .order-logo {
        height: 3.75rem;
        width: 3.75rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .name {
        text-transform: lowercase;
      }
      > .active {
        padding-left: 0.5rem;
        svg {
          stroke-width: 1px;
          height: 1rem;
          width: 1rem;
        }
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
  }
  > .list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .no-items {
      align-items: center;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

const OrdersPanel = ({
  selectedOrganizationId,
  organizationName,
  fetchOrganizations,
  organizationOnboardingStep,
}) => {
  const [ordersFetch, setOrdersFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const [newOrder, setNewOrder] = useState({
    mode: '', // 'create', 'edit', 'delete'
    id: null,
    items: {
      baseProducts: [],
      addOns: [],
      discounts: [],
    },
    active: false,
    organizationId: selectedOrganizationId,
    actionState: 'start',
    errorMessage: '',
    isModalOpen: false,
  });
  const { axiosWithAuth } = useAxioswithAuth();

  // const [columnSort, setColumnSort] = useState({
  //   column: 'name',
  //   order: 'asc',
  // });

  // const updateColumnSort = (column) => {
  //   if (columnSort.column === column) {
  //     setColumnSort({
  //       column,
  //       order: columnSort.order === 'asc' ? 'desc' : 'asc',
  //     });
  //   } else {
  //     setColumnSort({
  //       column,
  //       order: 'asc',
  //     });
  //   }
  // };

  const fetchOrders = async () => {
    setOrdersFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get(
        `/orders/in_organization/${selectedOrganizationId}`
      );
      const orders = data?.map((order) => ({
        id: order.id,
        baseItem: order.order_items?.find(
          (orderItem) => orderItem.product_type === 'base_product'
        )?.product_name,
        addOns: order.order_items
          ?.filter((orderItem) => orderItem.product_type === 'add_on')
          .map((orderItem) => orderItem.product_name),
        price: order.order_items?.reduce((acc, orderItem) => {
          if (orderItem.product_type === 'discount')
            return acc - orderItem.price;
          return acc + orderItem.price;
        }, 0),
        sow_url: order.sow_url,
        charged: 0,
        items: order.order_items,
        active: order.active,
      }));
      setOrdersFetch({ fetchState: 'success', data: orders });
    } catch (error) {
      setOrdersFetch({ fetchState: 'error', data: [] });
    }
  };

  useEffect(() => {
    if (!selectedOrganizationId) return;
    fetchOrders();
  }, [selectedOrganizationId]);

  return (
    <StyledOrdersPanel className="orders-nav-panel">
      <NewOrderModal
        newOrder={newOrder}
        organizationName={organizationName}
        setNewOrder={setNewOrder}
        fetchOrders={async () => {
          await fetchOrders();
          await fetchOrganizations();
        }}
      />
      <div className="panel-header">
        <span className="title">
          {selectedOrganizationId && `${organizationName} Orders`}
        </span>
        {(() => {
          if (typeof organizationOnboardingStep !== 'number') return null;
          if (organizationOnboardingStep >= 3) {
            return <span className="onboarding-step green">Payment Setup</span>;
          }
          return (
            <span className="onboarding-step orange">Payment Not Setup</span>
          );
        })()}
        <span className="grow" />
        <Button
          className="stand-alone-btn"
          onClick={() =>
            setNewOrder({
              mode: 'create',
              id: null,
              items: {
                baseProducts: [{}],
                addOns: [],
                discounts: [],
              },
              organizationId: selectedOrganizationId,
              active: false,
              actionState: 'start',
              errorMessage: '',
              isModalOpen: true,
            })
          }
          variant="secondary"
          icon={<Plus />}
        />
      </div>
      <div className="list-row">
        {[
          { property: '', label: 'ID' },
          { property: '', label: 'Base Item' },
          { property: '', label: 'Add-Ons' },
          { property: '', label: 'Price' },
          { property: '', label: 'SOW' },
          { property: '', label: 'Charged' },
          { property: '', label: 'Active' },
        ].map(({ property, label }) => (
          <div
            className="column-title"
            // onClick={() => updateColumnSort(property)}
            // role="button"
            // tabIndex={0}
            // onKeyDown={() => updateColumnSort(property)}
          >
            {label}
            {/* <SortIcons
              sorting={
                ordersFetch.fetchState === 'success' &&
                columnSort.column === property
                  ? columnSort.order
                  : undefined
              }
            /> */}
          </div>
        ))}
        <div className="column-title" />
      </div>
      <div className="list-body">
        {ordersFetch.fetchState === 'success' && !ordersFetch.data.length && (
          <div className="no-items">No orders yet</div>
        )}
        {ordersFetch?.data?.map((order) => (
          <div
            className="list-row order"
            // disabled={!order.references_count}
            data-active={selectedOrganizationId === order.id}
          >
            <div className="id">{order.id}</div>
            <div className="base-product">{order.baseItem}</div>
            <div className="add-ons">
              {!order.addOns.length && '—'}
              {order.addOns.map((addOn) => (
                <span className="add-on">+{addOn}</span>
              ))}
            </div>
            <div className="price">{formatUsd.format(order.price)}</div>
            <div className="sow">
              {order.sow_url ? (
                <Button
                  icon={<ExternalLink />}
                  variant="secondary"
                  onClick={() => window.open(order.sow_url, '_blank')}
                />
              ) : (
                '—'
              )}
            </div>
            <div className="charged">{formatUsd.format(order.charged)}</div>
            <div className="active">{order.active ? <Check /> : <X />}</div>
            <OrderActionsMenu order={order} setNewOrder={setNewOrder} />
          </div>
        ))}
      </div>
    </StyledOrdersPanel>
  );
};

export default OrdersPanel;
