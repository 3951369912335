import React from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';

const StyledSettingsNavPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
    }
    > .cui-textfield {
      flex-grow: 1;
    }
  }
  > .list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .list-row {
      display: grid;
      grid-template-columns: 1fr 1.5fr 0.5fr;
      grid-gap: 0.75rem;
      border-bottom: 1px solid #e0e0e0;
      padding: 0.5rem 0.75rem;
      align-items: center;
      white-space: nowrap;
      font-size: 14px;
      cursor: pointer;
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &.active {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
      }
    }
  }
`;

const SettingsNavPanel = () => {
  return (
    <StyledSettingsNavPanel className="scripts-nav-panel">
      <div className="panel-header">
        <span className="title">SETTINGS</span>
      </div>
      <div className="list-body">
        <NavLink
          to="/settings/profile"
          className="list-row"
          activeClassName="active"
        >
          <span>My Profile</span>
        </NavLink>
        <NavLink
          to="/settings/team"
          className="list-row"
          activeClassName="active"
        >
          <span>Team</span>
        </NavLink>
        <NavLink
          to="/settings/billing"
          className="list-row"
          activeClassName="active"
        >
          <span>Orders & Billing</span>
        </NavLink>
      </div>
    </StyledSettingsNavPanel>
  );
};

export default SettingsNavPanel;
