import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, Text, Button, Checkbox } from 'colabs-ui-react';
import NotificationTypes from './NotificationTypes';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const StyledNotificationsSubscriptions = styled(Modal)`
  width: 100%;
  > .cui-card {
    width: 100%;
    max-width: 32rem;
    overflow-y: auto;
    max-height: 90vh;
    padding: 1rem;
    > .title {
      margin-bottom: 0.5rem;
    }
    > .subtitle {
      margin-bottom: 1.5rem;
    }
    > .notificationTypes {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 1rem;
      margin-bottom: 2rem;
    }
    > .actions {
      display: flex;
      justify-content: flex-end;
      > :nth-child(1) {
        margin-right: 1rem;
      }
    }
  }
`;

const NotificationsSubscriptions = ({ isOpen, setIsOpen }) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const [subscriptions, setSubscriptions] = useState();
  const [subscriptionsToUpdate, setSubscriptionsToUpdate] = useState([]);
  const user = useSelector((state) => state.user);

  const fetchSubscriptions = async () => {
    try {
      const { data } = await axiosWithAuth().get(
        '/notifications/subscriptions'
      );
      setSubscriptions(data);
    } catch (e) {
      console.log(e);
    }
  };

  const updateNotificationSubscriptions = async () => {
    if (!subscriptionsToUpdate) return;
    await axiosWithAuth().post('/notifications/subscriptions', {
      subscriptions_to_update: subscriptionsToUpdate,
    });
    setSubscriptionsToUpdate([]);
    setIsOpen(false);
  };

  const handleCheckboxClick = (item) => {
    setSubscriptionsToUpdate((e) => {
      if (e.includes(item)) {
        return e.filter((_item) => _item !== item);
      }
      return [...e, item];
    });
  };

  useEffect(() => {
    if (!isOpen) return;
    fetchSubscriptions();
  }, [isOpen]);

  return (
    <StyledNotificationsSubscriptions
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      withCloseButton
      withBackdrop
    >
      <Text size={5} weight="medium" className="title">
        Notification Settings
      </Text>
      <Text className="subtitle">
        Choose when you want to receive notifications.
      </Text>
      <div className="notificationTypes">
        {Object.keys(NotificationTypes)
          .filter(
            (item) =>
              !NotificationTypes[item].colabsOnly || user?.organization_id === 1
          )
          .map((item) => {
            const isSubscribed =
              subscriptions?.findIndex(
                ({ type, is_subscribed }) => type === item && is_subscribed
              ) > -1;
            const isChecked = subscriptionsToUpdate?.includes(item)
              ? !isSubscribed
              : isSubscribed;
            return (
              <>
                <Text>{NotificationTypes[item].label}</Text>
                <Checkbox
                  checked={isChecked}
                  onChange={() => handleCheckboxClick(item)}
                />
              </>
            );
          })}
      </div>
      <div className="actions">
        <Button
          text="Reset"
          variant="secondary"
          onClick={() => setSubscriptionsToUpdate([])}
        />
        <Button
          text="Save"
          onClick={updateNotificationSubscriptions}
          disabled={subscriptionsToUpdate.length === 0}
        />
      </div>
    </StyledNotificationsSubscriptions>
  );
};

NotificationsSubscriptions.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

NotificationsSubscriptions.defaultProps = {};

export default NotificationsSubscriptions;
