import { Divider } from 'colabs-ui-react';
import React from 'react';
import { Home, Users } from 'react-feather';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import permissionBlocks from '../../permissionBlocks';
import DrawerBlock from './DrawerBlock';

const StyledDrawer = styled.nav`
  position: relative;
  width: 64px;
  transition: width 0.2s ease-in-out;
  a {
    text-decoration: none;
    color: black;
  }
  &[data-expanded='true'] {
    width: 240px;
  }
  > .drawer-container:hover,
  &[data-expanded='true'] > .drawer-container {
    width: 240px;
    transition: width 0.2s ease-in-out 0s;
    * {
      transition: all 0.2s ease-in-out 0s !important;
    }
    > .stand-alone-btn {
      > .name {
        margin-left: 6px;
        max-width: 22rem;
      }
    }
    > .permission-block {
      .permission-title {
        > .name {
          margin-left: 6px;
          max-width: 22rem;
        }
      }
      > .pages {
        > .page {
          padding-left: 2rem;
          &[data-important='true'] {
            padding-left: 1.5rem;
            margin: 0 0.5rem;
          }
          > .name {
            margin-left: 6px;
            max-width: 22rem;
          }
        }
      }
    }
  }
  > .drawer-container {
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1000;
    height: 100%;
    border-right: 1px solid #d3d3d3;
    position: absolute;
    width: 100%;
    transition: width 0.2s ease-in-out 0.49s;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 3px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #d3d3d3;
    }
    svg {
      height: 1.5rem;
      width: 1.5rem;
      stroke-width: 2px;
      flex-shrink: 0;
    }
    .stand-alone-btn {
      cursor: pointer;
      list-style: none;
      white-space: nowrap;
      display: flex;
      align-items: center;
      height: 48px;
      margin: 8px 0 8px 2px;
      padding-left: 12px;
      :hover {
        background: #f5f5f5;
      }
      &[data-selected='true'] {
        border-left: 3px solid #1171fa;
        background: #edf4ff;
        cursor: default;
        :hover {
          background: #edf4ff;
        }
      }
      &[data-disabled='true'] {
        opacity: 0.5;
        pointer-events: none;
      }
      > svg {
        margin-left: 6px;
      }
      > .name {
        max-width: 0;
        overflow: hidden;
        transition: all 0.2s ease-in-out 0.49s;
      }
    }
  }
`;

const Drawer = () => {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const userBlocks = Object.keys(permissionBlocks).reduce((acc, block) => {
    if (!user) return acc;
    const rawInstances = permissionBlocks[block].getPaths(user);
    const instances = rawInstances?.map((path, idx) => ({
      ...permissionBlocks[block],
      blockPath: path,
      name: `${permissionBlocks[block].name}${
        rawInstances.length > 1 ? ` #${idx + 1}` : ''
      }`,
    }));
    acc.push(...instances);
    return acc;
  }, []);

  return (
    <StyledDrawer data-expanded={user?.settings.drawer_expanded === 'true'}>
      <div className="drawer-container">
        <NavLink
          to="/home"
          className="stand-alone-btn"
          data-selected={location.pathname.includes('home')}
        >
          <Home />
          <span className="name">Home</span>
        </NavLink>
        {/* <Divider /> */}
        {/* <NavLink
          to="/organization"
          className="stand-alone-btn"
          data-selected={location.pathname.includes('organization')}
        >
          <Users />
          <span className="name">Organization</span>
        </NavLink> */}
        <Divider />
        {userBlocks.map((block) => (
          <>
            <DrawerBlock permissionBlock={block} />
            <Divider />
          </>
        ))}
      </div>
    </StyledDrawer>
  );
};

export default Drawer;
