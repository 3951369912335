import { Button, Chip, Textfield } from 'colabs-ui-react';
import React, { useState } from 'react';
import { MoreVertical, Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import SortIcons from '../SortIcons';
import OrganizationActionsMenu from './OrganizationActionsMenu';

const StyledOrganizationsNavPanel = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  > .organization-list-row,
  > .organization-list-body > .organization-list-row {
    grid-template-columns: 1fr 2fr 2fr 0.75fr;
  }
  &[data-superadmin='true'] {
    > .organization-list-row,
    > .organization-list-body > .organization-list-row {
      grid-template-columns: 1fr 2fr 2fr 0.75fr 0.75fr;
    }
  }
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
    }
    > .cui-textfield {
      flex-grow: 1;
    }
  }
  > .organization-list-row,
  > .organization-list-body > .organization-list-row {
    display: grid;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    .message-icon {
      stroke-width: 1px;
      height: 1.25rem;
      width: 1.25rem;
    }
    &.organization {
      font-size: 14px;
      cursor: pointer;
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &[data-active='true'] {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
      }
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      > .organization-logo {
        height: 3.75rem;
        width: 3.75rem;
        border: 1px solid #d3d3d3;
        border-radius: 0.5rem;
        overflow: hidden;
        object-fit: cover;
      }
      > .active-orders {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
        > .order {
          border-radius: 9999px;
          padding: 0.1rem 0.75rem;
          font-size: 14px;
          background-color: #dceaff;
        }
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
  }
  > .organization-list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
`;

const OrganizationsNavPanel = ({
  organizationsFetch,
  selectedOrganizationId,
  setSelectedOrganizationId,
  setNewOrganization,
}) => {
  const user = useSelector((state) => state.user);
  const isSuperAdmin = user?.organization?.type === 'superadmin';
  // const [columnSort, setColumnSort] = useState({
  //   column: 'name',
  //   order: 'asc',
  // });

  // const updateColumnSort = (column) => {
  //   if (columnSort.column === column) {
  //     setColumnSort({
  //       column,
  //       order: columnSort.order === 'asc' ? 'desc' : 'asc',
  //     });
  //   } else {
  //     setColumnSort({
  //       column,
  //       order: 'asc',
  //     });
  //   }
  // };

  return (
    <StyledOrganizationsNavPanel
      className="organizations-nav-panel"
      data-superadmin={isSuperAdmin}
    >
      <div className="panel-header">
        <span className="title">ALL ORGANIZATIONS</span>
        <Button
          className="stand-alone-btn"
          onClick={() =>
            setNewOrganization({
              mode: 'create',
              id: null,
              name: '',
              type: 'non-agency',
              logo_url: '',
              GA_id: '',
              GSheet_ID: '',
              Exec_Report_Url: '',
              actionState: 'start',
              isModalOpen: true,
            })
          }
          variant="secondary"
          icon={<Plus />}
        />
      </div>
      <div className="organization-list-row">
        <div className="column-title" />
        {[
          { property: 'name', label: 'Name' },
          { property: 'active_orders', label: 'Active Orders' },
          { property: 'agency', label: 'Agency?' },
        ]
          .filter(({ property }) =>
            isSuperAdmin ? true : property !== 'agency'
          )
          .map(({ property, label }) => (
            <div
              className="column-title clickable"
              // onClick={() => updateColumnSort(property)}
              role="button"
              tabIndex={0}
              // onKeyDown={() => updateColumnSort(property)}
            >
              {label}
              {/* <SortIcons
              sorting={
                organizationsFetch.fetchState === 'success' &&
                columnSort.column === property
                  ? columnSort.order
                  : undefined
              }
            /> */}
            </div>
          ))}
        <div className="column-title" />
      </div>
      <div className="organization-list-body">
        {organizationsFetch?.data
          // ?.sort(
          //   (a, b) =>
          //     (columnSort.order === 'asc' ? 1 : -1) *
          //     (a[columnSort.column] > b[columnSort.column] ? 1 : -1)
          // )
          ?.map((organization) => (
            <div
              className="organization-list-row organization"
              // disabled={!organization.references_count}
              data-active={selectedOrganizationId === organization.id}
              data-id={organization.id}
              onClick={() => setSelectedOrganizationId(organization.id)}
              role="button"
              tabIndex={0}
              onKeyDown={() => setSelectedOrganizationId(organization.id)}
            >
              <img
                className="organization-logo"
                src={organization.logo_url || blueImage}
                onError={(e) => {
                  e.target.src = blueImage;
                }}
                alt=""
              />
              <div className="name">{organization.name}</div>
              <div className="active-orders">
                {!organization.baseProducts.length && 'None'}
                {organization.baseProducts.map((baseProduct) => (
                  <span className="order">{baseProduct}</span>
                ))}
              </div>
              {isSuperAdmin && (
                <div className="agency">
                  {organization.type === 'agency' ? '✔' : ''}
                </div>
              )}
              <OrganizationActionsMenu
                organization={organization}
                setNewOrganization={(e) => {
                  setNewOrganization(e);
                  setSelectedOrganizationId(organization.id);
                }}
              />
            </div>
          ))}
      </div>
    </StyledOrganizationsNavPanel>
  );
};

export default OrganizationsNavPanel;
