import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBadge = styled.div`
  border-radius: 0.875rem;
  height: 1.25rem;
  width: 1.25rem;
  color: ${({ theme }) => theme.color.primary.dark};
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
`;

const Badge = ({ text }) => {
  return <StyledBadge className="cui-badge">{text}</StyledBadge>;
};

Badge.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Badge;
