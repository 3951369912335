import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BottomNavButton from './BottomNavButton';
import { mainSections } from '../../staticData';

const StyledNavBar = styled.nav`
  height: 5rem;
  display: grid;
  padding: 0;
  z-index: 1000;
  position: fixed;
  transition: top 1s ease-in-out 1s;
  bottom: 0;
  width: 100vw;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.9);
  .navLink {
    text-decoration: none;
    flex-grow: 1;
  }
  .navLinkActive {
    span {
      color: ${({ theme }) => theme.color.primary.main};
      stroke: ${({ theme }) => theme.color.primary.main};
      * {
        stroke: ${({ theme }) => theme.color.primary.main};
      }
    }
  }
`;

const NavBar = () => {
  const user = useSelector((state) => state.user);
  const sections = mainSections.filter(({ checkPermission }) =>
    checkPermission(user)
  );

  return (
    <StyledNavBar
      style={{ gridTemplateColumns: `repeat(${sections?.length}, 1fr)` }}
    >
      {sections?.map(({ path, icon, name }) => (
        <BottomNavButton
          className="navLink"
          activeClassName="navLinkActive"
          component={NavLink}
          to={path}
          icon={icon}
          text={name}
          disabled={!user?.organization?.payment_setup_complete}
        />
      ))}
    </StyledNavBar>
  );
};

NavBar.propTypes = {};

export default NavBar;
