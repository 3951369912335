import React from 'react';
import styled from 'styled-components';
import MainLayout from '../layouts/Main';

const StyledProductsAndPricing = styled.div``;

const ProductsAndPricing = () => {
  return (
    <MainLayout centered>
      <StyledProductsAndPricing>Products & Pricing</StyledProductsAndPricing>
    </MainLayout>
  );
};

export default ProductsAndPricing;
