/* eslint-disable react/prop-types */
import { Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Comment from './Comment';

const StyledCreativeCommentsPanel = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  height: 100%;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.primary.main};
  }
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &.message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const CreativeCommentsPanel = ({
  commentsFetchingStatus,
  shownComments,
  editComment,
  pinIsAttached,
  pinIsGrabbed,
  shownAdPinnedNotes,
  internalCommentsShown,
  likeComment,
  creativeDeck,
  deleteComment,
  postComment,
  user,
  setActiveAssetIndex,
  cleanUpPin,
  setPinIsGrabbed,
  newPinnedNoteIndex,
  setCommentsWithTextfields,
  videoRef,
  isViewInGrid,
  activeAdIndex,
}) => {
  const genStyledCommentWithTimestamps = (content, createdAt) => {
    if (
      !videoRef?.current ||
      new Date(creativeDeck.updated_at) > new Date(createdAt)
    )
      return content;
    const interleave = ([x, ...xs], ys = []) =>
      x === undefined ? ys : [x, ...interleave(ys, xs)];

    const timestampRegex = /[0-9]{1,2}:+[0-9]{2}/g;
    const splitComment = content.split(timestampRegex);
    const timestamps = Array(...content.matchAll(timestampRegex));
    return interleave(
      splitComment,
      timestamps?.map((timestamp) => (
        <span
          key={timestamp[0]}
          onKeyDown={() => false}
          role="button"
          style={{ cursor: 'pointer' }}
          tabIndex={0}
          onClick={() => {
            videoRef.current.currentTime = timestamp[0]
              .split(':')
              .reduce(
                (acc, cur, index) =>
                  acc + (index === 0 ? Number(cur) * 60 : Number(cur))
              );
          }}
        >
          <Text tag="span" color="blue" weight="medium">
            {timestamp[0]}
          </Text>
        </span>
      ))
    );
  };
  return (
    <StyledCreativeCommentsPanel
      className={commentsFetchingStatus !== 'success' ? 'message' : ''}
    >
      {(() => {
        if (['start', 'loading'].includes(commentsFetchingStatus))
          return (
            <Text size={4} align="center">
              Loading...
            </Text>
          );
        if (commentsFetchingStatus === 'error')
          return (
            <Text size={4} align="center">
              Error loading comments.
            </Text>
          );
        return shownComments?.map((comment) => (
          <Comment
            key={comment.id}
            editComment={editComment}
            comment={comment}
            genStyledCommentWithTimestamps={genStyledCommentWithTimestamps}
            pinIsAttached={pinIsAttached}
            pinIsGrabbed={pinIsGrabbed}
            adPinnedNotes={shownAdPinnedNotes}
            internalCommentsShown={internalCommentsShown}
            likeComment={likeComment}
            adLastUpdate={new Date(creativeDeck?.updated_at)}
            deleteComment={deleteComment}
            postComment={postComment}
            assets={creativeDeck?.ads[activeAdIndex]?.assets}
            avatar={user?.profile_pic_url}
            setActiveAssetIndex={setActiveAssetIndex}
            cleanUpPin={cleanUpPin}
            setPinIsGrabbed={setPinIsGrabbed}
            newPinnedNoteIndex={newPinnedNoteIndex}
            setCommentsWithTextfields={setCommentsWithTextfields}
            videoRef={videoRef}
            dropPinDisabled={isViewInGrid && creativeDeck?.ads?.length > 1}
          />
        ));
      })()}
    </StyledCreativeCommentsPanel>
  );
};

CreativeCommentsPanel.propTypes = {};

CreativeCommentsPanel.defaultProps = {};

export default CreativeCommentsPanel;
