import { Button } from 'colabs-ui-react';
import React from 'react';
import { Check, ChevronRight } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import TempMobileMessage from './TempMobileMessage';

const StyledBriefBars = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1310px;
  padding: 0 0.5rem;
  @media (max-width: 900px) {
    > .brief-stepper-wrapper,
    > .main,
    > .brief-bottom-bar {
      display: none !important;
    }
  }
  > .brief-stepper-wrapper {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    width: 100%;
    > .brief-organization-name {
      align-self: center;
      border-radius: 9999px;
      width: fit-content;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      > .chevron-icon {
        height: 1rem;
        width: 1rem;
        margin: 0 0.5rem;
        /* margin-left: 0.5rem; */
        stroke-width: 1px;
      }
    }
    > .brief-stepper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 3.25rem;
      padding: 0 0.75rem;
      gap: 1rem;
      width: 100%;
      max-width: 900px;
      > .step {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: center;
        /* opacity: 0.5; */
        &[data-button='true'] {
          cursor: pointer;
          &:hover {
            opacity: 0.75;
          }
        }
        &.done {
          > .number {
            background-color: #1171fa;
          }
        }
        &.active {
          /* opacity: 1; */
          > .number {
            background-color: #1171fa;
          }
          > .label {
            color: #1171fa;
          }
        }
        > .number {
          height: 1.375rem;
          width: 1.375rem;
          border-radius: 100rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #888;
          color: white;
          > .check-icon {
            height: 1rem;
            width: 1rem;
          }
        }
      }
      > .line {
        border-bottom: 1px solid #d3d3d3;
        min-width: 1rem;
        flex-grow: 1;
      }
    }
  }
  > .main {
    display: grid;
    grid-gap: 10px;
    width: 100%;
    flex-grow: 1;
    height: 0rem;
    position: relative;
    .templates-reference {
      margin-bottom: -4px;
      object-fit: contain;
      object-position: center;
      overflow: hidden;
      border-radius: 12px;
      width: 100%;
      height: 100%;
      border: 1px solid #d3d3d3;
    }
  }
  > .brief-bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.25rem;
    gap: 0.5rem;
    width: 100%;
  }
`;

const BriefBars = ({
  briefOrganizationName,
  children,
  currentStep,
  mainWrapperProps,
  bottomBarMessage,
  nextBtnProps,
}) => {
  const user = useSelector((state) => state.user);
  const { push } = useHistory();
  const isSuperAdmin = user?.organization?.type === 'superadmin';

  return (
    <StyledBriefBars>
      <TempMobileMessage />
      <div className="brief-stepper-wrapper">
        {['superadmin', 'agency'].includes(user.organization?.type) ? (
          <div
            data-hidden={user.organization_id !== 1}
            className="brief-organization-name"
          >
            BRIEFS{}
            <ChevronRight className="chevron-icon" />
            {briefOrganizationName}
          </div>
        ) : (
          <span />
        )}
        <div className="brief-stepper">
          {[
            'Answer These Questions',
            'Pick Your Trends',
            'Review Your Scripts',
            'Pick Your Actors',
          ].map((item, index) => (
            <>
              <div
                key={item}
                data-button={isSuperAdmin && index >= 2}
                onClick={
                  isSuperAdmin && index >= 2
                    ? () =>
                        push(
                          `${window.location.pathname
                            .split('/')
                            .slice(0, -1)
                            .join('/')}/${
                            ['start', 'trends', 'scripts', 'actors'][index]
                          }`
                        )
                    : undefined
                }
                className={`step${(() => {
                  if (currentStep === index) return ' active';
                  if (currentStep > index) return ' done';
                  return '';
                })()}`}
              >
                <span className="number">
                  {index < currentStep ? (
                    <Check className="check-icon" />
                  ) : (
                    index + 1
                  )}
                </span>
                <span className="label">{item}</span>
              </div>
              {index !== 3 && <div className="line" />}
            </>
          ))}
        </div>
      </div>
      <div className="main" {...mainWrapperProps}>
        {children}
      </div>
      <div className="brief-bottom-bar">
        <span />
        {bottomBarMessage}
        <Button text="Next" {...nextBtnProps} />
      </div>
    </StyledBriefBars>
  );
};

export default BriefBars;
