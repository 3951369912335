export default {
  color: {
    primary: {
      main: '#1171FA',
      light: '#0089FF',
      dark: '#0542F6',
    },
    secondary: {
      main: '#60E2FD',
      light: ' #CFF6FE',
      dark: ' #33CFFC',
    },
    background: 'white',
    blue: {
      main: '#1171ff',
      light: '#edf5ff',
      dark: '#062d65',
    },
    green: {
      main: '#44dc7a',
      light: '#c7ffdb',
      dark: '#144224',
    },
    red: {
      main: '#f55050',
      light: '#ffdbdb',
      dark: '#5b1d1d',
    },
    purple: {
      main: '#ae41e8',
      light: '#ecc7ff',
      dark: '#3a154e',
    },
    orange: {
      main: '#ffa311',
      light: '#ffe9c7',
      dark: '#3a154e',
    },
    gray: {
      main: '#666666',
      light: '#f7f7f7',
      dark: '#434343',
    },
    white: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#f7f7f7',
    },
    black: {
      main: '#000000',
      light: '#434343',
      dark: '#000000',
    },
  },
  font: {
    main: {
      regular: '"TTNormsRegular", "Arial", "Helvetica", "sans-serif"',
      medium: '"TTNormsMedium", "Arial", "Helvetica", "sans-serif"',
      light: '"TTNormsLight", "Arial", "Helvetica", "sans-serif"',
      bold: '"TTNormsBold", "Arial", "Helvetica", "sans-serif"',
      italic: '"TTNormsItalic", "Arial", "Helvetica", "sans-serif"',
    },
  },
  elevation: {
    sm: 'box-shadow: 0px 4px 10px -7px rgba(0,0,0,0.5);',
    md: 'box-shadow: 0px 4px 10px -5px rgba(0,0,0,0.5);',
    lg: 'box-shadow: 0px 20px 20px -15px rgba(0,0,0,0.2);',
  },
  breakpoint: {
    sm: '(max-width: 599px)',
    md: '(max-width: 959px)',
    lg: '(max-width: 1279px)',
    xl: '(max-width: 1919px)',
  },
  scrollbar: `::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
  }
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }`,
};
