import { Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import googleBottom from '../../../assets/images/google_Bottom.png';
import googleTop from '../../../assets/images/google_Top.png';

const StyledGoogle = styled.div`
  @import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌lic,400italic,500,500italic,700,700italic,900italic,900);
  * {
    font-family: Roboto, 'Helvetica Neue', Arial, sans-serif !important;
  }
  background-color: #eef1f2;
  width: 100%;
  height: 100%;
  > .appTop,
  > .appBottom {
    background: #fafafa;
    object-fit: contain;
    width: 100%;
    margin-bottom: -0.4rem;
  }
  > .appTop {
    z-index: 10;
    margin-bottom: 0rem;
  }
  > .viewport {
    height: 80%;
    overflow-y: auto;
    ${({ theme }) => theme.scrollbar}
    .cui-text {
      &.medium {
        font-weight: 700;
      }
    }
    .result {
      &.paid {
        padding: 0.8rem;
        background-color: #fff;
        margin-bottom: 0.5rem;
      }
      &.organic {
        padding: 0.8rem;
        background-color: #fff;
        margin-bottom: 0.5rem;
        opacity: 0.6;
      }
      > .postHeader {
        color: #3d3e40;
        * {
          font-size: 0.75rem;
        }
        margin-bottom: 0.5rem;
      }
      > .headline {
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #1558d6;
      }
      > .description {
        font-size: 0.75rem;
        color: #3c4043;
      }
    }
  }
  > .appBottom {
    position: absolute;
    bottom: 0;
    z-index: 10;
  }
`;

const Google = ({
  selectedCopys,
  socialUsername,
  trimCopy,
  // cta,
  // creative,
  // socialProfilePicture,
  // adTextIsExpanded,
  // setAdTextIsExpanded,
  // isLongerThanLimit,
}) => {
  return (
    <StyledGoogle>
      <img className="appTop" alt="" src={googleTop} />
      <div className="viewport">
        <div>
          <div className="result paid">
            <Text className="postHeader">
              <Text tag="span" className="medium">
                Ad ·{' '}
              </Text>
              <Text tag="span">{socialUsername}</Text>
            </Text>
            <Text className="headline" weight="medium">
              {selectedCopys?.headline1?.content} |{' '}
              {selectedCopys?.headline2?.content}
            </Text>
            <Text className="description">
              {trimCopy(selectedCopys?.description, 'max')}{' '}
              {trimCopy(selectedCopys?.description2, 'max')}
            </Text>
          </div>
          {[...Array(3)].map((_, index) => (
            <div key={index} className="result organic">
              <Text className="headline" weight="medium">
                This is an Organic Result
              </Text>
              <Text className="description">
                {[...Array(6)].map(() => 'This is an organic result. ')}
              </Text>
            </div>
          ))}
        </div>
      </div>
      <img alt="" className="appBottom" src={googleBottom} />
    </StyledGoogle>
  );
};

Google.propTypes = {
  selectedCopys: PropTypes.shape().isRequired,
  socialUsername: PropTypes.string.isRequired,
  trimCopy: PropTypes.func.isRequired,
  // cta: PropTypes.string.isRequired,
  // creative: PropTypes.string.isRequired,
  // socialProfilePicture: PropTypes.string.isRequired,
  // adTextIsExpanded: PropTypes.bool.isRequired,
  // setAdTextIsExpanded: PropTypes.func.isRequired,
  // isLongerThanLimit: PropTypes.func.isRequired,
};

Google.defaultProps = {};

export default Google;
