import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from 'colabs-ui-react';
import React from 'react';
import { MoreVertical } from 'react-feather';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
  border-radius: 0.5rem;
  width: fit-content;
  :focus,
  :active {
    outline: 1px solid #d3d3d3;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 6rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
  &[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const Separator = styled(DropdownMenu.Separator)`
  border-top: 1px solid #dfdfdf;
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const ScriptActionsMenu = ({ script, setNewScript, fetchScripts }) => {
  const { axiosWithAuth } = useAxioswithAuth();
  const handleApprovalChange = async () => {
    try {
      await axiosWithAuth().put(`/scripts/${script.id}/approval`, {
        action: 'complete',
      });
      fetchScripts();
    } catch (error) {
      alert('Error updating script status');
    }
  };

  return (
    <>
      <DropdownMenu.Root>
        <Trigger className="decks-actions-menu">
          <Button icon={<MoreVertical />} variant="tertiary" />
        </Trigger>
        <DropdownMenu.Portal>
          <Content
            side="right"
            align="start"
            onClick={(e) => e.stopPropagation()}
          >
            <Item
              disabled={script.status !== 'approved'}
              onClick={handleApprovalChange}
            >
              Mark as Complete
            </Item>
            <Separator />
            <Item
              onClick={() =>
                setNewScript({
                  mode: 'edit',
                  id: script.id,
                  templateId: script.template_id,
                  errorMessage: '',
                  name: script.name,
                  isModalOpen: true,
                })
              }
            >
              Edit
            </Item>
            <Separator />
            <Item
              onClick={() =>
                setNewScript({
                  mode: 'delete',
                  id: script.id,
                  templateId: script.template_id,
                  name: script.name,
                  errorMessage: '',
                  isModalOpen: true,
                })
              }
            >
              Delete
            </Item>
            <Arrow />
          </Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default ScriptActionsMenu;
