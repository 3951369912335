/* eslint-disable no-param-reassign */
import { Button, Divider, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { CornerDownRight, MoreVertical, ThumbsUp } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { formatNameOrEmail } from '../../utils/helper';
import CommentMenu from './CommentMenu';
import NotesTextField from './CommentTextField';

const StyledComment = styled.div`
  padding: 0.75rem 1rem;
  position: relative;
  > .comment {
    > .head {
      display: flex;
      position: relative;
      margin-bottom: 0.25rem;
      align-items: center;
      > .new {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 0.25rem;
        background-color: #1171fa;
        margin-left: 1rem;
      }
      > .avatar {
        margin-right: 0.5rem;
        height: 1.75rem;
        width: 1.75rem;
        border-radius: 100rem;
        object-fit: cover;
      }
      > :nth-child(2) {
        margin-right: 1rem;
      }
      > .grow {
        flex-grow: 1;
      }
      > .menuContainer {
        position: relative;
      }
    }
    .content {
      white-space: pre-wrap;
      overflow-wrap: break-word;
      display: inline;
    }
    > .actions {
      margin-top: 0.5rem;
      display: flex;
      > .cui-button {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        cursor: pointer;
        color: gray;
        > .icon {
          stroke: gray;
          margin-right: 0.1rem;
          height: 1rem;
          &.active {
            /* stroke: #1372fa; */
            stroke: red;
            border: 1px solid red;
          }
        }
      }
    }
  }
`;

const Comment = ({
  comment,
  postComment,
  deleteComment,
  editComment,
  likeComment,
  parentCommentUser,
  commentAttachment,
  onTextfieldCancel,
}) => {
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const menuContainerRef = useRef();

  return (
    <>
      <StyledComment
        style={{
          paddingLeft: `${
            comment.depth > 1 ? `${(comment.depth + 1) * 1 - 1}rem` : ''
          }`,
        }}
        data-depth={comment.depth}
      >
        {[...Array(comment.depth - 1)].map((_, i) => (
          <div
            key={i}
            className="new"
            style={{
              position: 'absolute',
              left: `${(i + 1) * 1}rem`,
              top: '0.5rem',
              width: '1px',
              height: 'calc(100% - 1rem)',
              backgroundColor: '#d3d3d3',
            }}
          />
        ))}
        {isEditing ? (
          <NotesTextField
            defaultContent={comment.content}
            onCancel={() => {
              setIsEditing(false);
              onTextfieldCancel();
            }}
            placeholder="Leave a comment here..."
            postComment={(content) => {
              editComment(comment?.id, content);
              setIsEditing(false);
            }}
          />
        ) : (
          <>
            <CommentMenu
              isOpen={isMenuOpen}
              setIsOpen={setIsMenuOpen}
              deleteComment={deleteComment}
              setIsEditing={setIsEditing}
              elementRef={menuContainerRef}
              commentId={comment?.id}
            />
            <div elevation={3} className="comment">
              <span className="head">
                <img
                  src={
                    comment.user_profile_pic_url ||
                    comment?.user?.profile_pic_url ||
                    'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x'
                  }
                  className="avatar"
                  alt="avatar"
                  onError={(e) => {
                    e.target.src =
                      'https://gravatar.com/avatar/d559c0b948620a119ac87908bdca93d0?s=400&d=mp&r=x';
                  }}
                />
                <Text weight="medium">
                  {formatNameOrEmail(
                    comment?.user_first_name,
                    comment?.user_last_name,
                    comment?.user_email
                  )}
                </Text>
                <Text color="gray" size={2}>
                  {new Date(comment.created_at).toLocaleDateString('en-EN', {
                    month: 'short',
                    day: '2-digit',
                    year:
                      new Date().getFullYear() !==
                      new Date(comment.created_at).getFullYear()
                        ? 'numeric'
                        : undefined,
                  })}
                </Text>
                <div className="grow" />
                {(comment.user_id === user?.id ||
                  comment?.userId === user?.id) &&
                  comment?.state !== 'deleted' && (
                    <div className="menuContainer" ref={menuContainerRef}>
                      <Button
                        variant="tertiary"
                        size="sm"
                        onClick={() => setIsMenuOpen(true)}
                        icon={<MoreVertical />}
                      />
                    </div>
                  )}
              </span>
              {comment?.state === 'deleted' ||
              comment?.comment_state === 'deleted' ? (
                <Text weight="italic" color="gray">
                  [This comment has been deleted]
                </Text>
              ) : (
                <span>
                  {parentCommentUser && (
                    <Text tag="span" color="blue" weight="medium">
                      @{parentCommentUser}{' '}
                    </Text>
                  )}
                  <Text className="content">
                    {commentAttachment}
                    {comment.content}
                  </Text>
                  {(comment?.state === 'edited' ||
                    comment?.comment_state === 'edited') && (
                    <Text tag="span" color="gray">
                      (Edited)
                    </Text>
                  )}
                </span>
              )}
              {comment?.state !== 'deleted' && (
                <span className="actions">
                  <Button
                    variant="tertiary"
                    onClick={() => likeComment(comment?.id)}
                    icon={
                      <ThumbsUp
                        className={`icon ${
                          comment?.liked_by?.includes(user?.id) ? 'active' : ''
                        }`}
                        color={
                          comment?.liked_by?.includes(user?.id) ? '#1372FA' : ''
                        }
                      />
                    }
                    text={`${comment.liked_by ? comment.liked_by.length : 0}`}
                  />
                  <Button
                    variant="tertiary"
                    icon={<CornerDownRight className="icon" />}
                    text="Reply"
                    onClick={() => {
                      setIsReplying(true);
                    }}
                  />
                </span>
              )}
            </div>
          </>
        )}
      </StyledComment>
      <Divider />
      {isReplying && (
        <>
          <div
            className="section"
            style={{ padding: '1rem', paddingLeft: `${comment.depth * 2}rem` }}
          >
            <NotesTextField
              commentToReply={comment}
              onCancel={() => {
                setIsReplying(false);
                onTextfieldCancel();
              }}
              placeholder="Write a reply..."
              postComment={(content) => {
                postComment(content, comment);
                setIsReplying(false);
              }}
            />
          </div>
          <Divider />
        </>
      )}
      {comment.replies?.map((reply) => (
        <Comment
          key={reply.id}
          comment={reply}
          postComment={postComment}
          deleteComment={deleteComment}
          onTextfieldCancel={onTextfieldCancel}
          parentCommentUser={formatNameOrEmail(
            comment?.user_first_name,
            comment?.user_last_name,
            comment?.user_email
          )}
          editComment={editComment}
          likeComment={likeComment}
        />
      ))}
    </>
  );
};

Comment.propTypes = {
  comment: PropTypes.shape().isRequired,
  postComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  editComment: PropTypes.func.isRequired,
  parentCommentUser: PropTypes.string,
  likeComment: PropTypes.func.isRequired,
  commentAttachment: PropTypes.shape().isRequired,
  onTextfieldCancel: PropTypes.func.isRequired,
};

Comment.defaultProps = {
  parentCommentUser: null,
};

export default Comment;
