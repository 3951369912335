import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PaymentMethodIcon from '../assets/icons/PaymentMethodIcon';
import SortIcons from '../components/SortIcons';
import NewPaymentMethodModal from '../components/billing/NewPaymentMethodModal';
import PaymentMethodActionsMenu from '../components/billing/PaymentMethodActionsMenu';
import SettingsLayout from '../layouts/Settings';
import { useAxioswithAuth } from '../utils/useAxioswithAuth';

const StyledBillingPage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  max-height: 100%;
  overflow: hidden;
  > .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    min-height: 3.25rem;
    padding: 0 0.75rem;
    gap: 0.5rem;
    > .title {
      margin-right: 1rem;
      text-transform: uppercase;
    }
    > .onboarding-step {
      border: 1px solid #d3d3d3;
      border-radius: 9999px;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      &.green {
        border: 1px solid #44dc7a;
        color: #44dc7a;
        /* background-color: #c7ffdb; */
      }
      &.red {
        border: 1px solid #f55050;
        color: #f55050;
        /* background-color: #ffdbdb; */
      }
      &.orange {
        border: 1px solid #ffa311;
        color: #ffa311;
        /* background-color: #ffe9c7; */
      }
    }
    > .grow {
      flex-grow: 1;
    }
  }
  > .list-row,
  > .list-body > .list-row {
    display: grid;
    grid-template-columns: 1fr 6fr 2fr 1fr;
    grid-gap: 0.75rem;
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem 0.75rem;
    align-items: center;
    > .column-title {
      border-radius: 4px;
      padding: 0.25rem 0;
      height: 100%;
      display: flex;
      align-items: center;
      line-height: 1.2;
      &.clickable {
        cursor: pointer;
        :hover {
          background-color: #f5f5f5;
        }
      }
    }
    .message-icon {
      stroke-width: 1px;
      height: 1.25rem;
      width: 1.25rem;
    }
    &.payment-method {
      font-size: 14px;
      border: 3px solid transparent;
      border-bottom: 1px solid #e0e0e0;
      :hover {
        background-color: #f5f5f5;
      }
      &[data-active='true'] {
        border: 3px solid #1171fa;
        border-radius: 0.5rem;
        background-color: #edf4ff;
      }
      &[disabled] {
        cursor: not-allowed;
        > * {
          opacity: 0.5;
        }
        :hover {
          background-color: transparent;
        }
      }
      > .description {
        white-space: nowrap;
      }
      > .default-tag {
        text-transform: lowercase;
        width: fit-content;
        border-radius: 9999px;
        padding: 0.1rem 0.75rem;
        font-size: 14px;
        background-color: rgb(220, 234, 255);
      }
      > .decks-actions-menu {
        justify-self: end;
      }
    }
  }
  > .list-body {
    flex-grow: 1;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #dadce0;
    }
    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
    > .no-items {
      align-items: center;
      padding: 2rem 0;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

const BillingPage = () => {
  const [paymentMethodsFetch, setPaymentMethodsFetch] = useState({
    fetchState: 'start',
    data: [],
  });
  const user = useSelector((state) => state.user);
  const [newPaymentMethod, setNewPaymentMethod] = useState({
    mode: '', // 'create', 'delete'
    id: null,
    name: '',
    actionState: 'start',
    isModalOpen: false,
  });
  const { axiosWithAuth } = useAxioswithAuth();

  const fetchPaymentMethods = async () => {
    setPaymentMethodsFetch((prev) => ({ ...prev, fetchState: 'loading' }));
    try {
      const { data } = await axiosWithAuth().post(
        `/payments/customer_payment_methods`,
        { organization_id: user.organization_id }
      );
      setPaymentMethodsFetch({ fetchState: 'success', data });
    } catch (error) {
      setPaymentMethodsFetch({ fetchState: 'error', data: [] });
    }
  };

  const getPaymentMethodType = (paymentMethod) => {
    if (paymentMethod?.card) {
      return 'card';
    }
    if (paymentMethod?.object === 'bank_account') {
      return 'bank_account';
    }
    if (paymentMethod?.sepa_debit) {
      return 'sepa_debit';
    }
    return null;
  };

  useEffect(() => {
    if (!user || user?.organization_role !== 'admin') return;
    fetchPaymentMethods();
  }, [user]);

  return (
    <SettingsLayout>
      <StyledBillingPage className="payment-method-nav-panel">
        <NewPaymentMethodModal
          newPaymentMethod={newPaymentMethod}
          setNewPaymentMethod={setNewPaymentMethod}
          fetchPaymentMethods={fetchPaymentMethods}
        />
        <div className="panel-header">
          <span className="title">PAYMENT METHODS</span>
          <span className="grow" />
          <Button
            className="stand-alone-btn"
            onClick={() =>
              setNewPaymentMethod({
                mode: 'create', // 'create', 'delete'
                id: null,
                name: '',
                actionState: 'start',
                isModalOpen: true,
              })
            }
            variant="secondary"
            icon={<Plus />}
          />
        </div>
        <div className="list-body">
          {user?.organization_role !== 'admin' && (
            <div className="no-items">
              Only admins can manage payment methods
            </div>
          )}
          {['loading', 'start'].includes(paymentMethodsFetch.fetchState) && (
            <div className="no-items">Loading...</div>
          )}
          {paymentMethodsFetch.fetchState === 'error' && (
            <div className="no-items">Error loading your payment methods</div>
          )}
          {paymentMethodsFetch.fetchState === 'success' &&
            !paymentMethodsFetch.data.length && (
              <div className="no-items">No payment methods yet</div>
            )}
          {paymentMethodsFetch?.data?.map((paymentMethod) => (
            <div
              className="list-row payment-method"
              // disabled={!paymentMethod.references_count}
            >
              {
                {
                  card: (
                    <>
                      <PaymentMethodIcon brand={paymentMethod.card?.brand} />
                      <span className="description">
                        {paymentMethod.card?.brand.toUpperCase()} ending in{' '}
                        {paymentMethod.card?.last4} | Exp:{' '}
                        {paymentMethod.card?.exp_month
                          ?.toString()
                          .padStart(2, 0)}
                        /{paymentMethod.card?.exp_year?.toString().slice(2)}
                      </span>
                    </>
                  ),
                  bank_account: (
                    <>
                      <div>
                        <svg
                          className="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg"
                          height="32"
                          width="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M0 0h32v32H0z" fill="#e3e8ee" />
                            <path
                              d="M7.274 13.5a1.25 1.25 0 0 1-.649-2.333C7.024 10.937 10.15 9.215 16 6c5.851 3.215 8.976 4.937 9.375 5.167a1.25 1.25 0 0 1-.65 2.333zm12.476 10v-8.125h3.75V23.5H25a1 1 0 0 1 1 1V26H6v-1.5a1 1 0 0 1 1-1h1.5v-8.125h3.75V23.5h1.875v-8.125h3.75V23.5z"
                              fill="#697386"
                            />
                          </g>
                        </svg>
                      </div>
                      <span className="description">
                        {paymentMethod?.bank_name?.toUpperCase()} ending in{' '}
                        {paymentMethod.last4}
                      </span>
                    </>
                  ),
                  sepa_debit: (
                    <>
                      <span className="paymentMethodIcon noLogo">
                        <span size={1}>SEPA</span>
                      </span>
                      <span className="description">
                        {paymentMethod.sepa_debit?.bank_code?.toUpperCase()}{' '}
                        ending in {paymentMethod.sepa_debit?.last4}
                      </span>
                    </>
                  ),
                }[getPaymentMethodType(paymentMethod)]
              }
              {paymentMethod.default_payment_method ? (
                <span className="default-tag">Default</span>
              ) : (
                <span />
              )}
              <PaymentMethodActionsMenu
                paymentMethod={paymentMethod}
                setNewPaymentMethod={setNewPaymentMethod}
              />
            </div>
          ))}
        </div>
      </StyledBillingPage>
    </SettingsLayout>
  );
};

export default BillingPage;
