import { Checkbox, Divider, Dropdown, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Filter } from 'react-feather';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 0.25rem;
  z-index: 1 !important;
  .toggle {
    @media ${({ theme }) => theme.breakpoint.md} {
      padding: 0.5rem !important;
    }
  }
  .select {
    max-height: 14rem;
    overflow-y: auto !important;
    ${({ theme }) => theme.scrollbar}
    > .option {
      > .cui-checkbox {
        width: 100%;
        > .label {
          width: 100%;
          cursor: pointer;
          display: flex;
          align-items: center;
          > .starIcon {
            height: 1rem;
            width: 1rem;
            padding-bottom: 0.1rem;
          }
        }
      }
      .color {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 0.25rem;
        margin-right: 0.5rem;
      }
    }
  }
`;

const OrganizationsDropdown = ({
  organizations,
  filteredOrganizations,
  setFilteredOrganizations,
}) => {
  const { width } = useWindowDimensions();
  const dividerIndex = organizations?.findIndex(
    ({ active_client }) => !active_client
  );
  const onCheckboxChange = (organization) => {
    setFilteredOrganizations((prev) => {
      if (prev?.includes(organization.id)) {
        return prev?.filter((id) => id !== organization.id);
      }
      return [...prev, organization.id];
    });
  };
  return (
    <StyledDropdown
      chevronShown={width > 959}
      align="right"
      toggle={width > 959 ? <Text>Filter by Organization</Text> : <Filter />}
      items={[
        <div
          onKeyDown={() => false}
          role="button"
          tabIndex={0}
          className="customOption"
          onClick={() => setFilteredOrganizations([])}
        >
          <Text noWrap>Show All</Text>
        </div>,
        ...(organizations
          ?.slice(0, dividerIndex)
          .map((organization) => (
            <Checkbox
              checked={filteredOrganizations?.includes(organization.id)}
              onChange={() => onCheckboxChange(organization)}
              label={organization.name}
            />
          )) || []),
        <Divider />,
        ...(organizations
          ?.slice(dividerIndex)
          .map((organization) => (
            <Checkbox
              checked={filteredOrganizations?.includes(organization.id)}
              onChange={() => onCheckboxChange(organization)}
              label={organization.name}
            />
          )) || []),
      ]}
    />
  );
};

OrganizationsDropdown.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  filteredOrganizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setFilteredOrganizations: PropTypes.func.isRequired,
};

OrganizationsDropdown.defaultProps = {};

export default OrganizationsDropdown;
