import { Button, Chip, Media, Text } from 'colabs-ui-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import blueImage from '../../assets/images/BlueImage.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { approvalStatus } from '../../staticData';

const StyledCreativeDeckRow = styled.div`
  padding: 0.75rem;
  display: grid;
  grid-template-columns: min-content 2fr min-content min-content;
  align-items: center;
  cursor: pointer;
  grid-gap: 1rem;
  border: 1px solid #ffffffff;
  border-radius: 0.675rem;
  /* &:focus {
            outline: none;
          } */
  @media ${({ theme }) => theme.breakpoint.sm} {
    grid-template-columns: min-content 1fr min-content;
  }
  &.selected {
    border: 1px solid #2980ff;
  }
  > .deckThumbnail {
    height: 3rem;
    width: 3rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
  .circle {
    height: 0.5rem;
    width: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 0.25rem;
  }
  > :last-child {
    margin-right: 0;
  }
`;

const CreativeDeckRow = ({
  deck,
  changeDeckPreview,
  selectedDeck,
  handleDeckKeyDown,
  isColabsMember,
}) => {
  const { width } = useWindowDimensions();
  return (
    <StyledCreativeDeckRow
      className={`deck${
        selectedDeck?.type === 'creative' && deck.id === selectedDeck?.deck.id
          ? ' selected'
          : ''
      }`}
      role="button"
      tabIndex={0}
      onKeyDown={handleDeckKeyDown}
      onClick={() => changeDeckPreview(deck, 'creative')}
    >
      <Media
        className="deckThumbnail"
        src={deck?.ads[0]?.assets[0]?.url}
        alt=""
        onError={(e) => {
          e.target.src = blueImage;
        }}
      />
      <Text>
        {isColabsMember && `${deck?.organization?.organization_name} • `}
        {deck?.title} - Creative Deck R{`${deck?.round}`}
      </Text>
      {(() => {
        const approvalState = approvalStatus.find(({ text }) =>
          !deck.published_at
            ? text === 'Not Published'
            : text === deck?.approval_state
        );
        if (width < 1279) return approvalState?.icon;
        return (
          <Chip
            color={
              ['Ready for Launch', 'Downloaded'].includes(approvalState?.text)
                ? approvalState?.chipColor
                : undefined
            }
            icon={approvalState?.icon}
            label={approvalState?.text}
          />
        );
      })()}
      <Link to={`ad-approval/creative/${deck?.id}`} className="reviewDeck">
        <Button variant="secondary" text="Review" />
      </Link>
    </StyledCreativeDeckRow>
  );
};

CreativeDeckRow.propTypes = {};

CreativeDeckRow.defaultProps = {};

export default CreativeDeckRow;
