import * as Slider from '@radix-ui/react-slider';
import React from 'react';
import styled from 'styled-components';

const StyledSlider = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2rem;
  > .value {
    width: 2.25rem;
    border-radius: 0.25rem;
    white-space: nowrap;
    &.max {
      text-align: right;
    }
  }
  > .Slider {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 100%;
    height: 20px;
    flex-grow: 1;
    .SliderTrack {
      background-color: #d3d3d3;
      position: relative;
      flex-grow: 1;
      border-radius: 9999px;
      height: 2px;
    }
    .SliderRange {
      position: absolute;
      background-color: #1171fa;
      border-radius: 9999px;
      height: 100%;
    }
    .SliderThumb {
      display: block;
      width: 20px;
      height: 20px;
      background-color: white;
      border: 1px solid #d3d3d3;
      /* box-shadow: 0 2px 10px gray; */
      border-radius: 10px;
      &:hover {
        background-color: #d3d3d3;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 5px gray;
      }
    }
  }
`;

const CustomSlider = ({
  field,
  filters,
  setFilters,
  max,
  min = 0,
  formatter = (e) => e,
}) => {
  return (
    <StyledSlider>
      <span className="value">{formatter(filters[field]?.min)}</span>
      <Slider.Root
        defaultValue={[filters[field]?.min, filters[field]?.max]}
        max={max}
        min={min}
        step={1}
        minStepsBetweenThumbs={1}
        className="Slider"
        onValueChange={(e) => {
          setFilters((prev) => ({
            ...prev,
            [field]: { min: e[0], max: e[1] },
          }));
        }}
      >
        <Slider.Track className="SliderTrack">
          <Slider.Range className="SliderRange" />
        </Slider.Track>
        <Slider.Thumb className="SliderThumb" aria-label="Volume" />
        <Slider.Thumb className="SliderThumb" aria-label="Volume" />
      </Slider.Root>
      <span className="value max">{formatter(filters[field]?.max)}</span>
    </StyledSlider>
  );
};

export default CustomSlider;
