import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from 'colabs-ui-react';
import React from 'react';
import { MoreVertical } from 'react-feather';
import styled from 'styled-components';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
  border-radius: 0.5rem;
  width: fit-content;
  :focus,
  :active {
    outline: 1px solid #d3d3d3;
  }
  > .want-this {
    width: 100%;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 8rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
  &[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Separator = styled(DropdownMenu.Separator)`
  border-top: 1px solid #dfdfdf;
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const DeckActionsMenu = ({
  deckId,
  deckName,
  deckOrganizationId,
  openDeck,
  setNewDeck,
  openDeckDisabled,
}) => {
  return (
    <>
      <DropdownMenu.Root>
        <Trigger className="decks-actions-menu">
          <Button icon={<MoreVertical />} variant="tertiary" />
        </Trigger>
        <DropdownMenu.Portal>
          <Content
            side="right"
            align="start"
            onClick={(e) => e.stopPropagation()}
          >
            <Item
              disabled={openDeckDisabled}
              onClick={() => (openDeckDisabled ? null : openDeck(deckId))}
            >
              Open
            </Item>
            <Separator />
            <Item
              onClick={() =>
                setNewDeck({
                  mode: 'edit',
                  id: deckId,
                  name: deckName,
                  organization_id: deckOrganizationId,
                  starter_deck_id: null,
                  isModalOpen: true,
                })
              }
            >
              Edit
            </Item>
            <Separator />
            <Item
              onClick={() =>
                setNewDeck({
                  mode: 'duplicate',
                  name: '',
                  starter_deck_id: deckId,
                  organization_id: null,
                  starter_deck_name: deckName,
                  id: null,
                  isModalOpen: true,
                })
              }
            >
              Duplicate
            </Item>
            <Separator />
            <Item
              onClick={() =>
                setNewDeck({
                  mode: 'delete',
                  id: deckId,
                  name: deckName,
                  organization_id: null,
                  starter_deck_id: undefined,
                  isModalOpen: true,
                })
              }
            >
              Delete
            </Item>
            <Arrow />
          </Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default DeckActionsMenu;
