import { Button, Textfield } from 'colabs-ui-react';
import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { fetchUserData } from '../actions';
import Alert from '../components/presentational/Alert';
import useWindowDimensions from '../hooks/useWindowDimensions';
import StartLayout from '../layouts/Start';
import { api } from '../utils/useAxioswithAuth';

const StyledSignUp = styled.form`
  width: 100%;
  > .cui-text:nth-child(1) {
    margin-top: 2rem;
  }
  > .title {
    font-family: ${({ theme }) => theme.font.main.medium};
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  > .form {
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.75rem;
    > * {
      grid-column: 1 / 3;
    }
    > :nth-child(1) {
      grid-column: 1 / 2;
      @media ${({ theme }) => theme.breakpoint.md} {
        grid-column: 1 / 3;
      }
    }
    > :nth-child(2) {
      grid-column: 2 / 3;
      @media ${({ theme }) => theme.breakpoint.md} {
        grid-column: 1 / 3;
      }
    }
    svg {
      cursor: pointer;
    }
  }
  > .cui-button {
    width: 50%;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  > .alternatives {
    display: flex;
    margin-bottom: 2rem;
    text-align: center;
    flex-direction: column;
    font-size: 14px;
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.color.primary.main};
    }
  }
`;

const SignUp = () => {
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    organization_name: '',
    password: '',
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [pwcheck, setPwcheck] = useState('');
  const [alertProps, setAlertProps] = useState();

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (pwcheck !== form.password) {
      setAlertProps({
        message: 'Passwords do not match',
        id: Math.random(),
        severity: 'warning',
      });
      return;
    }
    try {
      await api.post(`/users/with_organization`, {
        first_name: form.first_name.trim(),
        last_name: form.last_name.trim(),
        email: form.email.toLowerCase().trim(),
        organization_name: form.organization_name.trim(),
        password: form.password.trim(),
      });
      dispatch(fetchUserData());
    } catch (error) {
      // console.log(error.response);
      if (error.response.status === 406) {
        setAlertProps({
          message: 'Invalid Email',
          id: Math.random(),
          severity: 'warning',
        });
        return;
      }
      if (error.response.data.error) {
        setAlertProps({
          message: error.response.data.error,
          id: Math.random(),
          severity: 'warning',
        });
        return;
      }
      setAlertProps({
        message: 'Invalid password, please try again',
        id: Math.random(),
        severity: 'warning',
      });
    }
  };

  return (
    <StartLayout centered>
      <Alert
        text={alertProps?.message}
        open={alertProps?.id}
        severity={alertProps?.severity}
      />
      <StyledSignUp height={height} name="signup" onSubmit={handleSubmit}>
        <h1 className="title">Create an Account</h1>
        <div className="form">
          <Textfield
            elevated
            label="First Name"
            name="first_name"
            value={form.first_name}
            type="text"
            placeholder="Joe"
            required
            maxLength={50}
            onChange={handleChange}
          />
          <Textfield
            elevated
            label="Last Name"
            name="last_name"
            type="text"
            value={form.last_name}
            placeholder="Doe"
            required
            maxLength={50}
            onChange={handleChange}
          />
          <Textfield
            elevated
            label="Email"
            type="email"
            name="email"
            value={form.email}
            placeholder="joe@acme.com"
            required
            maxLength={50}
            onChange={handleChange}
          />
          <Textfield
            elevated
            label="Company"
            type="organization_name"
            name="organization_name"
            value={form.organization_name}
            placeholder="Acme Inc."
            required
            maxLength={50}
            onChange={handleChange}
          />
          <Textfield
            elevated
            label="Password"
            required
            minLength={8}
            helperText="min 8 characters &nbsp;|&nbsp; a letter &nbsp;|
                &nbsp; a number"
            maxLength={32}
            title="Must contain at least 8 characters, including letters and numbers."
            pattern={
              '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};:\'"\\\\|,.<>?~]*$'
            }
            onChange={(e) => setPwcheck(e.target.value)}
            icon={
              passwordShown ? (
                <EyeOff onClick={() => setPasswordShown(false)} />
              ) : (
                <Eye onClick={() => setPasswordShown(true)} />
              )
            }
            iconPos="right"
            type={passwordShown ? 'text' : 'password'}
          />
          <Textfield
            elevated
            label="Confirm Password"
            name="password"
            value={form.password}
            required
            minLength={8}
            maxLength={32}
            onChange={handleChange}
            pattern={
              '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};:\'"\\\\|,.<>?~]*$'
            }
            icon={
              passwordShown ? (
                <EyeOff onClick={() => setPasswordShown(false)} />
              ) : (
                <Eye onClick={() => setPasswordShown(true)} />
              )
            }
            iconPos="right"
            type={passwordShown ? 'text' : 'password'}
          />
        </div>
        <Button type="submit" text="Sign Up" size="md" />
        <div className="alternatives">
          <span className="noAccount">
            Already have an account?{' '}
            <Link
              className="navLink"
              activeClassName="navLinkActive"
              to="/login"
            >
              Log in
            </Link>
          </span>
        </div>
      </StyledSignUp>
    </StartLayout>
  );
};

export default SignUp;
