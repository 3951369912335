import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { logoutUser } from '../../actions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getDisplayName } from '../../utils/helper';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
  border-radius: 0.5rem;
  width: fit-content;
  :focus,
  :active {
    outline: none;
  }
  border: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  cursor: pointer;
  .avatar {
    border-radius: 100rem;
    width: 2rem;
    height: 2rem;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 6rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
  &[data-disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const Separator = styled(DropdownMenu.Separator)`
  border-top: 1px solid #dfdfdf;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
  margin: 0;
  > * {
    margin: 0;
  }
`;

const AvatarDropdown = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  async function logout() {
    dispatch(logoutUser());
  }

  return (
    <>
      <DropdownMenu.Root>
        <Trigger>
          <>
            <img
              className="avatar"
              onError={(e) => {
                e.target.src =
                  'https://esri-es.github.io/arcgis-experts/assets/imgs/no_avatar.jpg';
              }}
              alt="avatar"
              src={
                user?.profile_pic_url ||
                'https://esri-es.github.io/arcgis-experts/assets/imgs/no_avatar.jpg'
              }
            />
            {width > 600 && user && <span>{getDisplayName(user, 16)}</span>}
          </>
        </Trigger>
        <DropdownMenu.Portal>
          <Content
            // open from the bottom align to left
            side="bottom"
            align="start"
            onClick={(e) => e.stopPropagation()}
          >
            <StyledLink to="/settings" variant="body1">
              <Item>Settings</Item>
            </StyledLink>
            <Separator />
            <StyledLink to="/login" variant="body1" onClick={logout}>
              <Item>Logout</Item>
            </StyledLink>
            <Arrow />
          </Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default AvatarDropdown;
