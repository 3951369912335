import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from 'colabs-ui-react';
import React, { useEffect, useState } from 'react';
import { MoreVertical } from 'react-feather';
import styled from 'styled-components';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Trigger = styled(DropdownMenu.Trigger)`
  padding: 0;
  border: none;
  background: none;
  border-radius: 0.5rem;
  width: fit-content;
  :focus,
  :active {
    outline: 1px solid #d3d3d3;
  }
  > .want-this {
    width: 100%;
  }
`;

const Content = styled(DropdownMenu.Content)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 8rem;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const SubContent = styled(DropdownMenu.SubContent)`
  background: white;
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #dfdfdf;
  min-width: 8rem;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Item = styled(DropdownMenu.Item)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
`;

const SubTrigger = styled(DropdownMenu.SubTrigger)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  outline: none;
  &:hover {
    background: #f5f5f5;
  }
`;

const Separator = styled(DropdownMenu.Separator)`
  border-top: 1px solid #dfdfdf;
`;

const Arrow = styled(DropdownMenu.Arrow)`
  fill: white;
  stroke: #a8a8a8;
  stroke-width: 1px;
`;

const AllReferencesActionsMenu = ({
  referenceId,
  viewReference,
  setNewReference,
}) => {
  const [allDecks, setAllDecks] = useState({
    fetchStatus: 'start',
  });
  const [isOpen, setIsOpen] = useState(false);
  const { axiosWithAuth } = useAxioswithAuth();

  const fetchOrganizations = async () => {
    setAllDecks((prev) => ({ ...prev, fetchStatus: 'loading' }));
    try {
      const { data } = await axiosWithAuth().get('/reference-tool/decks/list');
      setAllDecks({ fetchStatus: 'success', data });
    } catch (e) {
      setAllDecks((prev) => ({ ...prev, fetchStatus: 'error' }));
    }
  };

  const assignReferenceToDeck = async (selectedDeckId) => {
    try {
      await axiosWithAuth().post('/reference-tool/reference-deck', {
        reference_id: referenceId,
        deck_id: selectedDeckId,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    fetchOrganizations();
  }, [isOpen]);

  return (
    <DropdownMenu.Root onOpenChange={(open) => setIsOpen(open)}>
      <Trigger className="decks-actions-menu">
        <Button icon={<MoreVertical />} variant="tertiary" />
      </Trigger>
      <DropdownMenu.Portal>
        <Content
          side="right"
          align="start"
          onClick={(e) => e.stopPropagation()}
        >
          <Item onClick={viewReference}>View</Item>
          <Separator />
          <DropdownMenu.Sub>
            <SubTrigger>Add to Deck</SubTrigger>
            <DropdownMenu.Portal>
              <SubContent sideOffset={2} alignOffset={-5}>
                {allDecks.fetchStatus === 'success' &&
                  allDecks.data.map((deck) => (
                    <>
                      <Item
                        key={deck.id}
                        onClick={() => assignReferenceToDeck(deck.id)}
                      >
                        {deck.name}
                      </Item>
                      <Separator />
                    </>
                  ))}
              </SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
          <Separator />
          <Item
            onClick={() => {
              setNewReference({
                mode: 'delete',
                id: referenceId,
                deck_id: null,
                url: '',
                isModalOpen: true,
              });
            }}
          >
            Delete
          </Item>
          <Arrow />
        </Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default AllReferencesActionsMenu;
