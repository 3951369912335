import { Button, Card, Media, Text } from 'colabs-ui-react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Download, Loader } from 'react-feather';
import { Portal } from 'react-overlays';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { approvalStatus } from '../../staticData';
import { useAxioswithAuth } from '../../utils/useAxioswithAuth';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
`;

const StyledBar = styled(Card)`
  grid-column: 1/2;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr max-content 1fr;
  width: 100%;
  padding: 0.5rem 1rem;
  display: grid;
  > :nth-child(1) {
    text-decoration: none;
    align-self: center;
    justify-self: left;
  }
  > :nth-child(3) {
    align-self: center;
    justify-self: right;
  }
  > :last-child {
    margin-right: 1rem;
  }
  > .nav {
    display: flex;
    > .cui-button {
      > .icon {
        margin: 0;
      }
    }
    > .previewer {
      position: relative;
      > .allCreatives {
        margin: 0 1rem;
        @media ${({ theme }) => theme.breakpoint.lg} {
          margin: 0 0.5rem;
        }
      }
      > .ads {
        background-color: white;
        border: 1px solid #dbdbdb;
        max-width: 53rem;
        overflow-x: auto;
        z-index: 1000;
        top: calc(100% + 0.25rem);
        ${({ theme }) => theme.elevation.md}
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0.675rem;
        position: absolute;
        display: none;
        padding: 0.675rem;
        ${({ theme }) => theme.scrollbar}
        @media ${({ theme }) => theme.breakpoint.lg} {
          max-width: 40rem;
        }
        @media ${({ theme }) => theme.breakpoint.md} {
          max-width: 27rem;
        }
        &.open {
          display: flex;
        }
        > .ad {
          cursor: pointer;
          position: relative;
          padding: 0.675rem 0.675rem 0 0.675rem;
          border: 1px solid #ffffff00;
          &.selected {
            > .cui-media {
              border: 3px solid #6da7fd;
            }
          }
          > .cui-media {
            border: 3px solid #e9eaeb;
            height: 11.25rem;
            width: 11.25rem;
            border-radius: 0.5rem;
            object-fit: cover;
            ${({ theme }) => theme.elevation.md}
          }
          > .chip {
            position: absolute;
            display: flex;
            padding: 0.25rem 0.75rem;
            border-radius: 2rem;
            background-color: #f7f7f7;
            bottom: 2rem;
            align-items: center;
            left: 50%;
            transform: translateX(-50%);
            > .dot {
              height: 0.5rem;
              width: 0.5rem;
              border-radius: 0.25rem;
              margin-right: 0.25rem;
            }
          }
        }
      }
    }
  }
`;

const Bar = ({
  ads,
  activeAdIndex,
  changeAd,
  dataFetchingStatus,
  creativeDeck,
}) => {
  const { width } = useWindowDimensions();
  const [downloadingDeckStatus, setDownloadingDeckStatus] = useState('start');
  const [isPreviewerOpen, setIsPreviewerOpen] = useState(false);
  const { axiosWithAuth } = useAxioswithAuth();

  const downloadCreativeDeck = async () => {
    try {
      setDownloadingDeckStatus('loading');
      const { data } = await axiosWithAuth().post(
        '/cms/downloadDeck',
        { creative_deck_id: creativeDeck.id },
        { responseType: 'blob', timeout: 30000 }
      );
      saveAs(data, `${creativeDeck.title}-r${creativeDeck.round}.zip`);
      setDownloadingDeckStatus('success');
    } catch (err) {
      setDownloadingDeckStatus('error');
    }
  };

  return (
    <StyledBar>
      <Button
        text={(() => {
          if (width < 959) return '';
          if (downloadingDeckStatus === 'loading') return 'Downloading...';
          return 'Download';
        })()}
        variant="secondary"
        icon={downloadingDeckStatus === 'loading' ? <Loader /> : <Download />}
        disabled={
          dataFetchingStatus !== 'success' ||
          downloadingDeckStatus === 'loading'
        }
        onClick={downloadCreativeDeck}
      />
      <div className="nav">
        <Button
          variant="tertiary"
          onClick={() => changeAd(-1)}
          icon={<ChevronLeft />}
          disabled={ads.length === 0}
          iconPos="left"
          text={width < 959 ? '' : 'Previous'}
        />
        <div className="previewer">
          <Button
            variant="secondary"
            // text={width > 959 ? 'All Creatives' : undefined}
            // icon={width <= 959 ? <Grid /> : <ChevronDown />}
            text="All Creatives"
            className="allCreatives"
            onClick={() => setIsPreviewerOpen(!isPreviewerOpen)}
            iconPos="right"
          />
          <div className={`ads ${isPreviewerOpen ? 'open' : ''}`}>
            <Portal container={document.querySelector('body')}>
              <Backdrop
                hidden={!isPreviewerOpen}
                onClick={() => setIsPreviewerOpen(false)}
              />
            </Portal>
            {ads.map((ad, index) => (
              <div
                key={ad.id}
                className={`ad ${index === activeAdIndex ? 'selected' : ''}`}
                role="button"
                onKeyDown={() => false}
                tabIndex={0}
                onClick={() => changeAd(null, index)}
              >
                <Media src={ad?.assets[0]?.url} srcSuffix="#t=2" />
                <span className="chip">
                  <div
                    className="dot"
                    style={{
                      backgroundColor: approvalStatus.find(
                        ({ text }) => text === ad.approval_state
                      )?.color,
                    }}
                  />
                  <Text noWrap>{ad?.approval_state?.split(' ')[0]}</Text>
                </span>
                <Text align="center">
                  {`${index + 1}`}. {ad?.name}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <Button
          variant="tertiary"
          iconPos="right"
          disabled={ads.length === 0}
          onClick={() => changeAd(1)}
          icon={<ChevronRight />}
          text={width < 959 ? '' : 'Next'}
        />
      </div>
      {dataFetchingStatus === 'success' && (
        <Text size={3} weight="medium" style={{ color: '#373A3E' }} noWrap>
          {width > 959 ? 'Creative' : ''} {activeAdIndex + 1}{' '}
          {width > 959 ? ' of ' : '/'} {ads?.length}
        </Text>
      )}
    </StyledBar>
  );
};

Bar.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.shape()),
  activeAdIndex: PropTypes.number.isRequired,
  dataFetchingStatus: PropTypes.string.isRequired,
  changeAd: PropTypes.func.isRequired,
};

Bar.defaultProps = {
  ads: [],
};

export default Bar;
