import { Button, Textfield } from 'colabs-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CoEfficientLabsIcon from '../assets/icons/CoefficientLabs';
import LinearGradient from '../components/presentational/LinearGradient';
import StartLayout from '../layouts/Start';
import { api } from '../utils/useAxioswithAuth';

const StyledForgotPassword = styled.div`
  > .link {
    margin-bottom: 2.5rem;
    display: block;
  }
  > svg {
    height: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    .logo {
      fill: url(#linearGradient);
    }
  }
  > .title {
    font-family: ${({ theme }) => theme.font.main.medium};
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  > .description {
    margin: 0;
    margin-bottom: 1rem;
  }
  > form {
    display: grid;
    grid-gap: 1rem;
    .recaptcha-wrapper {
      > div > div {
        margin: 0 auto;
      }
    }
  }
`;

const ForgotPassword = () => {
  const { status } = useParams();
  const { push } = useHistory();
  const [form, setForm] = useState({ email: '' });
  const [submitError, setSubmitError] = useState(false);
  const recaptchaRef = useRef();

  function handleChange(e) {
    if (submitError) setSubmitError(null);
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      if (!recaptchaRef.current.getValue().length) {
        throw new Error('Captcha Error');
      }
      await api.post('/auth/reset-password', {
        email: form.email.toLowerCase().trim(),
      });
      push('/forgot-password/sent');
    } catch (error) {
      if (error.message === 'Captcha Error') {
        setSubmitError('Please verify you are not a robot');
        return;
      }
      // TODO: validate it's actually a 404 error
      setSubmitError("Email doesn't exist");
    }
  }

  useEffect(() => {
    if (status && status !== 'sent') {
      push('/forgot-password');
    }
  }, [status]);

  return (
    <StartLayout>
      <StyledForgotPassword>
        <Link to="/login" className="link">
          <Button
            variant="secondary"
            text="Go Back"
            icon={<ArrowLeft />}
            size="sm"
          />
        </Link>
        <LinearGradient color1="#1171FF" color2="#61E3FF" id="linearGradient" />
        <CoEfficientLabsIcon />
        {status && (
          <>
            <h1 className="title">Instruction Sent</h1>
            <p className="description">
              If an account with that email exists, we sent you an email with
              instructions on how to reset your password.
            </p>
          </>
        )}
        {!status && (
          <>
            <h1 className="title">Reset Password</h1>
            <p className="description">
              Please provide your email connected to your account.
            </p>
            <form onSubmit={onSubmit}>
              <Textfield
                error={submitError}
                helperText={submitError}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email"
                type="email"
                id="email"
                autoComplete="email"
                onChange={handleChange}
                value={form.email}
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                className="recaptcha-wrapper"
                sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                text="Send Instruction"
              />
            </form>
          </>
        )}
      </StyledForgotPassword>
    </StartLayout>
  );
};

export default ForgotPassword;
